import React, {useEffect, useRef, useState} from 'react';
import './TwoWayMessageInput.scss'
import {Button} from '@material-ui/core';
import TwoWayConversationService from '../../Store/TwoWayMessageService/TwoWayConversationService';
import {environment} from '../../environment';
import TwoWayModal from '../TwoWayModal/TwoWayModal';
import TwoWayEmoji from '../TwoWayEmoji/TwoWayEmoji';
import TwoWayAttachment from '../TwoWayAttachment/TwoWayAttachment';
import TwoWayImageCard from '../TwoWayImageCard/TwoWayImageCard';
import Logger from '../../Store/Logger/Logger';
import Spinner from '../Spinner/Spinner';
import {FiAlertTriangle} from 'react-icons/all';
import UserService from '../../Store/UserService';
import {v4 as uuidv4} from 'uuid';
import TwoWayConversationTemplate from '../TwoWayConversationTemplate/TwoWayConversationTemplate';

const TwoWayMessageInput = ({
                              isLocationAware,
                              isMMSEnabled,
                              conversation,
                              selectedLocation,
                              refreshConversationAfterSend,
                              refreshConversationListAfterSend,
                              newMessage,
                              unknownPatient,
                              smsToList,
                              smsToListComplete,
                              hasFiles,
                              setHasFiles,
                              retrySendingMessage,
                              toggleRetrySendingMessage,
                              messageTemplates
                            }) => {

  const characterCountMax = environment.messageCountMax;
  let body = 'Currently unable to send message';
  const limitReachedBody = 'Reached the maximum number (3) of allowed attachments'
  const uniqueFileBody = '1 or more files attached are not unique'
  const fileSizeLimitBody = 'Selected files must be less than 5MB'
  const callTwoWayModalAlert = useRef(null);
  const callTwoWayModalAttachmentLimit = useRef(null);
  const callTwoWayModalUniqueFile = useRef(null)
  const callTwoWayModalFileSizeLimit = useRef(null)
  const twoWayMsgInput = useRef();
  const [characterCount, setCharacterCount] = useState(0);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [sendDisabled, setSendDisabled] = useState(true);
  const [hasTempates, setHasTempates] = useState(true);
  const [sendingmessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState();
  const callTwoWayModalMessageError = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [filesData, setFilesData] = useState([]);
  const [imageCards, setImageCards] = useState(null);
  const [patient, setCurrentPatient] = useState(null);
  const [placeholder, setPlaceholder] = useState('Message ');
  const maxFiles = 3;

  const toggleTwoWayModalAttachmentLimit = () => {
    callTwoWayModalAttachmentLimit.current.setAlert()
  }

  const maxMmsLimit = () => {
    if (filesData.length === maxFiles) {
      callTwoWayModalAttachmentLimit.current.setAlert()
      return true;
    }
    return false;
  }

  const calculateTotalFileSize = (newFile) => {
    let totalFileSize = 0;
    for (let i = 0; i < filesData.length; i++) {
      totalFileSize += filesData[i].size;
    }
    return totalFileSize += newFile.size;
  }

  const handleSelectCard = (e) => {
    if (!maxMmsLimit()) {
      e.id = uuidv4();
      setFilesData(filesData => [...filesData, e])
    }
  }

  const handleRemoveCard = (e) => {
    let filteredArray = filesData.filter(element => element.id !== e.id)
    setFilesData(filteredArray)
  }
  useEffect(() => {
    let flag = false;
    if(messageTemplates){
      if(messageTemplates.templates) {
        if(messageTemplates.templates.length > 0) {
          flag = true;
        }
      }
    }
    setHasTempates(flag);
  }, [messageTemplates]);
  

  useEffect(() => {
    if (retrySendingMessage) {
      setSendingMessage(true);
      setSendDisabled(true);
    } else {
      setSendingMessage(false);
      setSendDisabled(false);
    }
  }, [retrySendingMessage]);

  useEffect(() => {
    setCharacterCount(message.length);
    if (message.length === 0) {
      setSelectedTemplate(null);
    }
  }, [message]);

  useEffect(() => {
    setImageCards(filesData.map((item, index) => {
      return (
        <TwoWayImageCard attachment={item} key={index} removeCard={handleRemoveCard}/>
      )
    }))
  }, [smsToList, filesData, hasFiles, characterCount]);

  useEffect(() => {
    updateSendButton();
  }, [characterCount, filesData]);

  useEffect(() => {
    setMessage('')
    setFilesData([])
    setCurrentPatient( (!conversation.firstName) ? 'patient' : conversation.firstName + ' ' + conversation.lastName);
    if(patient){
      setPlaceholder('Message '); // patient
    } else {
      setPlaceholder('Message');
    }

  }, [conversation]);

  useEffect(() => {
    setMessage('');
  }, []);

  const handleKeyUp = (e) => {
    // e.target.value = e.target.value.replace(/<.*>/, '');
    setMessage(e.target.value);
  }

  const handleChange = (e) => {
    // e.target.value = e.target.value.replace(/<.*>/,'');
    setMessage(e.target.value);
  }

  const handleKeyDown = (e) => {
    if (characterCount > 0 && e.key === 'Enter') {
      //validateSend();
      setupSending();
    }
  }

  const handleEmojiAdded = (e) => {
    setMessage(message + '' + e + ' ');
    twoWayMsgInput.current.focus();
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      if (Array.from(e.target.files).length + filesData.length > maxFiles) {
        e.preventDefault();
        toggleTwoWayModalAttachmentLimit();
        return;
      }

      setHasFiles(true)

      var i;
      for (i = 0; i < e.target.files.length; i++) {
        try {
          let reader = new FileReader();
          let file = e.target.files[i]
          reader.onloadend = () => {
            let newFile = {
              "fileName": file.name,
              "id": uuidv4(),
              "size": file.size,
              "url": reader.result,
            }

            if (filesData.some(e => e.url === newFile.url)) {
              callTwoWayModalUniqueFile.current.setAlert();
              return;
            }

            // if total file size > 5MB
            if (calculateTotalFileSize(newFile) > environment.attachmentFileSizeMax) {
              callTwoWayModalFileSizeLimit.current.setAlert();
              return;
            }

            setFilesData(filesData => [...filesData, newFile])
          }
          reader.readAsDataURL(file)
        }
        catch {
        }
      }
    }
    e.target.value = ''
  }

  const handleMessageTemplateAdded = (template) => {
    setSelectedTemplate(template)
    setMessage(template.body)
  }

  const updateSendButton = () => {
    setSendDisabled(false);

    if (characterCount >= characterCountMax) {
      setError('You have reached the your message limit.');
    } /*else {
      if(characterCount > environment.messageCountMax / 2){
        setError('Messages over 160 characters will be split');
      } else {
        setError(null);
      }
    }*/

    if (characterCount <= 0 && filesData.length === 0) {
      setSendDisabled(true);
    }

    if (characterCount > 1 && newMessage && smsToList) {
      if (smsToList.length <= 0) {
        setSendDisabled(true);
      }
    }
  }

  const setupSending = () => {
    setError(null);
    if (newMessage === 'true') {
      validateSendNewConversation();
    } else {
      validateSend();
    }
  }

  const validateSend = async () => {
    let mymessage = message;
    const maxLength = environment.messageCountMax;
    let message1 = mymessage;
    let message2;
    if (mymessage.length > maxLength) {
      message1 = mymessage.substr(0, maxLength)
      message2 = mymessage.substr(message1.length, mymessage.length)
    }
    await handleSend(message1).then((resp) => {
      if (!message2) {
        handleSendComplete(resp);
      }
    });

    if (message2) {
      await handleSend(message2).then((resp2) => {
        handleSendComplete(resp2);
      })
    }
  }

  const handleSendComplete = (resp) => {
    refreshConversationListAfterSend();
    refreshConversationAfterSend();
    setSendingMessage(false);
    setMessage('');
    setSelectedTemplate(null);
    setFilesData([]);
    if (
      conversation.messageStatus === environment.viewStatus.unanswered ||
      conversation.messageStatus === environment.viewStatus.unread ||
      conversation.conversationStatus === environment.viewStatus.unanswered
    ) {
      conversation.status = environment.viewStatus.read;
      conversation.viewStatus = environment.viewStatus.read;
      updateMessageStatus(conversation)
    }

    if (resp.data) {
      if (resp.data.errorMessage) {
        callTwoWayModalAlert.current.setAlert();
      } else {
        if (newMessage === 'true') {
          smsToListComplete();
        }
      }
    }
  }

  const convertImageUrls = (images) => {
    let imageUrls = []
    images.forEach(e => {
      let contentType = e.url.match(/data:(image\/.*);base64,/);
      let newUrl = e.url.toString().replace(contentType[0],'')
      let obj = {
        ContentType: contentType[1],
        Data: newUrl
      }
      imageUrls.push(obj)
    })
    return imageUrls;
  }

  const handleSend = async (_message) => new Promise((resolve, reject) => {
      let imageArray = convertImageUrls(filesData)

      const payload = {
        SessionId: UserService.getSessionId(),
        ConversationKey: conversation.convKey,
        Message: _message,
        PublicImageUrls: [],
        PrivateImages: imageArray,
        IsTemplateMessage: selectedTemplate === null ? false : true,
        TemplateType: selectedTemplate?.type ?? "",
      }
      setSendingMessage(true);
      setSendDisabled(true);
      toggleRetrySendingMessage(true);
      return TwoWayConversationService.postMessage(payload)
        .then( (resp) => {
          resolve(resp);
        })
        .catch( (err) => {
          setSendingMessage(false);
          toggleRetrySendingMessage(false);
          Logger.printLog('postMessage', payload, 'error');
          reject(err)
        })
  })

  const validateSendNewConversation = async () => {
    let mymessage = message;
    const maxLength = environment.messageCountMax;
    let message1 = mymessage;
    let message2;
    if (mymessage.length > maxLength) {
      message1 = mymessage.substr(0, maxLength)
      message2 = mymessage.substr(message1.length, mymessage.length)
    }
    if (smsToList) {
      let count = 0;
      let flag = false;
      smsToList.map((patient) => {
        if (patient) {
          handleSendNewConversation(message1, patient.PMSPatientID, patient.addKey, patient.cellPhone)
            .then((resp) => {
              if (!message2) {
                if (!flag && count >= smsToList.length) {
                  handleSendComplete(resp);
                  setHasTempates(false);
                }
              }
            }).catch((err) => {
            flag = true;
            setMessageError('Unable to send a message to ' + patient.cellPhone + '');
            callTwoWayModalMessageError.current.setAlert();
            setSendingMessage(false);
          })

          if (message2) {
            handleSendNewConversation(message2, patient.PMSPatientID, patient.addKey, patient.cellPhone).then((resp2) => {
              if (!flag && count >= smsToList.length) {
                handleSendComplete(resp2);
                setHasTempates(false);
              }
            }).catch((err) => {
              flag = true;
              setMessageError('Unable to send a message to ' + patient.cellPhone + '');
              callTwoWayModalMessageError.current.setAlert();
              setSendingMessage(false);
            })
          }
        } else {
          flag = true;
          setMessageError('Unable to send a message to ' + patient.cellPhone + '');
          callTwoWayModalMessageError.current.setAlert();
          setSendingMessage(false);
        }
        count++;

      })
    }
  }

  const handleSendNewConversation = async (_message, pmsPatientID, addKey, smsTo) => new Promise((resolve, reject) => {
    let imageArray = convertImageUrls(filesData)
    const payload = {
      SessionId: UserService.getSessionId(),
      locKey: isLocationAware ? selectedLocation.id : 0,
      PMSPatientId: pmsPatientID,
      AddKey: addKey,
      Message: _message,
      SMSTo: smsTo,
      PublicImageUrls: [],
      PrivateImages: imageArray,
      IsTemplateMessage: selectedTemplate === null ? false : true,
      TemplateType: selectedTemplate?.type ?? "",
    }
    setSendingMessage(true);
    setSendDisabled(true);
    return TwoWayConversationService.postNewConversation(payload)
      .then((resp) => {
        let flag = false;
        if (resp.data) {
          if (resp.data.errors) {
            if (resp.data.errors.length >= 1) {
              flag = true;
            }
          }
        }
        if (!flag) {
          resolve(resp);
        } else {
          reject(resp.data.errors[0])
        }
      })
      .catch((err) => {
        setSendingMessage(false);
        Logger.printLog('postMessage', payload, 'error');
        reject(err)
      })
  })

  const updateMessageStatus = (_payload) => {
    const payload = {
      sessionId: UserService.getSessionId(),
      conversationKey: _payload.convKey,
      viewStatus: environment.viewStatus.read
    }
    TwoWayConversationService.updateMessageStatus(payload)
      .then((resp) => {
      })
      .catch((err) => {
        Logger.printLog('updateMessageStatus', err, 'error');
      })
  }
  
  return (
    <>
      <TwoWayModal ref={callTwoWayModalMessageError} heading='Unable to send message' body={messageError} button1='' button2='Ok' />
      <TwoWayModal ref={callTwoWayModalAlert} heading='Unable to send.' body={body} button1='' button2='Ok' />
      <TwoWayModal ref={callTwoWayModalAttachmentLimit} heading='Attachment limit reached' body={limitReachedBody} button1='' button2='Ok' />
      <TwoWayModal ref={callTwoWayModalUniqueFile} heading='Non-unique file' body={uniqueFileBody} button1='' button2='Ok' />
      <TwoWayModal ref={callTwoWayModalFileSizeLimit} heading='File size limit' body={fileSizeLimitBody} button1='' button2='Ok' />

      {error && <span className='txt--small conversation-input-error txt--alert'> <FiAlertTriangle/> {error}</span>}
      <div className='conversation-input-holder'>
        <div className='conversation-input'>
          <div className='conversation-input-textarea'>
            {filesData != [] && filesData.length > 0 ? (
              <div className="conversation-files-holder"> {imageCards} </div>) : <> </>}
            <textarea style={{
              width: '100%',
              height: '100%',
              resize: 'none', 
              boxSizing: 'border-box',
              outline:'none'
              }} disabled={sendingmessage} onKeyDown={handleKeyDown} ref={twoWayMsgInput} maxLength={characterCountMax} value={message} placeholder={placeholder} onChange={handleChange} onKeyUp={handleKeyUp}></textarea>
          </div>
        </div>
        <div className='conversation-input-error-holder'>

          <div className='conversation--action-container'>
            <div className='conversation--action-container--buttons'>

              <div className='conversation--action--item'><span className='txt--small conversation-input-counter'>{characterCount} / {characterCountMax}</span></div>

              <div className='conversation--action--item--left'>
                {hasTempates && <div className='conversation--action--item conversation--action--item--relative'><TwoWayConversationTemplate messageTemplates={messageTemplates} templateAdded={handleMessageTemplateAdded}/></div>}
                <div className='conversation--action--item conversation--action--item--relative'><TwoWayEmoji emojiAdded={handleEmojiAdded}/></div>
                {(isLocationAware && isMMSEnabled) && <div className='conversation--action--item'> <TwoWayAttachment attachments={filesData} handleFileUpload={handleFileUpload} handleSelectCard={handleSelectCard} toggleModal={toggleTwoWayModalAttachmentLimit}></TwoWayAttachment></div>}
                {!sendingmessage && <div className='conversation--action--item'><Button disabled={sendDisabled} variant="contained" color='primary' disableElevation onClick={setupSending}>Send</Button></div>}
                {sendingmessage && <span className='conversation-input-spinner'><Spinner/></span>}
              </div>
            </div>
          </div>




        </div>
      </div>
    </>
  )
}

export default TwoWayMessageInput;




