import React from "react";
import {
  ContentContainer,
  WebApptWeekTable,
} from "@essilorditac/customer-dash-ui";
import "./AppointmentsReports.scss";
import { getCoverKey, getJwt } from "./ReportsUtils";

export const WebScheduled = () => {
  
 const coverKey = getCoverKey();
 const authorization = getJwt();
  
    const AUTH_AVAILABLE = coverKey && authorization;

    return(
        <ContentContainer>
        {AUTH_AVAILABLE && (
            <WebApptWeekTable
            coverKey={coverKey}
            authorization={authorization}
            />
        )}
      </ContentContainer>
    )

}
