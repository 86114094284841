import React from 'react';

export class SessionContextObject {
    _clients = {
        valueUpdated: {}
    };

    _value = {
        sessionID: '',
        sessionUpdated: new Date()
    };

    get SessionID() {
        return this._value;
    }

    SetValue(value) {
        this._value = {...value};
        this.UpdateClients_ValueUpdated();
    }

    UpdateClients_ValueUpdated() {
        Object.values(this._clients.valueUpdated).forEach(x => {
            x(this._value);
        });
    };

    Subscribe_ValueUpdated(componentId, delegate) {
        if (delegate) this._clients.valueUpdated[componentId] = delegate;
    }
}

const SessionContext = React.createContext(new SessionContextObject())
export default SessionContext;
