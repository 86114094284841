import React, { useEffect } from 'react';
import {PageTitle, CANCELLATION_TABS,TabsList,Tab} from "@essilorditac/customer-dash-ui";
import {CancellationTableRec} from "./CancellationTableRec";
import {CancellationTableAppt} from "./CancellationTableAppt";
import UserService from '../../Store/UserService';
import Constants from '../../constants';


const CancellationPage = () => {
    const [tab, setTab] = React.useState(0);
    const [allowCancellationMenu] = React.useState(UserService.checkElementAccess(Constants.cancellationMenu));

    useEffect( async() => {    
        UserService.checkUser();
    },[]);

    return(
        <>
            {allowCancellationMenu ?
            <div className='appt-reports-page-wrapper'>
                <div className="web-appointments-wrapper">
                    <div className="page-picker-containers">
                        <PageTitle title="Cancellations" />
                    </div>
                <TabsList
                    tabs={CANCELLATION_TABS}
                    selected={tab}
                    setTab={setTab}
                >
                    <Tab index={0} selected={tab}>

                    <CancellationTableAppt />
                    </Tab>
                    <Tab index={1} selected={tab}>
                    <CancellationTableRec />
                    </Tab>
                </TabsList>
            </div>
        </div>
        :
        <div className="loading-error undefined">
          Not enough user rights
        </div>
        }
    </>
    )
}

export default CancellationPage;