/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
  /**
   * Represents the current user key.
   */
  get currUserKey() {
    return 'cusr';
  },
  /**
   * Represents the Session ID.
   */
  get sessionID() {
    return 'sessionID';
  },
  get userRole() {
    return 'userRole';
  },
  get userType() {
    return 'userType';
  },
  get familyName() {
    return 'family_name';
  },
  get locations() {
    return 'locations';
  },
  get currentLocKeys() {
    return 'currentLocKeys';
  },
  get locationAware() {
    return 'locationAware';
  },
  get allLocations() {
    return 'allLocations';
  },
  get encryptedElements() {
    return 'encElements';
  },
  /**
   * Represents the Session ID key.
   */
  get sessionIDKey() {
    return 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';
  },
  get userDataKey() {
    return 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata';
  },

  get key() {
    return 'key';
  },
  get applicationId() {
    return 3;
  },
  /*
  Element Names
  */
  get dashboardMenu() {
    return 'Dv4Communications';
  },
  get reportsMenu() {
    return 'Dv4Reports';
  },
  get new2WayMenu() {
    return 'Dv4Chat';
  },
  get registrationFormMenu() {
    return 'Dv4PatientForms';
  },
  get onlineBookingSetupMenu() {
    return 'Dv4OBS';
  },
  get insuranceTypeMenu() {
    return 'Dv4InsuranceTypeMenu';
  },
  get appointmentTypeMenu() {
    return 'Dv4AppointmentTypeMenu';
  },
  get patientInsuranceMenu() {
    return 'Dv4PatientInsuranceMenu';
  },
  get generalMenu() {
    return 'Dv4GeneralMenu';
  },
  get scheduleMenu() {
    return 'Dv4ScheduleMenu';
  },
  get scriptGeneratorMenu() {
    return 'Dv4ScriptGeneratorMenu';
  },
  get recallOverviewMenu() {
    return 'Dv4RecallOverview';
  },
  get recallDetailMenu() {
    return 'Dv4RecallDetailMenu';
  },
  get remindersMenu() {
    return 'Dv4RemindersMenu';
  },
  get appointmentsMenu() {
    return 'Dv4AppointmentsMenu';
  },
  get cancellationMenu() {
    return 'Dv4CancellationMenu';
  },
  get ordersMenu() {
    return 'Dv4OrdersMenu';
  },
  get visitsMenu() {
    return 'Dv4VisitsMenu';
  },
  get purchaseRecaptureMenu() {
    return 'Dv4PurchaseRecaptureMenu';
  },
  get sidebarMenu() {
    return 'Dv4Dashboard';
  },
  get headerKey() {
    return 'x-frm-adm';
  },
  get headerValue() {
    return 'bb2910fb-1a14-4df9-9483-e25e40387163';
  },
  get jwtKey() {
    return 'x-jwt';
  },
};

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;
