import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { useFiltersContext, useRequest, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

import InsuranceTypes_List from './InsuranceTypes_List';
import InsuranceTypes_Form from './InsuranceTypes_Form';
import FormContainer from '../../FormContainer';

export default function InsuranceTypes() {
    var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');

    const componentId = "insuranceTypes";
    const ui = useUIContext(componentId);
    const filtersContext = useFiltersContext(componentId, async (context) => {
        //On filters changed
        
        if (cover != context.cover) {
            await initInsuranceData();
            await initInsuranceData_Onboarding();
        }

        setCover(context.cover);
      });
    const Request = useRequest(); 

    const [cover, setCover] = useState(filtersContext.Values.cover);
    const [listRefresh, setListRefresh] = useState(new Date());

    const initInsuranceData = async () => {
        ui.ShowOverlay();

        let model = {
            coverKey: coverKey
        };

        let res = await Request.modInitInsuranceData(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Insurance data was successfully initialized. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const initInsuranceData_Onboarding = async () => {
        ui.ShowOverlay();

        let model = {
            coverKey: coverKey
        };

        let res = await Request.modInitInsurance_FromOnboardingAsync(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Onboarding settings were successfully added to Insurance data. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const saveData = async (model, callback) => {
        ui.ShowOverlay();    
        let res = await Request.modSaveInsuranceData(model);

        if (res.success) {
            setListRefresh(new Date());

            let d = res.data;
            let summary = "";
            if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing record(s)";
            if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new record(s)"; 
            
            ui.ShowSnackbar("Insurance type was successfully saved" + summary);
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();

        callback && callback();
    };

    return (<>
        {cover ? (<>
            <Box p={2} bgcolor="background.paper">
                <FormContainer title="Add Insurance Type">
                    <InsuranceTypes_Form saveData={saveData} />
                </FormContainer>
                <InsuranceTypes_List refresh={listRefresh} saveData={saveData} />
            </Box>
        </>) : (<>
            {ui.uiJSX.loadingJSX}
        </>)}        
    </>);
}