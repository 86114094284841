import React, { useState, useEffect,useContext } from 'react';
import MaterialTable from "material-table";

import { arraySort } from '../../../../ContextLib/Core/coreLib';
import { useFiltersContext, useRequest, useResponsiveUtil, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

import './AppointmentTypes.scss';

import { CommonObjects } from '../../OnlineBookingSetupMaster';
import { useTour } from '@reactour/tour'
import TourSteps from '../../../../Store/TourSteps'
import {TourServiceContext} from '../../../../Store/TourServiceProvider'

export default function AppointmentTypes_List({refresh}) {    
    var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');
    const { setIsOpen, setCurrentStep ,setSteps } = useTour();

    let componentId = "appointmentTypes_List";
    const filtersContext = useFiltersContext(componentId, ({locKey, acctKey}) => {
        //On filters changed
        setLocKey(locKey);
        setAcctKey(acctKey);
      });

      const {
        setCurrentTab,
        setTourNext
       } = useContext(TourServiceContext);        
    const ui = useUIContext(componentId);
    const Request = useRequest();    

    const [locKey, setLocKey] = useState(filtersContext.Values.locKey);
    const [acctKey, setAcctKey] = useState(filtersContext.Values.acctKey);
    const [data, setData] = useState([]);

    useEffect(() => {        
        getData();        
    }, [locKey, acctKey, refresh]);

    const getData = async () => {
        ui.ShowOverlay();

        let {locations, providers} = filtersContext.Values;

        locations  = locations.filter(x => x.coverKey == coverKey); 
        providers = providers.filter(x => x.coverKey == coverKey); 

        let arrLocKeys = [], arrAcctKeys = [];

        if (locKey !== 0) {
            arrLocKeys.push(locKey.toString());
        } else {
            if (locations.length > 0) {
                locations.map((loc) => {
                    arrLocKeys.push(loc.locKey.toString())
                });
            }
        }

        if (acctKey !== 0) {
            arrAcctKeys.push(acctKey.toString());
        } else {
            if (providers.length > 0) {
                providers.map((prov) => {
                    arrAcctKeys.push(prov.acctKey.toString())
                });
            }
        }

        let model = {
            acctKeys: arrAcctKeys,
            locKeys: arrLocKeys
        };

        let res = await Request.getAllAppointmentTypes(model);

        if (res.success) {
            let d = res.data?.length > 0 ? arraySort(res.data, "appointmentType") : [];
            setData(d);
            // set Tours
            //setIsOpen(false)
            setSteps(TourSteps.tourStepsAppointment)    
            setCurrentTab(componentId)        
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const deleteData = async (model) => {        
        ui.ShowOverlay();    
        let res = await Request.deleteAppointmentType({
            taskKey: model.taskKey
        });

        if (res.success) {
            getData();
            
            ui.ShowSnackbar("Appointment type was successfully deleted");
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };


    const saveData = async (model) => {
        ui.ShowOverlay();    

        const m = {
            taskKey: model.taskKey,
            locKey: locKey,
            acctKey: acctKey,
            appointmentType: model.appointmentType,
            length: Number(model.length),
            slotSize: Number(model.slotSize),
            cost: Number(model.cost)
        };

        let res = await Request.updateAppointmentType(model);

        if (res.success) {
            getData();

            ui.ShowSnackbar("Appointment type was successfully saved");
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const columns = [
        { field: 'appointmentType', title: 'Appointment Type', width: 195, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <CommonObjects.HtmlTooltip title={dataRow.appointmentType} placement="top-start"><span>{dataRow.appointmentType}</span></CommonObjects.HtmlTooltip>}},
        { field: 'length', title: 'Length', width: 100, lookup: {5: '5', 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40', 45: '45', 50: '50', 55: '55', 60: '60', 90: '90', 120: '120'}},
        { field: 'slotSize', title: 'Booking Increment', width: 175, lookup: {5: '5', 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40', 45: '45', 50: '50', 55: '55', 60: '60', 90: '90', 120: '120'}},
        { field: 'schedule', title: 'Schedule', width: 225, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <CommonObjects.HtmlTooltip title={dataRow.schedule} placement="top-start"><span>{dataRow.schedule}</span></CommonObjects.HtmlTooltip>}},
    ]; 

    const bp = useResponsiveUtil();

    return (<>
        <div data-tut="reactour__getappointment_general_notes">
        <MaterialTable
            height="200"
            columns={columns}
            data={data}
            options={{...CommonObjects.TableOptions, actionsColumnIndex: bp.xs.window ? 0 : -1}}
           editable={{
                onRowUpdate: saveData
            }} 
          /*    actions={[{
                icon: 'delete',
                tooltip: 'Delete',
                onClick: async (event, model) => {
                    if (data.length <= 1) {
                        ui.MessageBox("Refer to our Support team to delete last appointment type. You can edit this appointment type", "Delete last appointment type");
                        return;
                    }
                    
                    let confirmed = await ui.ConfirmBox("Removing appointment types may affect Online Web Scheduler. Please reach out to the support team for assistance. Are you sure you want to delete this record?");
                    if (confirmed != 1) return;
            
                    deleteData(model);
                }
            }]} */
        /> 
        </div>
    </>);
}