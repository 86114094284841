import React, {useContext, useEffect, useState} from 'react';
import './TwoWayList.scss';
import {Button} from '@material-ui/core';
import TwoWayCard from '../TwoWayCard/TwoWayCard';
import TwoWayConversation from '../TwoWayConversation/TwoWayConversation';
import {Route} from 'react-router';
import '../TwoWayCard/TwoWayCard.scss';
import {FiRotateCw, FiSearch, FiXCircle, MdMessage, MdSearch} from 'react-icons/all';
import '../TwoWayModal/TwoWayMultiRecipientModal.scss';
import {environment} from '../../environment';
import TwoWayCardSkeleton from '../TwoWayCard/TwoWayCardSkeleton';
import Spinner from '../Spinner/Spinner';
import TwoWayConversationNew from '../TwoWayConversationNew/TwoWayConversationNew';
import {LocationServiceContext} from '../../Store/LocationsService/LocationServiceProvider';
import LocationService from '../../Store/LocationsService/LocationsService';
import { useHistory } from 'react-router';
import Utils2 from '../../utils';
import {FiChevronDown} from 'react-icons/all';
import SidebarMenuLocations from '../../Components/SidebarMenuLocations/SidebarMenuLocations';
import Storage from '../../storage';
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const TwoWayList = ({
                      flagToRefreshConversation,
                      conversations,
                      conversationClick,
                      confirmMessageStatus,
                      windowHeight,
                      refreshList,
                      filterList,
                      loadMoreToList,
                      newMessage,
                      currentPage,
                      loadingConversations,
                      searchList,
                      lastRefreshed,
                      loadingInterval,
                      showRefreshButton,
                      filtersCount,
                      selectedLocation,
                      hasFiles,
                      setHasFiles
                    }) => {
  const history = useHistory();

  const {selectedLocationKeys} = useContext(LocationServiceContext);
  let windowHeightAdjustment = 175;
  const [countUnread, setCountUnread] = useState(0);
  const [countUnanswered, setCountUnanswered] = useState(0);
  const [countFlagged, setCountFlagged] = useState(0);
  // const [messageListScrollHeight, setMessageListScrollHeight] = useState();
  const [conversation, setConversation] = useState();
  const [patient, setPatient] = useState({firstName: '', lastName: '', smsTo: ''});
  const [currentFilter, setCurrentFilter] = useState(-1);
  const [enableSearch, setEnableSearch] = useState(false);
  const [cards, setCards] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [rawConversations, setRawConversations] = useState([]);
  const [unknownPatient, setUnknownPatient] = useState(false);
  const [sortFilter, setSortFilter] = useState(null);
  let sortFilterInterval = 5000;
  const isLocationAware = LocationService.getLocationAware()
  let currentSelectedLocation = selectedLocation

  //Start: Location drop down variables
  const [isLocationAwareState, setIsLocationAwareState] = useState(false);
  const [coverKey, setCoverKey] = useState(0);
  const {
    showModal,
    setShowModal,
    gCoverKey,
    allowedCoverKeysForRegForms,
    allowedCoverKeysForBookings
   } = useContext(LocationServiceContext);
  const [showLocations, setShowLocations] = useState(false);
  const [hasMultipleLocations, setHasMultipleLocations] = useState(false);
  const [primaryLocationInitial, setPrimaryLocationInitial] = useState('S');
  const [primaryLocation, setPrimaryLocation] = useState('Select Location(s)');
  const [allowedCoverKeysBookings, setAllowedCoverKeysBookings] = useState([]);
  const [allowedCoverKeysRegForms, setAllowedCoverKeysRegForms] = useState([]);
  const [familyName, setFamilyName] = useState(null);
  
  const toggleIsLocationAware = () => {
    setIsLocationAwareState(true);
  };
  const toggleHasMultipleLocations = () => {
    setHasMultipleLocations(true);
  };
  const putPrimaryLocation = (location) => {
    if (location) {
      setPrimaryLocationInitial(location.name.substr(0, 1));
      setPrimaryLocation(location.name.substr(0, location.name.indexOf('-')));
    } else {
      setPrimaryLocation('Select Location(s)');
      setPrimaryLocationInitial('S');
    }
  };
  //End: Location drop down variables

  const randomizeFunEmoji = () => {
    return funEmoji[Math.floor(Math.random() * funEmoji.length)];
  }
  const funEmoji = ['✌️', '🥳', '😇', '🤯', '😜', '🤓', '😎', '🤩', '🤗', '🚀', '🌟'];
  const [funEmojiItem, setFunEmojiItem] = useState();

  const handleConversationClick = (item) => {
    setConversation(item);
    setPatient({firstName: item.firstName, lastName: item.lastName, smsTo: item.smsTo});
    conversationClick(item);
  }

  const handleConfirmMessageStatus = (viewStatus, _conversation) => {
    confirmMessageStatus(viewStatus, _conversation);
  }

  const handleFilterList = (viewStatus) => {
    setCurrentFilter(viewStatus);
  }

  const checkLocations = () => {
    if (selectedLocationKeys?.length === 0 || selectedLocationKeys === null) {
      setShowModal(true);
    } else {
      newMessage();
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      filterList(currentFilter, sortFilter);
    }, sortFilterInterval);

    return () => clearTimeout(timer);

  }, [sortFilter, currentFilter]);

  useEffect(() => {
    if (selectedLocationKeys) {
      let match = selectedLocationKeys.find((x) => x?.id === selectedLocation?.id);
      if (!match) {
        currentSelectedLocation = {};
        if (window.location.pathname === '/communications/chat/new') {
          history.push('/communications/chat/');
        }
      }
    }
  }, [selectedLocationKeys]);


  useEffect(() => {
    setCountFlagged(filtersCount.flagged)
    setCountUnread(filtersCount.unRead)
    setCountUnanswered(filtersCount.unAnswered)
  }, [filtersCount]);


  useEffect(() => {
    setFunEmojiItem(randomizeFunEmoji)
    setRawConversations(rawConversations)
    if (conversations) {
      if (conversations.length >= 1 && !loadingConversations) {
        setCards(conversations.map((item, key) => {
          return <TwoWayCard conversation={item} status={item.status} key={key}
                             conversationClick={handleConversationClick}
                             confirmMessageStatus={handleConfirmMessageStatus}/>
        }))
      } else if (!loadingConversations) {
        setCards(noMessagesFound)
      } else {
        setCards(<TwoWayCardSkeleton/>);
      }
    }

  }, [conversations]);

  useEffect(() => {
    setUnknownPatient(((patient.firstName === null && patient.lastName === null) || (patient.firstName === '' && patient.lastName === '')) ? true : false);
  }, [conversation])

  useEffect(()=>{
    document.getElementById('root').classList.add('two-way-page');

    return ()=> {
      document.getElementById('root').classList.remove('two-way-page');
    };
  },[]);

  const handleLoadMoreToList = () => {
    loadMoreToList();
  }

  const handelSortKeyPress = (e) => {

  }
  const handelSortChange = (e) => {

    const sortTerm = e.target.value.toLowerCase();
    setSortFilter(sortTerm);
  }

  const handleSearchList = () => {
    if (!enableSearch) {
      if (document) {
        if (document.querySelector('.message--list--scroll')) {
          document.querySelector('.message--list--scroll').scrollTo(0, 0)
        }
      }

    } else {
      setSortFilter('');
    }
    searchList();
    setEnableSearch(enableSearch => !enableSearch);
    setSearchResults([])
  }
  const handleSearchClear = () => {
    setSortFilter('');
    document.getElementById('searchTerm').value = '';
  }


  const noMessagesFound = (
    <div className="card--holder">
      <div className="card" onClick={(event) => isLocationAware ? checkLocations() : newMessage() }>
        <div className='row' style={{fontWeight : "bold"}}><span>Create new messages {funEmojiItem}</span></div>
        <p>Click here to get started</p>
      </div>
    </div>
  )

  // useEffect(() => {
  //   setMessageListScrollHeight({height: windowHeight - windowHeightAdjustment + 'px'})
  // }, [windowHeight])

  useEffect(() => {
    setFamilyName(UserService.getFamilyName())
  }, [isLocationAwareState]);

  useEffect(() => {
    if (isLocationAwareState) {
      setShowLocations((showLocations) => !showLocations);
    }
  }, [showModal]);

  useEffect(() => {
    const user = Storage.getItem(Constants.currUserKey);
    if (user) {
      const jwtData = Utils2.decodeJwt(user.jwt);
      setCoverKey(jwtData.CoverKey);
    };
  }, [gCoverKey]);

  useEffect(() => {
    setAllowedCoverKeysBookings(allowedCoverKeysForBookings.split(","))
    setAllowedCoverKeysRegForms(allowedCoverKeysForRegForms.split(","))
  }, [allowedCoverKeysForBookings,allowedCoverKeysForRegForms]);

  const multi = (<>{isLocationAwareState && <>
      {hasMultipleLocations ? <>
            <div className="cursor sidebarTitle" onClick={() => { setShowModal(!showModal) }}> 
                <div id="circle">{primaryLocationInitial}</div>
                <div>{primaryLocation}</div> 
                <div><span>  <FiChevronDown/></span></div>                                     
            </div>
      </> : <>
            <div className="sidebarTitle"> 
                <div id="circle">{primaryLocationInitial}</div>
                <div>{primaryLocation}</div> 
            </div>
        </>
      }
      </>
  }</>);

  return (
    <>
      <div className="locationDropDownAndMessageList">
        <div className="multipleLocation">
          <SidebarMenuLocations toggleHasMultipleLocations={toggleHasMultipleLocations}
                                  putPrimaryLocation={putPrimaryLocation} toggleIsLocationAware={toggleIsLocationAware}
                                  showLocations={showLocations} toggleLocations={() => { setShowModal(!showModal) }}/>     
        </div>
        <div className="locationDropDown"> 
          {multi}
        </div>
        <div className='message--list'>
          <div className='message--list--header'>
            <div className='btn-row'>

              <Button variant="contained" color='primary' onClick={(event) => isLocationAware ? checkLocations() : newMessage() }><MdMessage/>&nbsp;New Messages
              </Button>

              <Button variant='outlined' color='primary' onClick={(event) => handleSearchList()}>{enableSearch &&
              <FiXCircle/>} {!enableSearch && <MdSearch/>}&nbsp; Search &nbsp;</Button>

            </div>

            <ul className='row'>
              <li className={(currentFilter === environment.viewStatus.all) ? 'active' : ''}
                  onClick={(event) => handleFilterList(environment.viewStatus.all)}>ALL
              </li>
              <li className={(currentFilter === environment.viewStatus.unread) ? 'active' : ''}
                  onClick={(event) => handleFilterList(environment.viewStatus.unread)}><span
                className='dot dot--blue'></span>Unread ({countUnread})
              </li>
              <li className={(currentFilter === environment.viewStatus.unanswered) ? 'active' : ''}
                  onClick={(event) => handleFilterList(environment.viewStatus.unanswered)}><span
                className='dot dot--green'></span>Unanswered ({countUnanswered})
              </li>
              <li className={(currentFilter === environment.viewStatus.flag) ? 'active' : ''}
                  onClick={(event) => handleFilterList(environment.viewStatus.flag)}><span
                className='dot dot--purple'></span>Flagged ({countFlagged})
              </li>
            </ul>
          </div>

          <div className='message--list--scroll'>
            <div className='message--list--refresher'>
              <div>
                <p>{lastRefreshed}</p>
                <span className='message--list--refresher--buttons'>
                  {loadingInterval && <span className='message--list--refresher--spinner'><Spinner/></span>}
                  {(!loadingInterval && showRefreshButton) &&
                  <Button className='btn-refresh' onClick={(event) => refreshList()}><FiRotateCw/></Button>}
                </span>
              </div>
            </div>

            {enableSearch &&
            <div className='message--list--input--search'>
              <div>
                <span className='btn-search'><FiSearch/></span>
                <input id='searchTerm' type='text' placeholder='Search Name Or Patient ID'
                      onKeyPress={(event) => handelSortKeyPress(event)} onChange={(event) => handelSortChange(event)}/>
                {searchResults.length <= 0 && <span className='txt--small txt--alert'></span>}
                <a className='btn-search--close fr' onClick={(event) => handleSearchClear()}><FiXCircle/></a>
              </div>
            </div>
            }

            {cards}

            <div className='message--list--scroll--loadmore--holder'>
              {conversations && (conversations.length >= environment.twoWayCardCountMax * currentPage && !loadingConversations) &&
              <Button className='btn-loadmore' variant="outlined" color='primary'
                      onClick={(event) => handleLoadMoreToList()}>Load More</Button>}
              {(loadingConversations) && <Button className='btn-loadmore' variant="outlined" color='primary'
                                                onClick={(event) => handleLoadMoreToList()}>Loading</Button>}
            </div>

          </div>

        </div>
      </div>
      <div className='message--conversations'>
        <Route path={`/communications/chat/message/:convKey`} render={(props) => {
          if (conversation) {
            return (<TwoWayConversation {...props} flagToRefreshConversation={flagToRefreshConversation}
                                        windowHeight={windowHeight} conversation={conversation} patient={patient}
                                        unknownPatient={unknownPatient} refreshConversationList={refreshList}
                                        hasFiles={hasFiles} setHasFiles={setHasFiles}/>)
          }
        }}/>
        <Route path={`/communications/chat/new`} render={(props) => {
          return (<TwoWayConversationNew {...props} refreshList={refreshList} windowHeight={windowHeight}
                                          selectedLocation={currentSelectedLocation} hasFiles={hasFiles}
                                          setHasFiles={setHasFiles}/>)
        }}/>
      </div>
    </>
  )
}

export default TwoWayList;