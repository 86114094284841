const pushServerPublicKey = "BET8Sbx8RxKONgRgVQgDRoBiS7BGtfGOlpF-yw3JGAPhcv0-lii1gpf31oiB1x3mKqcGRttZlgoM8lJUVMdyYP4";

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}
/**
 * shows a notification
 */


/**
 *
 */
function registerServiceWorker() {
  return navigator.serviceWorker.register('/service-worker-4pc.js').then(function(reg) {
    reg.onupdatefound = function() {
      var newSW = reg.installing;
      newSW.onstatechange = function() {
        if (newSW.state === 'waiting') {
          window.location.reload();
        }
      };
    };
  })

  //return navigator.serviceWorker.register("/service-worker-4pc.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey
  });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function(serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function(pushSubscription) {
      return pushSubscription;
    });
}

function unRegisterServiceWorker() {
  if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
  }
}

function findServiceWorker() {
 // return true;
  if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      return true;
    });
    //return false;
    }
  else
     return false;
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
  unRegisterServiceWorker,
  findServiceWorker
};
