import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import { getB2cConfig, b2cPolicies, msalConfig } from "../msalConfig";
import { 
    PublicClientApplication,
    IPublicClientApplication
 } from "@azure/msal-browser";
import Utils from '../utils';
import CryptoService from './CryptoService';
import LocationService from './LocationsService/LocationsService';
import Enums from '../enums';
import queryString from 'query-string';

const getSessionId = () => {
  let sessionId;
  if (localStorage.sessionID) {
    if (
      localStorage.sessionID !== undefined &&
      localStorage.sessionID !== 'undefined'
    ) {
      sessionId = parseInt(JSON.parse(localStorage.sessionID));
    }
  }
  // if(process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'local'){
  //   sessionId = parseInt(process.env.REACT_APP_API_SESSIONID);
  // }
  /*if(process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'development'){
    sessionId = parseInt(process.env.REACT_APP_API_SESSIONID);
  }
  if(process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'qa'){
    sessionId = parseInt(process.env.REACT_APP_API_SESSIONID);
  }*/

  return sessionId;
};

const getFamilyName = () => {
  let familyName;
  if (localStorage.family_name) {
    if (
      localStorage.family_name !== undefined &&
      localStorage.family_name !== 'undefined'
    ) {
      try {
        familyName = JSON.parse(localStorage.family_name);
      } catch (e) {
        familyName = localStorage.family_name;
      }
    }
  }

  return familyName;
};

const checkElementAccess = (element) => {
  try {
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    if (decryptedString) {
      let userAccess = JSON.parse(decryptedString);
      var result = userAccess.find((obj) => {
        return obj.ElementName === element;
      });
      if (result) {
        if (result.ParentName && result.ParentName.trim() != '') {
          return checkElementAccess(result.ParentName);
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

const getChildElements = (parent) => {
  try {
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    let userAccess = JSON.parse(decryptedString);
    return userAccess.filter((obj) => {
      return obj.ParentName === parent;
    });
  } catch {
    return [];
  }
};

const getParentElement = (child) => {
  try {
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    let userAccess = JSON.parse(decryptedString);
    var element = userAccess.find((obj) => {
      return obj.ElementName === child;
    });
    if (element) {
      var parent = userAccess.find((obj) => {
        return obj.ElementName === element.ParentName;
      });
      return parent;
    } else {
      return null;
    }
  } catch {
    return [];
  }
};

const getElementByRoute = (routeName) => {
  try {
    if (routeName.substr(routeName.length - 1) === '/') {
      routeName = routeName.substr(0, routeName.length - 1);
    }
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    let userAccess = JSON.parse(decryptedString);
    return userAccess.find((obj) => {
      return obj.Url === routeName;
    });
  } catch {
    return [];
  }
};

const getElementByName = (elementName) => {
  try {
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    let userAccess = JSON.parse(decryptedString);
    return userAccess.find((obj) => {
      return obj.ElementName === elementName;
    });
  } catch {
    return null;
  }
};

const getSidebarMenuElements = () => {
  try {
    var decryptedString = CryptoService.decryptString(
      Storage.getItem('encElements')
    );
    let userAccess = JSON.parse(decryptedString);
    return userAccess.filter((obj) => {
      return (
        obj.ParentName !== Constants.sidebarMenu && obj.ParentName !== null
      );
    });
  } catch {
    return [];
  }
};

const getElementsForUserCoverKey = async () => {
  var encryptedResponse = await axios.get(
    `${process.env.REACT_APP_AUTHORIZATION_API}/elements/encrypted`
  );
  Storage.setItem(Constants.encryptedElements, encryptedResponse.data);
};

const removeUserData = () => {
  Storage.removeItem(Constants.currUserKey);
};

const resetUserData = () => {
  Storage.removeItem(Constants.currUserKey);
  Storage.removeItem(Constants.familyName);
  Storage.removeItem(Constants.locations);
  Storage.removeItem(Constants.currentLocKeys);
  Storage.removeItem(Constants.locationAware);
  Storage.removeItem(Constants.allLocations);
  Storage.removeItem(Constants.sessionID);
  Storage.removeItem(Constants.userRole);
  Storage.removeItem(Constants.userType);
  Storage.removeItem(Constants.encryptedElements);
  Storage.removeItem('showregistration');
  Storage.removeItem('showonlinebooking');
};
const logout = async () => {
  //const {instance} = MsalContext;
  const instance = new PublicClientApplication(msalConfig);
  resetUserData();
  const logoutRequest = {
    account: instance.getActiveAccount()
  }
  await instance.logoutRedirect(logoutRequest);
  window.location = '/';
};

const getLocations = async (setSelectedLocationKeys, setAllLocations) => {
  return new Promise((resolve) => {
    LocationService.getLocations().then((resp) => {
      try {
        if (resp) {
          const myLocations = LocationService.updateLocationStore(resp);
          setSelectedLocationKeys(myLocations);
          setAllLocations(myLocations);

          const myLocationKeys = LocationService.getCacheLocations();
          LocationService.updateLocKeys(myLocationKeys);
          resolve(true);
        }
      } catch (err) {}
    });
  });
};

const decodeSession = async (
  sessionIDParam,
  setSelectedLocationKeys,
  setAllLocations,
  setGCoverKey,
  setstate,
  state,
  setAllowedCoverKeysBookings,
  setAllowedCoverKeysRegForms
) => {
  try {
    const secretCode = process.env.REACT_APP_API_KEY;
    var url = `${process.env.REACT_APP_AUTHENTICATION_API}/LoginSession?sessionID=${sessionIDParam}&secretCode=${secretCode}`;
    var res = await axios.post(url);
    const jwt = res.data;
    const jwtData = Utils.decodeJwt(jwt);
    let user = Storage.getItem(Constants.currUserKey);
    let sid = jwtData[Constants.sessionIDKey];

    Storage.setItem('userRole', jwtData.role);
    Storage.setItem('sessionID', sid);
    
    let familyName = jwtData[Constants.familyName];
    Storage.setItem(Constants.familyName, familyName);

    if (!user) {
      user = {
        jwt: '',
      };
    }

    user.jwt = jwt;
    Storage.setItem(Constants.currUserKey, user);
    setGCoverKey(jwtData.CoverKey);

    const url2 = `${process.env.REACT_APP_CUSTOMER_API}/allCoverKeys`;
    const res2 = await axios.get(url2);

    if (res2?.data) {
      if (res2.data.length === 1 || jwtData.role.indexOf('admin') >= 0) {
        await getLocations(setSelectedLocationKeys, setAllLocations);
      } else {
        setstate({ ...state, redirectToCoverSearch: true });
      }
    }

    // try {
    //   // Get Side Menu Restrictions
    //   const environment = process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT;
    //   const envi =
    //     environment == 'local' || environment == 'development'
    //       ? 'Dev'
    //       : environment;
    //   var applicationData = '$.EnableCoversBookings';
    //   var applicationName = '4PC.CustomerDashboardUI';
    //   url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
    //   res = await axios.get(url);
    //   if (res?.data) {
    //     var data = res.data.join(',');
    //     setAllowedCoverKeysBookings(data);
    //   }

    //   applicationData = '$.EnableCoversRegForms';
    //   url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
    //   res = await axios.get(url);
    //   if (res?.data) {
    //     var data = res.data.join(',');
    //     setAllowedCoverKeysRegForms(data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    await getElementsForUserCoverKey();
    window.location = window.location.origin + window.location.pathname;
  } catch (error) {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusUnauthorizedError)
    ) {
      setstate({ ...state, redirectToLogin: true });
    } else if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      window.location = '/';
    }
  }
};

const checkUser = async () => {
  const user = Storage.getItem(Constants.currUserKey);
  if (user && user.jwt) {
    try {
      const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
      await axios.get(jwtCheckUrl);
    } catch (error) {
      if (error.response?.status === 401) {
        logout();
      }
      window.location = '/';
    }
  } else {
    window.location = '/';
  }
};

const checkUser2 = async (
  setAllowedCoverKeysBookings,
  setAllowedCoverKeysRegForms
) => {
  const user = Storage.getItem(Constants.currUserKey);
  const secretCode = process.env.REACT_APP_API_KEY;
  if (user && user.jwt) {
    try {
      const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
      await axios.get(jwtCheckUrl);
      // try {
      //   // Get Side Menu Restrictions
      //   const environment = process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT;
      //   const envi =
      //     environment == 'local' || environment == 'development'
      //       ? 'Dev'
      //       : environment;
      //   var applicationData = '$.EnableCoversBookings';
      //   var applicationName = '4PC.CustomerDashboardUI';
      //   var url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
      //   var res = await axios.get(url);
      //   if (res?.data) {
      //     var data = res.data.join(',');
      //     setAllowedCoverKeysBookings(data);
      //   }

      //   applicationData = '$.EnableCoversRegForms';
      //   url = `${process.env.REACT_APP_WEBSCHEDULER_API}/api/v1/Patient/getApplicationCoverByKey?key=${applicationData}&env=${envi}&applicationName=${applicationName}`;
      //   res = await axios.get(url);
      //   if (res?.data) {
      //     var data = res.data.join(',');
      //     setAllowedCoverKeysRegForms(data);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          logout();
        }
      }
      window.location = '/';
    }
  } else {
    window.location = '/';
  }
};

const validateJwt = () => {
  const user = Storage.getItem(Constants.currUserKey);
  if (user && user.jwt) {
    try {
      // const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
      // axios.get(jwtCheckUrl).
      // then(response => {
      //   return true;
      // })
      // .catch(error => {
      //   return false;
      // });
      const token = Utils.decodeJwt(user.jwt);
      var current_time = new Date().getTime() / 1000;
	    return current_time <= token.exp; { /* expired */ }
    } catch {
      return false;
    }
  }else{
    return false;
  }
}

const authenticateSession = async (
  props,
  session,
  setSelectedLocationKeys,
  setAllLocations,
  setGCoverKey,
  setstate,
  state,
  setAllowedCoverKeysBookings,
  setAllowedCoverKeysRegForms
) => {
  const value = queryString.parse(props.location.search);
  const sessionIDParam = value.SessionID;
  if (sessionIDParam) {
    await decodeSession(
      sessionIDParam,
      setSelectedLocationKeys,
      setAllLocations,
      setGCoverKey,
      setstate,
      state,
      setAllowedCoverKeysBookings,
      setAllowedCoverKeysRegForms
    );
    session.SetValue({
      sessionID: sessionIDParam,
      sessionUpdated: new Date(),
    });
  } else {
    checkUser2(setAllowedCoverKeysBookings, setAllowedCoverKeysRegForms);
  }
};

export default {
  getSessionId,
  getFamilyName,
  checkElementAccess,
  getElementsForUserCoverKey,
  getChildElements,
  getElementByName,
  getParentElement,
  getElementByRoute,
  removeUserData,
  resetUserData,
  logout,
  checkUser,
  checkUser2,
  decodeSession,
  authenticateSession,
  getSidebarMenuElements,
  validateJwt,
};
