import React from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import Close from "@material-ui/icons/Close";

const Modal = ({
    onClose,
    show = false,
    children,
    hideCloseButton = false,
    closeModalOnOutsideClick = true,
    className = ''
}) => {
    const portal = document.getElementById("portal");

    const onCloseHandler = () => {
        if(onClose){
            onClose();
        }
    }

    const overlayOnClickHandler = () => {
        if(closeModalOnOutsideClick) onCloseHandler()
    }

    if (!show) return null;
    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay" onClick={overlayOnClickHandler}></div>
            <div className={`modal ${className}`}>
       
                {
                    !hideCloseButton && 
                    <Close className="modal__close" onClick={() => onCloseHandler()} />
                }

                <div className="modal__content">
                    {children}
                </div>

            </div>
        </>,
        portal
    )
}

export default Modal;