import { List, ListItem } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { createMProps } from "./Core/coreUI";
import { _ConfirmBox, _MessageBox } from "./CoreConsumer/Components/DialogBoxes";
import UIContext from "./CoreConsumer/uiContext";

function UIContextObjectManager() {    
    const uiContext = useContext(UIContext);
    const mProps = createMProps("uiContextObjectManagerProvider");

    const [comp, setComp] = useState(uiContext.Components);

    const onUpdateComponents = (c) => {
        setComp(c);
    };

    useEffect(() => {
        uiContext.Subscribe(mProps.componentId, onUpdateComponents);

        return() => {
            uiContext.UnSubscribe(mProps.componentId);
        }
    }, []);

    return (
        <div id="uicontext-workspace" style={{height:0}}>   
            <List>
                {comp.map(x => (
                    <ListItem key={x.object.guid}>
                        <div id={x.object.managedComponentId} className="uicontext-manageditem">
                            {uiContext.GenerateComponent(x.object)}                            
                        </div>
                    </ListItem>
                ))}
            </List>
            
        </div>
    );
}

export default function UIContextProvider(props) {
    const uiContext = useContext(UIContext);

    return (
        <>
            <UIContext.Provider value={uiContext}>
                {props.children}
                <UIContextObjectManager/>
            </UIContext.Provider>
        </>
    );
}