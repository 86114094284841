import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './init';
import App from './App';
import Overlay from './Components/Overlay/Overlay';
import SimpleReactLightbox from 'simple-react-lightbox'
import {BrowserRouter} from 'react-router-dom';
import {TourServiceProvider} from './Store/TourServiceProvider.js'
/* import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css'; */
import { PublicClientApplication, EventType} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

// Mobile fix for 100vh.
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Suspense fallback={<Overlay show={true}/>}>
      <SimpleReactLightbox>
      <BrowserRouter>
        <TourServiceProvider>
          <App/>
        </TourServiceProvider>
      </BrowserRouter>
      </SimpleReactLightbox>
    </Suspense>
  </MsalProvider>,
  document.getElementById('root')
);

