import React from 'react';
import {format, parseISO} from 'date-fns';

import Grid from '@material-ui/core/Grid';
import LensIcon from '@material-ui/icons/Lens';
import Typography from '@material-ui/core/Typography';

import './CustomerInformationAppointmentDetails.scss';

const CustomerInformationAppointmentDetails = (props) => {
  const {
    data,
    status,
  } = props;

  let divNoShowCancelled;
  let noShowCancelledIcon;

    const dateNow = format(new Date(), 'yyyy-LL-dd');
    let date = format(parseISO(data), 'yyyy-LL-dd');
    let futureAppt = (dateNow < date);
    if (!futureAppt) {
        switch(status) {
            case "noshow":
                divNoShowCancelled = "div-appointment-no-show";
                noShowCancelledIcon = <LensIcon className="appointment-details-noshow-icon"/>;
                break;
            case "cancelled":
                divNoShowCancelled = "div-appointment-cancelled";
                noShowCancelledIcon = <LensIcon className="appointment-details-cancelled-icon"/>;
                break;
            default:
                divNoShowCancelled = null;
                noShowCancelledIcon = null;
        }
    }


    return (
        <div className="div--appointment--details">
            <Grid item className={divNoShowCancelled}>
                <div className="div-time">
                {noShowCancelledIcon}
                <Typography variant="h6" gutterBottom align="right" >
                    {format(parseISO(data), 'h:mm a')}
                </Typography>
                </div>
                <div className="div-details">
                <Typography variant="body2" gutterBottom align="right">
                    CUSTOMER:
                </Typography>
                <Typography variant="body2" gutterBottom align="right">
                    SERVICE TYPE:
                </Typography>
                <Typography variant="body2" align="right">
                    SERVICE DURATION:
                </Typography>
                <Typography variant="body2" align="right">
                    SERVICE PROVIDER:
                </Typography>
                </div>
            </Grid>
        </div>
        
    );
}

export default CustomerInformationAppointmentDetails;