import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import { useFiltersContext, useRequest, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

import AppointmentTypes_List from './AppointmentTypes_List';
import AppointmentTypes_Form from './AppointmentTypes_Form';
import FormContainer from '../../FormContainer';

export default function AppointmentTypes() {
    var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');

    const componentId = "appointmentTypes";
    const ui = useUIContext(componentId);
    const filtersContext = useFiltersContext(componentId, async (context) => {
        //On filters changed
        
        if (cover != context.cover) {
            await initAppointmentsData_Onboarding();
        }

        setCover(context.cover);
      });
    const Request = useRequest(); 

    const [cover, setCover] = useState(filtersContext.Values.cover);
    const [listRefresh, setListRefresh] = useState(new Date());

    const initAppointmentsData_Onboarding = async () => {
        ui.ShowOverlay();

        let model = {
            coverKey: coverKey
        };

        let res = await Request.modInitAppointments_FromOnboardingAsync(model);

        if (res.success) {
            let d = res.data;
            if (d.Inserted > 0) {
                let msg = `Onboarding settings were successfully added to Appointments data. Inserted ${d.Inserted} record(s)`;                    
                ui.ShowSnackbar(msg, Severity.success, 10000);
            }            
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    return (<>
        {cover ? (<>         
            <Box p={2} bgcolor="background.paper">
{/*                 <FormContainer title="Add Appointment Type">
                    <AppointmentTypes_Form setListRefresh={setListRefresh} />
                </FormContainer> */}
                <AppointmentTypes_List refresh={listRefresh} />
            </Box>
        </>) : (<>
            {ui.uiJSX.loadingJSX}
        </>)}        
    </>);
}