import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import '../../Theme/index.scss';
import Utils from '../../Store/Utils/Utils';
import '../../app-responsive.scss';
import CRMTabsContext, { CRMTabsContextObject } from '../../CRMComponents/CRMTabs/CRMTabsContext';
import SessionContext, { SessionContextObject } from '../../SessionContext';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import Seo from '../SEO/Seo';
import SidebarMenuNew from '../SidebarMenu/SidebarMenuNew';

const AppLayout = (props) => {  
  let navbar = useRef();
  const [activeHeaderElement, setActiveHeaderElement] = useState('');
  
  const onCloseMenu = (e) => {
    if (Utils.getAgent() === 'iOS' || Utils.getAgent() === 'android') {
      if (e.target?.classList.contains('nav-toggle')) {
        navbar.current[0]?.classList.toggle('open');
      } else {
        navbar.current[0]?.classList.remove('open');
      }
    }
  }

  const dashboardHeaderCallback = (dashboardHeaderData) => {
    setActiveHeaderElement(dashboardHeaderData.ElementName);
  }

  useEffect(() => {
    if (document) {
      navbar.current = document.getElementsByTagName('nav');
    }
  }, []);
 
  return (
    <CRMTabsContext.Provider value={new CRMTabsContextObject()}>
      <SessionContext.Provider value={new SessionContextObject()}>
        <div className="App light">
          <div className='split-pane pane-top'>          
            <Seo title='4PatientCare'/>
            <DashboardHeader activeTabIndex={-1} parentCallback={dashboardHeaderCallback} />
          </div>
          <div className='split-pane pane-bottom dashboard'>
            <div className='split-pane pane-left'>
                <SidebarMenuNew activeHeaderElement={activeHeaderElement} />
            </div>
            {props.children}
          </div>
        </div>
      </SessionContext.Provider>
    </CRMTabsContext.Provider>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;