import jwtDecode from 'jwt-decode';
import {Base64} from 'js-base64';

/**
 * Represents a collection of utility methods.
 */
class Utils {

  /**
   * Updates a property of an object.
   * @param {Object} obj The object to update (object/array).
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value.
   */
  static update(obj, path, value) {
    if (obj && path) {
      if (path.length === 1) {
        obj[path] = value;
      } else {
        this.update(obj[path[0]], path.slice(1), value);
      }
    }
  }

  /**
   * Decodes the provided json web token.
   * @param {String} jwt The json web token.
   */
  static decodeJwt(jwt) {
    let decodedJwt = null;

    if (jwt) {
      decodedJwt = jwtDecode(jwt);
    }

    return decodedJwt;
  }

  /**
   * Base 64 encodes the provided string.
   * @param {String} str The string to encode.
   */
  static encodeBase64(str) {
    return Base64.encode(str);
  }

  /**
   * Base 64 decodes the provided string.
   * @param {String} str The string to decode.
   */
  static decodeBase64(str) {
    return Base64.decode(str);
  }


  /**
   * Debounces a function.
   * @param {Function} func The function to debounce.
   * @param {Number} wait The amount in milliseconds to wait to call the function.
   * @param {Boolean} isImmediate A value that determines whether to call the function immediately.
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's debounce function.
   */
  static debounce(func, wait, isImmediate) {
    let timeout = null;

    return function () {
      const that = this;
      const args = arguments;
      const callLater = () => {
        timeout = null;

        if (!isImmediate) {
          func.apply(that, args);
        }
      };
      const callNow = isImmediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(callLater, wait);

      if (callNow) {
        func.apply(that, args);
      }
    };
  }

}

// Lock object to prevent modification (true static).
Object.freeze(Utils);

export default Utils;
