import React, { useState, Children, isValidElement, cloneElement } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

export default function FormContainer(props) {
    const [expanded, setExpanded] = useState(true);

    const childrenWithProps = Children.map(props.children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, { setExpanded: setExpanded })
        }  
        return child;
    });

    return (<>
        <Accordion expanded={true} >
            <AccordionSummary  style={{cursor : "default"}}>
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                {childrenWithProps}
            </AccordionDetails>
        </Accordion>  
    </>);
}