import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './TabHead.scss';

const TabHead = ({
    grow,
    index,
    isActive, 
    height,
    baseBgColor, 
    bgColor,
    color, 
    activeBgColor,
    activeColor,
    onClick, 
    children
}) => {
    const _height = height ?? "initial";
    const _bgColor = bgColor ?? "gray";
    const _color = color ?? "black";
    const _activeBgColor = activeBgColor ?? "white";
    const _activeColor = activeColor ?? "black";

    const styles = {
        root: {
            "& .main": {
                height: _height,
                backgroundColor: _bgColor,
                color: _color
            },

            "& .extra": {
                backgroundColor: _bgColor,

                "& > div": {
                    backgroundColor: baseBgColor ?? "initial",
                }
            },

            "&.active": {
                "& .main": {
                    backgroundColor: _activeBgColor,
                    color: _activeColor
                },
    
                "& .extra": {
                    backgroundColor: _activeBgColor
                }
            }
        }
    };
    const classes = makeStyles(() => styles)();

    const getActiveClass = ()=> isActive ? 'active' : 'inactive';
    const [activeClass, setActiveClass] = useState(getActiveClass());

    const clickHandler = () => {
        onClick && onClick(index);  
    };
    
    useEffect(()=>{
        setActiveClass(getActiveClass());
    }, [isActive]);

    return (
        <div 
            data-tut={"reactour__tab_"+children}
            className={`tab-head ${activeClass} ${classes.root}`} 
            onClick={clickHandler} 
            style={{flexGrow: grow}}
        >
            <div className='extra bf'><div>&nbsp;</div></div>
            <div className='main'>{children}</div>
            <div className='extra af'><div>&nbsp;</div></div>
        </div>
    );
};

export default TabHead;