import React from 'react';
import './TwoWayImageCard.scss';
import {FiX} from 'react-icons/all';
import {Tooltip} from '@material-ui/core';


const TwoWayImageCard = ({attachment, removeCard}) => {
  const handleRemoveCard = (e) => {
    removeCard(e);
  }

  return (
    <Tooltip title={attachment.fileName}>
      <div className={`image-card--holder`}>
        <div className='image-card--btn-close' onClick={() => handleRemoveCard(attachment)}><FiX/></div>
        <img className="card--image" src={attachment.url}/>
      </div>
    </Tooltip>
  )
}

export default TwoWayImageCard;