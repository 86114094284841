import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import './CustomerInformationPatientHistory.scss';

const CustomerInformationPatientHistory = (props) => {
  const {
    lastVisitDate,
    lastExamType,
    knownPurchases
  } = props;

  let hasPurchase = (knownPurchases.toLowerCase() === 'none') ? false : true;
  const preventDefault = (event) => event.preventDefault();

  return (
    <Grid item xs={3} sm={3}>
      <div className="div-patient-history">
        <Typography variant="body2" align="center">
          PATIENT HISTORY
        </Typography>

        <Divider/>

        <Typography variant="body2" align="left" className="history-label">
          LAST VISIT DATE:
        </Typography>
        <Typography variant="body2" align="left" className="history-description">
          {lastVisitDate}
        </Typography>

        <Divider variant="middle"/>

        <Typography variant="body2" align="left" className="history-label">
          LAST EXAM TYPE:
        </Typography>
        <Typography variant="body2" align="left" className="history-description" noWrap>
          {lastExamType}
        </Typography>

        <Divider variant="middle"/>

        <Typography variant="body2" align="left" className="history-label">
          KNOWN PURCHASES:
        </Typography>
        <Typography variant="body2" align="left" className="history-description" noWrap>
          {knownPurchases}
        </Typography>

        {hasPurchase ?
          <Typography align="left">
            <Link variant="body2" onClick={preventDefault} target="_blank">
              {'See Details >'}
            </Link>
          </Typography>
          : null
        }

      </div>
    </Grid>
  );
}

export default CustomerInformationPatientHistory;