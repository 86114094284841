import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Multiselect} from 'multiselect-react-dropdown';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './TwoWayModal.scss';
import './TwoWayModalLocationSelect.scss';
import Spinner from '../Spinner/Spinner';
import LocationService from '../../Store/LocationsService/LocationsService';

const TwoWayModalLocationSelect = forwardRef((props, ref) => {
  const [locations, setLocations] = useState([]);
  const [hasLocations, setHasLocations] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [open, setOpen] = useState(false);
  const [userSelected, setUserSelected] = useState(false);

  useImperativeHandle(ref, () => ({
    setAlert(e) {
      handleOpen();
      setIsOpen(true)
    },
    closeAlert(e) {
      handleClose();
    }

  }));

  const handleOpen = () => {
    setOpen(true);
    setIsOpen(true)
  };

  const handleClose = () => {
    setUserSelected(false);
    setOpen(false);
    setIsOpen(false)
  };
  const handleCreateNewMessage = () => {
    props.selectedLocation(selectedLocation)
    props.createNewMessage();
    handleClose();
  };
  const handleSelectedLocation = (e) => {
    setSelectedLocation(e[0])
    setUserSelected(true)
  }

  useEffect(() => {
    if (locations) {
      setHasLocations(true)
    }
  }, [locations])

  useEffect(() => {
    setLocations(LocationService.getCacheLocations())
  }, [isOpen])

  return (
    <>
      <Dialog
        disableBackdropClick={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='TwoWayModalLocationSelect'
      >
        <DialogTitle id="alert-dialog-title">{props.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.body}
          </DialogContentText>

          {hasLocations && <Multiselect
            textInputProps={{editable: false, autoFocus: false}}
            options={locations}
            singleSelect
            displayValue="name"
            placeholder="Select location"
            disable={false}
            avoidHighlightFirstOption
            onSelect={handleSelectedLocation}
          />}
        </DialogContent>

        {props.spinner && <span className='spinner-centered'><Spinner/></span>}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.button1}
          </Button>
          <Button disabled={!userSelected} onClick={handleCreateNewMessage} color="primary" autoFocus>
            {props.button2}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default TwoWayModalLocationSelect;