import React, { useState, useContext, useEffect } from 'react';
import './App.scss';
import {ThemeProvider} from '@material-ui/styles';
import theme from './Theme/theme';
import {ToastProvider} from 'react-toast-notifications';
import {LocationServiceProvider} from './Store/LocationsService/LocationServiceProvider';
import AppRouter from './Router/AppRouter';
import AppLayout from './Components/Layout/AppLayout';
import { TourProvider } from '@reactour/tour'
import Utils from './utils';
import Constants from './constants';
import LocationService from './Store/LocationsService/LocationsService'
import { useTour } from '@reactour/tour'
import {TourServiceContext} from './Store/TourServiceProvider.js'
import { environment } from './environment';
import {Helmet} from "react-helmet";
import UserService from './Store/UserService';
import axios from 'axios';
//import Button from '@material-ui/core/Button';

// import { getB2cConfig, b2cPolicies } from "./msalConfig";
// import { EventType } from "@azure/msal-browser";
// import { 
//    UnauthenticatedTemplate,
//    AuthenticatedTemplate, 
//    useMsal,
//    useIsAuthenticated,
//    //MsalContext,
//    //withMsal,
// } from "@azure/msal-react";


const App = () => {

  const radius = 10
  const [hideNav, setHideNav] = useState(true);
  const [tabName, setTabName] = useState('');
  const { isOpen, currentStep, setIsOpen, setCurrentStep ,setSteps, setDisabledActions, stepsLength,disabledActions } = useTour();

  const {
    tourNext,
    currentTab,
    setTourNext,
    currentLockey,
    currentAcctKey
   } = useContext(TourServiceContext);  

   useEffect(async () => {
     setHideNav(tourNext)
     setTabName(currentTab)
  }, [tourNext, currentTab]);

  const opositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
  }

  const  doArrow = (position, verticalAlign, horizontalAlign) => {
    if (!position || position === 'custom') {
      return {}
    }
  
    const width = 30
    const height = 30
    const color = 'white'
    const isVertical = position === 'top' || position === 'bottom'
    const spaceFromSide = -10
    const obj = {
      [isVertical ? 'borderLeft' : 'borderTop']: `${width /
        2}px solid transparent`, // CSS Triangle width
      [isVertical ? 'borderRight' : 'borderBottom']: `${width /
        2}px solid transparent`, // CSS Triangle width
      [`border${position[0].toUpperCase()}${position.substring(
        1
      )}`]: `${height}px solid ${color}`, // CSS Triangle height
      [isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
        height + spaceFromSide, // space from side
      [opositeSide[position]]: -height + 2,
    }
  
    return {
      '&::after': {
        content: "''",
        width: 0,
        height: 0,
        position: 'absolute',
        ...obj,
      },
    }
  }

  return (
    <>
    {/* environment.env !== "prod" ? 
      <Helmet>
          <script type="text/javascript" charset="utf-8">
          {`
              (function (g, e, n, es, ys) {
                  g['_genesysJs'] = e;
                  g[e] = g[e] || function () {
                      (g[e].q = g[e].q || []).push(arguments)
                  };
                  g[e].t = 1 * new Date();
                  g[e].c = es;
                  ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
              })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {
                  environment: 'usw2',
                  deploymentId: 'a7efa96c-42da-4e07-8b10-132b9ac473b5'
              });
              `} 
          </script>
      </Helmet>  : null */} 
      <TourProvider 
          showDots={false}
          showPrevNextButtons={hideNav}
          styles={{
            popover: (base, state) => ({
              ...base,
              ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),    
              '--reactour-accent': '#ef5a3d',
              borderRadius: radius,
            }),
            maskArea: base => ({ ...base, rx: radius }),
            maskWrapper: base => ({ ...base, opacity : 0, display : "none"}),
            badge: base => ({ ...base, left: '4em' }),
            controls: base => ({ ...base, marginTop: 20 }),
            close: base => ({ ...base, right: 8, top: 8 }),
          }}       
            badgeContent={({ totalSteps, currentStep }) =>
            `${currentStep + 1}/${totalSteps}`
            }       
            position='left'
            prevButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
              const first = currentStep === 0
              const prev = first ? 0 : currentStep - 1
              const navigateStep = (idx) =>
              {
                setCurrentStep(idx)
              }
              return (
                <Button  hideArrow={true} onClick={() =>  navigateStep(prev)}>
                   {!first && <button className="button-tour" >Prev</button>}
                </Button>       
              )
            }}       
            nextButton={({
              Button,
              currentStep,
              stepsLength,
              setIsOpen,
              setCurrentStep      
            }) => {
              const last = currentStep === stepsLength - 1
              const next = last ? stepsLength - 1 : currentStep + 1
              const navigateStep = (idx, last) =>
              {
                if ((tabName === "webscheduler" && next === 3) ||
                    (tabName === "webscheduler" && next === 1 && currentAcctKey === 0)
                   )
                {
                   setTourNext(false)
                }            
      /*          else if (next <= 1 && (currentLockey > 0 && currentAcctKey === 0))
                {
                  setTourNext(false)
                }  */
 

                setCurrentStep(idx)
                if (last)
                  setIsOpen(false)
              }
              return (
                <Button  hideArrow={true} onClick={() =>  navigateStep(next, last)}>
                   <button  className="button-tour">{!last ? "Next" : "Close"}</button>
                </Button>                            
              )
            }} 
      >   
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <LocationServiceProvider>
            <AppLayout>
              <AppRouter />
              {/* <AuthenticatedTemplate>
                <AppRouter />
              </AuthenticatedTemplate> 
              <UnauthenticatedTemplate>
                <div>Signing in...</div>
              </UnauthenticatedTemplate> */}
            </AppLayout>
          </LocationServiceProvider>
        </ToastProvider>
      </ThemeProvider>
      </TourProvider>
      </>

  );
};

export default App;