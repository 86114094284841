//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}`,
        knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
        redirectUri: "/",
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: "/",
    }
};

export const msalConfigJA = {
    auth: {
        clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}`,
        knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
        redirectUri: "/",
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: "/?authExperience=ja",
    }
};

export const msalConfigSvsVision = {
    auth: {
        clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}`,
        knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
        redirectUri: "/",
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: "/?authExperience=svsvision",
    }
};

export const b2cPolicies = {
    names: {
        signInLocal: "SignInLocal",
        signInSvs: "SignInSvs",
        signInJA: "SignInJA",
    },
    authorities: {
        signInSvs: {
            clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
            authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}`,
            knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
            redirectUri: "/",
            postLogoutRedirectUri: "/logout",
            //scopes: ['openid',  process.env.REACT_APP_B2CAAD_CLIENT_ID, 'offline_access']
        },
        signInLocal: {
            clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
            authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}`,
            knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
            redirectUri: "/",
            postLogoutRedirectUri: "/logout",
            //scopes: ['openid',  process.env.REACT_APP_B2CAAD_CLIENT_ID, 'offline_access']
        },
        signInJA: {
            clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}`,
            authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}`,
            knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}`],
            redirectUri: "/",
            postLogoutRedirectUri: "/logout",
            //scopes: ['openid',  process.env.REACT_APP_B2CAAD_CLIENT_ID, 'offline_access']
        }
    }
}


export function getB2cConfig(policyName, authExperience="default") {
    const names = b2cPolicies.names;
    let policy= null;

    switch (policyName) {
        case names.signInLocal:
            policy = b2cPolicies.authorities.signInLocal;
            break;
        case names.signInSvs:
            policy = b2cPolicies.authorities.signInSvs;
            break;
        case names.signInJA:
            policy = b2cPolicies.authorities.signInJA;
            break;
        default:
            {
                if (authExperience==="svsvision")
                {
                    policy =  b2cPolicies.authorities.signInSvs;
                }
                else if (authExperience==="ja")
                {
                    policy =  b2cPolicies.authorities.signInJA;
                }
                else {
                    policy =  b2cPolicies.authorities.signInLocal;
                }
                policy.postLogoutRedirectUri = `/?authExperience=${authExperience}`;
            }
    }
    
    return policy;
}

export function getB2cConfigExperience(authExperience="default") {
    if (authExperience==="svsvision")
    {
        return msalConfigSvsVision;
    }
    else if (authExperience==="ja")
    {
        return msalConfigJA;
    }
    else {
        return msalConfig;
    }
}
