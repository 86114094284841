import {isToday} from 'date-fns'
import {format, utcToZonedTime} from 'date-fns-tz'
import jwtDecode from 'jwt-decode';
import {Base64} from 'js-base64';

import Constants from '../../constants';
import Storage from '../../storage';

const formatDateTime = (date) => {

  let datetime;
  const clientTimeZone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clientDate = utcToZonedTime(date, clientTimeZone)

  if (!isToday(new Date(clientDate))) {
    datetime = format(new Date(clientDate), 'MM/dd/yy', {timeZone: clientTimeZone});
  } else {
    datetime = format(new Date(clientDate), 'MM/dd/yy h:mm a', {timeZone: clientTimeZone});
  }
  return datetime
}

const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    try
    {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    if (cleaned.length >= 10)
    {
        const match = cleaned.match(/(\d*)(\d{10})/)
        const last9digits = match[2].match(/(\d{3})(\d{3})(\d{4})/)

        if (match) {
          const intlCode = `+${match[1]} `
          return [intlCode, '(', last9digits[1], ') ', last9digits[2], '-', last9digits[3]].join('')
        }
     }
     else
     {
       return phoneNumberString
     }
   }
   catch(ex)
   {
      var message = ex
   }
  }
  return phoneNumberString
}

const formatPhoneNumberOnlyDashes = (phoneNumberString) => {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  phoneNumberString = cleaned.slice(0, 3) + '-' + cleaned.slice(3, 6) + '-' + cleaned.slice(6, 15);
  return phoneNumberString;
}

const getAgent = () => {

  let agent = navigator.userAgent.toLowerCase(),
    obj = {
      viewport:
        {
          is:
            {
              ie10: !!(agent.match(/msie 10.0/)),
              ie9: !!(agent.match(/msie 9.0/)),
              ie8: !!(agent.match(/msie 8.0/)),
              ie7: !!(agent.match(/msie 7.0/)),
              ie6: !!(agent.match(/msie 6.0/)),
              opera: !!(agent.match(/opera/)),
              chrome: !!(agent.match(/chrome/)),
              safari: !!(agent.match(/safari/)),
              firefox: !!(agent.match(/firefox/)),
              android: !!(agent.match(/android/)),
              iOS: !!(agent.match(/iphone/) || agent.match(/ipod/))
            }
        }
    };

  for (var key in obj.viewport) {
    var o = obj.viewport[key];
    for (var prop in o) {
      if (o[prop])
        agent = prop;
    }
  }
  return agent;
};

/**
 * Decodes the provided json web token.
 * @param {String} jwt The json web token.
 */
const decodeJwt = (jwt) => {
  let decodedJwt = null;

  if (jwt) {
    decodedJwt = jwtDecode(jwt);
  }

  return decodedJwt;
}

/**
 * Base 64 encodes the provided string.
 * @param {String} str The string to encode.
 */
const encodeBase64 = (str) => {
  return Base64.encode(str);
}

/**
 * Base 64 decodes the provided string.
 * @param {String} str The string to decode.
 */
const decodeBase64 = (str) => {
  return Base64.decode(str);
}

const appendSessionIdToUrl = (url) => {
  if(url.toUpperCase().indexOf('SESSIONID=') > -1){
    return url.replace(/SESSIONID=/gi, 'SessionId=' + Storage.getItem(Constants.sessionID))
  }
  return '';
}

export default {
  formatPhoneNumber,
  formatPhoneNumberOnlyDashes,
  formatDateTime,
  getAgent,
  decodeJwt,
  encodeBase64,
  decodeBase64,
  appendSessionIdToUrl
};