import './SidebarMenuNew.scss';
import './SidebarMenu.scss';
import { MdKeyboardArrowRight } from 'react-icons/all';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Constants from '../../constants';
import Utils from '../../utils';
import Utils2 from '../../Store/Utils/Utils';
import { FiChevronDown } from 'react-icons/all';
import './SidebarMenu.scss';
import SidebarMenuLocations from '../SidebarMenuLocations/SidebarMenuLocations';
import UserService from '../../Store/UserService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { environment } from '../../environment';
import { LocationServiceContext } from '../../Store/LocationsService/LocationServiceProvider';
import { useHistory } from 'react-router';
import Storage from '../../storage';
import { NavigationFullscreen } from 'material-ui/svg-icons';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItem,
  Drawer,
  ListItemText,
} from "@material-ui/core";
import { List } from "material-ui";
import { useTour } from '@reactour/tour';
import { 
   useMsal,
} from "@azure/msal-react";
import { getB2cConfig, b2cPolicies,msalConfig } from "../../msalConfig";

const SidebarMenuNew = (props) => {
const { setIsOpen} = useTour();
const [coverKey, setCoverKey] = useState(0);
const [currentSideMenu, setCurrentSideMenu] = useState(0);
const [showLocations, setShowLocations] = useState(false);
const [isLocationAware, setIsLocationAware] = useState(false);
const [primaryLocation, setPrimaryLocation] = useState('Select Location(s)');
const [primaryLocationInitial, setPrimaryLocationInitial] = useState('S');
const [hasMultipleLocations, setHasMultipleLocations] = useState(false);
const [familyName, setFamilyName] = useState(null);
const [allowedCoverKeysBookings, setAllowedCoverKeysBookings] = useState([]);
const [allowedCoverKeysRegForms, setAllowedCoverKeysRegForms] = useState([]);
const {
 gCoverKey,
 allowedCoverKeysForRegForms,
 allowedCoverKeysForBookings
} = useContext(LocationServiceContext);
const {instance} = useMsal();

  const [allowChat] = useState(
    UserService.checkElementAccess(Constants.new2WayMenu)
  );
  const [allowRegistrationForm] = useState(
    UserService.checkElementAccess(Constants.registrationFormMenu)
  );
  const [allowOnlineBookingSetup] = useState(
    UserService.checkElementAccess(Constants.onlineBookingSetupMenu)
  );
  const [menuItems, setMenuItems] = useState(
    UserService.getChildElements(props.activeHeaderElement)
  );
  const [sidebarMainMenuItems, setSidebarMainMenuItems] = useState(
    UserService.getChildElements('Dv4Dashboard')
  );
  // const [sidebarSubMenuItems, setSidebarSubMenuItems] = useState(
  //   UserService.getSidebarSubMenuElements()
  // );

  const [currentItem, setCurrentItem] = useState(-1);

  const version = environment.version;
  const showRegistration = Storage.getItem('showregistration');
  const showOnlineBookingSetup = Storage.getItem('showonlinebooking');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const [showSideBar, setShowSideBar] = useState(false);

  const handleLogout = async () => {
    //await instance.logout(); 
    //await instance.logoutRedirect(); 
    //await instance.logoutRedirect(msalConfig); 
    //await instance.logoutRedirect(getB2cConfig(b2cPolicies.names.signIn)); 
    UserService.logout(msalConfig);

  }

  useEffect(() => {
    const user = Storage.getItem(Constants.currUserKey);
    if (user) {
      const jwtData = Utils.decodeJwt(user.jwt);
      setCoverKey(jwtData.CoverKey);
      setShowSideBar(true);
    }
  }, [gCoverKey]);

  useEffect(() => {
    setAllowedCoverKeysBookings(allowedCoverKeysForBookings.split(','));
    setAllowedCoverKeysRegForms(allowedCoverKeysForRegForms.split(','));
  }, [allowedCoverKeysForBookings, allowedCoverKeysForRegForms]);

  useEffect(() => {
    setMenuItems(UserService.getChildElements(props.activeHeaderElement));
    setCurrentItem(-1);
  }, [props.activeHeaderElement]);

  const menuClick = (id) => {
    setCurrentItem(id);
    setIsOpen(false);
  };

  const subMenuClick = (id) => {
    setCurrentItem(id);
    setIsOpen(false);
    setOpenDrawer(false);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    var element = UserService.getElementByRoute(window.location.pathname);
    if (element && element.ElementName !== props.activeHeaderElement) {
      var parentElement = UserService.getParentElement(element.ElementName);
      var elements = UserService.getChildElements(parentElement.ElementName);
      elements.map((x, i) => {
        if (x.ElementName === element.ElementName) {
          setCurrentItem(i);
        }
      });
    }
  }, [props.activeHeaderElement]);

  return (
    <>
      {showSideBar ? (
        <div className='nav-toggle' onClick={toggleDrawer}>
          &#9776;
        </div>
      ) : null}
      <div className='multipleLocation'></div>
      <div className='navigation'>
        <div id='nav' className={isLocationAware ? 'location-aware' : ''}>
          <ul>
            {menuItems.map((x, i) => {
              return (
                <li
                  className={currentItem === i ? 'active' : 'list'}
                  onClick={() => menuClick(i)}
                >
                  {x.Type === 'app' ? (
                    <Link
                      to={x.Url}
                      className={
                        currentItem === i ? 'activeLink' : 'inActiveLink'
                      }
                    >
                      <b></b>
                      <b></b>
                      <div style={{ display: 'flex' }}>
                        <span className='title'>{x.DisplayName}</span>
                        <span
                          className={
                            currentItem === i ? 'itemActive' : 'itemInActive'
                          }
                        >
                          <MdKeyboardArrowRight size={30} />
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <a
                      href={Utils2.appendSessionIdToUrl(x.Url)}
                      target='_blank'
                      rel='noreferrer'
                      className={
                        currentItem === i ? 'activeLink' : 'inActiveLink'
                      }
                    >
                      <b></b>
                      <b></b>
                      <div style={{ display: 'flex' }}>
                        <span className='title'>{x.DisplayName}</span>
                        <span
                          className={
                            currentItem === i ? 'itemActive' : 'itemInActive'
                          }
                        >
                          <MdKeyboardArrowRight size={30} />
                        </span>
                      </div>
                    </a>
                  )}
                </li>
              );
            })}
            {/* {allowChat ?
                    <li className={currentSideMenu === 0 ? "active" : "list"} onClick={() => menuClick(0) }>
                     <Link to='/chat' className={currentSideMenu === 0 ? "activeLink" : "inActiveLink"}>
                       <b></b>
                       <b></b>          
                       <div style={{display :"flex"}}>
                            <span className="title">Chat (2-Way Messaging)</span>
                            <span className={currentSideMenu === 0 ? "itemActive" : "itemInActive"} ><MdKeyboardArrowRight size={30}/></span>
                        </div>   
                     </Link>                                               
                    </li> : null}
                    {((environment.env !== "prod" || allowedCoverKeysRegForms.includes(coverKey)) && allowRegistrationForm) && <li className={currentSideMenu === 1 ? "active" : "list"} onClick={() => menuClick(1)}>
                    <Link to='/registration' className={currentSideMenu === 1 ? "activeLink" : "inActiveLink"}>  
                       <b></b>
                       <b></b>     
                       <div style={{display :"flex"}}>
                            <span className="title">Patient Forms</span>
                            <span className={currentSideMenu === 1 ? "itemActive" : "itemInActive"}  ><MdKeyboardArrowRight size={30}/></span>
                        </div>   
                     </Link>                                                        
                    </li>}
                    {((environment.env !== "prod" || allowedCoverKeysBookings.includes(coverKey)) && allowOnlineBookingSetup) && <li className={currentSideMenu === 2 ? "active" : "list"} onClick={() => menuClick(2)}>
                    <Link to='/onlinebooking' className={currentSideMenu === 2 ? "activeLink" : "inActiveLink"}>    
                        <b></b>
                        <b></b>      
                        <div style={{display :"flex"}}>
                            <span className="title">Online Booking Setup</span>
                            <span className={currentSideMenu === 2 ? "itemActive" : "itemInActive"}  ><MdKeyboardArrowRight size={30}/></span>
                        </div>  
                        </Link>                                                 
                    </li>}                          */}
          </ul>
        </div>
      </div>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <div className='drawer-contents-new'>
          <ul>
            {sidebarMainMenuItems.map((x, i) => {
              var sidebarMenuItems1 = UserService.getChildElements(x.ElementName);
              return (
                <>
                  {/* {
                    setSidebarMenuItems(UserService.getChildElements(x.ElementName))
                  } */}
                  <li className='mainItem' onClick={() => setOpenDrawer(false)}>
                    <Link to={x.Url}>{x.DisplayName}</Link>
                  </li>
                  <ul>
                    {sidebarMenuItems1 && sidebarMenuItems1.map((y,j) => {
                      return(
                        <li
                          className={(currentItem === j ? 'active' : 'list') + ' subItem'}
                          onClick={() => subMenuClick(j)}
                        >
                          {x.Type === 'app' ? (
                            <Link
                              to={y.Url}
                              className={
                                currentItem === j ? 'activeLink' : 'inActiveLink'
                              }
                            >
                              <div style={{ display: 'flex' }}>
                                <span className='title'>{y.DisplayName}</span>
                                <span
                                  className={
                                    currentItem === j ? 'itemActive' : 'itemInActive'
                                  }
                                ></span>
                              </div>
                            </Link>
                          ) : (
                            <a
                              href={Utils2.appendSessionIdToUrl(y.Url)}
                              target='_blank'
                              rel='noreferrer'
                              className={
                                currentItem === j ? 'activeLink' : 'inActiveLink'
                              }
                            >
                              <div style={{ display: 'flex' }}>
                                <span className='title'>{y.DisplayName}</span>
                                <span
                                  className={
                                    currentItem === j ? 'itemActive' : 'itemInActive'
                                  }
                                ></span>
                              </div>
                            </a>
                          )}
                        </li>
                      )})
                    }
                  </ul>
                </>
              );
            })}

            <li className='logout' onClick={handleLogout}>
              <a href='#'>Logout</a>
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarMenuNew;
