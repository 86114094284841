// Dependencies
import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Route, 
  Switch, 
  Redirect
} from 'react-router-dom';
import Utils from '../Store/Utils/Utils';
import Constants from '../constants';
import Storage from '../storage';
import axios from 'axios';
import UserService from '../Store/UserService';
import Overlay from '../Components/Overlay/Overlay'
import LocationService from '../Store/LocationsService/LocationsService'
import { LocationServiceContext} from '../Store/LocationsService/LocationServiceProvider';

import MainForm from '../Pages/MainPage/MainPage';
import RegistrationForms from '../Pages/PatientForm/RegistrationForms';
import OnlineBookingMaster from '../Pages/OnlineBooking/OnlineBookingSetupMaster';
import ChooseCoverUser from '../Pages/ChooseCoverUser/chooseCoverUser';
import CustomerInformation from '../Pages/CustomerInformationPage/CustomerInformationPage';
import ReportsPage from '../Pages/Reports/ReportsPage';
import RecallDetailsPage from '../Pages/Reports/RecallDetailsPage';
import RemindersPage from '../Pages/Reports/RemindersPage';
import AppointmentsPage from '../Pages/Reports/AppointmentsPage';
import CancellationPage from '../Pages/Reports/CancellationPage';
import OrdersPage from '../Pages/Reports/OrdersPage';
import VisitsPage from '../Pages/Reports/VisitsPage';
import PurchaseRecapturePage from '../Pages/Reports/PurchaseRecapturePage';
import WelcomePage from '../Pages/WelcomePage/WelcomePage';
import PushNotificationsPage from '../Pages/PushNotification/PushNotification.js';

import CommunicationsTab from '../Pages/CommunicationsTab/CommunicationsTab.js';
import ReportsTab from '../Pages/ReportsTab/ReportsTab.js';
import SetupTab from '../Pages/SetupTab/SetupTab.js';

// import RouteHandler from './RouteHandler.js';

import { getB2cConfig, b2cPolicies } from "../msalConfig";
import {
  EventType,
  InteractionType,
 } from "@azure/msal-browser";
import {
   useMsal,
   useIsAuthenticated,
   useMsalAuthentication,
   //useMsalAuthentication,
   UnauthenticatedTemplate,
   AuthenticatedTemplate,
   //MsalContext,
   //withMsal,
} from "@azure/msal-react";
// Components


/**
 * Remove per conflict on master
 <Route
 path={["/customer"]}
 component={CustomerInformationPage} />
 */

export default function AppRouter() {
  const {
    setAllLocations,
    setSelectedLocationKeys
  } = useContext(LocationServiceContext);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [readyToRoute, setReadyToRoute] = useState(false);
  const [redirectToCoverSearch, setRedirectToCoverSearch] = useState(false);

  let navbar = useRef();

  const [state, setstate] = useState({
    disableLoginBtn: false,
    isVisible: false,
    redirectToCoverSearch: false,
    showLoadingOverlay: false,
    redirectToDashbaord: false,
    user: null
  });
  const queryParams = new URLSearchParams(window.location.search);
  const hasAuthExperience = queryParams.has("authExperience");
  const authExperience = queryParams.get("authExperience")??"default";
  let actionableAuthExperience = "default";
  if (hasAuthExperience) {
    actionableAuthExperience = authExperience;
    Storage.setItem('authExperience', authExperience);
  }
  else {
    const storedAuthExperience = Storage.getItem('authExperience');
    if (storedAuthExperience) {
      actionableAuthExperience = storedAuthExperience;
    }
  }
  var activeAccount = instance?.getActiveAccount()?.username;
  
  // console.log(`actionableAuthExperience ${actionableAuthExperience}`);
  // const [isAuthenticatedState, setIsAuthenticatedState] = React.useState<boolean>(false)
  const loginRequest = getB2cConfig("", actionableAuthExperience);  
  useMsalAuthentication(InteractionType.Redirect,loginRequest);
  // console.log('get active account', instance.getActiveAccount());
  const account = accounts&& accounts.length>0?accounts[0]:null;

  var sessionID = Storage?.getItem(Constants.sessionID);
  var userRole = Storage?.getItem(Constants.userRole);
  var userType = Storage?.getItem(Constants.userType);

  const onCloseMenu = (e) => {
    if (Utils.getAgent() === 'iOS' || Utils.getAgent() === 'android') {
      if (e.target?.classList.contains('nav-toggle')) {
        navbar.current[0]?.classList.toggle('open');
      } else {
        navbar.current[0]?.classList.remove('open');
      }
    }
  }
  
  useEffect(() => {

    const getLocations = async () => {
      LocationService.getLocations().then((resp) => {
          try {
              if (resp) {
                  const myLocations = LocationService.updateLocationStore(resp);
                  setSelectedLocationKeys(myLocations)
                  setAllLocations(myLocations);

                  const myLocationKeys = LocationService.getCacheLocations()
                  LocationService.updateLocKeys(myLocationKeys);
                  //setstate({ ...state, redirectToDashbaord: true });
              }
          } catch (err) {
          }
      })
    };
    
    const processUserChangeEffect = async () =>
    {
      
      activeAccount = instance?.getActiveAccount()?.username;
      userRole = Storage?.getItem(Constants.userRole);
      sessionID = Storage?.getItem(Constants.sessionID);
      userType = Storage?.getItem(Constants.userType);
      // console.log('processUserChangeEffect: activeAccount: ' + activeAccount);
      // console.log('processUserChangeEffect: userRole: ' + userRole);
      if(activeAccount) {

        if ((userRole && (userRole.indexOf('user') >= 0 || userRole.indexOf('superadmin') >= 0)) || (userType && userType.indexOf('Internal') >= 0)){
          const url = `${process.env.REACT_APP_CUSTOMER_API}/allCoverKeys`;
          const res = await axios.get(url);

          if (res?.data) {
            if(userType && userType.indexOf('Internal') >= 0) {
              setRedirectToCoverSearch(sessionID&&true);
            }
            else if (res.data.length === 1) {
                //Locations logic here
                await UserService.getElementsForUserCoverKey()
                await getLocations();
            } 
            else
            {
              //setstate({ ...state, redirectToCoverSearch: true })
              //setstate( s => ({...state, redirectToCoverSearch:(sessionID&&true)}))
              setRedirectToCoverSearch(sessionID&&true);
            }
          }
        }
        else {
          //setstate({ ...state, redirectToDashbaord: true })
          //setstate( s => ({...state, redirectToDashbaord:true}))
          //setRedirectToDashbaord(true);
          setRedirectToCoverSearch(false);
        }
        setReadyToRoute(true);
      }
      else {
        //setstate({ ...state, redirectToDashbaord: true })
        //setstate( s => ({...state, redirectToDashbaord:true}))
        //setRedirectToDashbaord(true);
      }
    };
    
    const _validateJwt = async () => {
      const user = Storage.getItem(Constants.currUserKey);
      if (user && user.jwt) {
        try {
          const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
          return true;
        } catch {
          return false;
        }
      }else{
        return false;
      }
    }

    const asyncPortionOfUseEffect = async () =>
    {
      // console.log(`interaction inProgress`)
      instance.setActiveAccount(account);

      if(!account){
          // console.log(`Account Not Set`)
      }
      else {
        // console.log(`Account Set:  ${JSON.stringify(account)}`);

        //at this point simulate what was done with NormalLogin
        try {
          
          let user = Storage.getItem(Constants.currUserKey);
          if(!(await _validateJwt()))
          {
            UserService.resetUserData();
            const authResultSilent = await instance.acquireTokenSilent(getB2cConfig("default", actionableAuthExperience));
            const b2cToken = authResultSilent.idToken;

            // now login with B2C token
            const url = `${process.env.REACT_APP_AUTHENTICATION_API}/loginUsingB2CToken`;
            const config = { headers: {'Content-Type': 'application/json'} }
            const res = await axios.post(url, '"'+b2cToken+'"', config);

            const jwt = res.data;
            const jwtData = Utils.decodeJwt(jwt);
            // console.log(`useEffect [account]: processing JWT  ${JSON.stringify(jwtData)} `);
            if (jwtData.OTP && jwtData.OTP != 0) {
              Storage.setItem('key', jwtData.OTP);
            }
            else
            {
              // console.log(`else`);
              // let user = Storage.getItem(Constants.currUserKey)
              let sid = jwtData[Constants.sessionIDKey]
              let userType = jwtData[Constants.userDataKey]
              Storage.setItem(Constants.userRole, jwtData.role)
              Storage.setItem(Constants.sessionID, sid)
              Storage.setItem(Constants.userType, userType)
              let familyName = jwtData[Constants.familyName]
              Storage.setItem(Constants.familyName, familyName)

              if (!user) {
                  user = {
                      jwt: ''
                  };
              }

              user.jwt = jwt;
              Storage.setItem(Constants.currUserKey, user);
              //setstate({...state, user});
            }
          }
          // console.log(`useEffect [account]: user ${JSON.stringify(user)} `);
          setstate(s => ({...state, user:user}));
          await processUserChangeEffect();
        } catch (error) {
          // console.log(`useEffect [account]: catch error ${JSON.stringify(error)} `)
        }


        //currently in production it is broken and will have html coming from S3 Storage of AWS
        Storage.setItem('showregistration', "resultreg.data");
        Storage.setItem('showonlinebooking', "resultbooking.data");
      }
    }

    asyncPortionOfUseEffect();
    // console.log(`useEffect start [activeAccount, userRole, sessionID]`);
    // console.log(`useEffect :activeAccount:\r\n ${activeAccount}`);
    // console.log(`useEffect :userRole:\r\n ${userRole}`);
    // console.log(`useEffect :sessionID:\r\n ${sessionID}`);
  
  }, [account, activeAccount]);
  
  const {user:userState} = state;
 
  return (
    <>
      <div className='split-pane pane-right'>
      <div className='container no-gutter' onClick={onCloseMenu}>
          <AuthenticatedTemplate>
            { userState &&
              <Switch>
                <Route
                  exact
                  path={["/"]}
                  render={() => {
                    if(readyToRoute) {
                      const user = Storage.getItem(Constants.currUserKey);  
                      const jwtData = Utils.decodeJwt(user.jwt);
                      var coverKey = jwtData.CoverKey;
                      if (redirectToCoverSearch && (coverKey == null)) {
                        return <ChooseCoverUser/>
                      }
                      // else if (sessionID) {
                      //   window.location.href = "/chat"
                      //   //return <MainForm/>
                      // }
                      // else if (redirectToDashbaord) 
                      // {
        
                      // }
                      else {
                        //window.location.href = "/chat";
                        window.location = "/welcome"
                        //return <MainForm/>
                      }
                    }
                    else {
                      return (
                      <Overlay show={true}>
                        <i className="spinner-eclipse"></i>
                      </Overlay>
                      )
                    }
                  }
                }
                  />
                {/* <Route
                  path={'/setup/patient-forms'}
                  render={(e) => onRender(e, '../Pages/MainPage/MainPage')}/> */}
                <Route
                  exact
                  path={['/communications']}
                  component={CommunicationsTab}/>
                <Route
                  exact
                  path={['/reports']}
                  component={ReportsTab}/>
                <Route
                  exact
                  path={['/setup']}
                  component={SetupTab}/>
                <Route
                  path={['/communications/chat']}
                  component={MainForm}/>
                <Route
                  path={['/chat']}
                  render={() => {
                    window.location = '/communications/chat';
                  }
                }
                />
                <Route
                  path={['/setup/patient-forms']}
                  component={RegistrationForms}/>
                <Route
                  path={['/registration']}
                  render={() => {
                    window.location = '/setup/patient-forms';
                  }
                }
                />
                <Route
                  path={['/setup/online-booking']}
                  component={OnlineBookingMaster}/>
                <Route
                  path={['/onlinebooking']}
                  render={() => {
                    window.location = '/setup/online-booking';
                  }
                }
                />
                <Route
                  path={['/setup/pushnotifications']}
                  component={PushNotificationsPage}/>
                <Route
                  path={['/reports/recall-overview']}
                  component={ReportsPage}/>
                <Route
                  path={['/reports/recall-detail']}
                  component={RecallDetailsPage}/>
                <Route
                  path={['/reports/reminders']}
                  component={RemindersPage}/>
                <Route
                  path={['/reports/appointments']}
                  component={AppointmentsPage}/>
                <Route
                  path={['/reports/cancellation']}
                  component={CancellationPage}/>
                <Route
                  path={['/reports/orders']}
                  component={OrdersPage}/>
                <Route
                  path={['/reports/visits']}
                  component={VisitsPage}/>
                <Route
                  path={['/reports/purchase-recapture']}
                  component={PurchaseRecapturePage}/>
                <Route
                  path={['/customer']}
                  component={CustomerInformation}/>
                <Route
                  path={['/chooseCoverUser']}
                  component={ChooseCoverUser}/>
                <Route
                  path={['/welcome']}
                  component={WelcomePage}/>
                <Route
                  path={["/logout"]}
                  render={() => {
                    UserService.removeUserData();
                    window.location = '/';
                  }
                  }/>
                <Route component={WelcomePage}/>
              </Switch>
            }
            { !userState &&
              // <div>Signing in...(User not yet loaded)</div>
              <Overlay show={true}>
                <i className="spinner-eclipse"></i>
              </Overlay>
            }
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {/* <div>Signing in...</div> */}
            <Overlay show={true}>
                  <i className="spinner-eclipse"></i>
            </Overlay>
          </UnauthenticatedTemplate>
        </div>
      </div>
    </>
  );
  }
