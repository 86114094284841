import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from "material-table";
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Select from '@material-ui/core/Select';
import { arraySort } from '../../../../ContextLib/Core/coreLib';
import { useFiltersContext, useRequest, useResponsiveUtil, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

import './InsuranceTypes.scss';

import { CommonObjects } from '../../OnlineBookingSetupMaster';
import { Button } from '@material-ui/core';
import { useTour } from '@reactour/tour'
import TourSteps from '../../../../Store/TourSteps'
import {TourServiceContext} from '../../../../Store/TourServiceProvider'

const prePropText = 'Number of days out that a patient can schedule an appointment for. 0 will allow them to schedule for the same day. Please note that processing times will be updated for all Insurance carriers';

export default function InsuranceTypes_List({refresh, saveData}) {
    var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');
    const { setIsOpen, setCurrentStep ,setSteps } = useTour();

    const {
        setCurrentTab,
        setTourNext
       } = useContext(TourServiceContext);  

    let componentId = "insuranceTypes_List";
    const filtersContext = useFiltersContext(componentId, ({locKey, acctKey}) => {
        //On filters changed
        setLocKey(locKey);
        setAcctKey(acctKey);
      });

    const ui = useUIContext(componentId);
    const Request = useRequest();    

    const NO_INSURANCE = "No Insurance";
    const OTHER_INSURANCE = "Other Insurance";

    const [locKey, setLocKey] = useState(filtersContext.Values.locKey);
    const [acctKey, setAcctKey] = useState(filtersContext.Values.acctKey);
    const [data, setData] = useState([]);

    useEffect(() => {        
        getData();        
    }, [locKey, acctKey, refresh]);

    const protectedRecord = (model) => {
        return model.insName == NO_INSURANCE || model.insName == OTHER_INSURANCE;
    };

    const getData = async () => {
        ui.ShowOverlay();

        let model = {
            coverKey: coverKey,
            locKey: locKey,
            acctKey: acctKey
        };

        let res = await Request.modGetInsuranceData(model);

        if (res.success) {
            let d = res.data?.length > 0 ? res.data : [];
            setData(d);
            // set Tours
           // setIsOpen(false)
            setSteps(TourSteps.tourStepsInsurance)
            setCurrentTab(componentId)
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const deleteData = async (model) => {
        if (protectedRecord(model)) {
            ui.ErrorBox("This is a required Insurance carrier and cannot be deleted");
            return;
        }

        let ret = await ui.ConfirmBox("Where would you like to delete this record?", 
        "Delete Record", {
            confirmText: "From this Schedule only",
            cancelText: "From All"
        });

        if (ret == -1) {
            return;
        }

        if (ret == 0) {
            model.locKey = locKey;
            model.acctKey = acctKey;
        }

        ui.ShowOverlay();    
        let res = await Request.modDeleteInsuranceData(model);

        if (res.success) {
            getData();
            
            let d = res.data;
            let summary = "";
            if (d.deleted.length > 0) summary += " Deleted " + d.deleted.length + " record(s)";
            
            ui.ShowSnackbar("Insurance type was successfully deleted" + summary);
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }
        ui.HideOverlay();
    };

    const _saveData = async (model) => {
        let confirmed = await ui.ConfirmBox("Warning: Pre-Processing Time is the number of days out that a patient can schedule an appointment for. 0 will allow them to schedule for the same day. Please note that processing times will be updated for all Insurance carriers. Would you like to update this record?");
        if (confirmed != 1) return;

        saveData(model);
    };

    const columns = [
        { field: 'insName', title: 'Insurance Carriers', width: 170, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <CommonObjects.HtmlTooltip title={dataRow.insName} placement="top-start"><span>{dataRow.insName}</span></CommonObjects.HtmlTooltip> } },
        { field: 'daysBlocked', title: 'Pre-Processing Time', type: 'numeric', width: 100, headerStyle: { paddingRight: 50, textAlign: "left" }, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <CommonObjects.HtmlTooltip title={dataRow.daysBlocked} placement="top-start"><span>{dataRow.daysBlocked}</span></CommonObjects.HtmlTooltip> },
        editComponent: (props) => (
            <Input 
              defaultValue={props.value} 
              onChange={(e) => {
                let ok = !isNaN(e.target.value);
                if (ok) ok = parseInt(e.target.value) >= 0;
                if (!ok) e.target.value = 0;
                props.onChange(e.target.value);
              }}
              type="number"
              inputProps={{ "min": 0 }}
            /> 
        )},
        { field: 'orderKey', title: 'Display order', width: 100, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" },
        render: dataRow => (orderKeyDisplayRender(dataRow.orderKey)),
        editComponent: props => (orderKeyEditComponentRender(props))},
        { field: 'schedule', title: 'Schedule', width: 225, editable: 'never', width: 105, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: "left" }, render: dataRow => { return <CommonObjects.HtmlTooltip title={dataRow.schedule} placement="top-start"><span>{dataRow.schedule}</span></CommonObjects.HtmlTooltip> } },
    ];

    const orderKeyDisplayRender = (orderKey) => {
        if (orderKey === 0) {
            return null;
        } else if (orderKey === 1) {
            return <span>Display first</span>
        }
        return <span>Display last</span>
    }

    const orderKeyEditComponentRender = (props) => {
        if (props.value === 0) {
            return null;
        }
        return <Select native value={props.value} 
                onChange={(event) => {props.onChange(event.target.value)}}>
                <option value={-1}>Display last</option>
                <option value={1}>Display first</option>
               </Select>
    }


    const bp = useResponsiveUtil();

    return (
    <div className="insurance-data">
        <MaterialTable
            height="200"
            columns={columns}
            data={data}
            options={{...CommonObjects.TableOptions, actionsColumnIndex: bp.xs.window ? 0 : -1}}
            editable={{
                onRowUpdate: _saveData
            }}
            actions={[
                (rowData) => (                    
                {
                    icon: <Delete />,
                    tooltip: protectedRecord(rowData) ? null : 'Delete',
                    onClick: async (event, model) => {                    
                        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this record?");
                        if (confirmed != 1) return;
                
                        deleteData(model);
                    },
                    disabled: protectedRecord(rowData)
                })
            ]}
            components={{
                Action: 
                    props => {
                        if (typeof props.action === "function"){
                            var element=props.action(props.data);
                            return (
                                <IconButton size="small" title={element.tooltip} onClick={e=>element.onClick(e, props.data)}
                                data-tut={`reactour__insurance_edit`}>
                                    <Icon>{element.icon?.render(props, element.icon)}</Icon>
                                </IconButton>);
                        } else if (typeof props.action.action === "function"){
                            var element=props.action.action(props.data);
                            return (
                                <IconButton size="small" title={element.tooltip} onClick={e=>element.onClick(e, props.data)}
                                data-tut={`reactour__insurance_delete`}>
                                    <Icon>{element.icon}</Icon>
                                </IconButton>
                            );
                        } else {
                            var element=props.action;
                            return (
                                <IconButton size="small" title={element.tooltip} onClick={e=>element.onClick(e, props.data)}
                                data-tut={`reactour__insurance_delete`}>
                                    <Icon>{element.icon?.render(props, element.icon)}</Icon>
                                </IconButton>
                            )
                        }
                    }
              }}
        />
    </div>);
}