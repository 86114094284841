import ClDataCore from '../Core/coreData';
import APIs from "../webschedulerconfig_api.json";

const secretCode = "ditac-1";

export class ClDataRequestObject extends ClDataCore {    
    _request = {};
    _v5Request = {};
    _cancelToken;

    constructor(cancelToken, onReturn) {
        super();
        this._cancelToken = cancelToken;
        this.onReturn = onReturn;
        this._init();
    }
    
    _init() {
        const getUrl = (x) => {
            let v = sessionStorage.getItem("REACT_APP_WEBSCHEDULERCONFIG_API");
            let url = v + x.url + "?secretCode=" + secretCode;

            // let r = /{.*?}/;
            // let matches = r.exec(x.url);                
            // if (matches) {
            //     let str = matches[0].replace("{process.env.", "").replace("}", "");
            //     let v = process.env[str];
            //     url = url.replace(r, v);
            // }
            return url;
        };

        const req = (x) => this._request[x.name] = async (model) => await this._Request(x.name, x.method, getUrl(x, model), model, this._cancelToken, x.v5ReturnType);
        const v5req = (x) => this._v5Request[x.name] = async (model) => await this._V5Request(x.name, x.method, getUrl(x, model), model, this._cancelToken);

        APIs.filter(x => !x.v5ReturnType).forEach(x => req(x));
        APIs.filter(x => x.v5ReturnType).forEach(x => v5req(x));
    }
    
    get Request() {
        return this._request;
    }

    get V5Request() {
        return this._v5Request;
    }
}