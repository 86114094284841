import React, {useState, useEffect} from 'react';
import '../TwoWayConversation/TwoWayConversation.scss';
import './TwoWayConversationMessages.scss';
import {FiChevronDown, FiSmile} from 'react-icons/all';
import {format} from 'date-fns';
import Utils from '../../Store/Utils/Utils';
import TwoWayConversationSkeleton from '../TwoWayConversation/TwoWayConversationSkeleton';
import UserService from '../../Store/UserService';
import TwoWayConversationService from '../../Store/TwoWayMessageService/TwoWayConversationService';
import Logger from '../../Store/Logger/Logger';
import LocationService from '../../Store/LocationsService/LocationsService';
import {Tooltip} from '@material-ui/core';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const TwoWayConversationMessages = ({
                                      conversationList,
                                      messages,
                                      conversation,
                                      patient,
                                      loadingConversationDetails,
                                      setSendingMessage,
                                      handleRefreshConversationAfterSend,
                                      handleRefreshConversationListAfterSend,
                                      unknownPatient,
                                      windowHeight,
                                      newMessage
}) => {
  const isLocationAware = LocationService.getLocationAware()
  const isMobile = (Utils.getAgent() !== 'iOS' && Utils.getAgent() !== 'android') ? false : true;
  const heightDesktopOffset = 305;
  let heightMobileOffset = 278;
  if (newMessage && isMobile) {
    heightMobileOffset = 290
  }

  let windowHeightAdjustment = unknownPatient ? (isMobile ? heightMobileOffset : heightDesktopOffset) : (isMobile ? heightMobileOffset + 30 : heightDesktopOffset);
  const windowAdjustLocationAware = (!isLocationAware && isMobile) ? 30 : 0;
  if(newMessage){
    windowHeightAdjustment = windowHeightAdjustment + 375;
    if (patient) {
      windowHeightAdjustment = windowHeightAdjustment - 195;
    }
  }

  const messageListScrollHeight = {
    height: windowHeight - windowHeightAdjustment + windowAdjustLocationAware + 'px',
    minHeight: windowHeight - windowHeightAdjustment + windowAdjustLocationAware + 'px'
  }

  const handleRetrySend = async (_message, _imageUrls) => new Promise((resolve, reject) => {
    const payload = {
      SessionId: UserService.getSessionId(),
      ConversationKey: conversation.convKey,
      Message: _message,
      PublicImageUrls: _imageUrls,
      PrivateImages: [],
    }
    setSendingMessage(true);
    return TwoWayConversationService.postMessage(payload)
      .then((resp) => {
        resolve(resp);
        setSendingMessage(false);
        handleRefreshConversationAfterSend();
        handleRefreshConversationListAfterSend();
      })
      .catch((err) => {
        setSendingMessage(false);
        Logger.printLog('postMessage', payload, 'error');
        reject(err)
      })
  })

  const loadDefaultSrc = (e) => {
    e.target.src = '';
    e.target.alt = 'Image failed to load'
  }

  const scrollToBottom = () => {
    document.querySelector('.message--conversation--list').scrollTo(0, document.querySelector('.message--conversation--list').scrollHeight)
  }

  const options = {
    settings: {
      autoplaySpeed: 0,
      lightboxTransitionSpeed: 0,
      lightboxTransitionTimingFunction: "anticipate"
    },
    buttons: {
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
      showAutoplayButton: false,
    },
    thumbnails: {
      showThumbnails: false
    }
  };

  const message = (msg, msgUrlArray) => {
    if (msg && !msgUrlArray) {
      return <p>{msg}</p>
    }
    if (msgUrlArray) {
      return (
        <>
          {msg !== '' && <p>{msg}</p>}
          <p className="image--holder">
            {
              msgUrlArray.map((item, index) => {
                return (
                  <span className="image--card" key={index}>
                    <SimpleReactLightbox>
                      <SRLWrapper options={options}>
                          <img className="image--img" src={item} alt="" 
                            onLoad={scrollToBottom}
                            onError={loadDefaultSrc}/>
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </span>
                )
              })
            }
          </p>
        </>
      )
    }
  }

  const deliveryStatus = (status, message, imageUrls, timestamp) => {
    if ( (status === 'scheduled') && (new Date() - timestamp > 60000)) {
      return (
        <span> Scheduled </span>
      )
    } else if (status === 'delivered') {
      return (
        <span> Sent </span>
      )
    } else if (status === 'failed' || status === 'delivery_unknown' || status === 'undelivered') {
      return (
        <Tooltip title='Click to resend this message'>
          <span onClick={() => handleRetrySend(message, imageUrls)} className="message--label-status"> Not Delivered <u> Retry </u></span>
        </Tooltip>
      )
    } else if (status === 'error') {
      return (
        <span className="message--label-status"> Not Delivered </span>
      )
    } else if (status === 'read') {
      return (
        <span> Read </span>
      )
    } else if (status === 'unread') {
      return (
        <span> Unread </span>
      )
    } else if (status === '' || !status || status === null || status === 'scheduled' || status === 'accepted' || status === 'queued' || status === 'sending' || status === 'sent') {
      return (
        <span> Sending... </span>
      )
    }
  }

  return (
    <>
      <div className='message--conversation message--conversation--list' ref={conversationList} style={messageListScrollHeight}>

        {(!loadingConversationDetails && patient) && messages.messages.map((item, key) => {

          let patientSmsTo = patient?.smsTo ? patient.smsTo.replace(/-/g, "") : '';
          let isOutbound = isLocationAware ? (item.smsFrom.indexOf(patientSmsTo) < 0) : (item.smsFrom.indexOf('4PC') >= 0);
          let stateFrom = isOutbound ? 'sent' : 'receive';
          const created = format(new Date(item.created), 'MM/dd/yy h:mm a');
          const phone = isOutbound ? 'Staff' : Utils.formatPhoneNumber(item.smsFrom);

          return <div key={key} className={stateFrom}>
            <span>{phone}</span>
            {message(item.message, item.imageUrls)}
            <div className="message--label">
              {(stateFrom === 'sent') && deliveryStatus(item.deliveryStatus, item.message, item.imageUrls, item.created)} <span>{created}</span> </div>
            </div>
        })}

        {loadingConversationDetails && <TwoWayConversationSkeleton/>}
      </div>
    </>
  )
}

export default TwoWayConversationMessages;