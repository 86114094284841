const mapTemplateInfo = (payload) => {
  let templates = [];
  if (payload.hasOwnProperty('Templates')) {
    payload.Templates.filter((template) => {
      template = {
        name: template.Name,
        type: template.Type,
        body: template.Body,
        locale: template.Locale
      }
      templates.push(template);
    });
  }

  let newPayload = {
    hipaa: {
      consented: payload.HasGivenHipaaConsent ?? null,
      lastRequestedDate: payload.LastSentHipaaRequestDate ?? null,
    },
    templates: templates
  }
  return newPayload;
}

export default {
  mapTemplateInfo
};