import React, { useEffect, useState,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Filters from '../../ContextLib/CoreConsumer/Components/Filters';
import { useFiltersContext, useRequest, useUIContext } from '../../ContextLib/contextHooks';
import './OnlineBookingSetup.scss';
import InsuranceTypes from './Components/InsuranceTypes/InsuranceTypes';
import AppointmentTypes from './Components/AppointmentTypes/AppointmentTypes';
import PatientInfo from './Components/PatientInfo/PatientInfo';
import General from './Components/General/General';
import ScriptGenerator from './Components/ScriptGenerator/ScriptGenerator';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import WebScheduler from './Components/WebScheduler/WebScheduler';
import TabPanels from '../../CRMComponents/CRMTabs/TabPanels/TabPanels';
import Tabs from '../../CRMComponents/CRMTabs/Tabs';
import { environment } from '../../environment';
import { useV5 } from '../../ContextLib/CoreConsumer/v5Contexts';
import CRMTabsContext from '../../CRMComponents/CRMTabs/CRMTabsContext';
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 10,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function OnlineBookingSetup({coverKey}) {
    const coverKeyScript = sessionStorage.getItem('onlinebookingsetup-coverkey');
    const componentId = "online-booking-setup";
    const ui = useUIContext(componentId);
    const tabContext = useContext(CRMTabsContext);
    const [isScriptGenerator,setIsScriptGenerator] = useState(true);

    const filtersContext = useFiltersContext(componentId, ({cover}) => {
        //On filters changed
        setCover(cover);
    });
    const Request = useRequest();

    const [tabValue, setTabValue] = useState(0);
    const [err, setErr] = useState(null);
    const [cover, setCover] = useState();

    const getCover = async () => {
        let d = {
            cover: null,
            locKey: 0,
            acctKey: 0
        };

        filtersContext.SetValues(d);
        filtersContext.UpdateClients_FiltersChanged();

        let res = await Request.getCover({coverKey: coverKey});

        if (res.success) {
            d.cover = res.data;
            
            if (d.cover) {
                filtersContext.SetValues(d);
                filtersContext.UpdateClients_FiltersChanged();
            } else {
                setErr(`No information found for CoverKey: ${coverKey}`);
            }
        } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
        }    
    };

    const { c } = useV5();
    
    useEffect(() => {    
        return ()=> c.CancelAllRequests();

    }, []);

    useEffect(()=>{
        sessionStorage.setItem("REACT_APP_WEBSCHEDULERCONFIG_API", process.env.REACT_APP_WEBSCHEDULERCONFIG_API);

    }, []);

    useEffect(async ()=> {        
        sessionStorage.setItem('onlinebookingsetup-coverkey', coverKey);
        await getCover();

    }, [coverKey]);


    const menuOnchange = (value) =>
    {
        tabContext.SetTabValue('registration-forms-tabs',value);
        setTabValue(value)
      
    }
    const scriptOnchange =() =>{
       setIsScriptGenerator(true)
    }

    var itemPanels = [
        {'ElementName':Constants.insuranceTypeMenu,'Title':'Insurance Types','Panel':<InsuranceTypes />},
        {'ElementName':Constants.appointmentTypeMenu,'Title':'Appointment Types','Panel':<AppointmentTypes />},
        {'ElementName':Constants.patientInsuranceMenu,'Title':'Patient Information','Panel':<PatientInfo />},
        {'ElementName':Constants.generalMenu,'Title':'General','Panel':<General />},
        {'ElementName':Constants.scheduleMenu,'Title':'Schedule','Panel':<WebScheduler menuOnchange={menuOnchange} />},
        {'ElementName':Constants.scriptGeneratorMenu,'Title':'Script Generator','Panel':<ScriptGenerator menuOnchange={scriptOnchange} CoverKey ={coverKeyScript}/>},
    ];

    var items = [];
    var panels = [];

    itemPanels.forEach(
        function(item, index){
            if(UserService.checkElementAccess(item.ElementName))
            {
                items.push(item.Title);
                panels.push(item.Panel);                
            }
        }   
    );

    const tabProps = {
        componentId: 'registration-forms-tabs',
        items: items,
        baseBgColor:'#f4f5f8',
        tabStyle:{
          height: '32px',
          color: 'black',
          bgColor: 'initial'
        },
        activeTabStyle:{
          bgColor: 'white',
          color: 'black'
        },
        activeIndex :  tabValue,
        onClick: setTabValue
      };
  
    const tabPanelsProps = {
    componentId: "registration-forms", 
    tabHeadComponentId: "registration-forms-tabs",
    panels: panels
    };  
      

    return (
        <>
            {cover ? (<>                
                <div className='module-title'>
                    <h1>{filtersContext.Values.cover?.orgName}</h1>
                </div>
                <div className='tabs-cont'>
                    <Tabs {...tabProps}/>     
                    <div className='filters-panels'> 
                    {
                       tabValue === 5 ? null : <Filters title={tabProps.items[tabValue]} /> 
                    }                                  
                      
                        <TabPanels {...tabPanelsProps}/>
                    </div>                       
                </div>
            </>) : 
            err ? 
            (<span style={{color:'red'}}>
                {err}
            </span>) :
            (<>
                {ui.uiJSX.loadingJSX}
            </>)}          
        </>
    );
}