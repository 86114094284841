/**
 * Represents storing items in local session.
 */
class Session {
  /**
   * Returns an item by the provided name.
   * @param {String} name The name of the item.
   * @returns String
   */
  static getItem(name) {
    let item = sessionStorage.getItem(name);

    if (item) {
      item = JSON.parse(item);
    }

    return item;
  }

  /**
   * Removes an item from local storage
   * @param {string} name  The name of the item
   */
  static removeItem(name) {
    sessionStorage.removeItem(name);
  }

  /**
   * Sets an item in session.
   * @param {String} name The name of the item.
   * @param {any} item The item to set.
   */
  static setItem(name, item) {
    if (name) {
      if (item) {
        sessionStorage.setItem(name, JSON.stringify(item));
      } else {
        sessionStorage.setItem(name, item);
      }
    }
  }
}

// Lock object to prevent modification (true static).
Object.freeze(Session);

export default Session;
