import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ColorPicker } from '@wellbees/color-picker-input'
import MaterialTable from "material-table";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from '@material-ui/icons/FilterList';
import Collapse from '@material-ui/core/Collapse';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 200,
  },
}));

export default function ScriptGenerator(props) {

  const classes = useStyles();
  const [coverKey, setCoverKey] = useState('');
  const [locKey, setLocKey] = useState('');
  const [locName, setLocName] = useState('');
  const [taskName, setTaskName] = useState('');
  const [taskKey, setTaskKey] = useState('');
  const [pathId, setPathId] = useState(0);
  const [colorValue, setColorValue] = useState('')
  const [checked, setChecked] = React.useState(false);
  const [showLinkGrid, setShowLinkGrid] = React.useState(false);
  const [collapseMoreOptions, setCollapseMoreOptions] = useState(false);
  const [tid, setTid] = useState('');
  const [gid, setGid] = useState('');
  const [referredby, setReferredBy] = useState('');
  const [source, setSource] = useState('');



  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');
  const [locKeyList, setlocKeyList] = useState([]);
  const [taskKeyList, setTaskKeyList] = useState([]);
  const [coverKeyHasChange, setCoverKeyHasChange] = useState(false);
  const [linkList, setLinkList] = useState([]);
  const [coverKeyList, setCoverKeyList] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [gidIsValid, setGidIsValid] = useState(false);



  useEffect(() => {
    getCoverKey();
    CoverKeyChange(props.CoverKey)

  },[]);
  useEffect(() => {
  
    if (groupMemberList.length > 0) {
      const filter = groupMemberList.filter((item) => item.groupID.toString() === gid);
      if (filter.length > 0) {
        setGidIsValid(true)
      }
      else {
        setGidIsValid(false)
      }
    }
    if(locKey === ''){
      setGidIsValid(true)
    }
  }, [gid]);

  const CoverKeyOnInput = (coverKey) => {
    setCoverKeyHasChange(true);
    setCoverKey(coverKey);
    setLocKey("");
    setLocName("");
    setTaskName("");
  };


  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
      fontFamily: 'Noto Sans, sans-serif',
    },
  }))(Tooltip);

  const linkColumns = [
    { field: 'coverKey', title: 'CoverKey', width: 70, cellStyle: { padding: 10 }, editable: 'never' },
    { field: 'locKey', title: 'Location', width: 70, cellStyle: { padding: 10 }, editable: 'never' },
    { field: 'name', title: 'Appointment', width: 150, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }, render: dataRow => { return <HtmlTooltip title={dataRow.name} placement="top-start"><span>{dataRow.name}</span></HtmlTooltip> } },
    {
      title: 'Link V5 Embedded',

      render: (rowData) =>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => handleCopyCode(rowData.v5Link)}
          className={classes.button}
        >
          COPY CODE
        </Button>,
      width: 120,
    },
    {
      title: 'V5 Web Scheduler Script',
      render: (rowData) =>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => handleCopyCode(rowData.v5WidgetLink)}
        >
          COPY CODE
        </Button>,
      width: 120,
    },
  ];

  const getCoverKey = async () => {
    setCoverKeyHasChange(false);
    setshowLoadingOverlay(true);
    setlocKeyList([]);
    setTaskKeyList([]);

    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/configuration/getCoverKeys?secretCode=${apiKey}`;
    const res = await axios.get(url);
    if (res?.data) {
      let d = res.data;
      if (d.length > 0) {
        let datas = res.data;
        let filteredCoverKey = datas.filter(cover => cover.coverKey === Number(props.CoverKey))
        setCoverKey(filteredCoverKey)
        setCoverKeyList(filteredCoverKey);
      }
      else {
        let message = 'Coverkey doesn\'t exist!';
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
    }
    else {
      let message = 'Coverkey doesn\'t exist!';
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
  };

  const CoverKeyChange = async (coverKey) => {
    if (coverKey !== null) {
      setCoverKeyHasChange(true);
      setCoverKey(coverKey);
      setLocKey("");
      setLocName("");
      setTaskName("");
      setshowLoadingOverlay(true);
      setlocKeyList([]);
      setTaskKeyList([]);
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      let _coverKey = coverKey === '' ? coverKey : coverKey.split('-')[0];
      let _locKey = locKey === '' ? locKey : locKey.split('-')[0];
      // const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/v1/configuration/getLocationsByCoverKey?secretCode=${apiKey}&coverKey=${_coverKey}`;
      const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/configuration/getLocationsByCoverKey?secretCode=${apiKey}&coverKey=${_coverKey}`;
      const res = await axios.get(url);
      if (res?.data) {
        let d = res.data;
        if (d.length > 0) {
          let datas = res.data;
          setlocKeyList(datas);
          GetTaskKey(_coverKey, 0);
        }
        else {
          let message = 'No Location for this cover';
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        }
      }
      else {
        let message = 'No Location for this cover';
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
      setshowLoadingOverlay(false);
    }
    else {
      handleClearFeild();
    }
  };
  const renderColorPicker = async => {
    return <ColorPicker
      label={"Color Scheme"}
      value={colorValue}
      inputType="mui"
      onChange={(color) => setColorValue(color)}
    />
  }

  const GenerateLink = async () => {
    setshowLoadingOverlay(true);
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    let hideDemoGrap = checked === true ? 1 : 0;
    let colorScheme = colorValue.replace('#', '');
    let _locKey = locKey===''? locKey: locKey.split('-')[0];
    let _coverKey = props.CoverKey
    const url = `${process.env.REACT_APP_WSI_API}/v1/Configuration/getWSGeneratedLink?secretCode=${apiKey}&coverKey=${_coverKey}&colorScheme=${colorScheme}&taskName=${taskName}&locKey=${_locKey}&tid=${tid}&gid=${gid}&source=${source}&referredBy=${referredby}`;
    const res = await axios.get(url);
    if (res?.data) {
      let d = res.data;
      if (d.length > 0) {
        let datas = res.data;
        setLinkList(datas);
        setShowLinkGrid(true)
      }
      else {
        let message = 'No avaiable link for this combination.';
        setOpenSnackBar(true);
        setSnackbarMessage(message);
        setShowLinkGrid(false);
      }

    }
    else {
      let message = 'No avaiable link for this combination.';
      setOpenSnackBar(true);
      setSnackbarMessage(message);
      setShowLinkGrid(false)
    }

    setshowLoadingOverlay(false);
  };

  const handleCopyCode = (link) => {
    navigator.clipboard.writeText(link);
    setOpenSnackBar(true);
    setSnackbarMessage("Copy to Clipboard.");

  };
  const GetTaskKey = async (_coverKey, _locKey) => {
    setshowLoadingOverlay(true);
    setTaskKeyList([])
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/Configuration/getTaskByCoverKey?secretCode=${apiKey}&coverKey=${_coverKey}&locKey=${_locKey}`;
    const res = await axios.get(url);
    if (res?.data) {
      let d = res.data;
      if (d.length > 0) {
        let datas = res.data;
        setTaskKeyList(datas);
      }
      else {

      }
    }
    setshowLoadingOverlay(false);
  };
  const GetGroupMembersByLocKey = async (_locKey) => {
    setshowLoadingOverlay(true);
    setGroupMemberList([])
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/Configuration/getWSGroupMembersByLockey?secretCode=${apiKey}&locKey=${_locKey}`;
    const res = await axios.get(url);
    if (res?.data) {
      let d = res.data;
      if (d.length > 0) {
        let datas = res.data;
        setGroupMemberList(datas);
      }
      else {

      }
    }
    setshowLoadingOverlay(false);
  };
  const handleClearFeild = async () => {
    setCoverKeyHasChange(false);
    setCoverKey("");
    setLocKey("");
    setLocName("");
    setTaskName("");
    setPathId("");
    setColorValue(0);
    setChecked(false);
    setShowLinkGrid("");
    setTid("");
    setGid("")
    setLinkList([]);
    renderColorPicker();
    setSource("");
    setReferredBy("");
    setGroupMemberList([]);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };



  const LocKeyChange = async (locKey) => {
    if (locKey === '' || locKey === null) {
      setLocKey('')
    }
    else {
      setLocKey(locKey)
      let _coverKey = props.CoverKey
      let _locKey = locKey === '' ? locKey : locKey.split('-')[0];
      GetTaskKey(_coverKey, _locKey);
      GetGroupMembersByLocKey(_locKey)
    }
  }

  const TaskNameChange = async (taskName) => {
    if (taskName === '' || taskName === null) {
      setTaskKey('')
      setTaskName('')
    }
    else {
      const filter = taskKeyList.filter((item) => item.name === taskName);
      setTaskName(taskName)
      setTaskKey(filter[0].taskKey)
    }
  }
  const clickFilter = async () => {
    let colapse = !collapseMoreOptions
    setCollapseMoreOptions(colapse)
  }
  return (
    <React.Fragment>
      <div >
        <div >
          <Box p={4} pl={12} bgcolor="background.paper">
            <Grid container justify="flex-start" alignItems="center" spacing={2} className="reporting-header">
              <Typography variant="h5" >
                Web Scheduler Script Generator
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Typography variant="h6">
                Create a Custom script to add web Scheduler to your practices website
              </Typography>
            </Grid>
          </Box>

        </div>

        <Divider className='header-divider' />
        <div>
          <Box p={3} bgcolor="background.paper">
            <ValidatorForm onSubmit={GenerateLink} onError={errors => console.log(errors)}>
              <Grid container justify="space-evenly"
                alignItems="center" spacing={3} >
                <Grid item xs={12} sm={4}>
                  <TextField
                    disabled
                    id="filled-disabled"

                    inputProps={{
                      style: {
                        height: "25px",
                        width: "575px",
                        paddingTop: "17px"
                      },
                    }}
                    value={coverKeyList.map((item) => `${item.coverKey.toString()}${item.orgName === null ? '' : ' - ' + item.orgName}`)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    noOptionsText={'No Lockey found'}
                    options={locKeyList.map((item) => `${item.locKey.toString()}${item.orgName === null ? '' : ' - ' + item.orgName}`)}
                    id="locKey"
                    value={locKey}
                    onChange={(event, value) => LocKeyChange(value)}
                    renderInput={(params) => <TextField  {...params} label="Location Key" margin="dense" />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    noOptionsText={'No TaskKey found'}
                    options={taskKeyList.map((item) => item.name)}
                    id="taskName"
                    value={taskName}
                    onChange={(event, value) => TaskNameChange(value)}
                    renderInput={(params) => <TextField
                      label={locKey !== '' ? 'Please Select Task Key' : 'TasK Name'}
                      required={locKey !== ''}
                      {...params} margin="dense" />}
                  />
                </Grid>
              </Grid>
              <Collapse in={collapseMoreOptions} timeout="auto">
                <Grid container justify="space-evenly" 
                  alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={3} >
                    <TextValidator
                      id="tid"
                      name="tid"
                      label="TID"
                      fullWidth
                      value={tid}
                      onInput={e => setTid(e.target.value)}
                      helperText={' '}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} >
                    <TextValidator
                      id="gid"
                      name="gid"
                      label="GID"
                      fullWidth
                      value={gid}
                      onInput={e => setGid(e.target.value)}
                      error={gid !== '' &&  gidIsValid === false}
                      helperText={ gidIsValid === false ? 'LocKey Does Not Belong to this Group ID.' : ' '}                           
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} >
                    <TextValidator
                      id="source"
                      name="source"
                      label="Source"
                      fullWidth
                      value={source}
                      onInput={e => setSource(e.target.value)}
                      helperText={' '}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} >
                    <TextValidator
                      id="referredby"
                      name="referredby"
                      label="Referred By"
                      fullWidth
                      value={referredby}
                      onInput={e => setReferredBy(e.target.value)}
                      helperText={' '}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Grid container justify="space-evenly" style={{ paddingTop: 20 }}
                alignItems="center" spacing={3}>
                <Grid item xs={12} sm={4}>
                  {renderColorPicker()}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Divider orientation="vertical" variant="middle" />
                  <div className="filterButton" alignItems="right">
                    <Tooltip title="More Options" aria-label="More Options">
                      <IconButton aria-label="More Options">
                        <FilterListIcon onClick={() => clickFilter()} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>

              <div style={{ marginBottom: 15, marginRight: 10, marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => handleClearFeild()}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 10 }}
                  type="button"
                >
                  Clear Field
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={gid !== '' &&  gidIsValid === false}
                >
                  Generate Code
                </Button>
              </div>
            </ValidatorForm>
          </Box>
        </div>


        <Divider />
        <div>
          {showLinkGrid &&
            <Box p={1} bgcolor="background.paper">
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12}>
                  <MaterialTable
                    height="100"
                    width="100"
                    columns={linkColumns}
                    data={linkList}
                    title=""
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [],
                      toolbar: false,
                      paging: true,
                      headerStyle: {
                        fontSize: 14,
                        padding: 10
                      },
                      actionsColumnIndex: -1,
                      tableLayout: "fixed"
                    }}

                  />
                </Grid>
              </Grid>
            </Box>
          }
        </div>
      </div>

      <Divider />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}