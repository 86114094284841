import React, { useState, useContext, useEffect }  from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import PreviewIcon from '@material-ui/icons/EventNoteTwoTone';

import { useV5_With_FilterAdapter, useFiltersContext, useRequest, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';

import Calendar from "./calendar";
import BlocksForm from './blocks-form';
import PreviewSchedule from './Preview-Schedule';

import './WebScheduler.scss';

import { useTour } from '@reactour/tour'
import TourSteps from '../../../../Store/TourSteps'
import {TourServiceContext} from '../../../../Store/TourServiceProvider'
import { useV5 } from '../../../../ContextLib/CoreConsumer/v5Contexts';
import { DateOnly } from '../../../../ContextLib/Core/coreLib';
import { CreateEventsFromBlocks } from './process';
import { Constants } from '../../../../ContextLib/Core/v5Core';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import './AvailabilityError.scss';

export default function WebScheduler(props) {        
    const componentId = "webscheduler";    
    const ui = useUIContext(componentId);
    const { c, f, coverLocAcctDt, n, V5Request } = useV5_With_FilterAdapter(componentId);
    
    const today = new Date();

    const {
        setCurrentTab,
        setTourNext
    } = useContext(TourServiceContext);

    const { isOpen, currentStep, setIsOpen, setCurrentStep, setSteps, setDisabledActions, stepsLength, disabledActions } = useTour();
    
    const [calendar, setCalendar] = useState();
    const [calendarDate, setCalendarDate] = useState(today);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerMode, setDrawerMode] = useState("");

    const [editEventData, setEventData] = useState();

    const [openDiaglog, setOpen] = React.useState(false);
    const [providerInfo, setProviderInfo] = React.useState({});
    const [locationInfo, setLocationInfo] = React.useState({});

    const [events, setEvents] = useState(null);
    const [minBlockDate, setMinBlockDate] = useState(today);
    const [maxBlockDate, setMaxBlockDate] = useState(today);
    const [gIdColors, setGIdColors] = useState([]);

    const [generatingLegacyBlocks, setGeneratingLegacyBlocks] = useState();
    const [mProps, setMProps] = useState();

    const [collapseAvailabilityErrors, setCollapseAvailabilityErrors] = useState(false);
    const [hasAvailabilityErrors, setHasAvailabilityErrors] = useState(false);
    const [availabilityErrors, setAvailabilityErrors] = useState([]);
    const [totalAvailabilityErrors, setTotalAvailabilityErrors] = useState(0);
    const [configFormWithError, setConfigFormWithError] = useState();
    const [loadConfigFormWithError, setLoadConfigWithError] = useState();
    const [calendarRef, setCalendarRef] = useState(null);
    const [highlightDate, setHighlightDate]  = useState(null);


    const generateLegacyBlocks = async () => {
        ui.ShowOverlay();
        setGeneratingLegacyBlocks(true);
        const res = await V5Request.updateLegacyTables(coverLocAcctDt());
        setGeneratingLegacyBlocks(false);
        ui.HideOverlay();
        return res;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const handleCreateAvailability = async () => {
        if (f.validAppointmentTypes.length > 0)
        {   
            setEventData(null);
            setDrawerMode("create-availability");
            setOpenDrawer(true);
        }
        else
        {
            setEventData(null);
            await ui.MessageBox("No appointment types have been configured. Please configure appointment types on the Appointment Types page")
            props.menuOnchange(1)
        }        
    }

    const handleBlackoutPeriod =async () => {
        if (f.validAppointmentTypes.length > 0)
        { 
            setEventData(null);
            setDrawerMode("create-blackoutperiod");
            setOpenDrawer(true);
        }
        else
        {
            setEventData(null);
            await ui.MessageBox("No appointment types have been configured. Please configure appointment types on the Appointment Types page")
            props.menuOnchange(1)
        }         
    }

    const closeDrawer = () => {
        setOpenDrawer(false);
    }

    const onSaved = async (res) => {    
        closeDrawer();
        if (res.status === 200) {
            getData();
        }
    }

    const handleCollapseAvailabilityErrors = () => {
        setCollapseAvailabilityErrors(!collapseAvailabilityErrors);
    }

    const onSaveAndValidate = async (res, resValidation, configFormWithError, configFormData) => {
        closeDrawer();
        
        await onValidate(resValidation, configFormWithError, configFormData);

        if (res.status === 200) {
            getData();
        }
    }

    const onValidate = async (res, configFormWithError, configFormData) => {
        let errorData = res.data;
        let totalErrorCount = getTotalErrorCount(errorData);
        setTotalAvailabilityErrors(totalErrorCount);
        setConfigFormWithError(configFormWithError);
        setLoadConfigWithError(configFormData);

        let conflictingDates = [];
        setAvailabilityErrors([]);
        errorData.forEach((data, idx) => {
            data.errors.forEach((error, errorId) => {
                conflictingDates.push(Object.assign({'id': errorId, 'conflictedWSConfigFormId': error.conflictedWSConfigFormId,
                                    'conflictedDate': error.conflictedDate, 'conflictedDateTimeBlock': error.conflictedDateTimeBlock}));
            });
        });

        conflictingDates.sort(function(a, b) {
            return new Date(a.conflictedDate).getTime() - new Date(b.conflictedDate).getTime();
        });

        setAvailabilityErrors(conflictingDates);

        if (totalErrorCount === 0)
        {
            setAvailabilityErrors([]);
            setConfigFormWithError([]);
            setLoadConfigWithError([]);
            setHasAvailabilityErrors(false);
            return;
        }
        else
        {
            const type = JSON.parse(configFormWithError.data).isBlackout ? "Blackout" : "Availability";
            let errorMessage = `The ${type} rule you are trying to save has a conflict with ${totalErrorCount} another Date(s). Click OK to show the conflict(s).` ;
            let ok = await ui.ErrorBox(errorMessage, "Availability Error");
    
            if (ok == 0 || ok ==-1) {
                setHasAvailabilityErrors(true);
                setCollapseAvailabilityErrors(true);
                setOpenDrawer(false);
            }
        }

   
    }

    const getTotalErrorCount = (errorData) => {
        let totalErrorCount = 0;

        errorData.forEach((validationError) => {
            totalErrorCount = validationError.totalErrorCount + totalErrorCount;
        });

        return totalErrorCount;
    }

    const handleCalendarRef = (ref) => {
        setCalendarRef(ref);
    };

    const onHandleClickConflictedDate = (date) => {
        let calendarAPI = calendarRef.getApi();
        calendarAPI.gotoDate(date);
        setHighlightDate(date);
    }

    const handleOnViewCurrentRequestClick = () => {
        if (JSON.parse(configFormWithError.data).isBlackout) {
            setDrawerMode("error-blackout");
        } else {
            setDrawerMode("error-availability");
        }     
        setOpenDrawer(true);
    }

    const handleOnCancelCurrentRequestClick = async () => {
        let confirmMessage = <>All current progress resolving conflicts will be lost. <br/> Do you want to cancel current request?</>;
        let confirmed = await ui.ConfirmBox(confirmMessage);
        if (confirmed == 1) {
            setHasAvailabilityErrors(false);
            setAvailabilityErrors([]);
            setTotalAvailabilityErrors(0);
            setConfigFormWithError([]);
    
          /*   let calendarAPI = calendarRef.getApi();
            calendarAPI.gotoDate(today); */
            setHighlightDate(null);
        }    
    }

    const editBlockGroup = async (eventData) => {
        if (eventData.isBlackout) {
            setDrawerMode("create-blackoutperiod");
        } else {
            setDrawerMode("create-availability");
        }
        var eventDataFilter = events.filter(x => x.groupId !== eventData.config.wsConfigFormId &&  new Date(x.start).toLocaleDateString('en-US') === new Date(eventData.editSingleEvent).toLocaleDateString('en-US'));

        var isGeneral = false;
        eventDataFilter.forEach(data => {
          if (data.extendedProps.config.configFormData.repetition.isGeneral === true)
              isGeneral = true;
        });

        if (!isGeneral)
        {
            setEventData(eventData);
            setOpenDrawer(true);      
        }
        else
             await ui.MessageBox("Delete all events on the day and re-add all schedules as required");        
    }

    const deleteBlockGroup = async (eventData) => {
        const config = eventData.config;
        const apiKey = `${process.env.REACT_APP_API_KEY}`;

        let refreshCalendarData = false;
        let confirmed = await ui.ConfirmBox("Are you sure you want to delete this event(s)?");
        if (confirmed == 1) {   
            ui.ShowOverlay();

            const configFormId = Number(config.wsConfigFormId);
            
            let url;
            let res;

            if (eventData.editSingleEvent != null)
            {
                const targetDate = moment(new Date(eventData.editSingleEvent)).format("YYYY-MM-DD");
                url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/DeleteSingleBlockByDate?configId=${configFormId}&targetDate=${targetDate}&secretCode=${apiKey}`;
            }
            else
            {
                url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/DeleteConfigForm?configId=${configFormId}&secretCode=${apiKey}`;
            }
            
            res = await axios.post(url);

            if (res.status === 200) {
                ui.ShowSnackbar(`Event was successfully deleted`);
                refreshCalendarData = true;
            }
            ui.HideOverlay();

            if (hasAvailabilityErrors)
            {
                const configFormId = Number(configFormWithError.groupId);
                const url = configFormId > 0 ? `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}&exceptConfigId=${configFormId}` : `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}`;
                const resValidation = await axios.post(url, configFormWithError);

                if (resValidation.status === 200 && resValidation.data.length === 0)
                {
                    if (configFormId > 0) //source of error is edit
                    {
                        let resUpdate;
                        let configWithErrorSinglEventOnly = (configFormWithError.startDate === configFormWithError.endDate && configFormWithError.repetitionType === "once") ? true : false;
                        if (editEventData.editSingleEvent || configWithErrorSinglEventOnly)
                        {
                            const configFormId = Number(configFormWithError.groupId);
                            const targetDate = moment(new Date(configFormWithError.startDate)).format("YYYY-MM-DD");
                            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditSingleBlockByDate?configId=${configFormId}&targetDate=${targetDate}&secretCode=${apiKey}`;
                            resUpdate = await axios.post(url, configFormWithError);
                        } else {
                            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditConfigForm?secretCode=${apiKey}`;
                            resUpdate = await axios.post(url, configFormWithError);
                        }

                        if (resUpdate.status === 200) {
                            ui.ShowSnackbar(`${JSON.parse(configFormWithError.data).isBlackout ? "Blackout" : "Availability"} was successfully updated`);
                        }
                    }
                    else //source of error is create
                    {
                        const urlSave = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/SaveConfigForm?secretCode=${apiKey}`;
                        res = await axios.post(urlSave, configFormWithError);
                        if (res.status === 200) {
                            ui.ShowSnackbar(`${JSON.parse(configFormWithError.data).isBlackout ? "Blackout" : "Availability"} was successfully created`);
                        }
                    }
                }

                await onValidate(resValidation, configFormWithError, loadConfigFormWithError);
            }
        }

        if (refreshCalendarData) getData();
    }

    const getData = async (refDate, prev) => {
        ui.ShowOverlay();
        const replenish = refDate ? (prev ? "prev" : "next") : null;
        if (!replenish) {
            setMinBlockDate(today);
            setMaxBlockDate(today);
        }

        var calendarParams = {
            ...coverLocAcctDt(refDate),
        }

        setEvents([]);

        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/GetCalendarData?secretCode=${apiKey}`;
        const res = await axios.post(url, calendarParams);

        if (res.status === 200 ) {
            
            if (res.data.blocks != null && res.data.blocks?.length > 0)
            {
                const periodData = res.data.period;
                const blocks = res.data.blocks;

                const _min = DateOnly(new Date(periodData.startDate));
                const _max = DateOnly(new Date(periodData.endDate));
                if (!minBlockDate || _min < minBlockDate) setMinBlockDate(_min);
                if (!maxBlockDate || _max > maxBlockDate) setMaxBlockDate(_max);

                loadData(blocks, refDate, replenish);
            } 
            else
            {
                const emptyBlocks = [];
                loadData(emptyBlocks, refDate, replenish);
            }
        }
        ui.HideOverlay();
    }

    const loadData = async (blocks, refreshApptTypes, replenish) => {
        //if (refreshApptTypes) await f.GetAppointmentTypes();

        await f.GetAppointmentTypes();
        
        if (blocks == null) {
            blocks = [];
            setEvents([]);
            return;
        }

        let _evBlocks = CreateEventsFromBlocks(blocks, f.validAppointmentTypes);
        const ev = replenish ? (events ?? []) : [];

        if (_evBlocks.length > 0) {
            if (ev.length > 0) {
                const prev = ev.filter(x => DateOnly(x.start) < DateOnly(_evBlocks[0].start));
                const next = ev.filter(x => DateOnly(x.start) > DateOnly(_evBlocks[_evBlocks.length - 1].start));

                _evBlocks = prev.concat(_evBlocks).concat(next);
            }   
        } else {
            _evBlocks = ev;
        } 
        setEvents(_evBlocks);
    };

    const replenishData = async () => {
        if (!f.onProviderLevel) return;

        if (maxBlockDate < calendarDate.end) {
            const dt = new Date(maxBlockDate);
            dt.setDate(maxBlockDate.getDate());
            ui.ShowSnackbar("Updating calendar events...", Severity.info);
            getData(dt);
        } 
        else if (minBlockDate > calendarDate.start) {
            const dt = new Date(minBlockDate);
            if (dt != today) 
            {
                dt.setDate(maxBlockDate.getDate() - 1);
            }
            else
            {
                dt.setDate(maxBlockDate.getDate());
            }
           
            ui.ShowSnackbar("Updating calendar events...", Severity.info);
            getData(dt);
        }
    }

    useEffect(() => {
        replenishData();
    }, [calendarDate]);

    useEffect(() => {
        if (f.onProviderLevel) {
            getData();
            setAvailabilityErrors([]);
            setConfigFormWithError([]);
            setLoadConfigWithError([]);
            setHasAvailabilityErrors(false);
            const providerSelected = f.providers.find(x => x.acctKey == f.acctKey);
            if (providerSelected) {
                var providerInfo = { "Name": providerSelected.firstName + ' ' + providerSelected.lastName, "Key": providerSelected.acctKey };
                setProviderInfo(providerInfo);
            }
            const locationSelected = f.locations.find(x => x.locKey == f.locKey);
            if (locationSelected) {
                var locationInfo = { "Name": locationSelected.orgName, "Key": locationSelected.locKey, "CoverKey": f.coverKey };
                setLocationInfo(locationInfo);
            }
        }
    }, [c.hideV5Entries, f.onProviderLevel, f.acctKey]);


    const oops = n[0].errors?.length > 0;
    const oops_providerNotFound = oops && n[0].errors?.indexOf("provider-not-found") >= 0;


    useEffect(() => {
        // Set Tours
        setIsOpen(false)
        setSteps(TourSteps.tourStepsSchedule)
        setCurrentTab(componentId)
    }, []);

    useEffect(() => {
        const last = currentStep === stepsLength - 1
        const next = last ? stepsLength - 1 : currentStep + 1
        if (next === 4) {
            setCurrentStep(next)
            setTourNext(true);
        }
    }, [openDrawer]);


    return (<>{!f.onProviderLevel ? (
        <>
            <Divider />
            <div className="select-cover-message">
                <h3>Please select location and provider</h3>
            </div>
        </>
    ) : (
        <div className="div-schedule">
            {hasAvailabilityErrors && availabilityErrors.length > 0 ? 
                <>
                    <Box p={1} bgcolor="background.paper" className="availability-errors">
                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={3}>
                            <Grid item>
                                <Typography variant="h4">
                                    Availability Errors - Total Error Count = {totalAvailabilityErrors}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <IconButton onClick={handleCollapseAvailabilityErrors}>
                                    {collapseAvailabilityErrors ? <ExpandLess onClick={handleCollapseAvailabilityErrors} /> : <ExpandMore onClick={handleCollapseAvailabilityErrors}/>}
                                    </IconButton>
                                </Typography>
                            </Grid>

                        </Grid>

                    </Box>
                    <Divider/>
                    <Collapse in={collapseAvailabilityErrors} timeout="auto">
                        <Box p={1} pb={2} bgcolor="background.paper" className="availability-errors">
                            <div className="errors-list">
                                <h3>The availability rule you are trying to create has a conflict with the listed dates below, please resolve all conflicted dates before proceeding.</h3>
                                <div className="errors-list--errors">
                                    {
                                        availabilityErrors.map((item, index) => (
                                            <div>
                                                <button onClick={() => onHandleClickConflictedDate(item.conflictedDate)} index={index}>{item.conflictedDateTimeBlock}</button>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                            <div className='errors-button'>
                                <div>
                                <Button color="primary" variant="contained" onClick={handleOnViewCurrentRequestClick}>View current request</Button>
                                </div>
                                <div>
                                <Button color="default" variant="contained" onClick={handleOnCancelCurrentRequestClick}>Cancel current request</Button>
                                </div>
                            </div>

                        </Box>
                    </Collapse>
                </>
                : null
            }

            <Divider />
            <Box p={2} bgcolor="background.paper" textAlign="right">
                <Typography>
                    <IconButton onClick={handleCreateAvailability} data-tut="reactour__schedule_createavailability" disabled={hasAvailabilityErrors}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                    Create Availability
                </Typography>
                <Typography>
                    <IconButton onClick={handleBlackoutPeriod} disabled={hasAvailabilityErrors}>
                        <AddCircleIcon />
                    </IconButton>
                    Blackout Period
                </Typography>
                <Typography>
                    <IconButton onClick={handleClickOpen}>
                        <PreviewIcon />
                    </IconButton>
                    Preview
                </Typography>
            </Box>

            <Divider />

            <Box p={2} bgcolor="background.paper" data-tut="reactour__schedule_calendar">
                <Calendar events={events} setEvents={setEvents} setCalendarDate={setCalendarDate} onSaved={onSaved} onCalendarRef={handleCalendarRef} highlightDate={highlightDate} editBlockGroup={editBlockGroup} deleteBlockGroup={deleteBlockGroup} setCalendar={setCalendar} />
            </Box>

            <Drawer anchor="right" open={openDrawer} onClose={closeDrawer} data-tut="reactour__schedule_modal"  >
                {f.validAppointmentTypes.length > 0 ?
                    (<>
                        <Box p={2} bgcolor="background.paper" style={{ width: 350 }}>
                            {drawerMode == "create-availability" && <BlocksForm dataEvent={events} appointmentTypesData={f.activeAppointmentTypes} isAvailability={true} onSaved={onSaved} onValidate={onValidate} onSaveAndValidate={onSaveAndValidate} loadData={editEventData} setOpenDrawer={setOpenDrawer} hasAvailabilityError={hasAvailabilityErrors} configWithError={configFormWithError}/>}
                            {drawerMode == "create-blackoutperiod" && <BlocksForm dataEvent={events} appointmentTypesData={f.activeAppointmentTypes} isAvailability={false} onSaved={onSaved} onValidate={onValidate} onSaveAndValidate={onSaveAndValidate} loadData={editEventData} setOpenDrawer={setOpenDrawer} hasAvailabilityError={hasAvailabilityErrors} configWithError={configFormWithError}/>}
                            {drawerMode == "error-availability" && <BlocksForm dataEvent={events} appointmentTypesData={f.activeAppointmentTypes} isAvailability={true} loadConfigWithError={loadConfigFormWithError} setOpenDrawer={setOpenDrawer} />}
                            {drawerMode == "error-blackout" && <BlocksForm dataEvent={events} appointmentTypesData={f.activeAppointmentTypes} isAvailability={false} loadConfigWithError={loadConfigFormWithError} setOpenDrawer={setOpenDrawer} />}
                        </Box>
                    </>) :
                    (<>
                        Loading appointment types...
                    </>)}
            </Drawer>

            {openDiaglog && <PreviewSchedule generatingLegacyBlocks={generatingLegacyBlocks} isOpen={openDiaglog} handleClose={handleClickClose} providerInfo={providerInfo} locationInfo={locationInfo} gIdColors={gIdColors} />}
          
        </div>
    )}</>);
};