import React, { useState } from 'react';

// Assuming totalPages and handlePageChange are defined in your component's props or state
const RegistrationFormPagination = ({ totalPages, handlePageChange,  activePage  }) => {
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(Math.min(5, totalPages));

  const nextPage = () => {
    const newStartPage = endPage + 1;
    const newEndPage = Math.min(newStartPage + 4, totalPages);
    setStartPage(newStartPage);
    setEndPage(newEndPage);
  };

  const prevPage = () => {
    const newEndPage = startPage - 1;
    const newStartPage = Math.max(newEndPage - 4, 1);
    setStartPage(newStartPage);
    setEndPage(newEndPage);
  };

  return (
    <div>
      <button onClick={prevPage} disabled={startPage === 1}>
        Previous
      </button>
      <span style={{ marginLeft: '10px', marginRight: '10px' }}>{totalPages > 1? 'Pages': 'Page'}</span>
      {Array.from(Array(endPage - startPage + 1).keys()).map(offset => {
        const page = startPage + offset;
        return (
          <button 
            key={page} 
            onClick={() => handlePageChange(page)}
            style={{
                marginRight: '5px',
                backgroundColor: activePage === page ? '#327dc6' : 'transparent',
                color: activePage === page  ? 'white' : 'black',
                border: '1px solid #ccc',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
          >
            {page}
          </button>
        );
      })}
      <span style={{ marginRight: '10px' }}> {endPage === totalPages? 'of': '.. of'} {totalPages}</span>
      <button onClick={nextPage} disabled={endPage === totalPages}>
        Next
      </button>

    </div>
  );
};

export default RegistrationFormPagination;
