import React, {useState, useEffect} from 'react';
import './TwoWayConversationTemplate.scss';
import {BiMessageAltDetail, FiChevronDown} from 'react-icons/all';
import {Tooltip} from '@material-ui/core';
import {format, subHours} from 'date-fns';

const TwoWayConversationTemplate = ({messageTemplates, templateAdded}) => {
  const { hipaa, templates } = messageTemplates || { hipaa: null, templates: null};
  const [showSheet, setShowSheet] = useState(false);
  const [templateItems, setTemplateItems] = useState();
  const handleShowSheet = () => {
    setShowSheet(showSheet => !showSheet);
  }

  const handleTemplateClick = (templateBody) => {
    templateAdded(templateBody)
    handleShowSheet();
  }

  let tzOffset = (new Date().toString().match(/([-\+][0-9]+)\s/)[1] * -1) / 100;
  let hippaLastSentDatea = hipaa?.lastRequestedDate ? subHours(new Date(hipaa?.lastRequestedDate), tzOffset) : null;
  let hippaLastSentDate = (hippaLastSentDatea !== null) ? `Sent ${format(new Date(hippaLastSentDatea), 'MM/dd/yy h:mm a')}` : ''

  useEffect(() => {
    if (templates) {
      setTemplateItems(
        templates.map((item, index) => {
          const blurb = (item?.body?.length > 100) ? item?.body?.substring(0, 100) + '...' : item?.body;
          return (
            <Tooltip title={blurb} placement='top' key={index}>
              <div key={index} onClick={(e) => handleTemplateClick(item)} className='template--item'  aria-label='template name'>
              <p className="template--item-name">{item.name}
                { item.locale && <span>{item.locale}</span>}
              </p>
                { item.type === 'HIPAA' && <p className="template--item-date">{hippaLastSentDate}</p>}
              </div>
            </Tooltip>
          )
        })
      )
    }
  }, [templates]);

  return (
    <>
    <Tooltip title='Insert Message Template' placement='left'>
      <div className='template--btn' onClick={ () =>{ handleShowSheet() }} >
          <BiMessageAltDetail />
      </div>
    </Tooltip>

      <div className='template--sheet noselect' style={showSheet ? {display: 'block'} : {display: 'none'}}>
        <div className='btn-close' onClick={handleShowSheet}><FiChevronDown/></div> 
        <div className='template--sheet--scrollable'>
          {templates && templateItems}
        </div>
      </div>

    </>
  )
}

export default TwoWayConversationTemplate;