import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Constants from '../../constants';
import {withTranslation} from 'react-i18next';
import Storage from '../../storage';
import Overlay from '../../Components/Overlay/Overlay';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {DataGrid} from '@material-ui/data-grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {withStyles} from '@material-ui/core/styles';
import './chooseCoverUser.scss';
import LocationService from '../../Store/LocationsService/LocationsService'
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
} from "change-case";
import UserService from '../../Store/UserService';
import { useCookies } from 'react-cookie';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiTablePagination-toolbar': {
      backgroundColor: 'white',
    },
    '.MuiTablePagination-toolbar:hover': {
      backgroundColor: 'white',
    },
  },
})(() => null);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function SetSessionCookie(sid) {
  const [cookies, setCookie] = useCookies([Constants.sessionID]);
  // Set Cookie
  setCookie(Constants.sessionID, sid, { withCredentials: true, SameSite: 'none', HttpOnly: true })
}

class ChooseCoverUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectionModel: [],
      redirectToLogin: false,
      showLoadingOverlay: false,
      searchCriteria: '',
      openSnackBar: false,
      SnackbarMessage: '',
      recordsFound: '',
      searchType: 1,
      selected: [],
      errorSeverity: 'success',
      userRole: '',
      userType: ''
    };
    //this.logoUrl = Utils.getLogoUrl();
  };
  
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {

    await this._validateJwt();
    // console.log(`chooseCoverUser componentDidMount START `);
    if (this.state.userRole === 'user' && this.state.userType !== 'Internal') {
      const url = `${process.env.REACT_APP_CUSTOMER_API}/allCoverKeys`;
      const res = await axios.get(url);

      // console.log(`chooseCoverUser componentDidMount: ${url} `);
      if (res?.data) {
        if (res.data.length === 1) {
          await this._submit(res.data);
        } else {
          //Convert Keys to lowercase         
          const newData = res.data.map((data, idx) => {
            const newObj = Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
                          [ pascalCase(key), val ]))
            return {...newObj, id: idx + 1};
          });
          this.setState(() => ({data: newData}));
          //this.setState(() => ({ data: res.data}));
        }
      }
    }
  }

  _handleSearchCriteriaChange = (sc) => {
    this.setState(() => ({searchCriteria: sc}));
  };

  _handleTypedChange = (search) => {
    this.setState(() => ({searchType: search}));
  };

  _handleSubmit = async () => {
    try {
      this.setState(() => ({showLoadingOverlay: true}));

      const coverKeys = [];
      const array = [...this.state.data];
      this.state.selectionModel.forEach(function (i) {
        var cKey = array.filter(function (item) {
          return item.id == i;
        });
        if (cKey.length > 0)
          coverKeys.push(cKey[0].CoverKey + '');
      });


      if (coverKeys.length > 0) {
        const url = `${process.env.REACT_APP_CUSTOMER_API}/submitCoverKeys`;

        const res = await axios.post(url, coverKeys);
        if (res?.data) {
          const jwt = res.data;
          const jwtData = Utils.decodeJwt(jwt);
          let user = Storage.getItem(Constants.currUserKey);
          let sid = jwtData[Constants.sessionIDKey];

          Storage.setItem('userRole', jwtData.role);
          Storage.setItem('sessionID', sid);
          // Set Cookie
          SetSessionCookie(sid);

          let familyName = jwtData[Constants.familyName];
          Storage.setItem('family_Name', familyName);

          if (!user) {
            user = {
              jwt: ''
            };
          }

          user.jwt = jwt;
          Storage.setItem(Constants.currUserKey, user);

          const url2 = `${process.env.REACT_APP_CUSTOMER_API}/persistCoverKeys`;
          var payload = {'sessionID': parseFloat(sid), 'coverKey': parseFloat(coverKeys[0])};
          await axios.post(url2, payload);

          this.setState(() => ({showLoadingOverlay: false}));
          window.location = '/welcome';
        } else
          this.setState(() => ({
            showLoadingOverlay: false,
            errorSeverity: 'error',
            openSnackBar: true,
            SnackbarMessage: 'Error in update.'
          }));
      } else
        this.setState(() => ({
          showLoadingOverlay: false,
          errorSeverity: 'error',
          openSnackBar: true,
          SnackbarMessage: 'Please select any CoverKey.'
        }));

    } catch {
      this.setState(() => ({
        showLoadingOverlay: false,
        errorSeverity: 'error',
        openSnackBar: true,
        SnackbarMessage: 'Error in update.'
      }));
    }


  }

  _handleSelect(rows) {
    const array = [...this.state.selected];
    var index = array.indexOf(rows.data.CoverKey + '');
    if (rows.isSelected) {
      if (index === -1)
        array.push(rows.data.CoverKey + '');
    } else {
      if (index !== -1) {
        array.splice(index, 1);
      }
    }

    this.setState(() => ({selected: array}));
  }

  _validateJwt = async () => {
    const user = Storage.getItem(Constants.currUserKey);
    const userType = Storage.getItem(Constants.userType);
    if (user && user.jwt) {
      try {
        const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        const jwtData = Utils.decodeJwt(user.jwt);
        this.setState(() => ({userRole: jwtData.role, userType: userType}));
      } catch {
        this.setState(() => ({
          redirectToLogin: true
        }));
      }
    } else {
      this.setState(() => ({redirectToLogin: true}));
    }
  }

  _handleSearch = async () => {
    if (this.state.searchType !== 0 && this.state.searchCriteria !== '') {
      this.setState(() => ({showLoadingOverlay: true}));
      let url = `${process.env.REACT_APP_CUSTOMER_API}`;

      if (this.state.searchType === 1) {
        url = url + '/byCoverKey?coverKey=' + this.state.searchCriteria;
      } else if (this.state.searchType === 2) {
        url = url + '/byCoverKey?byLogin=' + this.state.searchCriteria;
      } else if (this.state.searchType === 3) {
        url = url + '/byCoverKey?byName=' + this.state.searchCriteria;
      } else if (this.state.searchType === 4) {
        url = url + '/byCoverKey?byFrontLine=' + this.state.searchCriteria;
      }

      const res = await axios.get(url);

      if (res?.data) {
        const newData = res.data.map((data, idx) => {
          const newObj = Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
                        [ pascalCase(key), val ]))
          return {...newObj, id: idx + 1};
        });        
/*         const newData = res.data.map((data, idx) => {
          return {...data, id: idx + 1};
        }); */
        this.setState(() => ({data: newData}));
      }
      this.setState(() => ({showLoadingOverlay: false}));
    } else {
      this.setState(() => ({errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Invalid parameter query.'}));
    }
  }

  _searchCriteria = async (sc) => {
    try {
      if (this.state.searchType !== 0 && sc !== '') {
        this.setState(() => ({showLoadingOverlay: true}));
        let url = `${process.env.REACT_APP_CUSTOMER_API}`;

        if (this.state.searchType === 1) {
          url = url + '/byCoverKey?coverKey=' + sc;
        } else if (this.state.searchType === 2) {
          url = url + '/byLogin?Login=' + sc;
        } else if (this.state.searchType === 3) {
          url = url + '/byName?OrgName=' + sc;
        } else if (this.state.searchType === 4) {
          url = url + '/byFrontLine?FrontLine=' + sc;
        }

        const res = await axios.get(url);

        if (res?.data) {
          const newData = res.data.map((data, idx) => {
          const newObj = Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
                        [ pascalCase(key), val ]))
          return {...newObj, id: idx + 1};          
/*           const newData = res.data.map((data, idx) => {
            return {...data, id: idx + 1}; */
          });
          this.setState(() => ({data: newData}));
        } else {
          this.setState(() => ({SnackbarMessage: sc + ' doesn\'t exist!', errorSeverity: 'info', openSnackBar: true}));
        }
        this.setState(() => ({showLoadingOverlay: false}));
      } else {
        this.setState(() => ({
          errorSeverity: 'error',
          openSnackBar: true,
          SnackbarMessage: 'Invalid parameter query.'
        }));
      }
    } catch {
      this.setState(() => ({
        errorSeverity: 'error',
        showLoadingOverlay: false,
        openSnackBar: true,
        SnackbarMessage: 'Error in searching the provider.'
      }));
    }
  }


  /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      data,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      recordsFound,
      errorSeverity,
      searchCriteria,
      searchType,
      userRole,
      userType,
    } = this.state;

    var columns = [
    {
      field: 'btn', headerName: 'Action', width: 120,
      renderCell: (params) => {
        const onClick = async () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== '__check__' && !!c);
          const thisRow = {};
  
          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          });
  
          const url = `${process.env.REACT_APP_CUSTOMER_API}/submitCoverKeys`;
          const coverKeys = [];
          coverKeys.push(params.getValue(fields[2]) + '');
  
          const res = await axios.post(url, coverKeys);
          if (res?.data) {
            const jwt = res.data;
            const jwtData = Utils.decodeJwt(jwt);
            let user = Storage.getItem(Constants.currUserKey);
            let sid = jwtData[Constants.sessionIDKey];
  
            Storage.setItem('userRole', jwtData.role);
            Storage.setItem('sessionID', sid);
  
            let familyName = jwtData[Constants.familyName];
            Storage.setItem(Constants.familyName, familyName);
  
            if (!user) {
              user = {
                jwt: ''
              };
            }
  
            user.jwt = jwt;
            Storage.setItem(Constants.currUserKey, user);
  
            const url2 = `${process.env.REACT_APP_CUSTOMER_API}/persistCoverKeys`;
            var payload = {'sessionID': parseFloat(sid), 'coverKey': parseFloat(coverKeys[0])};
            await axios.post(url2, payload);
            var elements = await UserService.getElementsForUserCoverKey();
            await LocationService.getLocations().then((resp) => {
              try {
                if (resp) {
                  const newData = Object.fromEntries(Object.entries(resp.data).map(([ key, val ]) =>
                                [ key !== "isLocationAware" ? pascalCase(key) : key, val ]))
  
                  const newDataLocation = newData.Locations.map((data, idx) => {
                    const newObj = Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
                                  [ pascalCase(key), val ]))
                    return {...newObj};
                  });
                  newData.Locations = newDataLocation;
                  resp.data = newData;
                  LocationService.updateLocationStore(resp);
                  LocationService.updateLocKeys(resp.data.Locations);
                  window.location.pathname = '/welcome'
                }
              } catch (err) {
                var e = err;
              }
            })
  
  
          }
        };
  
        return <Button
          onClick={onClick}
          variant="contained"
          size="small"
          color="primary"
          type="button">
          Select
        </Button>;
      }
    },
    {field: 'Id', headerName: '#', width: 70},
    {field: 'CoverKey', headerName: 'CoverKey', width: 150},
    {field: 'Practice', headerName: 'Practice', width: 350},
    {field: 'OrgText', headerName: 'OrgText', width: 150},
    {field: 'Contact', headerName: 'Contact', width: 200},
    {field: 'Address', headerName: 'Address', width: 350},
    {field: 'Status', headerName: 'Status', width: 100},
  ];
    
    if (redirectToLogin) {
      return <Redirect to={{pathname: '/'}}/>;
    }

    return (
      <div className="chooseCoverUserDiv">
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={openSnackBar} autoHideDuration={3000}
                  onClose={() => this.setState({openSnackBar: false})}>
          <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage}
          </Alert>
        </Snackbar>
        <Box p={3} bgcolor="background.paper">
          <Grid container justify="space-between" alignItems="center" spacing={3}>
            <Grid item xs={6} sm={2}>
              <Typography align='left'>
                Please select a coverKey
              </Typography>
            </Grid>
            {/* <Grid item xs={6}  sm={2}>
                    <Button onClick={(e)=>this._handleSubmit(e.target.value)}
                        className="btnSubmit" 
                        variant="contained" 
                        color="primary" 
                        type="button">
                        Submit
                    </Button>
                 </Grid> */}
          </Grid>
        </Box>
        <Divider/>
        {(userRole === 'superadmin' || userType === 'Internal') ?
          <div>
            <Box p={3} bgcolor="background.paper">
              <ValidatorForm onSubmit={this._handleSearch} onError={errors => console.log(errors)}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                      <InputLabel id="Search_Type">Search Type</InputLabel>
                      <Select
                        labelId="Search_Type"
                        id="search_type"
                        value={searchType}
                        onChange={(e) => this._handleTypedChange(e.target.value)}
                      >
                        <MenuItem value={1}>Cover Key</MenuItem>
                        <MenuItem value={2}>Cover Login</MenuItem>
                        <MenuItem value={3}>Cover Name</MenuItem>
                        <MenuItem value={4}>Phone Number</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextValidator
                      validators={['required']}
                      errorMessages={['this field is required', 'Must be numeric and greater than zero']}
                      fullWidth
                      value={searchCriteria}
                      onInput={(e) => this._handleSearchCriteriaChange(e.target.value)}
                      onKeyPress={(ev) => {
                      }}
                      label="Search"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton type="submit">
                              <SearchIcon/>
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      onClick={() => this._searchCriteria(searchCriteria)}
                      variant="contained"
                      color="primary"
                      type="button">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Box>
          </div>
          : null}
        <Box p={1} bgcolor="background.paper" style={{maxHeight: '100vh', overflow: 'auto'}}>
          <div style={{height: '75%', width: '100%', overflow: 'auto'}}>
            <GlobalCss/>
            <DataGrid rows={data}
                      columns={columns}
                      pageSize={10}
                      density="compact"
                      autoHeight={true}
                      rowsPerPageOptions={[5, 10, 20]}
                      scrollbarSize={10}
            />
          </div>

        </Box>
        <Box p={20} bgcolor="background.paper">
        </Box>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>

      </div>
    );
  }

}

export default withTranslation()(ChooseCoverUser);