import React, { useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import './PatientInfo.scss';
import { useUIContext } from '../../../../ContextLib/contextHooks';
import InfoIcon from '@material-ui/icons/Info';

const maxCharacters = 50;

export default function PatientInfo_Others({model, onChangeMultiple}) {
  const componentId = "PatientInfo_Oters";
  const ui = useUIContext(componentId);

  const [characterCount0, setcharacterCount0] = useState(0);
  const [characterCount1, setcharacterCount1] = useState(0);
  const [characterCount2, setcharacterCount2] = useState(0);
  const [characterCount3, setcharacterCount3] = useState(0);
  const [characterCount4, setcharacterCount4] = useState(0);
  const [characterCount5, setcharacterCount5] = useState(0);

  const [slotCount, setSlotCount] = useState(2);
  const [slot2, setSlot2] = useState(false);
  const [slot3, setSlot3] = useState(false);
  const [slot4, setSlot4] = useState(false);
  const [slot5, setSlot5] = useState(false);

  const hasEmptySlots = () => {
    return model.notesLbl == "" || model.dReferredBy1lbl == "" || slot2 && model.dReferredBy2lbl == "" || slot3 && model.dReferredBy3lbl == "" || slot4 && model.dReferredBy4lbl == "" || slot5 && model.dReferredBy5lbl == "";
  }

  const getSlotCount = (s2, s3, s4, s5)=> {
    let c = 2;
    if (s2) c++;
    if (s3) c++;
    if (s4) c++;
    if (s5) c++;
    return c;
  }  

  const getNextSlot = ()=> {
    if (!slot2) return setSlot2;
    else if (!slot3) return setSlot3;
    else if (!slot4) return setSlot4;
    else if (!slot5) return setSlot5;
    else return null;
  }

  const onAddQuestion = () => {
    if (hasEmptySlots()) {
      ui.MessageBox("Please fill up empty slots first", "Add Question");
      return;
    }

    const ns = getNextSlot();
    if (ns) {
      ns(true);
      setSlotCount(slotCount+1);
    }
  }

  const handleSubmit = (e) => {
      e.preventDefault();
  }

  const updateCharCount = (index)=> {
    if (index == 0) setcharacterCount0(maxCharacters - model.notesLbl.length);
    if (index == 1) setcharacterCount1(maxCharacters - model.dReferredBy1lbl.length);
    if (index == 2) setcharacterCount2(maxCharacters - model.dReferredBy2lbl.length);
    if (index == 3) setcharacterCount3(maxCharacters - model.dReferredBy3lbl.length);
    if (index == 4) setcharacterCount4(maxCharacters - model.dReferredBy4lbl.length);
    if (index == 5) setcharacterCount5(maxCharacters - model.dReferredBy5lbl.length);
  }

  const [autoHide, setAutoHide] = useState(true);

  useEffect(()=>{
    updateCharCount(0);
    updateCharCount(1);
    updateCharCount(2);
    updateCharCount(3);
    updateCharCount(4);
    updateCharCount(5);

    if (autoHide) {
      const s2=model.dReferredBy2lbl != "";
      const s3=model.dReferredBy3lbl != "";
      const s4=model.dReferredBy4lbl != "";
      const s5=model.dReferredBy5lbl != "";

      setSlot2(s2);
      setSlot3(s3);
      setSlot4(s4);
      setSlot5(s5);

      const c = getSlotCount(s2, s3, s4, s5);
      setSlotCount(c);
    }
    setAutoHide(true);

  },[model]);

  const itemX = (index, fieldName, fieldValue, fieldLabel, _fieldName, _fieldValue, _fieldLabel, _hasDelete) => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id={fieldName}>{fieldLabel}</InputLabel>
            <Select
              labelId={fieldName}
              id={fieldName}
              value={fieldValue}
              onChange={e => {
                setAutoHide(false);
                onChangeMultiple(fieldName, e.target.value, _fieldName, _fieldValue,);
              }}
            >
              <MenuItem value={0}>Desired </MenuItem>
              <MenuItem value={-1}>Don't Ask </MenuItem>
              <MenuItem value={1}>Required </MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10} sm={5} style={{ paddingRight: 0 }}>
            <TextValidator
              label={_fieldLabel}
              value={_fieldValue}
              fullWidth
              onChange={e => {        
                setAutoHide(false);
                updateCharCount(index);
                onChangeMultiple(fieldName, fieldValue, _fieldName, e.target.value, false);
              }}
              required
              validators={['labelRequired']}
              errorMessages={['This field is required']}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          {_hasDelete ?
            <Grid item sm={1} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <IconButton type="submit" component="span" onClick={()=>{
                  onChangeMultiple(fieldName, fieldValue, _fieldName, "", true);
                }}>
                <HighlightOffIcon fontSize="small" />
              </IconButton>
            </Grid>
            : null
          }
      </>
    );
  };

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)}>
        <h3>Patient Demographics</h3>
        <div className="help-icon-cont">
          <p>
            <InfoIcon />For maximum bookings, keep your questions brief and only include essential ones
          </p>
          <div style={{clear: "both"}}>&nbsp;</div>
        </div>

        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>          
          {itemX(0, "notesFld", model.notesFld, "Custom Question #1", "notesLbl", model.notesLbl, "Custom Question #1 Label (Characters Left: " + characterCount0 + "/" + maxCharacters + ")", false)}
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
          {itemX(1, "dReferredBy1", model.dReferredBy1, "Custom Question #2", "dReferredBy1lbl", model.dReferredBy1lbl, "Custom Question #2 Label (Characters Left: " + characterCount1 + "/" + maxCharacters + ")", false)}
        </Grid>
        {(slot2) && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {itemX(2, "dReferredBy2", model.dReferredBy2, "Custom Question #3", "dReferredBy2lbl", model.dReferredBy2lbl, "Custom Question #3 Label (Characters Left: " + characterCount2 + "/" + maxCharacters + ")", true)}
          </Grid>
        )}
        {(slot3) && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {itemX(3, "dReferredBy3", model.dReferredBy3, "Custom Question #4", "dReferredBy3lbl", model.dReferredBy3lbl, "Custom Question #4 Label (Characters Left: " + characterCount3 + "/" + maxCharacters + ")", true)}
          </Grid>
        )}
        {(slot4) && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {itemX(4, "dReferredBy4", model.dReferredBy4, "Custom Question #5", "dReferredBy4lbl", model.dReferredBy4lbl, "Custom Question #5 Label (Characters Left: " + characterCount4 + "/" + maxCharacters + ")", true)}
          </Grid>
        )}
        {(slot5) && (
          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
            {itemX(5, "dReferredBy5", model.dReferredBy5, "Custom Question #6", "dReferredBy5lbl", model.dReferredBy5lbl, "Custom Question #6 Label (Characters Left: " + characterCount5 + "/" + maxCharacters + ")", true)}
          </Grid>
        )}
      </ValidatorForm>
      
      {slotCount<6 && (
        <Box pt={2}>
          <Button color="primary" onClick={onAddQuestion}>+ Add Question</Button>
        </Box>
      )}

    </div>
  );
};

