import React , { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../PatientForm/FormStatus.scss'
import './RegistrationForms.scss'
import '../../ContextLib/CoreConsumer/Components/Filters.scss';
import Constants from '../../constants';
import Utils from '../../utils';
import Storage from '../../storage';
import RegistrationFormView from './RegistrationFormView';
import PatientForm from './PatientForms';
import ContextProviders from '../../ContextLib/ContextProviders';
import { TabContext } from '@material-ui/lab';
import { TabPanel } from '../../ContextLib/CoreConsumer/Components/TabPanel';
import { AppBar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import UserService from '../../Store/UserService';
import queryString from 'query-string';
import Enums from '../../enums';
import LocationService from '../../Store/LocationsService/LocationsService';
import { LocationServiceContext } from '../../Store/LocationsService/LocationServiceProvider';
import TabPanels from '../../CRMComponents/CRMTabs/TabPanels/TabPanels';
import Tabs from '../../CRMComponents/CRMTabs/Tabs';
import Button from '@material-ui/core/Button';

import { MdLowPriority } from "react-icons/md"
import { useTour } from '@reactour/tour'
import TourSteps from '../../Store/TourSteps'
import {TourServiceContext} from '../../Store/TourServiceProvider'
import { useFiltersContext, useRequest, useUIContext } from '../../ContextLib/contextHooks';

const RegistrationForm = (props) => {


	const [tourStart, setTourStart] = useState(false);
	const componentId = "registrationform";
    const ui = useUIContext(componentId);
    const {
        setCurrentTab,
        setTourNext,
		currentTab
       } = useContext(TourServiceContext);  

    const {  isOpen, currentStep, setIsOpen, setCurrentStep ,setSteps, setDisabledActions, stepsLength,disabledActions } = useTour();
	
	const filtersContext = useFiltersContext(componentId, null, (context) => {   
		//On filter values updated
	
		  if (currentTab === "registration-forms")
		  {
			  const last = currentStep === stepsLength - 1
			  const next = last ? stepsLength - 1 : currentStep + 1
			  if (currentStep == 0 && context.locKey > 0 &&  isOpen)
			  {
					setCurrentStep(next)
				if (context.acctKey === 0 )    
					setTourNext(false)
				else
					setTourNext(true)     
			  }
			  else if (currentStep == 1  && context.acctKey > 0  && isOpen)
			  {
					setCurrentStep(next)
					setTourNext(true)
			  }
			  else if (currentStep == 0 && context.locKey === 0 &&  isOpen)
			  {
				setTourNext(false)
			  }
			  else if (currentStep == 1 && context.acctKey === 0 &&  isOpen)
			  {
				setTourNext(false)
			  }   
		  }
		  else
			 setTourNext(true) 
	  });  
    const { setAllLocations, setSelectedLocationKeys } = useContext(
      LocationServiceContext
    );
    const [coverKey, setCoverKey] = useState(0);
    const [allowRegistrationForm] = useState(UserService.checkElementAccess(Constants.registrationFormMenu));
    
    const getCoverKey = async () => {
      const value = queryString.parse(props.location.search);
        const sessionIDParam = value.SessionID;
        if (sessionIDParam) {
          await decodeSession(sessionIDParam);
        } else {
          checkUser();
          const user = Storage.getItem(Constants.currUserKey);
          if (user) {
            const jwtData = Utils.decodeJwt(user.jwt);
            setCoverKey(jwtData.CoverKey);
          }
        }
    };

    const decodeSession = async (sessionIDParam) => {
      try {
        const secretCode = process.env.REACT_APP_API_KEY;
        const url = `${process.env.REACT_APP_AUTHENTICATION_API}/LoginSession?sessionID=${sessionIDParam}&secretCode=${secretCode}`;
        const res = await axios.post(url);
        const jwt = res.data;
        const jwtData = Utils.decodeJwt(jwt);
        let user = Storage.getItem(Constants.currUserKey);
        let sid = jwtData[Constants.sessionIDKey];

        Storage.setItem('userRole', jwtData.role);
        Storage.setItem('sessionID', sid);

        let familyName = jwtData[Constants.familyName];
        Storage.setItem(Constants.familyName, familyName);

        if (!user) {
          user = {
            jwt: '',
          };
        }

        user.jwt = jwt;
        Storage.setItem(Constants.currUserKey, user);

        const url2 = `${process.env.REACT_APP_CUSTOMER_API}/allCoverKeys`;
        const res2 = await axios.get(url2);

        if (res2?.data) {
          if (res2.data.length === 1 || jwtData.role.indexOf('admin') >= 0) {
            await getLocations();
          } else {
            window.location = '/chooseCoverUser';
          }
        }
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusUnauthorizedError)
        ) {
          window.location = '/';
        } else if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          window.location = '/';
        }
      }
    };      

    const checkUser = async () => {
      const user = Storage.getItem(Constants.currUserKey);
      if (user && user.jwt) {
        try {
          const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
          await axios.get(jwtCheckUrl);
        } catch (error) {
          if (error.response?.status === 401) {
            UserService.logout();
          }
          window.location = '/';
        }
      } else {
        window.location = '/';
      }
    };     

    const getLocations = async () => {
      LocationService.getLocations().then((resp) => {
        try {
          if (resp) {
            const myLocations = LocationService.updateLocationStore(resp);
            setSelectedLocationKeys(myLocations);
            setAllLocations(myLocations);

            const myLocationKeys = LocationService.getCacheLocations();
            LocationService.updateLocKeys(myLocationKeys);
          }
        } catch (err) {}
      });
    };  

	const openTour= () =>
    { 
		if (!tourStart)
		setCurrentStep(0)
 
	   setTourStart(!tourStart)
	   setIsOpen(!tourStart)
 

		  setTourNext(true) 
	 
	}
    useEffect( async() => {    
      getCoverKey();
    },[]);
	useEffect(async () => {
		setTourStart(isOpen)
	  }, [isOpen]);
	

    const tabProps = {
      componentId: 'registration-forms-tabs',
      items: ['Form Templates','Completed Forms'],
      baseBgColor:'#f4f5f8',
      tabStyle:{
        height: '32px',
        color: 'black',
        bgColor: 'initial'
      },
      activeTabStyle:{
        bgColor: 'white',
        color: 'black'
      }
    };

    const tabPanelsProps = {
      componentId: "registration-forms", 
      tabHeadComponentId: "registration-forms-tabs",
      panels: [
        <PatientForm coverKey={coverKey} />,
		<RegistrationFormView coverKey={coverKey} />,
      ]
    };   
    
    return (
		<>

			<div className='page-cont'>
      {allowRegistrationForm ? <ContextProviders>
				
					<div className="registrationsection">
						<div className="registration-forms">
							<div className="module-title">
								<h1>
									Registration Intake Forms
								</h1>
							</div>
							<div className="test">
								<Button  variant="contained" onClick={() => openTour()} startIcon={<MdLowPriority />} color={!tourStart ? 'primary' : 'secondary'}>
									{!tourStart ? 'Start User Guide' : 'End User Guide'}
								</Button>
							</div>

							<div className='tabs-cont'>
								<Tabs {...tabProps} />
								<TabPanels {...tabPanelsProps} />
							</div>
						</div>

					</div>
	
				</ContextProviders>
        :        
        <div className="loading-error undefined">
          Not enough user rights
        </div>
      }
				<div id="registration-forms-workspace"></div>
			</div>
		</>
    );
};

export default RegistrationForm;