/**
 * Represents a collection of enum values.
 */
const Enums = {
  /**
   * Represents the supported http status codes.
   */
  HttpStatusCodes: {
    /**
     * Returns the HTTP status ok value.
     */
    get httpStatusOk() {
      return 200;
    },

    /**
     * Returns the HTTP status server error value.
     */
    get httpStatusInternalServerError() {
      return 500;
    },

    get httpStatusUnauthorizedError() {
      return 401;
    },

    get httpStatusAccountLockdError() {
      return 600;
    },

    get httpStatusExpiredPasswordError() {
      return 400;
    }
  },

  /**
   * Represents the supported keyboard keys.
   */
  KeyboardKeys: {
    /**
     * Indicates the Enter key.
     */
    get enterKey() {
      return 'Enter';
    }
  },

}

// Lock object to prevent modification (true static).
Object.freeze(Enums);

export default Enums;
