import React, {useContext, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {BiCalendarEvent, FiChevronDown, IoMdArrowDropdown} from 'react-icons/all';
import {format} from 'date-fns'
import Constants from '../../constants';
import Storage from '../../storage';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import UserService from '../../Store/UserService';
import {LocationServiceContext} from '../../Store/LocationsService/LocationServiceProvider';
import { environment } from '../../environment';
import { useHistory } from 'react-router';
import './DashboardHeader.scss';
import Tabs from '../../CRMComponents/CRMTabs/Tabs';
import SessionContext from '../../SessionContext';

const DashboardHeader = (props) => {
  const history = useHistory();
  const [sessionID, setSessionId] = useState(Storage.getItem(Constants.sessionID));
  const [activeTabIndex, setActiveTabIndex] = useState(props.activeTabIndex);
  
  const session = useContext(SessionContext);
  session.Subscribe_ValueUpdated("DashboardHeader", (value) => {
    setSessionId(value.sessionID);
  });

  const imageClick = () => {
    const url = `${process.env.REACT_APP_4PC_URL}?SessionID=${sessionID}`;
    window.open(url, '_blank');
  };
  
  if(!(UserService.validateJwt()))
  {
    UserService.resetUserData();
  }

  // var _dashboardItem = {text:'Dashboard', relativeLink: '/chat'};
  // var _recallReportItem = {text:'Reports', relativeLink: '/recall-report'};
  var _items = [];
  var rootElement = UserService.getChildElements(null);
  if(rootElement && rootElement[0])
  {
    var userElements = UserService.getChildElements(rootElement[0].ElementName);  
    if(userElements)
    {
      userElements.forEach(element => {
        _items.push({text: element.DisplayName, elementName: element.ElementName});
      });
    }
  }
  // if(environment.env !== "prod" && UserService.checkElementAccess(Constants.dashboardMenu))
  //   _items.push(_dashboardItem);
  // if(UserService.checkElementAccess(Constants.reportsMenu))
  //   _items.push(_recallReportItem);

  useEffect(() => {
    var element = UserService.getElementByRoute(window.location.pathname)
    if(element)
    {
      var parentElement = UserService.getParentElement(element.ElementName);
      _items.map((x,i) => {
        if(parentElement.ElementName === x.elementName || x.elementName === element.ElementName)
        {
          setActiveTabIndex(i);
        }
      });
    }
  }, [window.location.pathname, _items, Storage.getItem(Constants.encryptedElements)]);

  const tabCallback = (e) => {
    var element = UserService.getElementByRoute(window.location.pathname);
    var parentElement = null;
    if(element)
    {
      parentElement = UserService.getParentElement(element.ElementName);
    }
    var tabElement = UserService.getElementByName(e.ElementName);
    if(!parentElement)
    {
      parentElement = { ElementName : '' };
    }
    if(parentElement.ElementName !== tabElement.ElementName)
    {
      history.push(tabElement.Url);
    }
    props.parentCallback(e);
  };
   
  const tabProps = {
    componentId: 'two-way-header-tabs',
    items: _items,
    baseBgColor:'#03122c',
    tabStyle:{
      height: '32px',
      color: 'white',
      bgColor: 'initial'
    },
    activeTabStyle:{
      bgColor: '#327DC6',
      color: 'white'
    },
    activeIndex: activeTabIndex,
    handleCallBack: tabCallback
  };

  return (<>
    {sessionID === null ? (<>
      <AppBar className='Appbar'>
        <Toolbar>
          <div className='logo' ><span>4PatientCare</span></div>
        </Toolbar>
      </AppBar>
    </>) : (
      <AppBar className='Appbar'>
        <Toolbar>
          <div className='logo' onClick={() => imageClick()} style={{cursor:"pointer"}}><span>4PatientCare</span></div>
          <Tabs {...tabProps} />
          <Account />
        </Toolbar>
      </AppBar>
    )}
  </>
  );
};

export default DashboardHeader;


const Account = ()=>{
  const {
    allLocations,
    setAllLocationKeys,
    selectedLocationKeys,
    setSelectedLocationKeys,
    setAllLocations,
    gCoverKey
  } = useContext(LocationServiceContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [familyName, setFamilyName] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    UserService.logout();
    setAnchorEl(null);
  };
  
  const today = format(new Date(), 'EEEE MMMM d, yyyy');
  useEffect(() => {
    setFamilyName(UserService.getFamilyName());
  }, [allLocations, setAllLocationKeys, selectedLocationKeys, setSelectedLocationKeys, setAllLocations])

  return (
    <div className='account-cont'>
      <div className='datetime'><span>{today}</span>&nbsp;<BiCalendarEvent/></div>
      <div className='account' onClick={handleClick}>
        <AccountBoxIcon/>
        <FiChevronDown/>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <h1>{familyName}</h1>
        <div className='divider'></div>
        <div className='logout-container'>
          <Button variant="contained" color='primary' onClick={handleLogout}>Sign out</Button>
        </div>
      </Menu>
    </div>
  );
};