export const ComponentType = Object.freeze({
   UnManaged: 0,
   MessageBox: 1,
   ConfirmBox: 2,
   ErrorBox: 3,
   Overlay: 4,
   Snackbar: 5
});

export const distinct = (arr) => {
   return arr?.map(x => JSON.stringify(x))
      .filter((v, i, s) => s.indexOf(v, 0) === i)
      .map(x => JSON.parse(x));
}

export const arrayCopy = (arr) => {
   return JSON.parse(JSON.stringify(arr));
}

export const arraySortOptions = {
   fieldName: "",
   ascending: true
};

export const arraySortExt = (arr, arrSortOptions) => {
   let options = [];
   if (Array.isArray(arrSortOptions)) {
      options = arrSortOptions;
   } else {
      options = [arrSortOptions];      
   }

   arr.sort((_a, _b) => {
      let ret = 0;
      let i = 0;
      
      while(i < options.length) {
         const fieldName = options[i].fieldName;
         const ascending = options[i].ascending;

         const a = _a[fieldName];
         const b = _b[fieldName];
            
         let s1, s2;

         if (typeof a == "string") {
            s1 = (a ?? "").toString().toLowerCase();
            s2 = (b ?? "").toString().toLowerCase();
         } else if (a instanceof Date) {
            s1 = a ?? new Date("1/1/1900");
            s2 = b ?? new Date("1/1/1900");
         } else {
            s1 = a ?? 0;
            s2 = b ?? 0;
         }
         
         i++;
         if (ascending) {
            ret = s1 > s2 ? 1 : (s2 > s1 ? -1 : 0);
         } else {
            ret = s1 < s2 ? 1 : (s2 < s1 ? -1 : 0);
         }
         if (ret != 0) break;
      }

      return ret;      
   });

   return arr;
};

export const arraySort = (arr, fieldName, desc) => {
   arr.sort((a, b) => {
      let s1 = (a[fieldName] == null ? '' : a[fieldName]).toString().toLowerCase();
      let s2 = (b[fieldName] == null ? '' : b[fieldName]).toString().toLowerCase();
      
      return s1 > s2 ? 1 : (s2 > s1 ? -1 : 0);
   });
   
   if (desc) arr.reverse();

   return arr;
};

export const arrayContains = (arr, value) => {
   return arr.some(x => x === value);
};

export const objectsAreEqual = (obj1, obj2) => {
   let eq1 = JSON.stringify(obj1);
   let eq2 = JSON.stringify(obj2);
   return eq1 === eq2;
 }

 export const arraysAreEqual = (arr1, arr2, fieldName) => {
   const a = arraySort(arr1, fieldName);
   const b = arraySort(arr2, fieldName);
   return objectsAreEqual(a, b);
}

export const createGuid = () => {  
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
       var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
       return v.toString(16);  
    });  
 } 

export const formatBytes = (bytes, decimals = 2) => {
   if (bytes === 0) return '0 Bytes';

   const k = 1024;
   const dm = decimals < 0 ? 0 : decimals;
   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const DateOnly = (dt) => {
   return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export const getDateTimeObj = (dateTime) => {
   const y = dateTime.getFullYear().toString();
   const _m = (dateTime.getMonth() + 1).toString();
   const _d = dateTime.getDate().toString();
   const _h = dateTime.getHours().toString();
   const _mm = dateTime.getMinutes().toString();
   const _s = dateTime.getSeconds().toString();
   
   const m = (_m < 10 ? '0' : '') + _m;
   const d = (_d < 10 ? '0' : '') + _d;
   const h = (_h < 10 ? '0' : '') + (_h - (_h > 12 ? 12 : 0));
   const mm = (_mm < 10 ? '0' : '') + _mm;
   const tt = _h < 12 ? 'AM' : 'PM';
 
   const _date = `${m}/${d}/${y}`;
   const _time = `${h}:${mm} ${tt}`;
   const _dateTime = `${_date} ${_time}`;
   return {
     strDate: _date,
     strTime: _time,
     strDateTime: _dateTime,
     dateTime: new Date(_dateTime)
   };
};
 
export const getRefDate = (dt) => {
   const d = dt ?? new Date();
   return getDateTimeObj(d).strDate;
};