import {Multiselect} from 'multiselect-react-dropdown';
import React, {useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import {LocationServiceContext} from '../../Store/LocationsService/LocationServiceProvider';
import './SidebarMenuLocations.scss';
import {FiX} from 'react-icons/all';
import LocationService from '../../Store/LocationsService/LocationsService';
import {Button} from '@material-ui/core';

const SidebarMenuLocations = ({
                                showLocations,
                                toggleLocations,
                                toggleIsLocationAware,
                                putPrimaryLocation,
                                toggleHasMultipleLocations
                              }) => {
  const {
    allLocations,
    setAllLocationKeys,
    selectedLocationKeys,
    setSelectedLocationKeys,
    setAllLocations,
  } = useContext(LocationServiceContext);
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);


  useEffect(() => {

    if (LocationService.getLocationAware()) {
      toggleIsLocationAware();
    }
    if (LocationService.getCacheAllLocations()) {
      updateSelectedLocations(LocationService.getCacheAllLocations());
      if (LocationService.getCacheAllLocations().length > 1) {
        toggleHasMultipleLocations();
        setLocations(LocationService.getCacheAllLocations())
        setSelectedLocations(LocationService.getCacheAllLocations())
      }
    }


  }, [setAllLocations, allLocations])

  useEffect(() => {
    setSelectedLocations(LocationService.getCacheLocations())
  }, [showLocations])

  const onSelect = (selectedList) => {
    var filteredLocationsArray = [];
    LocationService.saveLocations(JSON.stringify(selectedList))
    selectedList.forEach(element => {
      filteredLocationsArray.push(element.id);
    });
    LocationService.saveCurrentLocKeys(JSON.stringify(filteredLocationsArray));
    setSelectedLocations(LocationService.getCacheLocations())
    updateSelectedLocations(selectedList);
  }

  const updateSelectedLocations = (selectedList) => {
    putPrimaryLocation(selectedList[0])
    setSelectedLocationKeys(selectedList);
  }

  const handleSelectAllLocations = () => {
    onSelect(locations)
  }
  const handleClearAllLocations = () => {
    setSelectedLocationKeys([]);
    onSelect([])
  }


  return (
    <span className='sidebarMenuLocation'>
      <div id='sidebarMenuLocation' className='sidebarMenuLocation--dropdown'>
        {showLocations &&
        <span className='sidebarMenuLocation--dropdown--holder'>
            <div className='modal'>
              <div className='modal-cont'>
                <div className='btn-close' onClick={toggleLocations}><FiX/></div>
                <div>
                  <Button variant='outlined' color="primary" onClick={(event) => handleSelectAllLocations()}>Select All Locations</Button>
                  <Button variant='' color="primary" className='m--l--sm' onClick={(event) => handleClearAllLocations()}>Clear All</Button>
                </div>
                <div>
                <Multiselect
                  showCheckbox={true}
                  options={locations}
                  selectedValues={selectedLocations}
                  onSelect={onSelect}
                  onRemove={onSelect}
                  displayValue="name"
                  closeIcon={FiX}
                  keepSearchTerm={true}
                  placeholder='Search for location(s)'
                  avoidHighlightFirstOption
                />
                </div>
                <br/>

              </div>
            </div>
            <span className='sidebarMenuLocation--mask' onClick={toggleLocations}></span>
          </span>
        }
      </div>
    </span>

  )
}


export default SidebarMenuLocations;



