import React, {useState,useEffect} from 'react';
import { ApptApi, ContentContainer, PageTitle,useApptReports, ApptFilters, ApptActions, Loading, LoadingError,OrdersApptTable, PreApptType,Pagination} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import {DEFAULT_APPT_BODY} from "./ReportsUtils";
import { getCoverKey, getJwt } from "./ReportsUtils";
import UserService from '../../Store/UserService';
import Constants from '../../constants';


const OrdersPage = () => {
    const PAGE_LIMIT = 10;
    const [filters, setFilters] = useState({
        commMethods: [],
        locations: [],
        providers: [],
      });
      const [page, setPage] = useState(1);
      const [apiBody, setApiBody] = useState({
        locKeys: [],
        commMethods: [],
        acctKeys: [],
        reportType: PreApptType.MSG,
        ...DEFAULT_APPT_BODY,
      });

      const [allowOrdersMenu] = useState(UserService.checkElementAccess(Constants.ordersMenu));
    
      const handleShowReport = (
        resHook,
        start = "",
        end = ""
      ) => {
        const startDate = start || resHook.dates.from;
        const endDate = end || resHook.dates.to;
        setApiBody({
          ...apiBody,
          locKeys: filters.locations?.map((item) => parseInt(item)) || [],
          commMethods: (filters.commMethods?.map((item) => item) ||
            []),
          beginDate: moment(startDate || moment().startOf("day")).format(),
          endDate: moment(endDate || moment().endOf("day")).format(),
        });
      };
    
      const handleQuickReport = (resHook) => {
        const start = moment().subtract(7, "days").format("YYYY-MM-DD");
        const end = moment().format("YYYY-MM-DD");
        resHook.setDateState({ from: start, to: end });
        handleShowReport(resHook, start, end);
      };
    

    const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

 const coverKey = getCoverKey();
 const authorization = getJwt();

      const APPT_RES = useApptReports(
        coverKey,
        authorization,
        ApptApi.ORDERS,
        apiBody
      );
      const {
        locations,
        providers,
        loadingFilters,
        errorFilters,
        columns,
        apptData,
        loadingData,
        errorData,
        FiltersProps,
      } = APPT_RES;

      const AUTH_AVAILABLE = coverKey && authorization;

      useEffect( async() => {    
          UserService.checkUser();
      },[]);

    return(
      <>
        {allowOrdersMenu ?
          <div className='appt-reports-page-wrapper'>
              <div className="web-appointments-wrapper">
                  <div className="page-picker-containers">
                      <PageTitle title="Orders" />
                  </div>
                  <ContentContainer>
                          {coverKey && locations && providers && (
                              <>
                                  <ApptFilters
                                      {...FiltersProps}
                                      filters={filters}
                                      locations={locations}
                                      providers={providers}
                                      setFilters={handleChange}
                                      handleShowReport={() => handleShowReport(APPT_RES)}
                                      handleQuickReport={
                                          handleQuickReport && (() => handleQuickReport(APPT_RES))
                                        }
                                  />
                                  <div className="web-appointments-results">
                                      <ApptActions
                                      search={apiBody.searchPhrase}
                                      setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                                      coverKey={coverKey}
                                      authorization={authorization}
                                      apiName={ApptApi.ORDERS}
                                      body={apiBody}
                                      />
                                      <div className="web-appointments-overflow">
                                          <OrdersApptTable 
                                              res={apptData?.pagedItems}
                                              loading={loadingData}
                                              columns={columns}
                                              error={errorData} 
                                          />
                                      </div>
                                      <Pagination
                                          page={page}
                                          setPage={(p) => {
                                              setPage(p);
                                              setApiBody({
                                              ...apiBody,
                                              pageStart: p,
                                              });
                                          }}
                                          total={apptData?.totalItemCount}
                                          pageSize={PAGE_LIMIT}
                                          />
                                  </div>
                              </>
                          )}
                          {AUTH_AVAILABLE && loadingFilters && <Loading full />}
                          {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
                              <LoadingError />
                          )}
                      </ContentContainer>
              </div>
          </div>
        :
        <div className="loading-error undefined">
          Not enough user rights
        </div>
        }
      </>
    );
};

export default OrdersPage;