import React, {useEffect, useRef, useState} from 'react';
import './TwoWayCard.scss';
import {FiAlertTriangle, FiFlag, FiTrash2, FiCheck, FiArrowRight, FiX} from 'react-icons/all';
import {environment} from '../../environment';
import Utils from '../../Store/Utils/Utils';
import UserService from '../../Store/UserService';
import {Tooltip} from '@material-ui/core';
import {subHours} from 'date-fns'
import LocationService from '../../Store/LocationsService/LocationsService'


const TwoWayCard = ({conversation, status, conversationClick, confirmMessageStatus}) => {
  const isLocationAware = LocationService.getLocationAware()
  const TwoWayCardHolder = useRef(null);
  const cardId = 'card--id--' + conversation.convKey;
  const [classStatus, setClassStatus] = useState('dot');
  const [cardHolderAction, setCardHolderAction] = useState('');
  let message = conversation.message.substring(0, environment.cardBlurbCountMax);
  if (conversation.message.length > environment.cardBlurbCountMax) {
    message += '...';
  }

  const hippaConsent = true;

  const switchStatus = (conversation) => {

    switch (conversation.messageStatus) {
      case environment.viewStatus.unread:
        setClassStatus('dot dot--blue');
        break;
      case environment.viewStatus.read:
        setClassStatus('');
        break;
    }
    switch (conversation.conversationStatus) {
      case environment.viewStatus.unanswered:
        setClassStatus('dot dot--green');
        break;
      case environment.viewStatus.flag:
        setClassStatus('dot dot--purple');
        break;
    }

  }

  const abbreviateLocation = (locationName) => {
    if (locationName === '') {
      return ''
    } else {
      var newLocationName = locationName.replace(/\,.*/, ''); // removes everything after the comma
      var abbreviated = newLocationName.match(/\b(\w| - )/g); // get first letter of each word and hyphen
      return abbreviated.join(''); // JSON
    }
  }

  const messagePreview = () => {
    let body = message;
    if (conversation.imageUrls) {
      if (conversation.imageUrls.length > 1) {
        body = `Attachments: ${conversation.imageUrls.length} Images`
      } else body = `Attachment: ${conversation.imageUrls.length} Image`
    }

    return <p>{body}</p>
  }

  const isPatient = conversation.firstName != null ? true : false;

  useEffect(() => {
    switchStatus(conversation);
  }, [conversation]);

  const handleMessageStatus = (viewStatus, _conversation) => {
    if (viewStatus === environment.viewStatus.unread || viewStatus === environment.viewStatus.read) {
      _conversation.conversationStatus = null;
      _conversation.messageStatus = viewStatus
    } else {
      _conversation.conversationStatus = viewStatus
    }
    switchStatus(_conversation);
    confirmMessageStatus(viewStatus, _conversation)
    setCardHolderAction(false)
  }

  const handleConversationClick = () => {
    let _conversation = conversation;
    if (_conversation.messageStatus === environment.viewStatus.unread && _conversation.conversationStatus !== environment.viewStatus.flag) {
      _conversation.conversationStatus = environment.viewStatus.unanswered;
      _conversation.unanswered = true;
    }
    _conversation.messageStatus = environment.viewStatus.read;

    switchStatus(_conversation);
    conversationClick(conversation)

    // Check to make sure the conversation has a valid patient
    handleExistingPatientLookup(_conversation, isPatient);
  }

  const handleExistingPatientLookup = (_conversation, _isPatient) => {
    if (!_isPatient) {
      const payload = {
        SessionId: UserService.getSessionId(),
        FirstName: '',
        LastName: '',
        Phone: _conversation.smsTo,
        PatientId: _conversation.PatientId
      }
      // getFindPatient(payload);
    }
    // Use /FindPatient to validate and create linkage for conversation
  }


  const handleShowMore = () => {
    setCardHolderAction('card--holder--active')
  }

  const handleHideMore = () => {
    setCardHolderAction('')
  }

  let tzOffset = (new Date().toString().match(/([-\+][0-9]+)\s/)[1]) * -1 / 100;
  return (
    <div className={`two-way-cont-card card--holder ${cardHolderAction}`} id={cardId} ref={TwoWayCardHolder} >
      <div className='card' onClick={(event) => handleConversationClick()}>
        {conversation.deliveryStatus === 'failed' ?
          <span className="status"> <FiAlertTriangle/> </span> :
          <span className={classStatus}></span>
        }
        <span id={`${cardId}--hotspot`} className='card--holder--hotspot' >
          <div className='row'>
            {conversation.firstName && <span>{conversation.firstName} {conversation.lastName}</span>}
            {!conversation.firstName && <span>{conversation.smsTo}</span>}
          {isLocationAware ? 
            <span>{Utils.formatDateTime(subHours(new Date(conversation.lastTransmission), tzOffset))}</span> : 
            <span>{Utils.formatDateTime(conversation.lastTransmission)}</span>
          } 
          </div>
          <div className='row'>
            {messagePreview()}
            <span
              className='location--label'>{abbreviateLocation(conversation.locName ? conversation.locName : '')}</span>
          </div>
        </span>



        {conversation.hasGivenHipaaConsent && <div className='row hipaa-consent'>
          <FiCheck/> <span>HIPAA Consent</span>
        </div>}



      </div>
      <div className='card--options' onMouseOver={(event) => handleShowMore()} onMouseOut={(event) => handleHideMore()} >


      <div className='card--option card--options--flag--holder'>


          <div className='card--options--flag'>
            <FiFlag/>
          </div>
          <div className='card--options--flags'>
            <Tooltip placement='top' title='Unread'  ><div onClick={(event) => handleMessageStatus(environment.viewStatus.unread, conversation)}><span className='dot dot--blue'/></div></Tooltip>
            <Tooltip placement='top' title='Unanswered'><div onClick={(event) => handleMessageStatus(environment.viewStatus.unanswered, conversation)}><span className='dot dot--green'/></div></Tooltip>
            <Tooltip placement='top' title='Flag'><div onClick={(event) => handleMessageStatus(environment.viewStatus.flag, conversation)}><span className='dot dot--purple'/></div></Tooltip>
            <Tooltip placement='top' title='Read'><div onClick={(event) => handleMessageStatus(environment.viewStatus.read, conversation)}><span className='dot dot--clear'/></div></Tooltip>
          </div>
        </div>
        <Tooltip placement='top' title='Remove'>
          <div className='card--option card--options--trash'
             onClick={(event) => handleMessageStatus(environment.viewStatus.trash, conversation)}><FiTrash2/></div>
        </Tooltip>

      </div>
    </div>
  )
}

export default TwoWayCard;