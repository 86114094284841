import React, {useState,useEffect} from 'react';
import { ApptApi, ContentContainer, useApptReports, ApptFilters, ApptActions, Loading, LoadingError,PurchaseApptTable,Pagination,PageTitle} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import {DEFAULT_APPT_BODY} from "./ReportsUtils";
import { getCoverKey, getJwt } from "./ReportsUtils";
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const PurchaseRecapturePage = () => {
  const [page, setPage] = useState(1);
  const PAGE_LIMIT = 10;
  const [filters, setFilters] = useState({
    locations: [],
    providers: [],
  });

  const [apiBody, setApiBody] = useState({
    locKeys: [],
    acctKeys: [],
    ...DEFAULT_APPT_BODY,
  });

  const [allowPurchaseRecaptureMenu] = useState(UserService.checkElementAccess(Constants.purchaseRecaptureMenu));

  const handleShowReport = (resHook) => {
    const startDate = resHook.dates.from;
    const endDate = resHook.dates.to;
    setApiBody({
      ...apiBody,
      locKeys: filters.locations?.map((item) => parseInt(item)) || [],
      acctKeys: filters.providers?.map((item) => parseInt(item)) || [],
      beginDate: moment(startDate || moment().startOf("day")).format(),
      endDate: moment(endDate || moment().endOf("day")).format(),
    });
  };
    
  const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

 const coverKey = getCoverKey();
 const authorization = getJwt();

  const APPT_RES = useApptReports(
    coverKey,
    authorization,
    ApptApi.PURCHASE,
    apiBody
  );
  const {
    locations,
    providers,
    loadingFilters,
    errorFilters,
    columns,
    apptData,
    loadingData,
    errorData,
    FiltersProps,
  } = APPT_RES;

  const AUTH_AVAILABLE = coverKey && authorization;

  useEffect( async() => {    
      UserService.checkUser();
  },[]);

return(
    <>
      {allowPurchaseRecaptureMenu ?
      <div className='appt-reports-page-wrapper'>
          <div className="web-appointments-wrapper">
              <div className="page-picker-containers">
                  <PageTitle title="Purchase Recapture" />
              </div>
              <ContentContainer>
                      {coverKey && locations && providers && (
                          <>
                              <ApptFilters
                                  {...FiltersProps}
                                  filters={filters}
                                  locations={locations}
                                  providers={providers}
                                  setFilters={handleChange}
                                  handleShowReport={() => handleShowReport(APPT_RES)}
                              />
                              <div className="web-appointments-results">
                                  <ApptActions
                                  search={apiBody.searchPhrase}
                                  setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                                  coverKey={coverKey}
                                  authorization={authorization}
                                  apiName={ApptApi.PURCHASE}
                                  body={apiBody}
                                  />
                                  <div className="web-appointments-overflow">
                                      <PurchaseApptTable 
                                          res={apptData?.pagedItems}
                                          loading={loadingData}
                                          columns={columns}
                                          error={errorData} 
                                      />
                                  </div>
                                  <Pagination
                                      page={page}
                                      setPage={(p) => {
                                          setPage(p);
                                          setApiBody({
                                          ...apiBody,
                                          pageStart: p,
                                          });
                                      }}
                                      total={apptData?.totalItemCount}
                                      pageSize={PAGE_LIMIT}
                                      />
                              </div>
                          </>
                      )}
                      {AUTH_AVAILABLE && loadingFilters && <Loading full />}
                      {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
                          <LoadingError />
                      )}
                  </ContentContainer>
        </div>
      </div>
      :
      <div className="loading-error undefined">
        Not enough user rights
      </div>
      }
    </>
);
};

export default PurchaseRecapturePage;