import React, {useContext, useEffect, useRef, useState} from 'react';
import './TwoWayConversation.scss';
import TwoWayMessageInput from '../TwoWayMessageInput/TwoWayMessageInput';
import TwoWayConversationService from '../../Store/TwoWayMessageService/TwoWayConversationService';
import TwoWayConversationTemplateService from '../../Store/TwoWayMessageService/TwoWayConversationTemplateService'
import {format, subHours} from 'date-fns'
import {FiCheck, FiChevronLeft, FiRotateCw} from 'react-icons/all';
import Logger from '../../Store/Logger/Logger';
import Utils from '../../Store/Utils/Utils';
import {environment} from '../../environment';
import Spinner from '../Spinner/Spinner';
import {Button} from '@material-ui/core';
import LocationService from '../../Store/LocationsService/LocationsService'
import {LocationServiceContext} from '../../Store/LocationsService/LocationServiceProvider';
import UserService from '../../Store/UserService';
import TwoWayConversationMessages from '../TwoWayConversationMessages/TwoWayConversationMessages';

const TwoWayConversation = ({
                              match,
                              windowHeight,
                              flagToRefreshConversation,
                              conversation,
                              patient,
                              unknownPatient,
                              refreshConversationList,
                              hasFiles,
                              setHasFiles
                            }) => {
  const {selectedLocationKeys} = useContext(LocationServiceContext);
  const [currentLocations, setCurrentLocations] = useState(selectedLocationKeys)
  const [loadingConversationDetails, setLoadingConversationDetails] = useState(true);
  const isLocationAware = LocationService.getLocationAware()
  const isMMSEnabled = LocationService.getMMSEnabled()
  const [loadingInterval, setLoadingInterval] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [loadingIntervalCount, setLoadingIntervalCount] = useState(0);
  const [currentPatient, setCurrentPatient] = useState(patient);
  const [isUnknownPatient, setIsUnknownPatient] = useState(unknownPatient);
  const [loadingIntervalTimer, setLoadingIntervalTimer] = useState(0);
  const [lastRefreshed, setLastRefreshed] = useState();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isSMSDisabled, setIsSMSDisabled] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState({});

  const [messages, setMessages] = useState({messages: []});
  const conversationList = useRef(null);
  const paramConvKey = match.params.convKey;
  const loadingIntervalCountMax = environment.conversationIntervalMax - 1;

  useEffect(() => {
    if (flagToRefreshConversation) {
      getNewConversation(conversation.convKey)
    }
  }, [flagToRefreshConversation]);

  useEffect(() => {
    getNewConversation(paramConvKey)
    const interval = setInterval(handleSetLoadingInterval, environment.getNewConversationInterval);
    setLoadingIntervalTimer(interval);
    setShowRefreshButton(false);
    return () => clearInterval(interval);

  }, [paramConvKey]);

  useEffect(() => {
    if (loadingIntervalCount > loadingIntervalCountMax) {
      setLoadingIntervalCount(0)
      setShowRefreshButton(true);
      setCurrentPatient(patient)
      setIsUnknownPatient(unknownPatient)
      clearInterval(loadingIntervalTimer)
    }
  }, [loadingIntervalCount])

  useEffect(() => {
    setCurrentLocations(selectedLocationKeys)
  }, [selectedLocationKeys])
  const handleSetLoadingInterval = () => {
    /**
     * Interval in which the conversations are polled
     */
    setLoadingIntervalCount(loadingIntervalCount => loadingIntervalCount + 1);
    getNewConversation(paramConvKey, false);
  }

  const refreshLoadingIntervalTimer = () => {
    /**
     * Get new messages every 60 seconds
     */
    const interval = setInterval(handleSetLoadingInterval, environment.getNewConversationInterval);
    setLoadingIntervalTimer(interval);
  }

  const getNewConversation = (_convKey, showloader = true) => {
    setLoadingInterval(true);
    if (showloader) {
      setLoadingConversationDetails(true);
    }
    setLastRefreshed(format(new Date(), '\'last updated at\' h:mm a'))
    const payload = {
      'sessionid': UserService.getSessionId(),
      'conversationKey': _convKey
    }
    TwoWayConversationService.getConversation(payload)
      .then((resp) => {
        setLoadingInterval(false);
        setLoadingConversationDetails(false);
        if (resp.data) {

          if (resp.data.hasOwnProperty('IsSMSDisabled')) {
            if (resp.data.IsSMSDisabled) {
              setIsSMSDisabled(true)
            } else {
              setIsSMSDisabled(false)
            }
          }

          let messagesObj = TwoWayConversationService.mapMessages(resp.data);
          let tzOffset = (new Date().toString().match(/([-\+][0-9]+)\s/)[1]) * -1 / 100;
          messagesObj.messages.filter((message) => {
            message.created = subHours(new Date(message.created), tzOffset);
            return message;
          })
          setMessages(messagesObj);
          if (document) {
            if (document.querySelector('.message--conversation--list')) {
              document.querySelector('.message--conversation--list').scrollTo(0, document.querySelector('.message--conversation--list').scrollHeight)
            }
          }
          setIsUnknownPatient(true)
          if (resp.data.FirstName) {
            setIsUnknownPatient(false)
            setCurrentPatient({...patient, firstName: resp.data.FirstName, lastName: resp.data.LastName})
          }
          if(resp.data.hasOwnProperty('Templates')) {
            if(resp.data.Templates.length){
              setMessageTemplates(TwoWayConversationTemplateService.mapTemplateInfo(resp.data))
            }
          }
        }
      })
      .catch((err) => {
        setLoadingInterval(false);
        setLoadingConversationDetails(false);
        Logger.printLog('getConversation', err, 'error');
      })
  }

  const conversationClose = () => {
    if (document) {
      if (document.getElementsByClassName(' message--conversation--holder')[0]) {
        document.getElementsByClassName(' message--conversation--holder')[0].classList.remove('message--conversation--active')
        document.getElementsByClassName(' message--conversation--holder--input')[0].classList.remove('message--conversation--holder--input--active')
      }
    }

  }

  const handleRefreshConversationAfterSend = () => {
    getNewConversation(conversation.convKey);
  }
  const handleRefreshConversationListAfterSend = () => {
    refreshConversationList();
  }



  const handleToggleSendMessage = (flag) => {
    setSendingMessage(flag)
  }

  return (
    <>
      {(!currentLocations && isLocationAware === true) ?
        (
          <> </>
        ) :
        (
          <>
          <div className='message--conversation--holder'>
            <div className='message--conversation message--conversation--header'>
              <div className='col'>
                <a className='btn-back' onClick={conversationClose}><FiChevronLeft/></a>
                {isUnknownPatient ?
                  (<div className='emptyName'></div>
                  ) : (
                    <div className='row'>
                      <h1>
                        <span className='txt--caps'>{currentPatient.firstName}</span> <span
                        className='txt--caps'>{currentPatient.lastName}</span>
                      </h1>
                      {conversation.hasGivenHipaaConsent && <div className='row hipaa-consent'>
                          <FiCheck/> <span>HIPAA Consent</span>
                        </div>}
                    </div>
                  )
                }
                <p>{Utils.formatPhoneNumber(patient.smsTo)}</p>
              </div>
              {conversation.locName && <div className='col'>
              <span className='message--location-badge'>
                <p>{conversation.locName}</p>
              </span>
              </div>}
            </div>


            <TwoWayConversationMessages
              conversationList={conversationList}
              messages={messages}
              conversation={conversation}
              patient={patient}
              loadingConversationDetails={loadingConversationDetails}
              setSendingMessage={setSendingMessage}
              handleRefreshConversationAfterSend={handleRefreshConversationAfterSend}
              handleRefreshConversationListAfterSend={handleRefreshConversationListAfterSend}
              unknownPatient={unknownPatient}
              windowHeight={windowHeight}
              newMessage={false}
            />
          </div>

            <div className='message--conversation--holder message--conversation--holder--input'>
            {!isSMSDisabled &&
              <>
                <TwoWayMessageInput newMessage='false' isLocationAware={isLocationAware} unknownPatient={unknownPatient}
                                    conversation={conversation}
                                    refreshConversationAfterSend={handleRefreshConversationAfterSend}
                                    refreshConversationListAfterSend={handleRefreshConversationListAfterSend}
                                    hasFiles={hasFiles} setHasFiles={setHasFiles}
                                    retrySendingMessage={sendingMessage}
                                    toggleRetrySendingMessage={handleToggleSendMessage}
                                    messageTemplates={messageTemplates}
                                    isMMSEnabled={isMMSEnabled}
                />

                <div className='message--conversation--refresher'>
                  {loadingInterval && <span className='message--conversation--refresher--spinner'><Spinner/></span>}
                  {(!loadingInterval && showRefreshButton) && <Button className='btn-refresh' onClick={(event) => refreshLoadingIntervalTimer()}><FiRotateCw/></Button>}
                </div>
              </>
            }

            {isSMSDisabled &&
            <div className='message--optout'><p>

              {!isUnknownPatient && currentPatient?.firstName} {!isUnknownPatient && currentPatient?.lastName}
              {isUnknownPatient && 'Patient'} has opted-out for text messages.
            </p></div>}


          </div>
          </>
        )}
    </>
  )
};

export default TwoWayConversation;