import React, { useState, useEffect, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import './WebScheduler.scss';

import axios from 'axios';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useRequest, useUIContext, useV5Request, useV5_With_FilterAdapter } from '../../../../ContextLib/contextHooks';
import ValidatedDatePicker from '../../../../Components/DatePicker/ValidatedDatePicker';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { RepeatType } from './process';
import InfoIcon from '@material-ui/icons/Info';

import { arraysAreEqual, arraySort } from '../../../../ContextLib/Core/coreLib';
import { useV5 } from '../../../../ContextLib/CoreConsumer/v5Contexts';
import moment from 'moment';
import _ from 'lodash';

const AllTypes = "(All Types)";
const AllTypesObj = { taskKey: -1, appointmentType: AllTypes, length: 0 };

const defaultBlackoutDays = [false, false, false, false, false, false, false];
const defaultAvailabilityDays = [false, true, true, true, true, true, false];
const defaultBlackoutRepeats = "once";
const defaultAvailabilityRepeats = "weekly";

const defaultRepetitionData = {
    days: defaultAvailabilityDays,
    repeats: defaultAvailabilityRepeats,
    ends: "never",
    endsDate: new Date().toLocaleDateString(),
    isGeneral: true
}

const defaultData = {
    startDate: new Date().toLocaleDateString(),
    startTime: "09:00",
    endTime: "18:00",
    breakPeriodList: [{startTime: '12:00', endTime: '13:00'}],
    repetition: defaultRepetitionData,
    anyAppointment: true,
    exemptions: [],
    simulataneous: 1
}
    
const isValidDays = (_days) => {
    return _days.find((x)=>x) ? true : false;
}

const timeStr = (s) => {
    s = s.trim().toLowerCase();
    if (s.indexOf("am") >= 0 || s.indexOf("pm") >= 0) {
        let t = s.split(" ")[0];
        let tt = s.split(" ")[1];
        let h = t.split(":")[0];
        let m = t.split(":")[1];
        
        let _h = parseInt(h);
        if (tt == "pm") _h += 12;
        h = (_h < 10 ? "0" : "") + _h;

        return `${h}:${m}`;
    } else {
        return s;
    }
}

const sortList = (src) => {
    return arraySort(src, "appointmentType");
}

const getAppointmentTypeOptions = (src) => {
    let a = [];
    if (src.length > 0) {
        const b = src.filter((x) => x != AllTypesObj);
        if (b.length > 0) a = [AllTypesObj, ...sortList(b)];
    }
    return a;
}

const BlocksForm=({dataEvent, appointmentTypesData, onSaved, isAvailability, loadData, setOpenDrawer, onValidate, onSaveAndValidate, loadConfigWithError, hasAvailabilityError, configWithError})=>{
    let componentId = "blocksForm";
    const ui = useUIContext(componentId);
    const { coverLocAcctDt, V5Request } = useV5_With_FilterAdapter(componentId);
      
    const [startDate, setStartDate] = useState(defaultData.startDate);
    const [startTime, setStartTime] = useState(defaultData.startTime);
    const [endTime, setEndTime] = useState(defaultData.endTime);
    const [breakPeriodList, setBreakPeriodList] = useState(defaultData.breakPeriodList);
    const [exemptions, setExemptions] = useState(defaultData.exemptions);

    const [anyAppointment, setAnyAppointment] = useState(defaultData.anyAppointment);

    const appointmentTypeOptionsInitial = getAppointmentTypeOptions(appointmentTypesData);

    const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState(sortList(appointmentTypesData));

    const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);
    const [showValidationMsgAT, setShowValidationMsgAT] = useState(false);
    
    const [timesValidationErrors, setTimesValidationErrors] = useState([]);
    const [bpValidationErrors, setBpValidationErrors] = useState([]);
    const [dateAndDaysValidationErrors, setDateAndDaysValidationErrors] = useState(null);
            
    const [repetition, setRepetition] = useState(defaultRepetitionData);
    const [_clearRepetition, setClearRepetition]=useState(new Date());
    const [_validateRepetition, setValidateRepetition]=useState(new Date());
    
    const [loadConfigData, setLoadConfigData] = useState(loadData);
    const [loadRepetitionData, setLoadRepetitionData] = useState();

    const [endsDt, setEndsDt] = useState(defaultRepetitionData.endsDate);

    const [editSingleEvent, setEditSingleEvent] = useState(null);
    const [simultaneous, setSimultaneous] = useState(defaultData.simulataneous);

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [loadConfigDataWithError, setLoadConfigDataWithError] = useState(loadConfigWithError);
    const [hasValidationErrors, setHasValidationErrors] = useState(hasAvailabilityError);
    const [configFormWithError, setConfigWithError] = useState(configWithError);

    const [disableCreate, setDisableCreate] = useState(false);
    const [editedConfigChanged, setEditedConfigChanged] = useState(true);
            
    const onCreateBlocks = async () => {
        let validated = validateTime() && validateBreakPeriodList() && validateAppointmentTypeList() && validateRepetition() && validateRepetitionWithinStartDateAndEndDate();
        
        if (!validated) return;
        
        const configFormData = getFormData();
        if (loadConfigData) validated = validateChangesMade(loadConfigData.config.configFormData, JSON.parse(configFormData.data.data), loadConfigData.editSingleEvent);

        if (!validated) return;

       // ui.ShowOverlay();

        let configForm = {
            ...coverLocAcctDt(),
            groupId: loadConfigData?.groupId,
            data: JSON.stringify({
                startDate: startDate,
                startTime: startTime,
                endTime: endTime,
                anyAppointment: isAvailability ? anyAppointment : true,
                appointmentTypeList: isAvailability ? selectedAppointmentTypes.map((x) => { return { taskKey: x.taskKey, name: x.appointmentType, length: x.length }; }) : [],
                breakPeriodList: isAvailability ? breakPeriodList : [],
                repetition: validateAndUpdateRepetition(repetition, startDate, false),
                isBlackout: !isAvailability,
                exemptions : loadConfigData === null ? [] : loadConfigData.config.configFormData.exemptions,
                editSingleEvent: editSingleEvent?.toLocaleDateString(),
                simultaneous: simultaneous
            }),
            wsConfigFormId: loadConfigData?.config.wsConfigFormId,
            startDate: startDate,
            endDate: repetition.endsDate,
            repetitionType: repetition.repeats,
            endType: repetition.ends,
            blockType: anyAppointment
        };

        var createBlockout = false
        var isGeneralBlockhasScheduleBefore = await checkGeneralBlocksFutureDate(configForm)
        if (isGeneralBlockhasScheduleBefore === "withPreviousBlocks")
        {
            await ui.MessageBox("All General block availability created for the future, requires a blackout in place till the start date of the availability. Please create blackouts for these dates and create future availability", "Validation Reminder")
        }
        else  if (isGeneralBlockhasScheduleBefore === "noPreviousBlocks")
        {
            createBlockout = await ui.ConfirmBox("All General block availability created for the future, require a blackout in place till the start date of the availability. Would you like to create this blackout ?", "Please Confirm")
        }          

        ui.ShowOverlay();
        //update event
        if (loadConfigData) {
            await handleValidateOrUpdate(configForm, configFormData.data)
        } 
        else {  
            await handleValidateOrSave(configForm, configFormData.data, createBlockout);
        }
        
        ui.HideOverlay();
    }

    const checkGeneralBlocksFutureDate = async(configForm) => {

        var isGeneral = configForm.repetitionType === "weekly" && configForm.endType === "never"
        var hasSchedulebefore = ""
        var compareDate = moment(configForm.startDate, "MM/DD/YYYY")        
        var dateNow = moment(moment().format("MM/DD/YYYY"))

        if (isGeneral && compareDate.isAfter(dateNow))
        {
            hasSchedulebefore = "noPreviousBlocks"
            dataEvent.forEach(el => {
                var startDate = moment(el.start, "MM/DD/YYYY")                
                if (startDate.isBefore(compareDate) && configForm.wsConfigFormId !== el.groupId)
                {
                    hasSchedulebefore = "withPreviousBlocks"
                }
            });
        }
        return hasSchedulebefore
    }      

    const checkOverlapTypeBlocks = async(configForm) => {

        var isGeneral = configForm.repetitionType === "weekly" && configForm.endType === "never"
        var isValid = true

        if (isGeneral)
        {
            var exemptions = JSON.parse(configForm.data).exemptions
            // Check if there are Special Blocks created
            // Dates onwards should All General Blocks            
            dataEvent.forEach(el => {
                var startDate = moment(el.start, "MM/DD/YYYY")
                var compareDate = moment(configForm.startDate, "MM/DD/YYYY")
                if (startDate > compareDate && el.extendedProps.config.configFormData.repetition.isGeneral === false)
                {
                    var dayOftheWeek =  startDate.day()
                    // check if the day is part of the exemption list, if true then ignore
                    var exemptionFound = exemptions.find(p => moment(p.startDate).format("MM/DD/YYYY") === startDate.format('MM/DD/YYYY'))                    
                    var daysList = JSON.parse(configForm.data).repetition.days;
                    if (daysList[dayOftheWeek] && !exemptionFound) // Check if Days is checked
                        isValid = false
                }
            });
        }
        else
        {
            // if not General Blocks 
            // Get all the dates with specific frequency
            var startDate = moment(configForm.startDate, "MM/DD/YYYY")
            var filerEventGeneralBlocks = dataEvent.filter(p => p.extendedProps.config.configFormData.repetition.isGeneral === true && p.groupId !== configForm.wsConfigFormId)     
            var repeats = JSON.parse(configForm.data).repetition.repeats
            var ends = JSON.parse(configForm.data).repetition.ends
            // Once
            if (repeats === "once" || repeats === "weekly")
            {
                var endDate = moment.now();
                if (repeats === "once")
                {
                    var endDayWeek = moment(configForm.startDate, "MM/DD/YYYY").add(6 - moment(configForm.startDate, "MM/DD/YYYY").days(),'days')         
                    endDate = (moment(configForm.endDate, "MM/DD/YYYY")).isAfter(endDayWeek) ? endDayWeek : (moment(configForm.endDate, "MM/DD/YYYY"))
                }
                else
                {
                    // Need to check if General Blocks will be converted to Special Blocks which is not allowed
                    var foundGeneral = dataEvent.find(p => p.extendedProps.config.configFormData.repetition.isGeneral === true
                        && moment(p.start).format("MM/DD/YYYY") === moment(configForm.startDate).format('MM/DD/YYYY')) 
                    if (foundGeneral)
                    {
                        isValid = false
                    }
                    else
                    {
                       var sixMonthsMax = moment(configForm.startDate, "MM/DD/YYYY").add(6,'M') 
                       endDate = (moment(configForm.endDate, "MM/DD/YYYY")).isAfter(sixMonthsMax) ? sixMonthsMax : (moment(configForm.endDate, "MM/DD/YYYY"))
                    }
                }

               for (var startDate = moment(startDate); startDate.isSameOrBefore(endDate); startDate.add(1, 'days')) {
                  var found = filerEventGeneralBlocks.find(p => moment(p.start).format("MM/DD/YYYY") === startDate.format('MM/DD/YYYY'))
                  if (found)
                  {
                    var dayOftheWeek =  startDate.day()
                    var daysList = JSON.parse(configForm.data).repetition.days;
                    if (daysList[dayOftheWeek]) // Check if Days is checked
                        isValid = false

                  }
               }
            }
            else if  (repeats === "bi-weekly")
            {        
                var endDate = moment(configForm.endDate, "MM/DD/YYYY")
               if (ends  === "never")
                   endDate = moment(configForm.startDate, "MM/DD/YYYY").add(6,'M') 

               while(startDate.isSameOrBefore(endDate))
               {                  
                    var found = filerEventGeneralBlocks.find(p => moment(p.start).format("MM/DD/YYYY") === startDate.format('MM/DD/YYYY'))
                    if (found)
                    {
                        var dayOftheWeek =  startDate.day()
                        var daysList = JSON.parse(configForm.data).repetition.days;
                        if (daysList[dayOftheWeek]) // Check if Days is checked
                            isValid = false
                    }

                    if (startDate.day() === 0)
                    {
                        startDate.add(8, 'days') // Start is Sunday
                    }
                    else
                    {
                        startDate.add(1, 'days')
                    }
               }
            } 
            else if  (repeats === "monthly")
            {
                var endDate = moment(configForm.endDate, "MM/DD/YYYY")
                if (ends  === "never")
                    endDate = moment(configForm.startDate, "MM/DD/YYYY").add(6,'M') 
                // Get the last day of the startDate
                var lastDayOfMonth = new Date(startDate.year(), startDate.month() + 1, 0);
                while(startDate.isSameOrBefore(endDate))
                {                  
                     var found = filerEventGeneralBlocks.find(p => moment(p.start).format("MM/DD/YYYY") === startDate.format('MM/DD/YYYY'))
                     if (found)
                     {
                         var dayOftheWeek =  startDate.day()
                         var daysList = JSON.parse(configForm.data).repetition.days;
                         if (daysList[dayOftheWeek]) // Check if Days is checked
                             isValid = false
                     }

                     if (startDate.day() === moment(lastDayOfMonth).day())
                     {
                         startDate.add(1, 'M').add(1, 'days')
                     }
                     else
                     {
                         startDate.add(1, 'days')
                     }
                }                
            } 


            // Bi-Weekly
            // MonthLy
        }
       return isValid
    }    

    
    //Edit availability action
    const handleValidateOrUpdate = async(configForm, configFormWithConfigId) => {

        var isValid = await checkOverlapTypeBlocks(configForm)

        if (!isValid)
        {
            ui.MessageBox("Delete all events on the day and re-add all schedules as required.", "Validation Failure")
            return
        }

        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const configFormToUpdate = {... configForm };
        
        if (hasValidationErrors) //with existing validation errors - validate the edited config first, reject update if it has overlaps, else update the config
        {
            let configEditedHasOverlapWithConfigWithError = await handleValidateEditedConfigWithConfigWithError(configForm);
            if (configEditedHasOverlapWithConfigWithError) {
                return;
            }
            const configFormId = Number(loadConfigData.groupId);
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}&exceptConfigId=${configFormId}`;
            const resValidation = await axios.post(url, configFormToUpdate);
            if (resValidation.status === 200 && resValidation.data.length > 0) 
            {
                ui.HideOverlay();
                const type = JSON.parse(configFormWithError.data).isBlackout ? "Blackout" : "Availability";
                let errorMessage = `The ${type} rule you are trying to update will cause another conflict. Please try updating the parameters to avoid another conflict.` ;
                await ui.ErrorBox(errorMessage, "Availability Error");
            } 
            else if (resValidation.status === 200 && resValidation.data.length === 0)
            {
                let resUpdate;
                if (loadConfigData.editSingleEvent)
                {
                    const configFormId = Number(loadConfigData.groupId);
                    const targetDate = moment(new Date(configForm.startDate)).format("YYYY-MM-DD");
                    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditSingleBlockByDate?configId=${configFormId}&targetDate=${targetDate}&secretCode=${apiKey}`;
                    resUpdate = await axios.post(url, configFormToUpdate);
                } else {
                    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditConfigForm?secretCode=${apiKey}`;
                    resUpdate = await axios.post(url, configFormToUpdate);
                }

                if (resUpdate.status === 200) { //if update is successful, run the validation again to update the overlap errors in the UI
                    const configFormId = Number(configFormWithError.groupId);
                    const url = configFormId > 0 ? `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}&exceptConfigId=${configFormId}` : `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}`;
                    const resValidation = await axios.post(url, configFormWithError);
    
                    if (resValidation.status === 200 && resValidation.data.length > 0) 
                    {
                        onSaveAndValidate(resUpdate, resValidation, configFormWithError, configFormWithError);
                        setHasValidationErrors(true);
                    } 
                    else if (resValidation.status === 200 && resValidation.data.length === 0) //if there are no more validation errors, proceed with saving of the config
                    {
                        const configFormId = Number(configFormWithError.groupId);
                        if (configFormId > 0) //source of error is edit
                        {
                            let configWithErrorSinglEventOnly = (configFormWithError.startDate === configFormWithError.endDate && configFormWithError.repetitionType === "once") ? true : false;
                            if (loadConfigData.editSingleEvent || configWithErrorSinglEventOnly)
                            {
                                const configFormId = Number(configFormWithError.groupId);
                                const targetDate = moment(new Date(configFormWithError.startDate)).format("YYYY-MM-DD");
                                const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditSingleBlockByDate?configId=${configFormId}&targetDate=${targetDate}&secretCode=${apiKey}`;
                                resUpdate = await axios.post(url, configFormWithError);
                            } else {
                                const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditConfigForm?secretCode=${apiKey}`;
                                resUpdate = await axios.post(url, configFormWithError);
                            }
    
                            if (resUpdate.status === 200) {
                                ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully updated`);
                                onSaveAndValidate(resUpdate, resValidation, configFormWithError, configFormWithError);
                            }
                        } else { //source of error is create
                            const urlSave = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/SaveConfigForm?secretCode=${apiKey}`;
                            const res = await axios.post(urlSave, configFormWithError);
                            if (res.status === 200) {
                                ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully created`);
                                onSaveAndValidate(res, resValidation, configFormWithError, configFormWithError);
                            }
                        }

                    }
                }
            }
        }
        else //no existing validation errors - validate the edited config first, show overlap errors if any, else update the config
        {
            const configFormId = Number(loadConfigData.groupId);
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}&exceptConfigId=${configFormId}`;
            const resValidation = await axios.post(url, configForm);
            if (resValidation.status === 200 && resValidation.data.length > 0) 
            {
                onValidate(resValidation, configForm, configFormWithConfigId);
                setHasValidationErrors(true);
                setConfigWithError(configForm);
            } 
            else if (resValidation.status === 200 && resValidation.data.length === 0) //no overlap errors found, update configuration
            {
                let res;
                if (loadConfigData.editSingleEvent)
                {
                    const configFormId = Number(loadConfigData.groupId);
                    const targetDate = moment(new Date(configForm.startDate)).format("YYYY-MM-DD");
                    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditSingleBlockByDate?configId=${configFormId}&targetDate=${targetDate}&secretCode=${apiKey}`;
                    res = await axios.post(url, configForm);
                } else {
                    const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/EditConfigForm?secretCode=${apiKey}`;
                    res = await axios.post(url, configForm);
                }

                if (res.status === 200) {
                    ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully updated`);
                    onSaved(res);
                }
            }
        }

    }

    //Create availability action - Check if config form has overlap errors with existing configs, else save the config.
    const handleValidateOrSave = async (configForm, configFormWithConfigId, createBlockout = false) => {

        var isValid = await checkOverlapTypeBlocks(configForm)

        if (isValid)
        {        
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/ValidateWSConfig?secretCode=${apiKey}`;
            const resValidation = await axios.post(url, configForm);

            if (resValidation.status === 200 && resValidation.data.length > 0) 
            {
                onValidate(resValidation, configForm, configFormWithConfigId);
                setHasValidationErrors(true);
                setConfigWithError(configForm);
            } else if (resValidation.data.length === 0) //no overlap errors found, save configuration
            {

                if (createBlockout)
                {
                     var blockoutConfig ={...configForm}
                     var data =  JSON.parse(blockoutConfig.data);
                     data.startDate = moment().format("MM/DD/YYYY")
                     data.repetition.endsDate = moment(blockoutConfig.startDate).subtract(1).format("MM/DD/YYYY")
                     data.repetition.repeats = "weekly"
                     data.repetition.ends = "date"
                     data.repetition.isGeneral = false
                     data.isBlackout = true
                     data.anyAppointment = true       
                     data.appointmentTypeList = []
                     data.breakPeriodList = []  
                     data.exemptions = []            
                     blockoutConfig.data = JSON.stringify(data)
                     blockoutConfig.endType = "date"
                     blockoutConfig.repetition = "weekly"
                     blockoutConfig.blockType = true
                     blockoutConfig.endDate = moment(blockoutConfig.startDate).subtract(1).format("MM/DD/YYYY")
                     blockoutConfig.startDate = moment().format("MM/DD/YYYY")
                     const urlBSave = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/SaveConfigForm?secretCode=${apiKey}`;
                     const response = await axios.post(urlBSave, blockoutConfig);
                     if (response.status === 200) {
                         ui.ShowSnackbar(`Blackout was successfully created`);
                         onSaved(response);
                     }
                }

                const urlSave = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/SaveConfigForm?secretCode=${apiKey}`;
                const res = await axios.post(urlSave, configForm);
                if (res.status === 200) {
                    ui.ShowSnackbar(`${isAvailability ? "Availability" : "Blackout"} was successfully created`);
                    onSaved(res);
                }
            }
        }
        else
        {
                  ui.MessageBox("Delete all events on the day and re-add all schedules as required.","Validation Failure")
        }        
    }

    const checkIfRepetitionDaysWillOverlap = (configForm) => {
        let configFormRepetitionDays = JSON.parse(configForm.data).repetition.days;
        let configFormWithErrorRepetitionDays = JSON.parse(configFormWithError.data).repetition.days;

        for (let i=0; i < 7; i++)
        {
            if (configFormRepetitionDays[i] && configFormWithErrorRepetitionDays[i])
            {
                return true;
            }
        }

        return false;
    }

    const checkIfAppointmentTypesWillOverlap = (configForm) => {
        let configFormAppointmentTypes = JSON.parse(configForm.data).appointmentTypeList;
        let configFormWithErrorAppointmentTypes = JSON.parse(configFormWithError.data).appointmentTypeList;

        let configFormAnyAppointment = JSON.parse(configForm.data).anyAppointment;
        let configFormWithErrorAnyAppointment = JSON.parse(configFormWithError.data).anyAppointment;

        if (configFormAnyAppointment || configFormWithErrorAnyAppointment)
        {
            return true;
        }
        else
        {
            for (let i = 0; i < configFormAppointmentTypes.length; i++)
            {
                for (let j = 0; j < configFormWithErrorAppointmentTypes.length; j++)
                {
                    if (configFormAppointmentTypes[i].taskKey === configFormWithErrorAppointmentTypes[j].taskKey)
                    {
                        return true;
                    }
                }
            }   
        }

        return false;
    }

    const checkIfConfigStartAndEndTimeWillOverlap = (configForm) => {
        let configFormStartDate = JSON.parse(configForm.data).startDate;
        let configFormStartTime = JSON.parse(configForm.data).startTime;
        let configFormEndDate = JSON.parse(configForm.data).repetition.endsDate;
        let configFormEndTime = JSON.parse(configForm.data).endTime;

        let configFormWithErrorStartTime = JSON.parse(configFormWithError.data).startTime;        
        let configFormWithErrorEndTime = JSON.parse(configFormWithError.data).endTime;

        const startDate1 = new Date(moment(configFormStartDate).format("YYYY-MM-DD") + "T" + configFormStartTime);
        const endDate1 = new Date(moment(configFormEndDate).format("YYYY-MM-DD") + "T" + configFormEndTime);

        const startDate2 = new Date(moment(configFormStartDate).format("YYYY-MM-DD") + "T" + configFormWithErrorStartTime);
        const endDate2 = new Date(moment(configFormStartDate).format("YYYY-MM-DD") + "T" + configFormWithErrorEndTime);

        const startTimestamp1 = startDate1.getTime();
        const endTimestamp1 = endDate1.getTime();

        const startTimestamp2 = startDate2.getTime();
        const endTimestamp2 = endDate2.getTime();

        // If the end of the first period is on or before the start of the second period, or vice versa, there is no overlap.
        if (endTimestamp1 <= startTimestamp2 || endTimestamp2 <= startTimestamp1)
        {
            return false;
        }

        return true;
    }

    //Validate current config being edited with configFormWithError
    const handleValidateEditedConfigWithConfigWithError = async (configForm) => {
        let repetitionDaysWillOverlap = checkIfRepetitionDaysWillOverlap(configForm);
        let appointmentTypesWillOverlap = checkIfAppointmentTypesWillOverlap(configForm);
        let dateTimeWillOverlap = checkIfConfigStartAndEndTimeWillOverlap(configForm);
        if (repetitionDaysWillOverlap === true && appointmentTypesWillOverlap === true && dateTimeWillOverlap === true)
        {
            ui.HideOverlay();
            const type = JSON.parse(configFormWithError.data).isBlackout ? "Blackout" : "Availability";
            let errorMessage = `The ${type} rule you are trying to update will still cause conflict with the current config you are trying to save. Please try updating the parameters to avoid another conflict.` ;
            await ui.ErrorBox(errorMessage, "Availability Error");
            return true;
        }
        return false;
    }

    const validateAndUpdateRepetition = (repetition, startDate, isSingleEvent) => {
        const startDate1 = new Date(moment(startDate).format("YYYY-MM-DD"));
        const endDate1 = new Date(moment(repetition.endsDate).format("YYYY-MM-DD"));

        let invalidEndDate = startDate1 > endDate1 ? true : false;
        if (repetition.repeats === "once" && invalidEndDate)
        {
            repetition.endsDate = startDate;
        }

        if (isSingleEvent)
        {
            repetition.endsDate = startDate;
        }

        repetition.isGeneral = repetition.repeats === "weekly" && repetition.ends === "never"
        return repetition;
    }

    const getFormData = (isNew)=> {
        return {
            ...coverLocAcctDt(),
            data: {
                ...coverLocAcctDt(),
                groupId: loadConfigData?.groupId,
                data: JSON.stringify({
                    startDate: startDate,
                    startTime: startTime,
                    endTime: endTime,
                    anyAppointment: isAvailability ? anyAppointment : true,
                    appointmentTypeList: isAvailability ? selectedAppointmentTypes.map((x) => { return { taskKey: x.taskKey, name: x.appointmentType, length: x.length }; }) : [],
                    breakPeriodList: isAvailability ? breakPeriodList : [],
                    repetition: repetition,
                    isBlackout: !isAvailability,
                    exemptions: exemptions,
                    editSingleEvent: editSingleEvent?.toLocaleDateString(),
                    simultaneous: simultaneous
                }),
                blockKeys: "",
                fromOnboarding: false,
                mongoDBDocumentId: "",
                orderRef: null
            }
        }
    }

    const onAddBreakPeriod = () => {
        setBreakPeriodList([...breakPeriodList, {startTime: '', endTime: ''}]);
    }

    const onDeleteBreakPeriod = index => {
        const list = [...breakPeriodList];
        list.splice(index, 1);
        setBreakPeriodList(list);
    }

    const handBreakPeriodChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...breakPeriodList];
        list[index][name] = value;

        setBreakPeriodList(list);
    }

    const checkifStartAndEndDateDayDiffLessThanAWeek = () => {
        const startDt = new Date(startDate);
        const endDt = new Date(repetition.endsDate);

        // Calculate the difference in milliseconds between the start and end dates
        const timeDiff = endDt.getTime() - startDt.getTime();

        // Calculate the number of days between the start and end dates
        const numDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // If diff days is less than or equal to 6, validate because the span of the start and end date is not one week.
        if (numDays <= 6) return true;

        return false;
    }

    const validateRepetitionWithinStartDateAndEndDate = () => {
        let isValid = true;
        if (repetition.ends != "never" && !editSingleEvent)
        {
            if (checkifStartAndEndDateDayDiffLessThanAWeek())
            {
                const daysOfWeek  = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const selectedDays = repetition.days;
                const selectedDayNames = [];
                for (let i = 0; i < selectedDays.length; i++) {
                    if (selectedDays[i]) {
                        selectedDayNames.push(daysOfWeek[i]);
                    }
                }

                let selectedStartDate = new Date(startDate);
                let endDate = new Date(repetition.endsDate);
                //if start date and end date is the same, validate one day only.
                if (startDate == repetition.endsDate)
                {
                    const startDateDayOfWeek = selectedStartDate.toLocaleString('en-US', { weekday: 'long' });
                    if (selectedDayNames.includes(startDateDayOfWeek) == false) 
                    {
                        isValid = false;
                    }
                } else {
                    // Get an array of day names for the start and end date range
                    const dateRange = [];
                    for (let d = selectedStartDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                        const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
                        dateRange.push(dayOfWeek);
                    }

                    // Check if at least one selected day is included in the date range
                    let isMatch = false;
                    for (let i = 0; i < selectedDayNames.length; i++) {
                        if (dateRange.includes(selectedDayNames[i])) {
                            isMatch = true;
                            break;
                        }
                    }

                    isValid = isMatch;
                }
            }
        }

        if (!isValid) {
            setDateAndDaysValidationErrors("Please select at least one day that's within the start and end date");
        } else {
            setDateAndDaysValidationErrors(null);
        }

        return isValid;
    }

    const validateChangesMade = (loadedConfigForm, editedConfigForm, editSingleEvent) => {
        let configFormLoadedOneDayOnly = loadedConfigForm.startDate === loadedConfigForm.repetition.endsDate ? true : false;
        let configFormEditedOneDayOnly = editedConfigForm.startDate === editedConfigForm.repetition.endsDate ? true : false;
        let isOneDayOnlyLoadedAndEdited = configFormLoadedOneDayOnly && configFormEditedOneDayOnly && !editSingleEvent;

        const loadedConfigFormRepetitionUpdated = loadedConfigForm.repetition.ends === "never" && loadedConfigForm.repetition.repeats === "weekly" ? { ...loadedConfigForm.repetition, isGeneral: true } : {...loadedConfigForm.repetition, isGeneral: false};
        const editedConfigFormRepetitionUpdated = editedConfigForm.repetition.ends === "never" && editedConfigForm.repetition.repeats === "weekly" ? { ...editedConfigForm.repetition, isGeneral: true } : {...editedConfigForm.repetition, isGeneral: false};
        
        let configFormLoaded = {
            anyAppointment: loadedConfigForm.isBlackout ? null : loadedConfigForm.anyAppointment,
            appointmentTypeList: loadedConfigForm.isBlackout ? null : loadedConfigForm.appointmentTypeList,
            breakPeriodList: loadedConfigForm.isBlackout ? null : loadedConfigForm.breakPeriodList.map(({sTime, eTime, ...rest}) => rest),
            startDate: loadedConfigForm.startDate,
            startTime: loadedConfigForm.startTime,
            endTime: loadedConfigForm.endTime,
            repetition: loadedConfigFormRepetitionUpdated
        };

        let configFormEdited = {
            anyAppointment: editedConfigForm.isBlackout ? null : editedConfigForm.anyAppointment,
            appointmentTypeList: editedConfigForm.isBlackout ? null : editedConfigForm.appointmentTypeList,
            breakPeriodList: editedConfigForm.isBlackout ? null : editedConfigForm.breakPeriodList,
            startDate: editedConfigForm.startDate,
            startTime: editedConfigForm.startTime,
            endTime: editedConfigForm.endTime,
            repetition: editedConfigFormRepetitionUpdated
        }

        let configChanged = true;

        if (_.isEqual(configFormLoaded, configFormEdited)) 
        {
            configChanged = false;
        }
        setEditedConfigChanged(configChanged);

        return configChanged;
    }

    const validateRepetition = () => {
        setValidateRepetition(new Date());
        return isValidDays(repetition.days ?? repetition.days);
    }
    
    const validateTime = () => {
        let errors = [];
        
        if (startTime === '' || endTime === '') errors.push(`Start/End time not specified`);
        if (getDateTime(startTime) > getDateTime(endTime)) errors.push(`Start time cannot be later than End time or the same as the Start time`);
        if (checkAvailabilityTime(startTime, endTime)) errors.push(`Please select availability between 6am and 11 pm only.`);
                
        setTimesValidationErrors(errors);
        return errors.length == 0;        
    }
    const checkAvailabilityTime = (startTime, endTime) => {
        let returnResult = false;
        let checkerStartTime = parseInt(startTime);
        let checkerEndTime = parseInt(endTime);
        if (checkerEndTime == 23) {
            if (endTime != "23:00") {
                returnResult = true;
            }
        }
        for (let i = 1;i <= 5;i++) {
            if (checkerStartTime == i) {
                returnResult = true;
            }
        }
        if (checkerEndTime == 0) {
            returnResult = true;
        }

        let result = checkerStartTime
        return returnResult;
    }

    const validateBreakPeriodList = () => {
        if (!isAvailability) return true;

        let errors = [];
        
        breakPeriodList.forEach((item, _i) => {
            let i = _i + 1;
            if (item.startTime === '' || item.endTime === '') errors.push(`Break #${i}: Start/End time not specified`);
            if (getDateTime(item.startTime) >= getDateTime(item.endTime)) errors.push(`Break #${i}: Start time cannot be later or equal to End time`);

            if (
                !(getDateTime(item.startTime) >= getDateTime(startTime) && getDateTime(item.endTime) <= getDateTime(endTime))
            ) errors.push(`Break #${i}: Breaks must be within availability times`);
        })
        
        setBpValidationErrors(errors);
        return errors.length == 0;        
    }

    const getDateTime = (stringTime, addSubtractMinutes) => {
        if (addSubtractMinutes == null) addSubtractMinutes = 0;

        let dt = new Date(startDate);
        dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), stringTime.substring(0,2), stringTime.substring(3,5), 0);
        return new Date(dt.getTime() + (addSubtractMinutes*60000));
    }

    const onAddAppointmentType = (selectedValue, reason) => {
        
        if (reason === 'select-option') {
            let a = [];
            let b = [];

            if (selectedValue.appointmentType == AllTypes) {
                a = sortList(appointmentTypesData);
                b = [];
            } else {
                a = sortList([...selectedAppointmentTypes, selectedValue]);
                b = getAppointmentTypeOptions(appointmentTypeOptions.filter((x) => x.appointmentType != selectedValue.appointmentType));
            }    

            setSelectedAppointmentTypes(a);
            setAppointmentTypeOptions(b);

            setShowValidationMsgAT(false);
        }
    }

    const onDeleteAppointmentType = (item, all) => {
        let a = [];
        let b = [];

        if (all) {
            b = [...appointmentTypeOptionsInitial];
        } else {
            a = selectedAppointmentTypes.filter((x) => x.appointmentType != item.appointmentType);
            b = getAppointmentTypeOptions([...appointmentTypeOptions, item]);    
        }        

        setSelectedAppointmentTypes(a);
        setAppointmentTypeOptions(b);
    }

    const validateAppointmentTypeList = () => {
        if (!isAvailability) return true;

        let returnFlag = true;
        if (selectedAppointmentTypes.length === 0) returnFlag = false;

        if (!returnFlag) setShowValidationMsgAT(true);
        if (returnFlag) setShowValidationMsgAT(false);
        
        return returnFlag;
    }

    const clearForm = () => {
        setLoadConfigData(null);

        setStartDate(defaultData.startDate)
        setStartTime(defaultData.startTime);
        setEndTime(defaultData.endTime);
        setBreakPeriodList(isAvailability ? defaultData.breakPeriodList : []);
        
        setClearRepetition(new Date());
        
        setSelectedAppointmentTypes(sortList(appointmentTypesData));
        setAppointmentTypeOptions([]);
        setShowValidationMsgAT(false);

        setExemptions(defaultData.exemptions);
        setSimultaneous(defaultData.simulataneous);
        setDisableCreate(true);
    }

    const loadForm = (data, editSingleEvent) => {
        const brk = isAvailability ? data.breakPeriodList.map((x)=> { return { startTime: x.startTime, endTime: x.endTime }; }) : [];
        const appt = isAvailability ? sortList(data.appointmentTypeList.map((x) => { return { taskKey: x.taskKey, appointmentType: x.name, length: x.length }; })) : [];
        const opts = getAppointmentTypeOptions(appointmentTypesData.filter((x) => !appt.find((y)=> y.taskKey == x.taskKey)));

        setStartDate(data.startDate)
        setStartTime(timeStr(data.startTime));
        setEndTime(timeStr(data.endTime));
        setBreakPeriodList(brk);
        setSelectedAppointmentTypes(appt);                
        setAppointmentTypeOptions(opts);
        setShowValidationMsgAT(false);
        setLoadRepetitionData(validateAndUpdateRepetition(data.repetition, data.startDate, editSingleEvent));
        setRepetition(data.repetition);

        setExemptions(editSingleEvent ? [] : data.exemptions);
        setEditSingleEvent(editSingleEvent);

        setSimultaneous(data.simultaneous);
    }

    const handleRepetitionChange = (data) => {
        setRepetition(data);
        if (data.ends == "never" && !isAvailability)
            setDisableCreate(true)
         else
            setDisableCreate(false)          
    }    

    const removeExemption = async (dispValue) => {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const configFormId = Number(loadConfigData.groupId);
        const dt = new Date(dispValue.split("-")[0]);
        var confirmed = await ui.ConfirmBox("Remove this date [" + dispValue + "] to the exemption list?" )
        if (confirmed == 1) {
            const url = `${process.env.REACT_APP_WEBSCHEDULERCONFIG_API}/api/v1/WebSchedulerConfig/DeleteExemptionByDate?configId=${configFormId}&targetDate=${dispValue}&secretCode=${apiKey}`;
            const res = await axios.post(url);
            if (res.status === 200) {
                ui.ShowSnackbar(`Exemption date deleted successfully`);
                onSaved(res);
            }            
        }
    }

    useEffect(()=>{        
        if (loadConfigData) 
        {
            loadForm(loadConfigData.config.configFormData, loadConfigData.editSingleEvent);
            if (loadConfigData.config.configFormData.repetition.ends == "never" && !isAvailability)
            setDisableCreate(true)
            else
            setDisableCreate(false)  
        }
    },[loadConfigData]);

    useEffect(() => {
        if (loadConfigDataWithError) 
        {
            loadForm(JSON.parse(loadConfigDataWithError.data), null);
            setIsReadOnly(true);
        }
    },[loadConfigDataWithError]);

    useEffect(()=> {
        const a = appointmentTypesData.map((x) => { return { taskKey: x.taskKey }; });
        const b = selectedAppointmentTypes.map((x) => { return { taskKey: x.taskKey }; });
        const c = arraysAreEqual(a, b, "taskKey");
        setAnyAppointment(c);
    });
    
    return (<div className='form-drawer'>
        {loadConfigData && isReadOnly ? (<>        
            <Typography variant="h6">            
                {`View ${isAvailability ? "Availability" : "Blackout"}`}
            </Typography>
            <Divider />
            <ValidatorForm readOnly={true}> 
                <Grid container direction="row" justify="flex-start"  alignItems="center" spacing={3} className="grid-drawer">                
                    <Grid item xs={12}>
                        <InputLabel className="pageLabel" shrink>Start Date</InputLabel>
                        <TextField 
                                value={new Date(startDate).toLocaleDateString()} 
                                fullWidth 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    readOnly: true
                                }}
                            />                   
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Start Time</InputLabel>
                        <TextField
                            id="startTime"
                            type="time"
                            value={startTime}
                            onChange={e => {setStartTime(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                                readOnly: true
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>End Time</InputLabel>
                        <TextField
                            id="endTime"
                            type="time"
                            value={endTime}
                            onChange={e => {setEndTime(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300,
                                readOnly: true
                            }}
                        />
                    </Grid>

                    {isAvailability && (<>
                        { breakPeriodList.length > 0 && (<>
                            {breakPeriodList.map((item, i) => {
                                return(<>
                                    { i === 0 &&  <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Break Period(s)</InputLabel>}
                                    <Grid key={i} container item style={{paddingTop: 0}}>
                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="startTime"
                                            value={item.startTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300,
                                            readOnly: true
                                            }}
                                        />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="endTime"
                                            value={item.endTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300,
                                            readOnly: true
                                            }}
                                            />
                                        </Grid>
                                    </Grid>                                
                                </>)                    
                            })}                            
                        </>)
                        }         
                    
                        {isAvailability && (
                        <>
                            <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Appointment Type(s)</InputLabel>
                            { selectedAppointmentTypes.length > 0 && (<>
                                {anyAppointment ? (<>
                                    <Grid key={0} container item style={{paddingTop: 0}}>
                                        <Grid item xs={10}>
                                            <TextField
                                            fullWidth
                                            disabled
                                            value={AllTypes}
                                        />
                                        </Grid>
                                    </Grid>
                                </>) : (<>
                                    {selectedAppointmentTypes.map((item, i) => {
                                        return(
                                        <>
                                        <Grid key={i} container item style={{paddingTop: 0}}>
                                            <Grid item xs={10}>
                                                <TextField
                                                fullWidth
                                                disabled
                                                value={item.appointmentType}
                                            />
                                            </Grid>
                                        </Grid>
                                        </>
                                        )
                                    })}
                                </>)}                            
                            </>)}
                        </>)}
                    </>)}

                    {isAvailability && (<>
                        <Grid item xs={12}>
                            <InputLabel className="pageLabel" shrink>Allow Simultaneous Appointments</InputLabel>
                            <TextField
                                id="simultaneous"
                                type="number"
                                value={simultaneous}
                                onChange={e => {setSimultaneous(e.target.value)}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                    required: true,
                                    readOnly: true
                                }}
                            />
                        </Grid>                    
                    </>)}

                    <Grid item xs={12}>
                        <Repetition endsDt={endsDt} clear={_clearRepetition} validate={_validateRepetition} onChange={handleRepetitionChange} 
                            loadData={loadRepetitionData} readOnly={true} isAvailability={isAvailability} />
                    </Grid>

                    {exemptions && exemptions.length > 0 && (<>
                        <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Exemptions</InputLabel>
                        <Grid item xs={12}>
                            <ul>
                                {exemptions.map((x)=> { return (<li>{x.displayValue}</li>)})}
                            </ul>
                        </Grid>
                    </>)}

                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={()=>setOpenDrawer(false)}>
                                Close
                        </Button>  
                    </Grid>
                </Grid>  
            </ValidatorForm>
        </>)
        : loadConfigDataWithError && isReadOnly ? (<>        
            <Typography variant="h6">            
                {`View ${isAvailability ? "Availability" : "Blackout"}`}
            </Typography>
            <Divider />
            <ValidatorForm readOnly={true}> 
                <Grid container direction="row" justify="flex-start"  alignItems="center" spacing={3} className="grid-drawer">                
                    <Grid item xs={12}>
                        <InputLabel className="pageLabel" shrink>Start Date</InputLabel>
                        <TextField 
                                value={new Date(startDate).toLocaleDateString()} 
                                fullWidth 
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    readOnly: true
                                }}
                            />                   
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Start Time</InputLabel>
                        <TextField
                            id="startTime"
                            type="time"
                            value={startTime}
                            onChange={e => {setStartTime(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                                readOnly: true
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>End Time</InputLabel>
                        <TextField
                            id="endTime"
                            type="time"
                            value={endTime}
                            onChange={e => {setEndTime(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300,
                                readOnly: true
                            }}
                        />
                    </Grid>

                    {isAvailability && (<>
                        { breakPeriodList.length > 0 && (<>
                            {breakPeriodList.map((item, i) => {
                                return(<>
                                    { i === 0 &&  <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Break Period(s)</InputLabel>}
                                    <Grid key={i} container item style={{paddingTop: 0}}>
                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="startTime"
                                            value={item.startTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300,
                                            readOnly: true
                                            }}
                                        />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="endTime"
                                            value={item.endTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300,
                                            readOnly: true
                                            }}
                                            />
                                        </Grid>
                                    </Grid>                                
                                </>)                    
                            })}                            
                        </>)
                        }         
                    
                        {isAvailability && (
                        <>
                            <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Appointment Type(s)</InputLabel>
                            { selectedAppointmentTypes.length > 0 && (<>
                                {anyAppointment ? (<>
                                    <Grid key={0} container item style={{paddingTop: 0}}>
                                        <Grid item xs={10}>
                                            <TextField
                                            fullWidth
                                            disabled
                                            value={AllTypes}
                                        />
                                        </Grid>
                                    </Grid>
                                </>) : (<>
                                    {selectedAppointmentTypes.map((item, i) => {
                                        return(
                                        <>
                                        <Grid key={i} container item style={{paddingTop: 0}}>
                                            <Grid item xs={10}>
                                                <TextField
                                                fullWidth
                                                disabled
                                                value={item.appointmentType}
                                            />
                                            </Grid>
                                        </Grid>
                                        </>
                                        )
                                    })}
                                </>)}                            
                            </>)}
                        </>)}
                    </>)}

                    {isAvailability && (<>
                        <Grid item xs={12}>
                            <InputLabel className="pageLabel" shrink>Allow Simultaneous Appointments</InputLabel>
                            <TextField
                                id="simultaneous"
                                type="number"
                                value={simultaneous}
                                onChange={e => {setSimultaneous(e.target.value)}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                    required: true,
                                    readOnly: true
                                }}
                            />
                        </Grid>                    
                    </>)}

                    <Grid item xs={12}>
                        <Repetition endsDt={endsDt} clear={_clearRepetition} validate={_validateRepetition} onChange={handleRepetitionChange} 
                            loadData={loadRepetitionData} readOnly={true} isAvailability={isAvailability} />
                    </Grid>

                    {exemptions && exemptions.length > 0 && (<>
                        <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Exemptions</InputLabel>
                        <Grid item xs={12}>
                            <ul>
                                {exemptions.map((x)=> { return (<li>{x.displayValue}</li>)})}
                            </ul>
                        </Grid>
                    </>)}

                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={()=>setOpenDrawer(false)}>
                                Close
                        </Button>  
                    </Grid>
                </Grid>  
            </ValidatorForm>
        </>)
         : (<>                 
            <Typography variant="h6" data-tut="reactour__schedule_startdate">            
                {`${loadConfigData ? "Edit" : "Create"} ${isAvailability ? "Availability" : "Blackout"}`}
            </Typography>
            <Divider />   
            <ValidatorForm onSubmit={onCreateBlocks} onError={errors => console.log(errors)}> 
                <Grid container direction="row" justify="flex-start"  alignItems="center" spacing={3} className="grid-drawer">                
                    <Grid item xs={12}>
                        <InputLabel className="pageLabel" shrink>Select Start Date</InputLabel>
                        {editSingleEvent ? (<>
                            <TextField 
                                value={new Date(startDate).toLocaleDateString()} 
                                fullWidth 
                                variant="filled"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>) : (<>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ValidatedDatePicker
                                    autoComplete="off"
                                    margin="none"
                                    id="from-date-dialog"
                                    format="MM/dd/yyyy"
                                    validators={["required"]}
                                    errorMessages={["Start date is required"]}
                                    fullWidth
                                    minDate={new Date()}
                                    value={startDate}
                                    onChange={(value) => {
                                        const v = value.toLocaleDateString();
                                        setStartDate(v);
                                        if (value > new Date(repetition.endsDate)) {
                                            repetition.endsDate = v;
                                            setRepetition(repetition);
                                            setEndsDt(v);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <DateRangeIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </>)}                        
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Select Start Time</InputLabel>
                        <TextField
                            id="startTime"
                            type="time"
                            value={startTime}
                            onChange={e => {setStartTime(e.target.value)}}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <InputLabel className="pageLabel" shrink>Select End Time</InputLabel>
                        <TextField
                            id="endTime"
                            type="time"
                            value={endTime}
                            onChange={e => {setEndTime(e.target.value)}}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                            step: 300, // 5 min
                            }}
                        />
                    </Grid>
                    {timesValidationErrors.map((x) => <div className="validator-message">{x}</div>)}

                    {isAvailability && (<>
                        { breakPeriodList.length > 0 && (<>
                            {breakPeriodList.map((item, i) => {
                                return(<>
                                    { i === 0 &&  <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Add Break Period(s)</InputLabel>}
                                    <Grid key={i} container item style={{paddingTop: 0}}>
                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="startTime"
                                            value={item.startTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <TextField
                                            id="time"
                                            type="time"
                                            name="endTime"
                                            value={item.endTime}
                                            onChange={e => {handBreakPeriodChange(e, i)}}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                            />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteBreakPeriod(i)}>
                                                <HighlightOffIcon fontSize="small"  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>                                
                                </>)                    
                            })}
                            
                            {bpValidationErrors.map((x) => <div className="validator-message">{x}</div>)}
                        </>)
                        }                    

                        <Grid item xs={12}>
                            { breakPeriodList.length === 0 && <InputLabel className="pageLabel" shrink>Add Break Period(s)</InputLabel> }
                            <TextField
                                disabled
                                placeholder="Add Break Period"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                    <IconButton onClick={onAddBreakPeriod}>
                                        <InputAdornment>
                                            <AddCircleOutlineIcon/>
                                        </InputAdornment>
                                    </IconButton>
                                    )}}
                            />
                        </Grid>
                    
                        {isAvailability && (
                        <>
                            <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Add Appointment Type(s)</InputLabel>
                            { selectedAppointmentTypes.length > 0 && (<>
                                {anyAppointment ? (<>
                                    <Grid key={0} container item style={{paddingTop: 0}}>
                                        <Grid item xs={10}>
                                            <TextField
                                            fullWidth
                                            disabled
                                            value={AllTypes}
                                        />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteAppointmentType(null, true)}>
                                                <HighlightOffIcon fontSize="small"  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </>) : (<>
                                    {selectedAppointmentTypes.map((item, i) => {
                                        return(
                                        <>
                                        <Grid key={i} container item style={{paddingTop: 0}}>
                                            <Grid item xs={10}>
                                                <TextField
                                                fullWidth
                                                disabled
                                                value={item.appointmentType}
                                            />
                                            </Grid>

                                            <Grid item xs={1}>
                                                <IconButton  style={{padding: 4}} type="submit" component="span" onClick={() => onDeleteAppointmentType(item)}>
                                                    <HighlightOffIcon fontSize="small"  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        </>
                                        )
                                    })}
                                </>)}                            
                            </>)}
                        
                            {!anyAppointment && (<>
                                <Grid item xs={12} className="grid-autocomplete">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                                disableClearable
                                                clearOnBlur
                                                clearOnEscape
                                                id="autoAppointmentType"
                                                inputValue=''
                                                value={null}
                                                options={appointmentTypeOptions}
                                                getOptionLabel={(x) => x.appointmentType}
                                                onChange={(event, newValue, reason) => {
                                                    onAddAppointmentType(newValue, reason);
                                                }}
                                                renderInput={(params) => <TextField {...params} placeholder="Add Appointment Type"/>}
                                            />
                                    </FormControl>
                                    {showValidationMsgAT && <div className="validator-message">Appointment Type is required</div> }
                                </Grid>
                            </>)}
                        </>)}
                    </>)}

                    {isAvailability && (<>
                        <Grid item xs={12}>
                            <InputLabel className="pageLabel" shrink>Allow Simultaneous Appointments</InputLabel>
                            <TextField
                                id="simultaneous"
                                type="number"
                                value={simultaneous}
                                onChange={e => {setSimultaneous(e.target.value)}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                    required: true
                                }}
                            />
                        </Grid>                    
                    </>)}

                    <Grid item xs={12}>
                        <Repetition endsDt={endsDt} clear={_clearRepetition} validate={_validateRepetition} onChange={handleRepetitionChange} 
                            loadData={loadRepetitionData} readOnly={editSingleEvent} isAvailability={isAvailability} />
                    </Grid>

                    {dateAndDaysValidationErrors && <div className="validator-message">{dateAndDaysValidationErrors}</div> }
                    {!editedConfigChanged && !dateAndDaysValidationErrors? <div className="validator-message">No updates will be made. Please edit the form.</div> : null}

                    {exemptions && exemptions.length > 0 && (<>
                        <InputLabel className="pageLabel" shrink style={{paddingLeft:12, paddingTop: 12, margin: 0}}>Exemptions</InputLabel>
                        <Grid item xs={12}>
                            <ul>
                                {exemptions.map((x)=> { return (<li>{x.displayValue}  [<a href="#" onClick={(e)=>removeExemption(x.displayValue)}>remove</a>]</li>)})}
                            </ul>
                        </Grid>
                    </>)}

                    {editSingleEvent && 
                        <Grid item xs={12}>
                            <div className="update-single-event-notification"><InfoIcon /> Updating Single Event ({editSingleEvent.toLocaleDateString()})</div>
                        </Grid>
                    }

                    <Grid item xs={6}>
                        <Button disabled={disableCreate} variant="contained" color="primary" type="submit" fullWidth>
                                {loadConfigData ? "UPDATE" : "CREATE"}
                        </Button>  
                    </Grid>

                    {!loadConfigData && (<>
                        <Grid item xs={6}>
                            <Button variant="contained" type="reset" fullWidth onClick={clearForm}>
                                    RESET FORM
                            </Button>  
                        </Grid>
                    </>)}
                </Grid>  
                <Grid item xs={12} style={{paddingTop : "15px"}}>
                        {disableCreate  && <div className="validator-message">An indefinite blackout cannot be created. Please select an end date for this blackout event</div>}
                </Grid>                   
            </ValidatorForm>
                <div className="data-diagnostics">
                    <Divider />
                    <div className='group-info'></div>
                    GroupId: {loadConfigData?.groupId !== undefined ? loadConfigData?.groupId : "None"}
                </div>    
        </>)}
    </div>);
}

export default BlocksForm;


const Repetition =({endsDt, clear, validate, onChange, loadData, readOnly, isAvailability})=> {

    const [days, setDays] = useState(isAvailability ? defaultAvailabilityDays : defaultBlackoutDays);
    const [repeats, setRepeats] = useState(isAvailability ? defaultAvailabilityRepeats : defaultBlackoutRepeats)
    const [ends, setEnds] = useState(defaultRepetitionData.ends);
    const [endsDate, setEndsDate] = useState(defaultRepetitionData.endsDate);
    const [isGeneral, setIsGeneral] = useState(true);

    const [daysValidationMessage, setDaysValidationMessage] = useState("");

    const validateDays = (_days) => {
        const msg = isValidDays(_days) ? "" : "Select at least one (1) day";
        setDaysValidationMessage(msg);
    }

    const daysChanged = (index, checked)=> {
        let d = [...days];
        d[index] = checked;

        validateDays(d);

        setDays(d);
        handleChange({days: d});
    }

    const clearForm=()=> {
        setDays(isAvailability ? defaultAvailabilityDays : defaultBlackoutDays);
        setRepeats(isAvailability ? defaultAvailabilityRepeats : defaultBlackoutRepeats);
        setEnds(defaultRepetitionData.ends);
        setEndsDate(endsDt);
    }

    const loadForm=()=> {
        setDays(loadData.days);
        setRepeats(loadData.repeats);
        setEnds(loadData.ends);
        setEndsDate(loadData.endsDate);
    }

    const handleChange = (value) => {
        onChange({
            ...{
                days: days,
                repeats: repeats,
                ends: ends,
                endsDate: endsDate,
                isGeneral: isGeneral
            },
            ...value
        });
    }

    useEffect(()=>{
        setEndsDate(endsDt);
        handleChange({endsDate: endsDt});
    }, [endsDt]);

    useEffect(()=>{
        handleChange({endsDate: endsDate});
    }, [endsDate]);
    
    useEffect(()=>{
        if (repeats === RepeatType.Weekly && ends === "never")
            setIsGeneral(true)
        else
           setIsGeneral(false)
    },[repeats, ends]);

    useEffect(()=>{
        clearForm();
        validateDays(days);
    }, [clear]);  
    
    useEffect(()=>{
        validateDays(days);
    }, [validate, days]);

    useEffect(()=>{
        if (loadData) loadForm();
    }, [loadData]);  

    return (<div className="repetition">
        <h4 data-tut="reactour__schedule_repetition">Repetition</h4>
        <Grid container>
            <Grid item sm={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Days</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[0]} onChange={(event, checked) => daysChanged(0, checked)} name="days" color="primary" />}
                            label="Sun"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[1]} onChange={(event, checked) => daysChanged(1, checked)} name="days" color="primary" />}
                            label="Mon"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[2]} onChange={(event, checked) => daysChanged(2, checked)} name="days" color="primary" />}
                            label="Tue"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[3]} onChange={(event, checked) => daysChanged(3, checked)} name="days" color="primary" />}
                            label="Wed"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[4]} onChange={(event, checked) => daysChanged(4, checked)} name="days" color="primary" />}
                            label="Thu"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[5]} onChange={(event, checked) => daysChanged(5, checked)} name="days" color="primary" />}
                            label="Fri"
                        />
                        <FormControlLabel
                            control={<Checkbox disabled={readOnly} checked={days[6]} onChange={(event, checked) => daysChanged(6, checked)} name="days" color="primary" />}
                            label="Sat"
                        />
                    </FormGroup>
                    {daysValidationMessage && <div className="validator-message">{daysValidationMessage}</div> }
                </FormControl>
            </Grid>            
        </Grid>
        <Grid container>
            <Grid item sm={12}>
                <FormControl>
                    <InputLabel>Repeats</InputLabel>
                    <Select
                        disabled={readOnly}
                        value={repeats}
                        onChange={(e)=> {
                            setRepeats(e.target.value);   
                            handleChange({repeats: e.target.value});                         
                        }}
                    >
                        <MenuItem value={RepeatType.Once} key={RepeatType.Once}>Once</MenuItem>
                        <MenuItem value={RepeatType.Weekly} key={RepeatType.Weekly}>Weekly</MenuItem>
                        <MenuItem value={RepeatType.BiWeekly} key={RepeatType.BiWeekly}>Bi-Weekly</MenuItem>
                        <MenuItem value={RepeatType.Monthly} key={RepeatType.Monthly}>Monthly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>            
        </Grid>
        <Grid container>
            <Grid item sm={12}>
                <FormControl>
                    <InputLabel>Ends</InputLabel>
                    <Select
                        disabled={readOnly}
                        // {/* bugfix/36091-blackout-disable-NEVER-as-end-date */}
                        value={ends} 
                        onChange={(e)=> {
                            setEnds(e.target.value);
                            handleChange({
                                ends: e.target.value,
                                endsDate: endsDt
                            });
                        }}
                        inputProps={{style:{width: "100%"}}}
                    >
                        {/* bugfix/36091-blackout-disable-NEVER-as-end-date */}
                  {/*       {isAvailability && (<MenuItem value={"never"} key={"never"}>Never</MenuItem>)} */}
                        <MenuItem value={"never"} key={"never"}>Never</MenuItem>
                        <MenuItem value={"date"} key={"date"}>Specific Date</MenuItem>
                    </Select>
                    <br />
                    {ends == "date" && 
                        (<>
                            <InputLabel className="pageLabel" shrink>Ends On</InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ValidatedDatePicker
                                    disabled={readOnly}
                                    autoComplete="off"
                                    margin="none"
                                    format="MM/dd/yyyy"
                                    validators={["required"]}
                                    errorMessages={["Ends On date is required"]}
                                    fullWidth
                                    minDate={new Date()}
                                    value={new Date(endsDate)}
                                    onChange={(value) => {
                                        const v = value.toLocaleDateString();
                                        setEndsDate(v);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <DateRangeIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </>)
                    }                    
                </FormControl>
                
            </Grid>
        </Grid>
    </div>)
}