import React from 'react';

export class CRMTabsContextObject {
    _clients = {
        valuesUpdated: {}
    };

    _tabHeads = {};

    SetTabValue(componentId, tabValue) {
        this._tabHeads[componentId] = tabValue;
        this.UpdateClients();
    }

    UpdateClients() {
        Object.values(this._clients.valuesUpdated).forEach(x => {            
            let v = this._tabHeads[x.tabHeadComponentId];
            this._tabHeads[x.tabHeadComponentId] >= 0  && x.delegate(v);
        });
    };

    Subscribe(componentId, tabHeadComponentId, delegate) {
        if (delegate) {
            this._clients.valuesUpdated[componentId] = {
                tabHeadComponentId: tabHeadComponentId,
                delegate: delegate
            };
        }
    }

    UnSubscribe(componentId) {
        if (this._clients.valuesUpdated[componentId]) {
            delete this._clients.valuesUpdated[componentId];
        }
    }
}

const CRMTabsContext = React.createContext(new CRMTabsContextObject())
export default CRMTabsContext;