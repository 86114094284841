import React, {createContext, useState} from 'react';

// Create Context Object
export const TourServiceContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const TourServiceProvider = props => {
    const [tourNext, setTourNext] = useState(true);
    const [currentTab, setCurrentTab] = useState('');
    const [currentLockey, setCurrentLockey] = useState('');
    const [currentAcctKey, setCurrentAcctKey] = useState('');
  
    return (
      <TourServiceContext.Provider value={{
        tourNext, setTourNext,
        currentTab, setCurrentTab,
        currentLockey, setCurrentLockey,
        currentAcctKey, setCurrentAcctKey
      }}>
        {props.children}
      </TourServiceContext.Provider>
    );
  }; 