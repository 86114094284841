import React, {forwardRef, useImperativeHandle, useState} from 'react';
import './TwoWayNewMessageModal.scss'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CloseIcon from '@material-ui/icons/Close';


const TwoWayNewMessageModal = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    setAlert(e) {
      handleOpen();
    }
  }));
  const [open, setOpen] = useState(false);
  // const [heading, setHeading] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog className='new-message-modal' open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogContent>
          <div className='icon-button'>
            <CloseIcon onClick={handleClose}/>
          </div>
          <div className='title--text'>
            <DialogTitle id="alert-dialog-title">
              <div className='heading--text'>
                {props.heading}
              </div>
            </DialogTitle>
            <div className='icon--position'>
              <GroupAddIcon/>
            </div>
            <div>
              <DialogContentText id="alert-dialog-description">
                {props.body}
              </DialogContentText>
            </div>
          </div>
          <div className='text--field--inputs' style={{display: 'flex'}}>
            <div className='text--inputs'>
              <span>First Name</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="First Name"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='text--inputs'>
              <span>Last Name</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="Last Name"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='phone--option'> OR</div>
            <div className='text--inputs'>
              <span>Phone Number</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="Number"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='search--button'>
              <Button variant='contained' onClick={handleClose} color="primary">
                {props.button1}
              </Button>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default TwoWayNewMessageModal;