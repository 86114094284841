import './TwoWayMessagePage.scss'

import Seo from '../../Components/SEO/Seo';
import TwoWayList from '../../Components/TwoWayList/TwoWayList';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TwoWayConversationService from '../../Store/TwoWayMessageService/TwoWayConversationService';
import { useHistory } from 'react-router';
import TwoWayModal from '../../Components/TwoWayModal/TwoWayModal';
import TwoWayMultiRecipientModal from '../../Components/TwoWayModal/TwoWayMultiRecipientModal';
import TwoWayNewMessageModal from '../../Components/TwoWayModal/TwoWayNewMessageModal';
import { environment } from '../../environment';
import Logger from '../../Store/Logger/Logger';
import { differenceInSeconds, format } from 'date-fns';
import Utils from '../../Store/Utils/Utils';
import UserService from '../../Store/UserService';

import { AppNotifications } from '../../Components/AppNotifications/AppNotifications';
import LocationsService from '../../Store/LocationsService/LocationsService';
import LocationService from '../../Store/LocationsService/LocationsService';
import TwoWayModalLocationSelect from '../../Components/TwoWayModal/TwoWayModalLocationSelect';
import TwoWayModalPatientSelect from '../../Components/TwoWayModal/TwoWayModalPatientSelect';
import { LocationServiceContext } from '../../Store/LocationsService/LocationServiceProvider';
import SessionContext from '../../SessionContext';
import Constants from '../../constants';

const TwoWayMessagePage = () => {
  const history = useHistory();
  const { selectedLocationKeys } = useContext(LocationServiceContext);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [refreshConversation, setRefreshConversation] = useState(false);
  const [lastConversationReceived, setLastConversationReceived] =
    useState(null);
  const [newMessageReceived, setNewMessageReceived] = useState(null);
  const [windowHeight, setWindowHeight] = useState({ height: '900px' });
  const [conversations, setConversationsList] = useState([]);
  const [conversation, setConversation] = useState();
  const [filtersCount, setFiltersCount] = useState({
    flagged: 0,
    unRead: 0,
    unAnswered: 0,
  });
  const [confersationListInit, setConfersationListInit] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(1);
  const [currentSortFilter, setCurrentSortFilter] = useState(null);
  const [notificationsLog, setNotificationsLog] = useState([]);
  const callTwoWayModal = useRef(null);
  const callTwoWayModalAlert = useRef(null);
  const callTwoWayModalAlertLandscape = useRef(null);
  const callTwoWayModalSelectPatientError = useRef(null);
  const callTwoWayModalLocationSelect = useRef(null);
  const callTwoWayModalPatientSelect = useRef(null);
  const callTwoWayModalSystemDown = useRef(null);
  const callMultiRecipientModal = useRef(null);
  const callNewMessageModal = useRef(null);
  const [lastRefreshed, setLastRefreshed] = useState();
  const [lastRefreshedDateObj, setLastRefreshedDateObj] = useState();
  const [patientDictionary, setPatientDictionary] = useState([]);
  const [loadingInterval, setLoadingInterval] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [loadingIntervalCount, setLoadingIntervalCount] = useState(0);
  const [loadingIntervalTimer, setLoadingIntervalTimer] = useState(0);
  const loadingIntervalCountMax = environment.conversationIntervalMax - 1;
  const interval = useRef(undefined);
  const notificationsSupported = 'Notification' in window;
  const [allowChat] = useState(UserService.checkElementAccess(Constants.new2WayMenu));

  const [sessionUpdated, setSessionUpdated] = useState(new Date());
  const session = useContext(SessionContext);
  session.Subscribe_ValueUpdated("TwoWayMessagePage", (value)=>{
    if (value.sessionUpdated > sessionUpdated) {
      setSessionUpdated(value.sessionUpdated);
      getMessages(currentPage, currentFilter);
    }
  });

  const createPayload = async (_currentPage, _currentFilter) => {
    let currentLocKeys;
    let payload = {
      sessionId: UserService.getSessionId(),
      viewStatus: _currentFilter,
      pageNumber: _currentPage,
      pageSize: environment.twoWayCardCountMax,
      filter: currentSortFilter,
    };

    try {
      currentLocKeys = await LocationService.getCacheCurrentLocKeys();
    } catch (err) {}
    payload.locKeys = currentLocKeys;
    return payload;
  };

  const getMessages = async (
    _currentPage,
    _currentFilter,
    showloader = true
  ) => {
    setLoadingInterval(true);
    if (showloader) {
      setLoadingConversations(true);
    }
    setLastRefreshed(format(new Date(), "'last updated at' h:mm a"));
    getFiltersCount();

    const payload = await createPayload(_currentPage, _currentFilter);

    await TwoWayConversationService.getMessageList(payload)
      .then((resp) => {
        setLoadingInterval(false);

        setLoadingConversations(false);
        if (resp.data) {
          const myconversations = TwoWayConversationService.mapConversation(
            resp.data
          );
          if (currentPage > 1) {
            setConversationsList(conversations.concat(myconversations));
          } else {
            setConversationsList(myconversations);
          }

          setTimeout(() => {
            if (window.location.href.indexOf('new') === -1) {
              if (myconversations) {
                if (
                  myconversations.length >= 1 &&
                  Utils.getAgent() !== 'iOS' &&
                  Utils.getAgent() !== 'android'
                ) {
                  if (document !== undefined) {
                    if (
                      document.getElementsByClassName('message--list--scroll')
                    ) {
                      if (
                        document.getElementsByClassName(
                          'message--list--scroll'
                        )[0]
                      ) {
                        if (
                          document
                            .getElementsByClassName('message--list--scroll')[0]
                            .getElementsByClassName('active').length === 0
                        ) {
                            if(document.getElementsByClassName('conversation-input-textarea')[0]?.value?.trim().length === 0)
                            {
                                  document
                                .querySelectorAll('.card--holder .card')[0]
                                .click();
                            }
                        }
                      }
                    }
                  }
                }
              }
            }
          }, 0);
          setTimeout(() => {
            if (window.location.pathname.indexOf('message') > 0) {
              if (document.querySelectorAll('.card.active')[0]) {
                updateActiveConversation();
                /*
                let firstCard = document.querySelectorAll('.card--holder')[0].id.split('--')[2];
                let activeCard = document.querySelectorAll('.card.active')[0].parentElement.id.split('--')[2];
                let activeConversation = window.location.pathname.split('/')[3];
                */
              }
            }
          }, 1000);

          setLastRefreshedDateObj(new Date());
        }
      })
      .catch((err) => {
        setLoadingInterval(false);
        setLoadingConversations(false);
        Logger.printLog('getMessageList', err, 'error');
      });
  };

  const updateActiveConversation = () => {
    const cards = document
      .getElementsByClassName('message--list--scroll')[0]
      .getElementsByClassName('card');
    for (let i = 0; i < cards.length; i++) {
      cards[i].classList.remove('active');
    }
    if (
      document.getElementById(
        'card--id--' + window.location.pathname.split('/')[3]
      )
    ) {
      document
        .getElementById('card--id--' + window.location.pathname.split('/')[3])
        .click();
      document
        .getElementById('card--id--' + window.location.pathname.split('/')[3])
        .getElementsByClassName('card')[0]
        .classList.add('active');
    }
  };

  const getFiltersCount = () => {
    const payload = {
      sessionId: UserService.getSessionId(),
      locKeys: LocationService.getCacheCurrentLocKeys(),
    };
    TwoWayConversationService.getFiltersCount(payload).then((resp) => {
      if (resp.data) {
        const myFilterCount = TwoWayConversationService.mapFilterCount(
          resp.data
        );
        setFiltersCount(myFilterCount);
      }
    });
  };

  const conversationClick = (e) => {
    setSelectedConversation(e);

    if (e.messageStatus === environment.viewStatus.unread || e.unanswered) {
      e.viewStatus = environment.viewStatus.unanswered;
      e.status = environment.viewStatus.unanswered;
      updateMessageStatus(e);
    }

    conversationClickDomUpdate(e);
    history.push('/communications/chat/message/' + e.convKey);

    const payload = {
      SessionId: UserService.getSessionId(),
      locKey: e.locKey,
      FirstName: '',
      LastName: '',
      Phone: e.smsTo,
      PatientId: e.PatientId,
    };

    if (e.addKey === 0 && e.patientId === null) {
      getFindPatient(payload);
    }
  };

  const getFindPatient = (payload) => {
    TwoWayConversationService.postFindPatient(payload)
      .then((resp) => {
        if (resp.data.ListOfPatientSearch) {
          const patients = TwoWayConversationService.mapPatients(resp.data);
          const patientDict = {};
          if (patients) {
            patients.listOfPatientSearch.forEach((pat) => {
              // only add patients that DO HAVE a PMSPatientID
              if (pat.PMSPatientID != null) {
                const familyName = `${pat.firstName} ${pat.lastName}`;
                patientDict[familyName] = pat;
              }
            });
            // if patientDic is not empty then trigger modal
            if (Object.keys(patientDict).length !== 0) {
              setPatientDictionary(patientDict);
              callTwoWayModalPatientSelect.current.setAlert();
            }
          }
        }
        return [];
      })
      .catch((err) => {
        Logger.printLog('getFindPatient', err, 'error');
      });
  };

  const conversationClickDomUpdate = (e = null) => {
    if (document) {
      const cards = document
        .getElementsByClassName('message--list--scroll')[0]
        .getElementsByClassName('card');
      for (let i = 0; i < cards.length; i++) {
        cards[i].classList.remove('active');
      }
      if (e) {
        document
          .getElementById('card--id--' + e.convKey)
          .getElementsByClassName('card')[0]
          .classList.add('active');
      }

      setTimeout(() => {
        if (
          document.getElementsByClassName('message--conversation--holder')[0]
        ) {
          document
            .getElementsByClassName('message--conversation--holder')[0]
            .classList.add('message--conversation--active');
          document
            .getElementsByClassName('message--conversation--holder--input')[0]
            .classList.add('message--conversation--holder--input--active');
        }
      }, 100);
    }
  };

  const conversationDelete = (newStatus) => {
    conversation.viewStatus = newStatus;
    updateMessageStatus(conversation);
    if (document) {
      document.getElementById('card--id--' + conversation.convKey).remove();
    }
  };

  const confirmMessageStatus = (newStatus, conversation) => {
    setConversation(conversation);
    if (newStatus === environment.viewStatus.trash) {
      callTwoWayModal.current.setAlert();
    } else {
      conversation.viewStatus = newStatus;
      updateMessageStatus(conversation);
    }
  };

  const updateMessageStatus = (_payload) => {
    const payload = {
      sessionId: UserService.getSessionId(),
      conversationKey: _payload.convKey,
      viewStatus: _payload.viewStatus,
    };
    TwoWayConversationService.updateMessageStatus(payload)
      .then((resp) => {
        getFiltersCount();
        if (resp.data.statusCode === 0) {
          callTwoWayModalAlert.current.setAlert();
        }
      })
      .catch((err) => {
        Logger.printLog('updateMessageStatus', err, 'error');
        callTwoWayModalAlert.current.setAlert();
      });
  };

  const multiRecipient = () => {
    callMultiRecipientModal.current.setAlert();
  };

  const newMessage = () => {
    var continueRouter = true;
    var myLocations = LocationsService.getCacheLocations();

    if (myLocations) {
      if (myLocations.hasOwnProperty('length')) {
        if (myLocations.length <= 1) {
          handleSelectLocation(myLocations[0]);
          history.push('/communications/chat/new');
        } else {
          continueRouter = false;
          callTwoWayModalLocationSelect.current.setAlert();
        }
      }
    }

    if (continueRouter) {
      history.push('/communications/chat/new');
    }
    setTimeout(() => {
      if (document.getElementsByClassName('message--conversation--holder')[0]) {
        document
          .getElementsByClassName('message--conversation--holder')[0]
          .classList.add('message--conversation--active');
      }
    }, 100);
  };

  const createNewMessage = () => {
    history.push('/communications/chat/new');
    setTimeout(() => {
      if (document.getElementsByClassName('message--conversation--holder')[0]) {
        document
          .getElementsByClassName('message--conversation--holder')[0]
          .classList.add('message--conversation--active');
      }
    }, 100);
  };

  const handleSelectLocation = (e) => {
    setSelectedLocation(e);
  };

  const handleSelectPatient = (e) => {
    setSelectedPatient(e);
  };

  const handleWindowResize = () => {
    setWindowHeight(window.innerHeight);
  };

  const handleWindowClick = (e) => {
    if (document) {
      if (document.querySelectorAll('.message--search--results')) {
        if (
          document.querySelectorAll('.message--search--results')[0] !==
          undefined
        ) {
          if (e.target.classList.contains('message--conversation')) {
            document.querySelectorAll(
              '.message--search--results'
            )[0].style.display = 'none';
            document.querySelectorAll(
              '.message--search--status'
            )[0].style.opacity = 'none';
          }
        }
      }
    }
  };

  const refreshList = () => {
    setLoadingIntervalCount(60000);

    getMessages(1, currentFilter);
    return logInterval();
  };

  const searchList = () => {};

  const loadMoreToList = () => {
    setCurrentPage(currentPage + 1);
  };

  const filterList = (viewStatus, sortFilter) => {
    let cards = document
      .getElementsByClassName('message--list--scroll')[0]
      .getElementsByClassName('card');

    for (let i = 0; i < cards.length; i++) {
      cards[i].classList.remove('active');
    }
    const chatUrl = '/communications/chat/';
    history.push(chatUrl);
    setCurrentSortFilter(sortFilter);
    setCurrentFilter(viewStatus);
    setCurrentPage(1);
  };

  const handleSetLoadingInterval = () => {
    /**
     * Get new messages every 60 seconds
     */
    setLoadingIntervalCount((loadingIntervalCount) => loadingIntervalCount + 1);
    getMessages(currentPage, currentFilter, false);
    checkIfNewMessagesExist();
  };

  const logInterval = () => {
    interval.current = setInterval(
      handleSetLoadingInterval,
      environment.getNewConversationInterval
    );
    setLoadingIntervalTimer(interval.current);
    setShowRefreshButton(false);
    return () => clearInterval(interval.current);
  };

  const refreshConversations = () => {
    setRefreshConversation(true);
    refreshList();
    setRefreshConversation(false);
  };

  const handleUpdateHasFiles = (value) => {
    setHasFiles(value);
  };

  const updateSelectPatient = () => {
    let patient = patientDictionary[selectedPatient.name];
    const payload = {
      SessionId: UserService.getSessionId(),
      ConversationKey: selectedConversation.convKey,
      PMSPatientId: patient.PMSPatientID,
      AddKey: patient.addKey,
      FirstName: patient.firstName,
      LastName: patient.lastName,
    };
    TwoWayConversationService.postSelectPatient(payload)
      .then((resp) => {
        if (!resp) {
          callTwoWayModalSelectPatientError.current.setAlert();
        }
        refreshConversations();
      })
      .catch((err) => {
        if (callTwoWayModalSelectPatientError.current) {
          callTwoWayModalSelectPatientError.current.setAlert();
        }
      });
  };

  const checkIfNewMessagesExist = () => {
    setNewMessageReceived(false);
    if (conversations) {
      if (conversations.length >= 1) {
        if (lastConversationReceived) {
          const diffSec = differenceInSeconds(new Date(), lastRefreshedDateObj);
          const payload = {
            SessionId: UserService.getSessionId(),
            locKeys: LocationService.getCacheCurrentLocKeys(),
            TimeStamp: lastRefreshedDateObj,
          };
          if (diffSec >= 1) {
            const localDate = new Date(lastRefreshedDateObj);
            TwoWayConversationService.postCheckNewList(payload).then((resp) => {
              if (resp.data) {
                // console.log('checkIfNewMessagesExist', resp.data);
                const found = notificationsLog.map((note) => {
                  if (note.message === conversations[0].message) {
                    return true;
                  }
                });
                setNotificationsLog([...notificationsLog, conversations[0]]);
                if (!found || notificationsLog.length <= 0) {
                  //setNewMessageReceived(true)
                }
                setNewMessageReceived(true);
              }
            });
            if (conversations[0].isOutbound === false) {
              setLastConversationReceived(conversations[0]);
            }
          }
        } else {
          if (conversations[0].isOutbound === false) {
            setLastConversationReceived(conversations[0]);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (confersationListInit) {
      getMessages(currentPage, currentFilter);
    }
    return logInterval();
  }, [currentPage, currentFilter, selectedLocationKeys, currentSortFilter]);

  useEffect(() => {
    try
    {
      if (
        windowHeight < 400 &&
        window.matchMedia('(orientation: landscape)').matches
      ) {
        callTwoWayModalAlertLandscape.current.setAlert();
      } else {
        callTwoWayModalAlertLandscape.current.closeAlert();
      }
    } catch
    {
      
    }
  }, [windowHeight]);

  useEffect(() => {
    if (loadingIntervalCount > loadingIntervalCountMax) {
      setLoadingIntervalCount(0);
      setShowRefreshButton(true);
      clearInterval(loadingIntervalTimer);
    }
  }, [loadingIntervalCount]);

  useEffect(() => {
    checkIfNewMessagesExist();
  }, [conversations]);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('click', handleWindowClick);
    document.addEventListener('visibilitychange', function (ev) {
     // window.location.reload(false);
      //refreshList()
    });

    setTimeout(() => {
      getMessages(currentPage, currentFilter);
    }, 1000);
    setTimeout(() => {
      setConfersationListInit(true);
    }, 3000);
  }, []);

  return (
    <div className='page-cont'>
      {allowChat ? <div className='two-way-cont'>
        <div className="two-way">
          <Seo title='4PC 2 Way Messaging' />
          {/*<TwoWayModal ref={callTwoWayModalAppDelay} heading='Gathering conversations 🌟'
                      body='We are finding all the latest chat conversations.' button1='' button2='' spinner='true'/>*/}
          <TwoWayModal
            ref={callTwoWayModalSystemDown}
            heading='Sleep Mode'
            body='Please click ok to load the latest conversations'
            button1=''
            button2='ok'
            refreshConversations={refreshConversations}
          />
          <TwoWayModal
            ref={callTwoWayModal}
            heading='Would you like to delete this conversation?'
            body='You cannot undo this action'
            button1='Cancel'
            button2='Delete'
            conversationDelete={conversationDelete}
          />
          <TwoWayModal
            ref={callTwoWayModalAlert}
            heading='Unable to update conversation.'
            body='Currently unable to update conversations.'
            button1=''
            button2='Ok'
          />
          <TwoWayModal
            ref={callTwoWayModalAlertLandscape}
            heading='Landscape not supported'
            body='Currently unable to fully view message on landscape mode'
            button1=''
            button2='Ok'
          />
          <TwoWayModal
            ref={callTwoWayModalSelectPatientError}
            heading='An error has occured'
            body='Unable to select a patient for this conversation'
            button1=''
            button2='Ok'
          />

          <TwoWayMultiRecipientModal
            ref={callMultiRecipientModal}
            heading='Multi-Recipient'
            body='Search for existing resources (patients) by first and last name, or phone number.'
            button1='Search'
            button2='Submit'
          />
          <TwoWayNewMessageModal
            ref={callNewMessageModal}
            heading='New Message'
            body='Search for existing resources (patients) by first and last name, or phone number.'
            button1='Search'
          />
          <TwoWayModalLocationSelect
            ref={callTwoWayModalLocationSelect}
            heading='Select Location'
            body='Please select the location for sending new messages'
            button1='cancel'
            button2='ok'
            createNewMessage={createNewMessage}
            selectedLocation={handleSelectLocation}
          />

          <TwoWayModalPatientSelect
            ref={callTwoWayModalPatientSelect}
            heading='Select Patient'
            body='Please select the corresponding patient'
            button1='cancel'
            button2='ok'
            selectedPatient={handleSelectPatient}
            patientDictionary={patientDictionary}
            updateSelectPatient={updateSelectPatient}
          />

          <TwoWayList
            filterList={filterList}
            loadMoreToList={loadMoreToList}
            flagToRefreshConversation={refreshConversation}
            refreshList={refreshList}
            conversations={conversations}
            confirmMessageStatus={confirmMessageStatus}
            conversationClick={conversationClick}
            newMessage={newMessage}
            multiRecipient={multiRecipient}
            windowHeight={windowHeight}
            currentPage={currentPage}
            loadingConversations={loadingConversations}
            searchList={searchList}
            lastRefreshed={lastRefreshed}
            loadingInterval={loadingInterval}
            showRefreshButton={showRefreshButton}
            filtersCount={filtersCount}
            selectedLocation={selectedLocation}
            patientDictionary={patientDictionary}
            hasFiles={hasFiles}
            setHasFiles={handleUpdateHasFiles}
          />

          {notificationsSupported && (
            <AppNotifications
              newMessageReceived={newMessageReceived}
              lastConversationReceived={lastConversationReceived}
            />
          )}
        </div>
      </div>
      :
      <div className="loading-error undefined">
        Not enough user rights
      </div>
      }
    </div>
  );
};
export default TwoWayMessagePage;