import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './CustomerInformationPatientDetails.scss';

const CustomerInformationPatientDetails = (props) => {
  const {
    customerName,
    serviceType,
    serviceDuration,
    serviceProvider
  } = props;

  return (
    <Grid item xs={3} sm={3}>
      <div className="div-patient-details">
        <Typography variant="h6" align="left">
          {customerName}
        </Typography>
        <Typography variant="h6" gutterBottom align="left" noWrap>
          {serviceType}
        </Typography>
        <Divider className='patient--details--divider'/>

        <div className="patient--details--bottom">
          <Typography variant="body2" align="left">
            {serviceDuration} Mins
          </Typography>

          <Typography variant="body2" align="left">
            {serviceProvider}
          </Typography>
        </div>
      </div>
    </Grid>
  );
}

export default CustomerInformationPatientDetails;