export const environment = {
  version: 2.04,
  local: false,
  prod: false,
  env: process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT,
  api: {
    base: process.env.REACT_APP_API_URL,
    prodSwitch:process.env.REACT_APP_PROD_SWITCH_API,
    authorize: 'User/authorize',
    messageList: 'Conversations/List',
    messages: 'Conversations/Messages',
    messageSend: 'Conversations/Messages/Send',
    updateMessageStatus: 'Conversations/UpdateStatus',
    findPatient: 'Conversations/FindPatient',
    selectPatient: 'Conversations/SelectPatient',
    addPatient: 'Conversations/CreateNewPatient',
    filtersCount: 'Conversations/FiltersCount',
    newConversation: 'Conversations/NewConversation',
    checkNewList: 'Conversations/CheckNewList',
    locations: 'Conversations/Locations'
  },
  dbname: 'twoway',
  tblPrefix: 'loc_',
  tblName: 'twowaytbl',
  twoWayCardCountMax: 15,
  messageCountMax: 320,
  smsToCountMax: 10,
  getNewConversationInterval: 60000,
  conversationIntervalMax: 180,
  cardBlurbCountMax: 30,
  toastTimeout: 3000,
  delayAppInit: 2000,
  attachmentFileSizeMax: 5000000,
  viewStatus: {
    all: -1,
    read: 1,
    unread: 0,
    unanswered: 8,
    flag: 2,
    trash: 9
  }
}