import React, { useState } from "react";
import { getCoverKey, getJwt } from "./ReportsUtils";
import {
  useApptReports,
  getWebApptTypes,
  Loading,
  LoadingError,
  ContentContainer,
  ApptFilters,
  ApptActions,
  WebApptTable,
  Pagination,
  ApptApi,
  ApptSort,
  WebApptType,
} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import "./AppointmentsReports.scss";

const PAGE_LIMIT = 10;

export const WebAppointments = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    type: WebApptType.APPT_CREATED,
    providers: [],
    locations: [],
  });

 const coverKey = getCoverKey();
 const authorization = getJwt();

  const [apiBody, setApiBody] = useState({
    locKeys: [],
    acctKeys: [],
    reportType: WebApptType.APPT_CREATED,
    pageSize: PAGE_LIMIT,
    pageStart: 1,
    beginDate: moment().startOf("day").format(),
    endDate: moment().endOf("day").format(),
    searchPhrase: "",
    sortColumn: "",
    sortOrder: ApptSort.NOT_SORTED,
  });

  const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

  const {
    dates,
    locations,
    providers,
    loadingFilters,
    errorFilters,
    columns,
    apptData,
    loadingData,
    errorData,
    FiltersProps,
  } = useApptReports(
    coverKey,
    authorization,
    ApptApi.WEB_APPOINTMENTS,
    apiBody
  );

  const handleShowReport = () =>
    setApiBody({
      ...apiBody,
      locKeys: filters.locations.map((item) => parseInt(item)),
      acctKeys: filters.providers.map((item) => parseInt(item)),
      reportType: filters.type || WebApptType.APPT_CREATED,
      beginDate: moment(dates.from || moment().startOf("day")).format(),
      endDate: moment(dates.to || moment().endOf("day")).format(),
    });

  const AUTH_AVAILABLE = coverKey && authorization;
  const typeFilter = getWebApptTypes();

  return (

        <ContentContainer>
          {coverKey && locations && providers && (
            <>
              <ApptFilters
                {...FiltersProps}
                filters={filters}
                types={typeFilter}
                locations={locations}
                providers={providers}
                setFilters={handleChange}
                handleShowReport={handleShowReport}
              />
              <div className="web-appointments-results">
                <ApptActions
                  search={apiBody.searchPhrase}
                  setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                  coverKey={coverKey}
                  authorization={authorization}
                  apiName={ApptApi.WEB_APPOINTMENTS}
                  body={apiBody}
                />
                <div className="web-appointments-overflow">
                  <WebApptTable
                    res={apptData?.pagedItems}
                    loading={loadingData}
                    columns={columns}
                    error={errorData}
                  />
                </div>
                <Pagination
                  page={page}
                  setPage={(p) => {
                    setPage(p);
                    setApiBody({
                      ...apiBody,
                      pageStart: p,
                    });
                  }}
                  total={apptData?.totalItemCount}
                  pageSize={PAGE_LIMIT}
                />
              </div>
            </>
          )}
          {AUTH_AVAILABLE && loadingFilters && <Loading full />}
          {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
            <LoadingError />
          )}
        </ContentContainer>

  );
};

