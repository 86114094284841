import React, {forwardRef, useImperativeHandle, useState} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {environment} from '../../environment';
import './TwoWayModal.scss';
import Spinner from '../Spinner/Spinner';

const TwoWayModal = forwardRef((props, ref) => {


  useImperativeHandle(ref, () => ({
    setAlert(e) {
      handleOpen();
    },
    closeAlert(e) {
      handleClose();
    }

  }));
  const [open, setOpen] = useState(false);
  // const [heading, setHeading] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.hasOwnProperty('refreshConversations')) {
      props.refreshConversations();
    }
    setOpen(false);
  };

  const handleDelete = () => {
    handleClose();
    const viewStatus = environment.viewStatus.trash;
    if (props.conversationDelete) {
      props.conversationDelete(viewStatus);
    }
  }

  return (
    <>
      <Dialog
        disableBackdropClick={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.body}
          </DialogContentText>
        </DialogContent>

        {props.spinner && <span className='spinner-centered'><Spinner/></span>}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.button1}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {props.button2}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default TwoWayModal;