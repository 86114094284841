import React, {useEffect, useState} from 'react';
import Utils from '../../Store/Utils/Utils';
import {environment} from '../../environment';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert} from '@material-ui/lab';

export const AppNotifications = ({lastConversationReceived, newMessageReceived}) => {

  const [notificationBody, setNotificationBody] = useState(null)
  const [open, setOpen] = useState(false);
  const icon = process.env.REACT_APP_DASHBOARD_URL + '/assets/logo-notification.png';


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const showNotification = () => {
    let prefix = Utils.formatPhoneNumber(lastConversationReceived.smsTo);
    if (lastConversationReceived.hasOwnProperty('firstName')) {
      prefix = lastConversationReceived.firstName ? lastConversationReceived.firstName + ' ' + lastConversationReceived.lastName : '';
    }
    setNotificationBody(prefix + ' ' + lastConversationReceived.message.substring(0, environment.cardBlurbCountMax) + '...');
  }

  useEffect(() => {
    Notification.requestPermission()
      .then((permission) => {
      })
      .catch((err) => {

      })
  })
  useEffect(() => {
    if (notificationBody) {
      new Notification('4PC New Message', {
        body: notificationBody
      });
      setOpen(true);
    }
  }, [notificationBody])

  useEffect(() => {
    // default, granted, denied
    if (Notification && lastConversationReceived) {
      if (Notification.permission === 'granted') {
        if (newMessageReceived) {

          if (newMessageReceived) {
            showNotification();
          }


        }
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission()
          .then((permission) => {
          })
          .catch((err) => {
          })
      }
    }

  }, [newMessageReceived])


  return (
    <>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        variant='success'
      ><Alert severity="success">{notificationBody}</Alert></Snackbar>
    </>
  )
}