import React, { useEffect } from 'react';
import { PageTitle, TabsList, Tab, VISITS_TABS} from "@essilorditac/customer-dash-ui";
import {VisitDataReport} from "./VisitDataReport";
import {VisitShowReport} from "./VisitShowReport";
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const VisitsPage = () => {

  const [tab, setTab] = React.useState(0);
  const [allowVisitsMenu] = React.useState(UserService.checkElementAccess(Constants.visitsMenu));

  useEffect( async() => {    
      UserService.checkUser();
  },[]);

  return(
      <>
        {allowVisitsMenu ?
        <div className='appt-reports-page-wrapper'>
            <div className="web-appointments-wrapper">
                <div className="page-picker-containers">
                    <PageTitle title="Visits" />
                </div>
            <TabsList
                tabs={VISITS_TABS}
                selected={tab}
                setTab={setTab}
            >
                <Tab index={0} selected={tab}>

                    <VisitDataReport />
                </Tab>
                <Tab index={1} selected={tab}>
                    <VisitShowReport />
                </Tab>
            </TabsList>
        </div>
    </div>
    :
    <div className="loading-error undefined">
      Not enough user rights
    </div>
    }
  </>
  )
};

export default VisitsPage;