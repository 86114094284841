import React from 'react';
import './TwoWayAttachment.scss';
import {MdAddAPhoto} from 'react-icons/all';
import {Tooltip} from '@material-ui/core';

const TwoWayAttachment = ({attachments, handleFileUpload, toggleModal}) => {
  const hiddenFileInput = React.useRef(null);

  const maxMmsLimit = () => {
    if (attachments.length === 3) {
      toggleModal();
      return true;
    }
    return false;
  }

  const handleClickUpload = () => {
    if (!maxMmsLimit()) {
      hiddenFileInput.current.click();
    }
  }

  return (
    <>
      <Tooltip title='Attach file' placement='left'>
        <div className='emoji--btn' onClick={ () =>{  handleClickUpload()  }} >
          <MdAddAPhoto />
          <input type="file" accept="image/x-png, image/jpeg, image/gif" multiple ref={hiddenFileInput}
                 onChange={handleFileUpload} style={{ display: 'none' }} />
        </div>
      </Tooltip>
    </>
  )
}

export default TwoWayAttachment;