import {environment} from '../../environment';
import axios from 'axios';
import LocationService from '../../Store/LocationsService/LocationsService';

const getMessageList = (payload) => {
  const pageMax = environment.twoWayCardCountMax;
  const headers = {
    'Content-Type': 'application/json',
  };
  let api = environment.api.base + environment.api.messageList;

  // if(filter !== null && filter >= 0) {
  //   api += '&viewStatus='+filter;
  // }

  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const getConversation = (payload) => {

  const headers = {
    'Content-Type': 'application/json',
  };
  let api = environment.api.base + environment.api.messages;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
}

const getFiltersCount = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  let api = environment.api.base + environment.api.filtersCount;

  // if(environment.local){
  //   api = '/api/messages.json';
  // }
  return axios.post(api, JSON.stringify(payload), {headers: headers});
}

const updateMessageStatus = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  let api = environment.api.base + environment.api.updateMessageStatus;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postMessage = (payload) => {
  const headers = {
    'Content-Type': 'application/json; charset=utf-8'
  };
  let api = environment.api.base + environment.api.messageSend;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postFindPatient = (payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  let api = environment.api.base + environment.api.findPatient;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postSelectPatient = (payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  let api = environment.api.base + environment.api.selectPatient;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postAddNewPatient = (payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  let api = environment.api.base + environment.api.addPatient;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postNewConversation = (payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  let api = environment.api.base + environment.api.newConversation;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const postCheckNewList = (payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  let api = environment.api.base + environment.api.checkNewList;
  return axios.post(api, JSON.stringify(payload), {headers: headers});
};

const mapConversation = (payload) => {
  var locDictionary = {}
  if (LocationService.getCacheLocations()) {
    LocationService.getCacheLocations().forEach(e => {
      locDictionary[e.id] = e.name;
    })
  }
  let conversationMap = [];
  if (payload.hasOwnProperty('Conversations')) {
    if (payload.Conversations) {
      payload.Conversations.filter((conversation) => {

        conversation = {
          addKey: conversation.AddKey,
          conversationStatus: conversation.ConversationStatus,
          coverKey: conversation.CoverKey,
          locKey: conversation.locKey,
          locName: (locDictionary[conversation.locKey]) ? locDictionary[conversation.locKey] : '',
          convKey: conversation.ConvKey,
          created: conversation.Created,
          deliveryStatus: conversation.DeliveryStatus,
          firstName: conversation.FirstName,
          lastName: conversation.LastName,
          lastTransmission: conversation.LastTransmission,
          message: conversation.Message,
          imageUrls: conversation.ImageUrls,
          messageStatus: conversation.MessageStatus,
          patientId: conversation.PatientId,
          smsTo: conversation.SMSTo,
          hasGivenHipaaConsent: conversation.HasGivenHipaaConsent,
          isOutbound: conversation.IsOutbound
        }
        conversationMap.push(conversation);
      });
    }

  } else {
    conversationMap = payload.conversations
  }
  return conversationMap;
}

const mapMessages = (payload) => {
  let messagesMap = [];
  if (payload.hasOwnProperty('Messages')) {
    payload.Messages.filter((message) => {
      message = {
        created: message.Created,
        deliveryStatus: message.DeliveryStatus,
        message: message.Message,
        smsFrom: message.SMSFrom,
        transKey: message.TransKey,
        userId: message.Userid,
        viewStatus: message.ViewStatus,
        imageUrls: message.ImageUrls
      }
      messagesMap.push(message);
    });
    payload.messages = messagesMap;
  }

  return payload;
}

const mapPatient = (payload) => {
  if (payload.hasOwnProperty('NewPatient')) {
    payload.newPatient = {
      addKey: payload.NewPatient.AddKey,
      firstName: payload.NewPatient.FirstName,
      lastName: payload.NewPatient.LastName,
      phoneNumber: payload.NewPatient.PhoneNumber,
      cellPhone: payload.NewPatient.PhoneNumber
    }
    payload.errors = payload.Errors;
    payload.success = payload.Success;
    payload.message = payload.Message;


  }
  return payload;
}

const mapPatients = (payload) => {
  if (payload.hasOwnProperty('ListOfPatientSearch')) {
    let listOfPatientSearch = [];

    if (payload.ListOfPatientSearch) {
      if (payload.ListOfPatientSearch.length > 0) {
        payload.ListOfPatientSearch.map((patient) => {
          var isValidFormat = false
          if (patient.Cellphone !== null)
          {
              if (patient.Cellphone.length >= 10 && !patient.Cellphone.match("^0+$"))
                isValidFormat = true  
          }
          patient = {
            addKey: patient.AddKey,
            cellPhone: patient.Cellphone,
            primaryPhone: patient.PrimaryPhone,
            secondaryPhone: patient.SecondaryPhone,
            dob: patient.DOB,
            email: patient.Email,
            firstName: patient.FirstName,
            lastName: patient.LastName,
            PMSPatientID: patient.PMSPatientID,
            patientId: patient.PatientId,
            vwPatientId: patient.VwPatientId,
            fullName: patient.FirstName + patient.LastName,
            isValidPhone : isValidFormat
          }
          patient.fullName = patient.fullName.toLowerCase();


          if (!patient.cellPhone) {
            if (patient.phoneNumber) {
              patient.cellPhone = patient.phoneNumber;
            }
            if (patient.primaryPhone) {
              patient.cellPhone = patient.primaryPhone;
            } else if (patient.seconardyPhone) {
              patient.cellPhone = patient.seconardyPhone;
            }
          } 

          if (patient.cellPhone !== null) {
            patient.cellPhone = patient.cellPhone.replace(/\D/g, '');
            listOfPatientSearch.push(patient)
          }
        })
      }
      listOfPatientSearch.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      })
      payload.error = payload.Error;
      payload.listOfPatientSearch = listOfPatientSearch;
    }

  }
  return payload;
}

const mapFilterCount = (payload) => {
  let filters = payload;
  if (payload.hasOwnProperty('Conversations')) {
    filters = {
      conversations: payload.Conversations,
      flagged: payload.Flagged,
      unAnswered: payload.UnAnswered,
      unRead: payload.UnRead
    }
  }
  return filters;
}

export default {
  getMessageList,
  getConversation,
  updateMessageStatus,
  postMessage,
  postFindPatient,
  postSelectPatient,
  postAddNewPatient,
  getFiltersCount,
  postNewConversation,
  postCheckNewList,
  mapConversation,
  mapFilterCount,
  mapMessages,
  mapPatients,
  mapPatient
};