import React, {createContext, useState} from 'react';
import LocationService from './LocationsService'

// Create Context Object
export const LocationServiceContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const LocationServiceProvider = props => {
  const [showModal, setShowModal] = useState(false);
  const [allLocationKeys, setAllLocationKeys] = useState(LocationService.getCacheAllLocations());
  const [selectedLocationKeys, setSelectedLocationKeys] = useState(LocationService.getCacheLocations());
  const [allLocations, setAllLocations] = useState(LocationService.getCacheAllLocations());
  const [gCoverKey, setGCoverKey] = useState('');
  const [allowedCoverKeysForBookings, setAllowedCoverKeysBookings] = useState('');
  const [allowedCoverKeysForRegForms, setAllowedCoverKeysRegForms] = useState('');
  const [tourNext, setTourNext] = useState(false);

  return (
    <LocationServiceContext.Provider value={{
      selectedLocationKeys, setSelectedLocationKeys,
      allLocationKeys, setAllLocationKeys,
      allLocations, setAllLocations,
      showModal, setShowModal,
      gCoverKey, setGCoverKey,
      tourNext, setTourNext,
      allowedCoverKeysForBookings, setAllowedCoverKeysBookings,
      allowedCoverKeysForRegForms, setAllowedCoverKeysRegForms
    }}>
      {props.children}
    </LocationServiceContext.Provider>
  );
};