import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CRMTabsContext from './CRMTabsContext';
import TabHead from './TabHead/TabHead';

const defaultTabStyle = {
    height: null,
    bgColor: null,
    color: null
};

const defaultProps = {
    componentId: '',
    items: [],    
    activeIndex: 0,
    baseBgColor: null,
    tabStyle: {...defaultTabStyle},
    activeTabStyle: {...defaultTabStyle},
    onClick: null
};

const Tabs = (props) => {
    const tabContext = useContext(CRMTabsContext);

    var args = {
        ...defaultProps,
        ...props,
        items: [...props.items],
        tabStyle: {...props.tabStyle},
        activeTabStyle: {...props.activeTabStyle},
        handleCallBack: props.handleCallBack,
        props: props,
        activeIndex: props.activeIndex
    };
    
    const [tabValue, setTabValue] = useState(args.activeIndex ?? 0);
    const [wrapped, setWrapped] = useState(false);
    
    const meRef = useRef(null);
    const getMedia = ()=> {    
        const me = meRef?.current;
        if (!me) return;

        const getThreshold = ()=> {
            let ret=0;
            for(var i=0;i<me.children.length;i++) {ret += (me.children[i]).offsetWidth;}
            return ret;
        };

        setWrapped((me.clientWidth-getThreshold())<0);
    };

    const onClickHandler = (index) => {
        if (args.items[index].absoluteLink) {
            window.location = args.items[index].absoluteLink;
        } else if (!args.items[index].relativeLink) {
            setTabValue(index);
            tabContext.SetTabValue(args.componentId, index);
            args.onClick && args.onClick(index);
        }
        props.handleCallBack &&
            props.handleCallBack(
                {
                    ElementName: args.items[index].elementName,
                    Index: index
                }
            );
    };

    useEffect(()=>{
        if(args.items.length >= (args.activeIndex + 1))
        {
            setTabValue(args.activeIndex)
            if(args.activeIndex > -1){
                onClickHandler(args.activeIndex);
            }
        }
        setTabValue(args.activeIndex);
    },[args.activeIndex]);

    useEffect(()=>{
        window.addEventListener('resize', getMedia);
        getMedia();
        return ()=>window.removeEventListener('resize', getMedia);
    },[]);

    return (
        <div className='tab-heads-cont'>
            <div className={`tab-heads ${wrapped ? 'wrapped' : ''}`} ref={meRef} style={{
                display:'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>
                {args.items.map((x,i)=>{return (
                    <TabHead
                        grow={wrapped ? 1 : 0}
                        height={args.tabStyle.height}
                        index={i}
                        isActive={tabValue==i} 
                        baseBgColor={args.baseBgColor}
                        bgColor={args.tabStyle.bgColor}
                        color={args.tabStyle.color}
                        activeBgColor={args.activeTabStyle.bgColor}
                        activeColor={args.activeTabStyle.color}
                        onClick={onClickHandler}
                    >
                        {typeof x == 'string' ? x : (x.relativeLink ? (<Link to={x.relativeLink}>{x.text}</Link>) : x.text)}                        
                    </TabHead>
                );})}
            </div>
        </div>
    );
};

export default Tabs;