import React, { useContext, useEffect, useState, useRef,useRect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Constants from '../../constants';
import Utils from '../../utils';
import Storage from '../../storage';
import ContextProviders from '../../ContextLib/ContextProviders';
import { useFiltersContext } from '../../ContextLib/contextHooks';
import OnlineBookingSetup from './OnlineBookingSetup';
import { useTour } from '@reactour/tour'
import TourSteps from '../../Store/TourSteps';
import { V5StatesWrapper } from '../../ContextLib/CoreConsumer/v5Contexts';
import UserService from '../../Store/UserService';


export default function OnlineBookingSetupMaster() {
    const componentId = "online-booking-setup-master";
    const filtersContext = useFiltersContext(componentId);

    const [coverKey, setCoverKey] = useState(0); 

    const { isOpen,setSteps } = useTour();
    
    const [allowOnlineBookingSetup] = useState(UserService.checkElementAccess(Constants.onlineBookingSetupMenu));

    const getCoverKey = () => {
        const user = Storage.getItem(Constants.currUserKey);
        const jwtData = Utils.decodeJwt(user.jwt);
        setCoverKey(jwtData.CoverKey);        
    };

    useEffect(()=>{
        filtersContext.SetValues({});
        getCoverKey();
        setSteps(TourSteps.tourStepsInsurance);
    },[]);

    const introOption ={
      scrollToElement : true,
      overlayOpacity : 0.2,
      autoPosition : true
    }

      const overflowValue = isOpen ?  'hidden' : 'auto'


    return (
        <div className='page-cont'>
            {allowOnlineBookingSetup ? <div className={'online-booking'} style={{overflow : overflowValue}}>
                {coverKey == 0 ? (
                <div>
                    <span>Loading. Please wait...</span>
                </div>
                ) : (
                        <>                       
                            <V5StatesWrapper>
                                <ContextProviders>
                                    <OnlineBookingSetup coverKey={coverKey} />
                                </ContextProviders>
                            </V5StatesWrapper>
                </>)}
            </div>
            :
            <div className="loading-error undefined">
                Not enough user rights
            </div>
            }
        </div>
    );
}

export const CommonObjects = {
    HtmlTooltip: withStyles((theme) => ({
                    tooltip: {
                        backgroundColor: '#f5f5f9',
                        color: 'rgba(0, 0, 0, 0.87)',
                        maxWidth: 150,
                        fontSize: theme.typography.pxToRem(16),
                        border: '1px solid #dadde9',
                        fontFamily: 'Noto Sans, sans-serif',
                    },
                }))(Tooltip),
    TableOptions: {
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20],
                    toolbar: false,
                    paging: true,
                    draggable: false,
                    sorting: false,
                    tableLayout: 'fixed',
                }
};