import React, { useState, useContext, useEffect } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from '@material-ui/core';
import { arrayCopy, arraySort } from '../../Core/coreLib';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./Filters.scss";
import { useFiltersContext, useRequest, useUIContext } from '../../contextHooks';
import { Severity } from './SnackbarMessage';
import { MdLowPriority } from "react-icons/md"
import { useTour } from '@reactour/tour'
import { TourServiceContext } from '../../../Store/TourServiceProvider';


export default function Filters({title}) {
  const [tourStart, setTourStart] = useState(false);
  const componentId = "filters";
  const ui = useUIContext(componentId);
  const Request = useRequest();
  const { isOpen, currentStep, setIsOpen, setCurrentStep ,setSteps, setDisabledActions, stepsLength,disabledActions } = useTour();

  const {
     setTourNext, setCurrentLockey,setCurrentAcctKey, currentTab
   } = useContext(TourServiceContext);

  const filtersContext = useFiltersContext(componentId, null, (context) => {   
    //On filter values updated
      setLocKey(context.locKey);
      setAcctKey(context.acctKey);

      setCurrentLockey(context.locKey)
      setCurrentAcctKey(context.acctKey);

      let l = initLocations(context.locations);
      let p = initProviders(context.providers);
      setLocations(l);
      setProviders(p);
      if (currentTab === "webscheduler")
      {
          const last = currentStep === stepsLength - 1
          const next = last ? stepsLength - 1 : currentStep + 1
          if (currentStep == 0 && context.locKey > 0 &&  isOpen)
          {
                setCurrentStep(next)
            if (context.acctKey === 0 )    
                setTourNext(false)
            else
                setTourNext(true)     
          }
          else if (currentStep == 1  && context.acctKey > 0  && isOpen)
          {
                setCurrentStep(next)
                setTourNext(true)
          }
          else if (currentStep == 0 && context.locKey === 0 &&  isOpen)
          {
            setTourNext(false)
          }
          else if (currentStep == 1 && context.acctKey === 0 &&  isOpen)
          {
            setTourNext(false)
          }   
      }
      else
         setTourNext(true) 
  });  

  const initLocations = (arr) => {
    if (filtersContext.Values.cover?.coverKey<=0) return [];

    let l = arrayCopy(arr);
    if (!arr.some(x => x.locKey == 0)) {
      l.splice(0,0,{
        locKey: 0,
        orgName: "All Locations"
      });
    }
    return l;
  };

  const initProviders = (arr) => {
    if (filtersContext.Values.cover?.coverKey<=0) return [];

    let p = arrayCopy(arr);
    if (!arr.some(x => x.acctKey == 0)) {
      p.splice(0,0,{
        acctKey: 0,
        firstName: "All Providers",
        lastName: ""
      });
    }
    return p;
  };

  const [locKey, setLocKey] = useState(-1);
  const [acctKey, setAcctKey] = useState(-1);
  const [locations, setLocations] = useState(initLocations(filtersContext.Values.locations));
  const [providers, setProviders] = useState(initProviders(filtersContext.Values.providers));
  const [filterByLocation, setFilterByLocation] = useState(false);
  const filteredProviders =
    !filterByLocation && locKey
      ? providers
      : providers?.filter(
          (p) =>
            (filterByLocation && locKey && p.locKeys?.includes(locKey)) ||
            p.acctKey === 0
        );
  const providerOptions = filteredProviders?.map((p) => p.acctKey);

  const setFilterValues = (v, raiseFiltersChangedEvent, source) => {
    filtersContext.SetValues(v);
    if (raiseFiltersChangedEvent) filtersContext.UpdateClients_FiltersChanged(source);
  };
  
  const loadSubData = async () => {
    let keys = {
      locKey: 0,
      acctKey: 0
    };

    ui.ShowOverlay();

    let res = await Promise.all([
      Request.getLocationsByCoverKey({coverKey: filtersContext.Values.cover.coverKey}),
      Request.getProvidersByCoverKeyExt({coverKey: filtersContext.Values.cover.coverKey})
    ]);

    if (!res.some(x => !x.success)) {
      setFilterValues({
        ...keys,
        locations: arraySort(res[0].data, "orgName"),
        providers: arraySort(res[1].data, "lastName")
      }, true);
    } else {
      let _err = res.filter(x => !x.success).map(x => x.error);
      let err = <>{_err.map(x => <div>{x}</div>)}</>

      ui.ShowSnackbar(err, Severity.error);
    }
    
    ui.HideOverlay();
  };

  const locationChanged = (lkey) => {
    setFilterValues({locKey: lkey}, true, "location");
  }; 

  const providerChanged = (akey) => {
    setFilterValues({acctKey: akey}, true, "provider");
  };

  const getlabel = (d, action) => {
    let ret = "";
    if (action == "location" && locations.length > 0) {
      let x = locations.filter(x => x.locKey == d)[0];      
      if (x) {
        let n = x.orgName ?? "[No OrgName]";        
        ret = d==0 ? n : `${x.locKey}: ${n}`;  
      }
    } else if (action == "provider" && providers.length > 0) {
      let x = providers.filter(x => x.acctKey == d)[0];
      if (x) {
        let _n = (`${x.firstName ?? ""} ${x.lastName ?? ""}`).trim();
        let n = _n ? _n : "[No Name]";
        ret = d==0 ? n : `${x.acctKey}: ${n}`;
      }
    }
    return ret;
  };

  useEffect(async () => {
    await loadSubData();
  }, []);



   const openTour= () =>
    {

     if (!tourStart)
       setCurrentStep(0)

      setTourStart(!tourStart)
      setIsOpen(!tourStart)

      if (currentTab === "webscheduler")
      {
          if ((locKey === 0 && acctKey === 0) || (locKey === 0 && acctKey > 0))
          {
            setTourNext(false)
          }
          else if (locKey > 0 && acctKey === 0)
          {
              setTourNext(true) 
          }       
          else
          {
              setTourNext(true)   
          }   
      }
      else
         setTourNext(true) 

    } 

    useEffect(async () => {
      setTourStart(isOpen)
    }, [isOpen]);
  
  return (
    <div className="filters">     
{/*       <Typography variant="h5">
          {title}
      </Typography>  */}  
      <FormControl fullWidth>
        <TextField 
          value={filtersContext.Values.cover?.coverKey + ': ' + filtersContext.Values.cover?.orgName} 
          label="Cover"
          variant="outlined"
          readOnly={true}
        />
      </FormControl>
      <FormControl fullWidth  data-tut="reactour__getlocation">
        <Autocomplete
              id="Location"
              options={locations.map(x => x.locKey)}
              getOptionLabel={(x) => getlabel(x, "location")}
              value={locKey}
              onChange={(event, newValue) => {
                locationChanged(newValue ?? 0);
              }}
              renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
            />
      </FormControl>
      <FormControl fullWidth data-tut="reactour__getprovider">
        <Autocomplete
            id="Provider"
            options={providerOptions}
            getOptionLabel={(x) => getlabel(x, "provider")}
            value={acctKey}
            onChange={(event, newValue) => {
              providerChanged(newValue ?? 0);
            }}
            renderInput={(params) => <TextField {...params} label="Provider" variant="outlined" />}
          />
      </FormControl>    
      <FormControlLabel
        className="filters-checkbox"
        control={
          <Checkbox
            color="default"
            checked={filterByLocation}
            onChange={() => setFilterByLocation((filtered) => !filtered)}
            disableRipple
          />
        }
        label="Filter Providers by Location"
        labelPlacement="start"
        style={{ display: locKey ? "flex" : "none" }}
      />
      <Button variant="contained" startIcon={<MdLowPriority />} onClick={() =>  openTour()} color={!tourStart ? 'primary' : 'secondary'}>
        {!tourStart ? 'Start User Guide' : 'End User Guide'}
      </Button> 
    </div> 
  );
}
