import Constants from '../../constants';
import Utils from '../../utils';
import Storage from '../../storage';
import moment from 'moment';
import {ApptSort} from "@essilorditac/customer-dash-ui";

export const getCoverKey = () => {
    const user = Storage.getItem(Constants.currUserKey);
    const jwtData = user && Utils.decodeJwt(user.jwt);
    return jwtData && jwtData.CoverKey;
}
export const getJwt = () => {
    const jwtData = Storage.getItem(Constants.currUserKey);
    return jwtData && jwtData.jwt;
}

export const APPT_PAGE_LIMIT = 10;
export const DEFAULT_APPT_BODY = {
    pageSize: APPT_PAGE_LIMIT,
    pageStart: 1,
    beginDate: moment().startOf("day").format(),
    endDate: moment().endOf("day").format(),
    searchPhrase: "",
    sortColumn: "",
    sortOrder: ApptSort.NOT_SORTED,
  };