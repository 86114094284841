import React, {useState} from 'react';
import { ApptApi, ContentContainer,useApptReports, ApptFilters, ApptActions, Loading, LoadingError, CancelledRecTable, RecallApptType,Pagination} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import {DEFAULT_APPT_BODY} from "./ReportsUtils";
import { getCoverKey, getJwt } from "./ReportsUtils";


export const CancellationTableRec = () => {

    const [filters, setFilters] = useState({
        locations: [],
        providers: [],
      });
      const [page, setPage] = useState(1);
      const PAGE_LIMIT = 10;
 const coverKey = getCoverKey();
 const authorization = getJwt();
      const AUTH_AVAILABLE = coverKey && authorization;
    
      const [apiBody, setApiBody] = useState({
        reportType: RecallApptType.MESSAGED,
        locKeys: [],
        acctKeys: [],
        ...DEFAULT_APPT_BODY,
      });
    
      const handleShowReport = (resHook) => {
        const startDate = resHook.dates.from;
        const endDate = resHook.dates.to;
        setApiBody({
          ...apiBody,
          locKeys: filters.locations?.map((item) => parseInt(item)) || [],
          acctKeys: filters.providers?.map((item) => parseInt(item)) || [],
          beginDate: moment(startDate || moment().startOf("day")).format(),
          endDate: moment(endDate || moment().endOf("day")).format(),
        });
      };
  
      const handleChange = (field, val) => setFilters({ ...filters, [field]: val });
  
      const APPT_RES = useApptReports(
          coverKey,
          authorization,
          ApptApi.CANCELLATIONS_RECAPTURED,
          apiBody
        );
        const {
          locations,
          providers,
          loadingFilters,
          errorFilters,
          columns,
          apptData,
          loadingData,
          errorData,
          FiltersProps,
        } = APPT_RES;

    return(
        <ContentContainer>
            {coverKey && locations && providers && (
                <>
                    <ApptFilters
                        {...FiltersProps}
                        filters={filters}
                        locations={locations}
                        providers={providers}
                        setFilters={handleChange}
                        handleShowReport={() => handleShowReport(APPT_RES)}
                    />
                    <div className="web-appointments-results">
                        <ApptActions
                        search={apiBody.searchPhrase}
                        setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                        coverKey={coverKey}
                        authorization={authorization}
                        apiName={ApptApi.CANCELLATIONS_RECAPTURED}
                        body={apiBody}
                        />
                        <div className="web-appointments-overflow">
                            <CancelledRecTable 
                            res={apptData?.pagedItems}
                            loading={loadingData}
                            columns={columns}
                            error={errorData} 
                            />
                        </div>
                        <Pagination
                            page={page}
                            setPage={(p) => {
                                setPage(p);
                                setApiBody({
                                ...apiBody,
                                pageStart: p,
                                });
                            }}
                            total={apptData?.totalItemCount}
                            pageSize={PAGE_LIMIT}
                        />
                    </div>
                </>
            )}
            {AUTH_AVAILABLE && loadingFilters && <Loading full />}
            {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
                <LoadingError />
            )}
        </ContentContainer>
    )
}