import {environment} from '../../environment';

const printLog = (action, obj = '', type = '') => {
  switch (type) {
    case 'error' :
      if (environment.env !== 'prod') {
        console.error(action, obj);
      }
      break;
    default:
      if (environment.env !== 'prod') {
        // console.log(action, obj);
      }
      break;
  }
};

const printVersion = () => {
  if (environment.env !== 'prod') {
    console.log('%c2WAY v.' + environment.version, 'color:#233E82; font-family:\'Tahoma\'; display: block;font-weight:bold; font-size:20px; background:#fff;');
  }
}

export default {
  printLog,
  printVersion
};