import './SetupTab.scss';
import React, { useContext, useState, useEffect } from 'react';
import UserService from '../../Store/UserService';
import { LocationServiceContext } from '../../Store/LocationsService/LocationServiceProvider';
import SessionContext from '../../SessionContext';

const SetupTab = (props) => {
  const session = useContext(SessionContext);
  const [state, setstate] = useState({
    redirectToLogin: false,
    redirectToCoverSearch: false,
  });
  const { setAllLocations, setSelectedLocationKeys, setGCoverKey,setAllowedCoverKeysBookings,setAllowedCoverKeysRegForms } = useContext(
    LocationServiceContext
  );

  useEffect(async () => {
    await UserService.authenticateSession(props, session, setSelectedLocationKeys, setAllLocations, setGCoverKey, setstate, state, setAllowedCoverKeysBookings, setAllowedCoverKeysRegForms);
  }, []);

  return (
    <>
      <div className='page-cont'>
        <div className='center'>
          <h1>Welcome to your<br/>Patient Engagement<br/>platform<br/>Setup</h1>
        </div>
      </div>
    </>
  );
};
export default SetupTab;
