import React, {useState} from 'react';
import { ApptApi, ContentContainer, useApptReports, ApptFilters, ApptActions, Loading, LoadingError,VisitTable,Pagination} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import {DEFAULT_APPT_BODY} from "./ReportsUtils";
import { getCoverKey, getJwt } from "./ReportsUtils";

export const VisitDataReport = () => {

    const [filters, setFilters] = useState({
        locations: [],
        providers: [],
      });
    
    const [apiBody, setApiBody] = useState({
        locKeys: [],
        acctKeys: [],
        ...DEFAULT_APPT_BODY,
      });

    const [page, setPage] = useState(1);
    const PAGE_LIMIT = 10;
 const coverKey = getCoverKey();
 const authorization = getJwt();
    
    const handleShowReport = (resHook) => {
    const startDate = resHook.dates.from;
    const endDate = resHook.dates.to;
    setApiBody({
        ...apiBody,
        locKeys: filters.locations?.map((item) => parseInt(item)) || [],
        acctKeys: filters.providers?.map((item) => parseInt(item)) || [],
        beginDate: moment(startDate || moment().startOf("day")).format(),
        endDate: moment(endDate || moment().endOf("day")).format(),
    });
    };
    const AUTH_AVAILABLE = coverKey && authorization;

    const APPT_RES = useApptReports(
        coverKey,
        authorization,
        ApptApi.VISITS,
        apiBody
      );
      const {
        locations,
        providers,
        loadingFilters,
        errorFilters,
        columns,
        apptData,
        loadingData,
        errorData,
        FiltersProps,
      } = APPT_RES;

      const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

    return(
        <ContentContainer>
            {coverKey && locations && providers && (
                <>
                    <ApptFilters
                        {...FiltersProps}
                        filters={filters}
                        locations={locations}
                        providers={providers}
                        setFilters={handleChange}
                        handleShowReport={() => handleShowReport(APPT_RES)}
                    />
                    <div className="web-appointments-results">
                        <ApptActions
                        search={apiBody.searchPhrase}
                        setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                        coverKey={coverKey}
                        authorization={authorization}
                        apiName={ApptApi.VISITS}
                        body={apiBody}
                        />
                        <div className="web-appointments-overflow">
                            <VisitTable 
                                res={apptData?.pagedItems}
                                loading={loadingData}
                                columns={columns}
                                error={errorData} 
                            />
                        </div>
                        <Pagination
                            page={page}
                            setPage={(p) => {
                                setPage(p);
                                setApiBody({
                                ...apiBody,
                                pageStart: p,
                                });
                            }}
                            total={apptData?.totalItemCount}
                            pageSize={PAGE_LIMIT}
                        />
                    </div>
                </>
            )}
            {AUTH_AVAILABLE && loadingFilters && <Loading full />}
            {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
                <LoadingError />
            )}
        </ContentContainer>
    )
}