
  import Card from '@material-ui/core/Card';
  import CardContent from '@material-ui/core/CardContent';
  import CardMedia from '@material-ui/core/CardMedia';
  import Typography from '@material-ui/core/Typography';


  const stepsBody = (title,message) =>
  {

    return (
      <>
        <Card >
            <CardMedia   
              style={{height: "90px"}}       
              component="img"
              image="/assets/user-guide.jpeg"
              alt="User Guide Image"
            />
            <CardContent>
              <Typography variant="subtitle2" component="div">
                {title}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                  {message}
              </Typography>
            </CardContent>
        </Card>      
      </>
    )
  }

  const tourStepsInsurance = [
    {
      selector: '[data-tut="reactour__getlocation"]',
      content: stepsBody('Location','Select the location(s) you wish to apply this setting change to.'),
    },
    {
      selector: '[data-tut="reactour__getprovider"]',
      content: stepsBody('Provider','Select the provider(s) you wish to apply this setting change to.'),
    },        
    {
      selector: '[data-tut="reactour__insurance_carrier"]',
      content: stepsBody('Add Insurance Type','To add a new Insurance, simply enter the name in the Insurance carrier section and set pre-processing time (days). Note that the pre-processing time will be applied to all insurance carriers. If Pre-processing is set to 1, patients cannot make same day bookings and can only make appointments that are at least a day from now.'),
    },
    {
      selector: '[data-tut="reactour__insurance_delete"]',
      content: stepsBody('Delete Option','Select this option if you want to delete an Insurance carrier that you no longer accept. This action cannot be undone. '),
    },    
    {
      selector: '[data-tut="reactour__insurance_edit"]',
      content: stepsBody('Edit Option','Select this option to edit name and/or processing time for an Insurance type.'),
    }
  ]    

  const tourStepsAppointment = [
    {
    selector: '[data-tut="reactour__getlocation"]',
    content: stepsBody('Location','Select the location(s) you wish to apply this setting change to.'),
    },
    {
    selector: '[data-tut="reactour__getprovider"]',
    content: stepsBody('Provider','Select the provider(s) you wish to apply this setting change to.'),
    },     
    {
      selector: '[data-tut="reactour__getappointment_general_notes"]',
      content: stepsBody('General Note for page','You can view all the current appointment types available to your patients on this page. Please reach out to our support team to make any changes or add a new appointment type.'),
    },   
  ]  
  
  const tourStepsPatient = [
    {
    selector: '[data-tut="reactour__getlocation"]',
    content: stepsBody('Location','Select the location(s) you wish to apply this setting change to.'),
    },
    {
    selector: '[data-tut="reactour__getprovider"]',
    content: stepsBody('Provider','Select the provider(s) you wish to apply this setting change to. '),
    },      
    {
      selector: '[data-tut="reactour__tab_Patient Validation"]',
      content: stepsBody('Patient Validation',"All input fields on this page are mandatory and cannot be edited."),
    },    
    {
      selector: '[data-tut="reactour__tab_Patient Demographics"]',
      content: stepsBody('Patient Demographics',"Choose how much data you wish to collect from a patient booking an appointment online. Each setting can be set to Don't ask, Desired or Required. For more bookings, keep your booking forms short and to the point."),
    },                      
    {
      selector: '[data-tut="reactour__tab_Patient Insurance"]',
      content: stepsBody('Patient Insurance','Choose to display or not display Vision AND/OR Medical Insurance. Should you select to display one of these sections, you will have to choose the other Desired or Required fields as well, in the corresponding Insurance section ( name, Subscriber number etc).'),
    },       
    {
      selector: '[data-tut="reactour__tab_Other"]',
      content: stepsBody('Others','Add upto 6 custom questions. Please note that patients are more likely to complete booking when they have lesser information or data to submit. For optimum bookings, keep the questions short and to the point.'),
    },       
  ]    

const tourStepsGeneral = [
  {
      selector: '[data-tut="reactour__getlocation"]',
      content: stepsBody('Location','Select the location(s) you wish to apply this setting change to.'),
    },
    {
      selector: '[data-tut="reactour__getprovider"]',
      content: stepsBody('Provider','Select the provider(s) you wish to apply this setting change to.'),
    },        
	{
		selector: '[data-tut="reactour__webschedulingstatus"]',
		content: stepsBody('Web Scheduling Status', 'Use to enable or disable Online setting. This setting should be in disabled status till all other settings have been configured.'),
	},
	
   {
      selector: '[data-tut="reactour__schedulingbahavior"]',
      content: stepsBody('Scheduling Behaviors','Choose the number of weeks that you would like to schedule and display your availability online.'),
    },  
	{
		selector: '[data-tut="reactour__emailbehavior"]',
		content: stepsBody('Email Behavior', 'Choose whether you wish patient info to be sent via email or not'),
	},
	{
		selector: '[data-tut="reactour__practiceemailsecurity"]',
		content: stepsBody('Practice email security', 'Choose if you need your staff to login to require appt data or whether to include it in the email.'),
	},
	{
		selector: '[data-tut="reactour__notificationemail"]',
		content: stepsBody('Notification email', 'Set up the email to be used for notifications like'),
	}, 
	{
		selector: '[data-tut="reactour__addmobilefiles"]',
		content: stepsBody('Add Mobile Files', 'Save the file to be uploaded on your computer, then Browse to select the file. Do not copy and paste a link to the file as it will not detect the file.'),
	},  
	{
		selector: '[data-tut="reactour_adddesktopfiles"]',
		content: stepsBody('Add Desktop Files', 'Save the file to be uploaded on your computer, then Browse to select the file. Do not copy and paste a link to the file as it will not detect the file.'),
	}, 
	{
		selector: '[data-tut="reactour_endingmessage"]',
		content: stepsBody('Ending Message', 'Please make sure the html copied does not have script tags. If a malicious tag is detected, it will not be accepted for Security reasons.'),
	}, 
    /*  {
      selector: '[data-tut="reactour__SendPatWSDataInEmail"]',
      content: stepsBody('Appointment Data in Email to Practice'),
    },   
    {
      selector: '[data-tut="reactour__RemoveNotificationEmail"]',
      content: stepsBody('Select Location','Click to remove the Email Notification.'),
    },        
    {
      selector: '[data-tut="reactour__copy"]',
      content: stepsBody('Select Location','Upload Profile picture'),
    },      */   
    // ...
  ]  
         

const tourStepsSchedule = [
    {
    selector: '[data-tut="reactour__getlocation"]',
    content: stepsBody('Location','Select the location(s) you wish to apply this setting change to. You cannot apply a schedule to multiple locations.'),
    },
    {
    selector: '[data-tut="reactour__getprovider"]',
    content: stepsBody('Provider','Select the provider(s) you wish to apply this setting change to. You cannot apply a schedule to multiple providers.'),
    },  
    {
      selector: '[data-tut="reactour__schedule_calendar"]',
      content: stepsBody('Calendar View','This section displays the current schedule setup for the provider. Any block in blue indicates an availability block, light grey blocks indicate a break and dark grey indicates a Blackout.'),
    },     
    {
      selector: '[data-tut="reactour__schedule_createavailability"]',
      content: stepsBody('Create Availability','This is to create and setup schedule availability.'),
    },                
    {
      selector: '[data-tut="reactour__schedule_startdate"]',
      content: stepsBody('Availability','Select a start and end date and time for the schedule. You can choose to add a break period now or come back and enter details later. You must also select the appointment types that this schedule is for. If you choose to book multiple bookings at the same time, set a number for simultaneous appointments. This will allow X patients to book the exact same availability slot. These are rare instances and only for appointment types that are administrative in nature. '),
    },              
    {
      selector: '[data-tut="reactour__schedule_repetition"]',
      content: stepsBody('Repetition','Select the days you wish to repeat the schedule for. You can choose particular days or the week, as week as choose how many weeks you want to repeat the schedule for, and/or decide an end date for the schedule. '),
  },        
]

const tourStepsFormTemplates = [
	{
		selector: '[data-tut="reactour__magnifyingglass"]',
		content: stepsBody('Magnifying Glass', 'View details of a particular form by clicking the icon. This will show you a Preview of the form as visible to a patient.'),
	},
	{
		selector: '[data-tut="reactour__link"]',
		content: stepsBody('Link', 'Retrieve  a link for the form that can be inserted anywhere on your website or email sent to patient. If you have multiple offices, you will be prompted to select a location and then copy the link.'),
	},
]
const tourStepsCompletedForms = [
	{
		selector: '[data-tut="reactour__appointmentdate"]',
		content: stepsBody('Appointment Date', 'Filter out patients based on the appointment date. By default, the system will display forms submitted for an appointment for 7 days (including today ). If your form does not contain a section to enter appointment information, the forms will be at the bottom of the list.'),
	},
	{
		selector: '[data-tut="reactour__filterby"]',
		content: stepsBody('Filter by', 'Choose additional filters to refine your search.'),
	},
	{
		selector: '[data-tut="reactour__magnifyingglasscompleted"]',
		content: stepsBody('Magnifying Glass', 'View a filled form that was submitted by the patient.'),
	},
	{
		selector: '[data-tut="reactour__print"]',
		content: stepsBody('Print', 'Choose a particular entry or select all to print multiple records at a time.'),
	},
]


  export default {
    tourStepsGeneral,
    tourStepsAppointment,
    tourStepsPatient,
    tourStepsInsurance,
    tourStepsSchedule,
	tourStepsFormTemplates,
	tourStepsCompletedForms
  };