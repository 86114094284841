/**
 * Represents storing items in local storage.
 */
class Storage {
  /**
   * Returns an item by the provided name.
   * @param {String} name The name of the item.
   * @returns String
   */
  static getItem(name) {
    let item = localStorage.getItem(name);

    if (item) {
      try {
      item = JSON.parse(item);
      } catch (exception) {
        // do nothing item is not json
      }
    }

    return item;
  }

  /**
   * Removes an item from local storage
   * @param {string} name  The name of the item
   */
  static removeItem(name) {
    localStorage.removeItem(name);
  }

  /**
   * Sets an item in storage.
   * @param {String} name The name of the item.
   * @param {any} item The item to set.
   */
  static setItem(name, item) {
    if (name) {
      if (item) {
        localStorage.setItem(name, JSON.stringify(item));
      } else {
        localStorage.setItem(name, item);
      }
    }
  }
}

// Lock object to prevent modification (true static).
Object.freeze(Storage);

export default Storage;
