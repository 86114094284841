import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#235789'
    },
    secondary: {
      light: '#ffffff',
      main: '#4285F4',
      contrastText: '#ffcc00',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiButton: {
      root: {},
      customVariantName: {}
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      elevation: 0
    },
    MuiToolbar: {
      elevation: 0
    },
    MuiAppBar: {
      elevation: 0
    },
    MuiButton: {
      elevation: 0
    },
    MuiPaper: {
      elevation: 0
    },
    MuiCard: {
      elevation: 0
    },
    MuiPagination: {
      outlined: {
        backgroundColor: 'blue'
      }
    },
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    h5: {
      fontWeight: 100,
      fontSize: '1.3rem'
    },
    button: {
      fontStyle: 'normal',
    },
    body1: {}
  },
});

export default theme;