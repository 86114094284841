import {environment} from '../../environment';
import axios from 'axios';
import UserService from '../UserService';


const getLocations = () => {
  const headers = {};
  let api = environment.api.base + environment.api.locations + '?sessionid=' + UserService.getSessionId();
  if (environment.local) {
    api = '/api/locations.json';
    api = '/api/locations.json';
  }
  return axios.get(api, {headers: headers});
}

const saveLocations = (locations) => {
  if (locations) {
    localStorage.setItem('locations', locations);
  }
}

const saveCurrentLocKeys = (keys) => {
  if (keys) {
    localStorage.setItem('currentLocKeys', keys);
  }
}

const saveLocationAware = (isLocationAware) => {
  if (isLocationAware !== undefined) {
    localStorage.setItem('locationAware', isLocationAware);
  }
}

const saveMMSEnabled = (isMMSEnabled) => {
  if (isMMSEnabled !== undefined) {
    localStorage.setItem('isMMSEnabled', isMMSEnabled);
  }
}

const getLocationAware = () => {
  return JSON.parse(localStorage.getItem('locationAware'))
}

const getMMSEnabled = () => {
  return JSON.parse(localStorage.getItem('isMMSEnabled'))
}

const saveAllLocations = (locations) => {
  if (locations) {
    localStorage.setItem('allLocations', locations);
  }
}

const getCacheLocations = () => {
  return JSON.parse(localStorage.getItem('locations'))
}

const getCacheCurrentLocKeys = () => {
  return JSON.parse(localStorage.getItem('currentLocKeys'))
}

const getCacheAllLocations = () => {
  return JSON.parse(localStorage.getItem('allLocations'))
}

const updateLocKeys = (_locations) => {


  const filteredLocationsArray = [];
  const selectedLocationKeys = _locations;
  const allLocationKeys = getCacheAllLocations();

  if (selectedLocationKeys !== null) {
    selectedLocationKeys.forEach(element => {
      if (element.hasOwnProperty('LocKey')) {
        filteredLocationsArray.push(element.LocKey);
      } else {
        filteredLocationsArray.push(element.id);
      }

    });
  }

  saveCurrentLocKeys(JSON.stringify(filteredLocationsArray));

  // Load conversations when no locations are selected
  if (selectedLocationKeys === null || selectedLocationKeys.length === 0) {
    for (let key in allLocationKeys) {
      filteredLocationsArray.push(key);
    }
  }

  if (getLocationAware()) {
    saveCurrentLocKeys(JSON.stringify(filteredLocationsArray));
  } else {
    saveCurrentLocKeys(JSON.stringify([]));
  }

}

const updateLocationStore = (_locations) => {
  let locations = _locations.data.Locations;
  let locationAware = _locations.data.isLocationAware;
  let mmsEnabled = _locations.data.IsMMSEnabled;
  let myLocations = [];
  let refreshLocations = [];
  saveLocationAware(locationAware);
  saveMMSEnabled(mmsEnabled)

  if (locations && locations.length > 0) {
    myLocations = locations.map((item) => {
      return {id: item.LocKey, name: item.LocOrgName + ' - ' + item.City + ', ' + item.State + ' ' + item.Zip}
    })

    if (!getCacheLocations()) {
      saveLocations(JSON.stringify(myLocations))
    } else {
      getCacheLocations().map((localLocation) => {
        let keepLocalItem = false;
        let keepLocationObj;
        locations.map((remoteLocations) => {
          if (remoteLocations.LocKey === localLocation.id) {
            keepLocalItem = true;
            keepLocationObj = remoteLocations;
          }
        })
        if (keepLocalItem) {
          refreshLocations.push({
            id: keepLocationObj.LocKey,
            name: keepLocationObj.LocOrgName + ' - ' + keepLocationObj.City + ', ' + keepLocationObj.State + ' ' + keepLocationObj.Zip
          })
        }
      })
      if (refreshLocations.length <= 0) {
        saveLocations(JSON.stringify(myLocations))
      } else {
        saveLocations(JSON.stringify(refreshLocations))
      }
    }
    saveAllLocations(JSON.stringify(myLocations))
    return myLocations;
  } else {
    saveLocations(JSON.stringify([]))
    saveAllLocations(JSON.stringify([]))
    return [];
  }
}

export default {
  getLocations,
  saveLocations,
  getCacheCurrentLocKeys,
  saveCurrentLocKeys,
  getCacheLocations,
  saveAllLocations,
  getCacheAllLocations,
  saveLocationAware,
  saveMMSEnabled,
  getLocationAware,
  getMMSEnabled,
  updateLocKeys,
  updateLocationStore
};