import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LoadFormData, { CreateIFrameInstance, GetFormDocTemplate } from './FormLoader';
import PrintIcon from '@material-ui/icons/Print';
import ListIcon from '@material-ui/icons/List';
import Button from '@material-ui/core/Button';
import { useUIContext } from '../../ContextLib/contextHooks';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import "./FormView.scss";

// const FormIOCont = React.forwardRef((props, ref) => {
//     return (
//         <div id="formio-result" ref={ref}/>
//     );
// });

const FormView = ({coverKey, data, setView}) => {
    let componentId = "form-view";
    const ui = useUIContext(componentId);
    const formioRef = useRef(null);

    const loadData = async () => {
        try{
            ui.ShowOverlay();
            await LoadFormData(formioRef.current, coverKey, data.formPath, data.formData, data.submissionData, data.key);            
        } catch (e) {
            ui.ShowSnackbar(e.message, Severity.error);
        } finally {
            ui.HideOverlay();
        }
    };

    const print = () => {
        try{
            ui.ShowOverlay();
            CreateIFrameInstance((fwindow)=> new Promise((resolve) => {                
                ReactDOM.render(GetFormDocTemplate(false, true), fwindow.document.body);

                let cont = fwindow.document.getElementById('formio-result');
                LoadFormData(cont, coverKey, data.formPath, data.formData, data.submissionData, data.key, fwindow._Formio)
                    .then(()=> {
                        fwindow.focus();
                        fwindow.print();

                  resolve(true);
                  ui.HideOverlay();
                });   
            }));
        } catch (e) {
            ui.ShowSnackbar(e.message, Severity.error);
        }
        ui.HideOverlay();
    };

    // const setHeight = ()=> {
    //     var r = document.querySelector('.registrationsection');
    //     var rh = r.clientHeight;

    //     var formViewCont = document.querySelector('.form-view-cont');
    //     formViewCont.style.maxHeight = `${rh-165}px`;
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', setHeight);
    //     setHeight();
    //     return ()=> window.removeEventListener('resize', setHeight);
    // }, []);

    useEffect(() => {
        loadData();
    }, [data, coverKey]);

    return (
        <>                            
            <div className='form-view-commands'>
                <Button onClick={print}>
                    <PrintIcon />&nbsp;Print Form
                </Button>
                <Button onClick={() => setView("main")}>
                    <ListIcon />&nbsp;Back to List
                </Button>  
            </div> 
            {GetFormDocTemplate(true, false, formioRef)}            
        </>        
    );
};

export default FormView;