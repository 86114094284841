import React, { useEffect } from "react";
import {
  useApi,
  DatePicker,
  TabsList,
  Tab,
  PageFilters,
  LoadingError,
  MainService,
  RECALL_REPORT_TABS,
  TotalAppt,
  RecalledPat,
  RecModality,
  PageTitle,
} from "@essilorditac/customer-dash-ui";
import { getCoverKey } from "./ReportsUtils";
import "./ReportsPage.scss";
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const DEFAULT_DATE = {
  from: "",
  to: "",
};

const ReportsPage = () => {
  const [selected, setSelected] = React.useState({
    apptStatus: [],
    apptTypes: [],
    locations: [],
    providers: [],
  });
  const [dateFilter, setDateFilter] = React.useState(DEFAULT_DATE);
  const [tab, setTab] = React.useState(0);

  const coverKey = getCoverKey();

  const [FILTERS, ERROR_FILTERS, LOADING_FILTERS] = useApi(
    new MainService().getFilters(coverKey),
    [tab]
  );

  const [allowrecallOverviewMenu] = React.useState(UserService.checkElementAccess(Constants.recallOverviewMenu));

  const handleFilter = (sel) => {
    setSelected(sel);
  };
  const handleDate = (date) => {
    setDateFilter(date);
  };

  const FiltersPage = () => (
    <PageFilters
      title={RECALL_REPORT_TABS.find((i) => i.id === tab)?.label || ""}
      filters={FILTERS}
      selectedFilters={selected}
      setFilter={handleFilter}
    />
  );

  useEffect( async() => {    
      UserService.checkUser();
  },[]);

  return (
    <>
      {allowrecallOverviewMenu ? <div className="reports-page-wrapper">
        <div className="page-picker-containers">
          <PageTitle title="Reports - Recall" />
          <DatePicker search={handleDate} />
        </div>
        <TabsList
          loading={LOADING_FILTERS}
          tabs={RECALL_REPORT_TABS}
          selected={tab}
          setTab={setTab}
        >
          <Tab index={0} selected={tab}>
            <>
              {FiltersPage()}
              {!ERROR_FILTERS && (
                <TotalAppt
                  selected={selected}
                  dateFilter={dateFilter}
                  coverKey={coverKey}
                />
              )}
              {ERROR_FILTERS && <LoadingError />}
            </>
          </Tab>
          <Tab index={1} selected={tab}>
            <>
              {FiltersPage()}
              {!ERROR_FILTERS && (
                <RecalledPat
                  selected={selected}
                  dateFilter={dateFilter}
                  coverKey={coverKey}
                />
              )}
              {ERROR_FILTERS && <LoadingError />}
            </>
          </Tab>
          <Tab index={2} selected={tab}>
            <>
              {FiltersPage()}
              {!ERROR_FILTERS && (
                <RecModality
                  selected={selected}
                  dateFilter={dateFilter}
                  coverKey={coverKey}
                />
              )}
              {ERROR_FILTERS && <LoadingError />}
            </>
          </Tab>
        </TabsList>
      </div>
      : 
      <div className="loading-error undefined">
        Not enough user rights
      </div>
      }
    </>
  );
};

export default ReportsPage;
