import React, {useEffect, useRef, useState} from 'react';
import '../TwoWayConversation/TwoWayConversation.scss';
import './TwoWayConversationNew.scss';
import TwoWayMessageInput from '../TwoWayMessageInput/TwoWayMessageInput';
import TwoWayConversationService from '../../Store/TwoWayMessageService/TwoWayConversationService';
import {subHours} from 'date-fns'
import {FiAlertTriangle, FiCheckCircle, FiChevronDown, FiSlash, FiX, FiXCircle} from 'react-icons/all';
import Logger from '../../Store/Logger/Logger';
import Utils from '../../Store/Utils/Utils';
import {environment} from '../../environment';
import Spinner from '../Spinner/Spinner';
import {Button, Tooltip} from '@material-ui/core';
import TwoWayModal from '../TwoWayModal/TwoWayModal';
import UserService from '../../Store/UserService';
import LocationService from '../../Store/LocationsService/LocationsService'
import TwoWayConversationMessages from '../TwoWayConversationMessages/TwoWayConversationMessages';
import TwoWayConversationTemplateService from '../../Store/TwoWayMessageService/TwoWayConversationTemplateService'

const TwoWayConversationNew = ({refreshList, match, windowHeight, selectedLocation, hasFiles, setHasFiles}) => {
  const ref = useRef()
  const [headerHeight, setHeaderHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const callTwoWayModalNewPatient = useRef(null);
  const callTwoWayModalHipaaConsent = useRef(null);
  const callTwoWayModalNewPatientError = useRef(null);
  const callTwoWayModalMaxPatientError = useRef(null);
  const callTwoWayModalNoCellPhone = useRef(null);
  const callTwoWayModalAddKeyError = useRef(null);
  const callTwoWayModalFindPatientError = useRef(null);
  const callTwoWayModalSendComplete = useRef(null);
  const [showSearchPatients, setShowSearchPatients] = useState(false);
  const txtMaxPatientsAdded = 'Only ten patients can be sent a message at a time.';
  const [searchFirstName, setSearchFirstName] = useState('');
  const [noPatients, setNoPatients] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileSearchResultsVisible, setIsMobileSearchResultsVisible] = useState(false);
  const [showAllOnMobile, setShowAllOnMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageList, setShowMessageList] = useState(false);
  const [searchLastName, setSearchLastName] = useState('');
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [searchPatientId, setSearchPatientId] = useState('');
  const [error, setError] = useState();
  const [newPatientBody, setNewPatientBody] = useState();
  const [hipaaAskBody, setHipaaAskBody] = useState();
  const [patientSearchResults, setPatientSearchResults] = useState([]);
  const [smsToList, setSmsToList] = useState([]);
  const [conversation, setConversation] = useState({
    firstName: '',
    lastName: '',
    convKey: ''
  });
  const [patient, setPatient] = useState({
    firstName: '',
    lastName: '',
    convKey: '',
  });
  const [isOnPaste, setIsOnPaste] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [hipaaTemplate, setHipaaTemplate] = useState([]);
  const [unknownPatient, setUnknownPatient] = useState(false);
  const [loadingConversationDetails, setLoadingConversationDetails] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);

  const [messagesList, setMessagesList] = useState([]);
  const [messages, setMessages] = useState({messages: []});
  const [patientResultAddStatus, setPatientResultAddStatus] = useState();
  const patientResultAddStatusConst = {
    DEFAULT: 'default',
    PENDING: 'pending',
    ADDED: 'added',
    DENY: 'deny'
  }
  const patientResultAddStatusTipConst = {
    DEFAULT: '',
    PENDING: 'Finding Patient',
    ADDED: 'Patient Added',
    DENY: 'Patient has opted-out of SMS',
    INVALID : 'The number is malformed'  
  }

  const isLocationAware = LocationService.getLocationAware();
  const isMMSEnabled = LocationService.getMMSEnabled();
//  let appBarHeight = 64;
  let messageHolderHeight = 0;
  let windowHeightAdjustment = 397;
  let searchTermInterval = 1000;
  let phoneNumberMaxLength = 12;
  const conversationList = useRef(null);

  let messageListScrollHeight = {
    height: windowHeight - windowHeightAdjustment +'px',
    minHeight: windowHeight - windowHeightAdjustment +'px'
  }

  if (windowWidth < 1276 && windowWidth > 675) {
    messageListScrollHeight  = {
      height: windowHeight - windowHeightAdjustment  - headerHeight + 'px',
      minHeight: windowHeight - windowHeightAdjustment - headerHeight + 'px'
    }
  }

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleWindowClick = (e) => {
    if (document) {
      if (document.querySelectorAll('.message--search--results')) {
        if (document.querySelectorAll('.message--search--results')[0] !== undefined) {
          if (e.target.classList.contains('message--conversation')) {
            document.querySelectorAll('.message--search--results')[0].style.display = 'none';
            document.querySelectorAll('.message--search--status')[0].style.opacity = 'none';
          }
        }
      }
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    setHeaderHeight(ref.current.clientHeight-170);
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('click', handleWindowClick);
    document.addEventListener('visibilitychange', function (ev) {
  });
  }, [windowWidth])

  const addButtonText = showSearchPatients ? "Close" : "Add another patient" 
  const toggleAddPatients = () => {
    setShowSearchPatients(!showSearchPatients)
  }

  const conversationClose = () => {
    if (document) {
      if (document.getElementsByClassName(' message--conversation--holder')[0]) {
        document.getElementsByClassName(' message--conversation--holder')[0].classList.remove('message--conversation--active')
        handelResetMessages()
      }
    }
  }
  const handleRefreshConversationAfterSend = () => {
  }

  const handleRefreshConversationListAfterSend = () => {
    refreshList()
  }

  const handelResetMessages = () => {
    setConversation([])
    setNoPatients(false)
    setSmsToList([]);
    setNoPatients(false)
    setPatientSearchResults('')
    handleClear();
  }

  const handlePatientIdChange = (e) => {
    const val = e.target.value = e.target.value.replace(/<.*>/, '<>');
    setSearchPatientId(val);
  }
  const handleFirstNameChange = (e) => {
    const val = e.target.value = e.target.value.replace(/<.*>/, '<>');
    setSearchFirstName(val);
  }

  const handleLastNameChange = (e) => {
    const val = e.target.value = e.target.value.replace(/<.*>/, '<>');
    setSearchLastName(val);
  }
  const handlePhoneChange = (e) => {
    let val = isOnPaste ? searchPhoneNumber : e.target.value = e.target.value.replace(/<.*>/, '<>');
    val = val.replace(/-/g, '');
    if (val.length >= 10) {
      val = Utils.formatPhoneNumberOnlyDashes(val);
    }
    setIsOnPaste(false)
    setSearchPhoneNumber(val);
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsOnPaste(false)
      checkAddNewPatient();
    }
  }

  const onPaste = (event) => {
    var val = event.clipboardData.getData('text/plain')
    if (val !== "")
    {
      var numb = val.match(/\d/g);
      numb = numb.join("");

      if (numb.length > 10) {
        numb = numb.slice(-10);
      }

      setIsOnPaste(true)
      setSearchPhoneNumber(numb)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchChange()
    }, searchTermInterval);
    return () => clearTimeout(timer);
  }, [searchFirstName, searchLastName, searchPhoneNumber, searchPatientId]);

  const handleClear = () => {
    setIsMobileSearchResultsVisible(false)
    setNoPatients(false)
    setPatientSearchResults('')
    setSearchFirstName('');
    setSearchLastName('');
    setSearchPhoneNumber('');
    setSearchPatientId('');
  }

  const displayAddToSmsStatus = (elementId, toggle) => {
    const ele = document.getElementById('patientResultItem_' + elementId);
    const eleAll = document.querySelectorAll('.patientResultItem');

    if (eleAll) {
      eleAll.forEach((item) => {
        item.style.opacity = 0;
      })
    }

    if (ele) {
      if (toggle) {
        ele.style.opacity = 1;
      } else {
        ele.style.opacity = 0;
      }
    }
    if (!toggle) {
      setPatientResultAddStatus(patientResultAddStatusConst.DEFAULT)
    }

  }

  const handleAddToSmsList = (_patient, elementId = null) => {
    if (smsToList.length >= 1) {
      toggleAddPatients()
    }
    displayAddToSmsStatus(elementId, true);
    setPatientResultAddStatus(patientResultAddStatusConst.PENDING)
    let canAddPatient = true;

    if (!_patient.cellPhone) {
      canAddPatient = false;
      callTwoWayModalNoCellPhone.current.setAlert()
    }

    if (smsToList) {
      smsToList.map((item) => {
        if (item.cellPhone === _patient.cellPhone) {
          canAddPatient = false;
        }
      })
    }

    if (canAddPatient) {
      if (smsToList.length < 10) {
        checkForExistingConversation(_patient, elementId);

      } else {
        callTwoWayModalMaxPatientError.current.setAlert()
      }
    } else {
      setPatientResultAddStatus(patientResultAddStatusConst.DENY)
      displayAddToSmsStatus(elementId, true);
    }

  }

  const updateShowMessages = () => {
    setShowMessageList(false)
    if(smsToList){
      if(smsToList.length === 1){
        if(messages) {
          if(messages.hasOwnProperty('Messages')) {
            if(messages.Messages.length >= 1) {
              setShowMessageList(true)
            }
          }

        }
      }
    }
  }
  const checkForExistingConversation = (_patient, elementId, addToSms = true, askForHipaaConsent = true) => {
    setLoadingConversationDetails(true)
    const payload = {
      SessionId: UserService.getSessionId(),
      PhoneNumber: _patient.cellPhone,
      LocKey: isLocationAware ? selectedLocation.id : 0,
      AddKey: _patient.addKey
    }
    TwoWayConversationService.getConversation(payload)
      .then((resp) => {
        if (resp.data) {
          let templateInfo = TwoWayConversationTemplateService.mapTemplateInfo(resp.data)
          _patient.isSmsDisabled = resp.data.IsSMSDisabled;
          if (resp.data.hasOwnProperty('Messages') && !_patient.isSmsDisabled) {
            if (resp.data.Messages) {
              let messagesObj = TwoWayConversationService.mapMessages(resp.data);
              let tzOffset = (new Date().toString().match(/([-\+][0-9]+)\s/)[1]) * -1 / 100;
              messagesObj.messages.filter((message) => {
                message.created = subHours(new Date(message.created), tzOffset);
                return message;
              })
              
              setMessages(messagesObj);
              setPatient(_patient);
              setMessagesList(messagesList => [messagesObj, ...messagesList]);
              setUnknownPatient(
                (_patient.firstName === null && _patient.lastName === null) ||
                  (_patient.firstName === '' && _patient.lastName === '')
                  ? true
                  : false
              );
              setSendingMessage(false)
              setLoadingConversationDetails(false)

            }
          }
          
          setPatient(_patient);

          // if (
          //   !templateInfo.hipaa.consented &&
          //   askForHipaaConsent &&
          //   !_patient.isSmsDisabled
          // ) {
          //   setHipaaAskBody(
          //     'Would you like to Ask ' +
          //       _patient.firstName +
          //       ' ' +
          //       _patient.lastName +
          //       ' for HIPAA consent?'
          //   );
          //   callTwoWayModalHipaaConsent.current.setAlert();
          // }
          if(templateInfo.templates){
            if(templateInfo.templates.length) {
              setTemplates(templateInfo)
            }
          }

        }
        if (_patient.isSmsDisabled) {
          setPatientResultAddStatus(patientResultAddStatusConst.DENY)
          displayAddToSmsStatus(elementId, true);
        } else {
          if (addToSms) {
            setPatientResultAddStatus(patientResultAddStatusConst.ADDED)
            setSmsToList(patient => [_patient, ...patient]);
            displayAddToSmsStatus(elementId, true);
          }
        }
        setTimeout(() => {
          displayAddToSmsStatus(elementId, false);
          handleClear()
        }, 2400)


      })
      .catch((err) => {
        Logger.printLog('checkForExistingConversation', err, 'error');
      })
  }

  const handleRemoveSmsList = (_patient) => {
    const array = [...smsToList];
    const newList = array.filter(item => item.cellPhone !== _patient.cellPhone);
    if(newList.hasOwnProperty('length')){
      if(newList.length === 1){
        if(messagesList) {
          messagesList.map( (item) => {
            if(item.cellPhone === newList[0].cellPhone){
              setMessages(item)
            }
          })
        }
      }
    }
    setSmsToList(newList);
    if (newList.length === 1) {
      checkForExistingConversation(newList[0], 0, false)
    }
  }

  const handleSearchChange = () => {
    let isValid = false;
    let localPhoneNumber = searchPhoneNumber;

    if (searchFirstName === '' && searchLastName === '' && localPhoneNumber === '' && searchPatientId === '') {
      setNoPatients(false)
      handleClear();
    }
    if (searchFirstName !== '' || searchLastName !== '' || localPhoneNumber !== '' || searchPatientId !== '') {
      isValid = true;
    }

    if (localPhoneNumber !== '') {
      if (localPhoneNumber.length !== phoneNumberMaxLength) {
        isValid = false;
        localPhoneNumber = '';
        //setNoPatients(false)
        //setSmsToList([]);
        setNoPatients(false)
        setPatientSearchResults('')
      }
    }

    if (searchPatientId !== '') {
      setSearchFirstName('');
      setSearchLastName('');
      setSearchPhoneNumber('');
    }

    if (isValid) {
      localPhoneNumber = localPhoneNumber.replaceAll('-', '')
      const payload = {
        SessionId: UserService.getSessionId(),
        locKey: isLocationAware ? selectedLocation.id : 0,
        FirstName: searchFirstName,
        LastName: searchLastName,
        Phone: localPhoneNumber,
        PatientId: searchPatientId
      }
      getFindPatient(payload);
    }

  }

  const checkAddNewPatient = () => {
    if (searchPhoneNumber && searchFirstName && searchLastName) {
      if (searchPhoneNumber.length === phoneNumberMaxLength && searchPhoneNumber !== "000-000-0000" && searchFirstName.length > 1 && searchLastName.length > 1) {
        setNewPatientBody('Would you like to add ' + searchFirstName + ' ' + searchLastName + ' ' + searchPhoneNumber + ' as a new patient?');
        callTwoWayModalNewPatient.current.setAlert();
      }
    }
  }

  const handleSendConsent = () => {
    let canSendHipaa = false
    let hipaaTemplate = {}
    let msgTemplates = []
    if(templates && templates.templates) {
      msgTemplates = templates.templates
      if(msgTemplates.length > 0) {
        msgTemplates.map( (template) => {
          if(template.hasOwnProperty('type')){
            if(template.type.toLowerCase() === 'hipaa') {
              setHipaaTemplate(template);
              hipaaTemplate = template;
              canSendHipaa = true;
            }
          }
        })
      }
    }
    if(canSendHipaa) {
      handleHipaaSend(hipaaTemplate)
    }
  }
  const handleAddNewPatient = () => {
    const newPatient = {
      firstName: searchFirstName,
      lastName: searchLastName,
      phoneNumber: searchPhoneNumber,
      sessionId: UserService.getSessionId(),
      locKey: isLocationAware ? selectedLocation.id : 0
    }
    TwoWayConversationService.postAddNewPatient(newPatient)
      .then((resp) => {

        let patient = TwoWayConversationService.mapPatient(resp.data);
        if (patient) {
          if (patient.errors || !patient.success) {
            if (patient.errors.length >= 1) {
              callTwoWayModalNewPatientError.current.setAlert();
            }
          } else {
            if (patient.newPatient.addKey !== 0) {
              handleAddToSmsList(patient.newPatient);
            } else {
              callTwoWayModalAddKeyError.current.setAlert();
            }
          }
        }
      })
      .catch(() => {
        callTwoWayModalNewPatientError.current.setAlert();
      })
  }

  const getFindPatient = (payload) => {
    setNoPatients(false)
    setIsLoading(true);
    setPatientResultAddStatus(patientResultAddStatusConst.DEFAULT)
    TwoWayConversationService.postFindPatient(payload)
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.ListOfPatientSearch) {
          let patients = TwoWayConversationService.mapPatients(resp.data);

          if (patients) {
            setPatientSearchResults(patients.listOfPatientSearch)
            if (document.querySelectorAll('.message--search--results')[0]) {
              document.querySelectorAll('.message--search--results')[0].style.display = 'block';
            }

            if (patients.listOfPatientSearch) {
              if (patients.listOfPatientSearch.length <= 0) {
                setNoPatients(true)
                checkAddNewPatient();
              }
            }
          }
        } else {
          setNoPatients(true)
          checkAddNewPatient();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setNoPatients(false)
        Logger.printLog('postFindPatient', err, 'error');
        if (callTwoWayModalFindPatientError.current) {
          callTwoWayModalFindPatientError.current.setAlert();
        }
      })
  }

  const handleSmsToListComplete = () => {
    handleClear();
    setSmsToList([]);
  }

  const handelCloseResults = () => {
    setPatientSearchResults('')
  }

  useEffect(() => {
    if (smsToList.length >= environment.smsToCountMax) {
      setError(txtMaxPatientsAdded)
    } else {
      setError('');
    }
    updateShowMessages();
  }, [smsToList])


  const handleSearchOnMobile = () => {
    setIsMobileSearchResultsVisible(isMobileSearchResultsVisible => !isMobileSearchResultsVisible);
  }
  const handleShowAllOnMobile = () => {
    setShowAllOnMobile(showAllOnMobile => !showAllOnMobile);
  }

  const handleHipaaSend = (hipaa) => {
    const _pat = patient;
    const payload = {
      SessionId: UserService.getSessionId(),
      locKey: isLocationAware ? selectedLocation.id : 0,
      PMSPatientId: _pat.PMSPatientID,
      AddKey: _pat.addKey,
      Message: hipaa.body,
      SMSTo: _pat.cellPhone,
      IsTemplateMessage: true,
      TemplateType: hipaa.type,
    };
    TwoWayConversationService.postNewConversation(payload)
      .then( (resp) => { 
        handleRefreshConversationAfterSend()
        handleRefreshConversationListAfterSend()
        checkForExistingConversation(patient, 0, false, false)
      })
      .catch( (err) => {
        Logger.printLog('handleHipaaSend', payload, 'error');
      })
  }

  useEffect(() => {
    if (Utils.getAgent() === 'iOS' || Utils.getAgent() === 'android') {
      setIsMobile(true);
      windowHeightAdjustment = 200;
      // setMessageListScrollHeight({height: windowHeight - windowHeightAdjustment + 'px', minHeight: windowHeight - windowHeightAdjustment + 'px',  maxHeight: '50px', overflow: 'hidden'})
    }
  }, [])

  useEffect(() => {
    if (smsToList.length > 0) {
      messageHolderHeight = 400;
      windowHeight = windowHeight + messageHolderHeight;
    }
  }, [smsToList])
  return (
    <>
      <TwoWayModal ref={callTwoWayModalNewPatient} heading='New Patient' body={newPatientBody} button1='Cancel'
                   button2='Yes' conversationDelete={handleAddNewPatient}/>
      <TwoWayModal ref={callTwoWayModalHipaaConsent} heading='HIPAA Compliance' body={hipaaAskBody} button1='Not now'
                   button2='Yes Send' conversationDelete={handleSendConsent}/>
      <TwoWayModal ref={callTwoWayModalNewPatientError} heading='Unable to add new patient'
                   body='Currently unable to add any new patients. Please try again later.' button1='' button2='Ok'/>
      <TwoWayModal ref={callTwoWayModalMaxPatientError} heading='Messages Limit'
                   body='Only 10 patients can be sent a message at once.' button1='' button2='Ok'/>
      <TwoWayModal ref={callTwoWayModalNoCellPhone} heading='Cell Phone Needed'
                   body='This patient does not have a cell phone.' button1='' button2='Ok'/>
      <TwoWayModal ref={callTwoWayModalAddKeyError} heading='Unable to add patient'
                   body='Currently unable to add this patients. Please try a different patient.' button1=''
                   button2='Ok'/>
      <TwoWayModal ref={callTwoWayModalFindPatientError} heading='Unable to send a message'
                   body='Currently unable to send any message. Please try again later.' button1='' button2='Ok'/>
      <TwoWayModal ref={callTwoWayModalSendComplete} heading='Message sent' body='Message successfully sent' button1=''
                   button2='Ok'/>

      <div className='message--conversation--holder message--conversation--holder-new'>
        {(isMobile && showAllOnMobile) && <span><br/></span>}
        {!showAllOnMobile &&
        <div className='message--conversation message--conversation--header-new' ref={ref}>
          <div className='row'>
            <a className='btn-back' onClick={conversationClose}><FiChevronDown/></a>
            <h1>Create a new message</h1>
            {selectedLocation && <span className='message--location-badge'>
                <p>{selectedLocation.name}</p>
              </span>}
          </div>
          {(!isMobile || (isMobile && smsToList.length === 0 )) &&

          <p>Find patient(s) by searching name, phone number or patient ID. You can add one or multiple patients to a
            new message.</p>
          } 
          { isMobile && smsToList.length > 0 && 
            <div className='row'> <Button variant="outlined" color={'primary'} className='btn-clear' onClick={ () => toggleAddPatients() }>{addButtonText}</Button> </div> 
          }

          {((isMobile && smsToList.length === 0) || (isMobile && showSearchPatients) || !isMobile ) &&
          <div className='row row__start '>
            <input autoComplete='false' value={searchPatientId} onChange={handlePatientIdChange}
                   className='message--search--input' type='text' placeholder='Patient ID' maxLength='20'/>

            <input disabled={searchPatientId !== ''} autoComplete='false' value={searchFirstName}
                   onChange={handleFirstNameChange} onKeyPress={handleKeyPress} className='message--search--input'
                   type='text' placeholder='First Name' maxLength='20'/>
            <input disabled={searchPatientId !== ''} autoComplete='false' value={searchLastName}
                   onChange={handleLastNameChange} onKeyPress={handleKeyPress} className='message--search--input'
                   type='text' placeholder='Last Name' maxLength='20'/>
            <input disabled={searchPatientId !== ''} autoComplete='false' value={searchPhoneNumber}
                   onChange={handlePhoneChange} onKeyPress={handleKeyPress} className='message--search--input'
                   type='text' placeholder='xxx-xxx-xxxx' maxLength={phoneNumberMaxLength} onPaste={onPaste}/>
            {(isMobile && patientSearchResults.length >= 1) &&
            <Button variant="outlined" color={'primary'} className='btn-clear' onClick={handleSearchOnMobile}>show
              results</Button>}

            {(searchFirstName || searchLastName || searchPhoneNumber || searchPatientId) &&
            <Button variant="outlined" color={'primary'} className='btn-clear' onClick={handleClear}>clear</Button>}
          </div>
          }

          {(patientSearchResults.length >= 1 && (!isMobile || isMobileSearchResultsVisible)) &&
          <div className='message--search--results'>
            <div className='btn-close' onClick={handelCloseResults}><FiX/></div>
            <p>Select patient(s) to send a message</p>
            {patientSearchResults.map((patient, key) => {
              return <div key={key} className='row row__start patientResultItem--holder'
                          onClick={(event) => patient.isValidPhone ? handleAddToSmsList(patient, key) : null}>
                <div id={'patientResultItem_' + key} className={`patientResultItem ${patientResultAddStatus}`}>
                  {(patientResultAddStatus === patientResultAddStatusConst.PENDING) &&
                  <Tooltip title={patientResultAddStatusTipConst.DEFAULT}>
                  <span className='message--conversation--refresher--spinner'><Spinner/></span>
                  </Tooltip>}

                  {(patientResultAddStatus === patientResultAddStatusConst.DENY) &&
                  <Tooltip title={patientResultAddStatusTipConst.DENY}>
                  <span className='message--conversation--deny'><FiSlash/></span>
                  </Tooltip>}

                  {(patientResultAddStatus === patientResultAddStatusConst.ADDED) &&
                  <Tooltip title={patientResultAddStatusTipConst.ADDED}>
                  <span className='message--conversation--added'><FiCheckCircle/></span>
                  </Tooltip>}

                </div>
                <div>{patient.firstName} {patient.lastName}</div>
                {!patient.isValidPhone && 
                  <Tooltip title={patientResultAddStatusTipConst.INVALID}>
                    <div className='message--conversation--invalid'>{`${patient.cellPhone} !`}</div>
                  </Tooltip>}
                {patient.isValidPhone && <div>{Utils.formatPhoneNumber(patient.cellPhone)}</div>} 
              </div>
            })}
          </div>}
          <span className='message--search--status'>
            {noPatients && <>Sorry no patients were found for
              "{searchPatientId} {searchFirstName} {searchLastName} {searchPhoneNumber.length === phoneNumberMaxLength && searchPhoneNumber}"</>}
            {(noPatients && searchPhoneNumber && (!searchLastName || !searchFirstName)) && <> To add a new patient enter
              a first, last, and phone number</>}
            {isLoading && <>Searching for
              "{searchPatientId} {searchFirstName} {searchLastName} {searchPhoneNumber.length === phoneNumberMaxLength && searchPhoneNumber}" <span
                className='message--conversation--refresher--spinner'><Spinner/></span></>}
            {(!isLoading && !noPatients && patientSearchResults.length >= 1) && <>{patientSearchResults.length} Results
              for
              "{searchPatientId} {searchFirstName} {searchLastName} {(patientSearchResults.length >= 1 && searchPhoneNumber.length === phoneNumberMaxLength) && searchPhoneNumber}"</>}
          </span>
        </div>
        }

        {(showMessageList && smsToList) &&
          <>
            <div className='message--conversation--patient'>
              <strong>{smsToList[0]?.firstName} {smsToList[0]?.lastName}</strong>
              <Tooltip title='Remove Patient'>
                <span className='message--conversation--patient--btn-remove cursor' onClick={(event) => handleRemoveSmsList(smsToList[0])}><FiXCircle/></span>
              </Tooltip>
              <p>{Utils.formatPhoneNumber(smsToList[0]?.cellPhone)}</p>
            </div>
            <TwoWayConversationMessages
              conversationList={conversationList}
              messages={messages}
              conversation={conversation}
              patient={ { ...smsToList[0], "smsTo": smsToList[0]?.cellPhone }}
              windowHeight={windowHeight}
              handleRefreshConversationAfterSend={handleRefreshConversationAfterSend}
              handleRefreshConversationListAfterSend={handleRefreshConversationListAfterSend}
              unknownPatient={unknownPatient}
              loadingConversationDetails={loadingConversationDetails}
              setSendingMessage={setSendingMessage}
              newMessage={true}
            />
          </>
        }


        {(!showMessageList) &&
        <div className='message--conversation' style={messageListScrollHeight}>
            <div className='row row__start message--search--smsTo--holder'>
              {smsToList.map((patient, key) => {
                return <div key={key}>
                  <div className='message--search--smsTo'>
                    <div>{patient.firstName} {patient.lastName} {Utils.formatPhoneNumber(patient.cellPhone)}</div>
                    <Tooltip title='Remove patient from message.'>
                    <span onClick={(event) => handleRemoveSmsList(patient)}><FiXCircle/></span>
                    </Tooltip>
                  </div>
                </div>
              })}
            </div>
            <div className='message--search--smsTo-error-holder'>
              {error &&
              <span className='txt--small conversation-input-error txt--alert'><FiAlertTriangle/> {error}</span>}
            </div>
          </div>
        }

        <span
          className='txt--small m--l--sm m--b--sm message--search--notify--counts'>{smsToList.length >= 1 && <>{smsToList.length} patient(s)
          will receive this message
          {(isMobile && smsToList.length >= 2 && !showAllOnMobile) &&
          <Button variant="outlined" color={'primary'} className='btn-showall' onClick={handleShowAllOnMobile}>show
            more</Button>}
          {(isMobile && smsToList.length >= 2 && showAllOnMobile) &&
          <Button variant="outlined" color={'primary'} className='btn-showall' onClick={handleShowAllOnMobile}>show
            less</Button>}

        </>}</span>

        <TwoWayMessageInput isLocationAware={isLocationAware}
                            smsToListComplete={handleSmsToListComplete} newMessage='true' smsToList={smsToList}
                            conversation={conversation}
                            selectedLocation={selectedLocation}
                            refreshConversationAfterSend={handleRefreshConversationAfterSend}
                            refreshConversationListAfterSend={handleRefreshConversationListAfterSend}
                            hasFiles={hasFiles}
                            setHasFiles={setHasFiles}
                            isMMSEnabled={isMMSEnabled}
                            messageTemplates={templates}
        />


      </div>
    </>
  )
};

export default TwoWayConversationNew;