import React, {forwardRef, useImperativeHandle, useState} from 'react';
import './TwoWayMultiRecipientModal.scss'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import {FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight} from 'react-icons/all';
import CloseIcon from '@material-ui/icons/Close';


const TwoWayMultiRecipientModal = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    setAlert(e) {
      handleOpen();
    }
  }));
  const [open, setOpen] = useState(false);
  // const [heading, setHeading] = useState();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog className='multi-modal' open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogContent>
          <div className='icon-button'>
            <CloseIcon onClick={handleClose}/>
          </div>
          <div className='title--text' style={{padding: 'none'}}>
            <DialogTitle id="alert-dialog-title">
              <div className='heading--text'>
                {props.heading}
              </div>
            </DialogTitle>
            <div className='icon--position'>
              <GroupAddIcon/>
            </div>
            <div>
              <DialogContentText id="alert-dialog-description">
                {props.body}
              </DialogContentText>
            </div>
          </div>
          <div className='text--field--inputs' style={{display: 'flex'}}>
            <div className='text--inputs'>
              <span>First Name</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="First Name"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='text--inputs'>
              <span>Last Name</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="Last Name"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='phone--option'> OR</div>
            <div className='text--inputs'>
              <span>Phone Number</span>
              <TextField
                className='text--size'
                id="outlined-helperText"
                // label="Number"
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='search--button'>
              <Button variant='contained' onClick={handleClose} color="primary">
                {props.button1}
              </Button>
            </div>
          </div>
          <div className='recipients--list' style={{display: 'flex'}}>
            <div className='text--inputs'>
              <span> Available Recipients</span>
              <TextField
                className='recipient--box'
                id="outlined-helperText"
                multiline
                rows={5}
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
            <div className='button--group'>
              <Button variant="outlined">
                <FiChevronRight/>
              </Button>
              <Button variant="outlined">
                <FiChevronsRight/>
              </Button>
              <Button variant="outlined">
                <FiChevronLeft/>
              </Button>
              <Button variant="outlined">
                <FiChevronsLeft/>
              </Button>
            </div>
            <div className='text--inputs'>
              <span>Recipient List</span>
              <TextField
                className='recipient--box'
                id="outlined-helperText"
                multiline
                rows={5}
                defaultValue=""
                variant="outlined"
                size='small'
              />
            </div>
          </div>

          <div>
            {/*<TwoWayMessageInput />*/}
          </div>
          {/* <div style={{display: "flex", paddingBottom: "10px" }}>
              <div className='text--inputs'>
                <span>Message</span>
                <br />
                <TextField
                  className='message--box'
                  id="outlined-helperText"
                  multiline
                  rows={5}
                  defaultValue=""
                  variant="outlined"
                  size='small'
                />
              </div> 
              <div>
                <Button className='submit--button' variant="contained" onClick={handleClose} color="primary" autoFocus>
                  {props.button2}
                </Button>
              </div>
            </div> */}
        </DialogContent>


      </Dialog>

    </>
  );
})

export default TwoWayMultiRecipientModal;