import React from 'react';
import './CustomerInformation.scss';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import CustomerInformationAppointmentDetails
  from '../CustomerInformationAppointmentDetails/CustomerInformationAppointmentDetails';
import CustomerInformationPatientDetails from '../CustomerInformationPatientDetails/CustomerInformationPatientDetails';
import CustomerInformationPatientHistory from '../CustomerInformationPatientHistory/CustomerInformationPatientHistory';
import CustomerInformationPatientAttendance
  from '../CustomerInformationPatientAttendance/CustomerInformationPatientAttendance';
// import CustomerInformationValueScore from "../CustomerInformationValueScore/CustomerInformationValueScore";
// import CustomerInformationValueScoreCircularProgress from "../CustomerInformationValueScoreCircularProgress/CustomerInformationValueScoreCircularProgress";

const CustomerInformation = (props) => {
  const {
    data,
  } = props;


  const items = [];
  for (const [index, value] of data.entries()) {
    items.push(
      <>
        <Grid container direction="row" justify="flex-start" key={value.VwAppointmentID}>

          <CustomerInformationAppointmentDetails data={value.StartTime} status={value.ApptStatus.toLowerCase()}/>

          <Divider orientation="vertical" flexItem/>

          <CustomerInformationPatientDetails customerName={value.FirstName + ' ' + value.LastName}
                                             serviceType={value.ExamType} serviceDuration={value.Length}
                                             serviceProvider={value.ProviderFirstName + ' ' + value.ProviderLastName}/>

          <Divider orientation="vertical" flexItem className="divider--blue"/>

          <CustomerInformationPatientHistory lastVisitDate={value.LastVisitOrExamDate} lastExamType={value.LastExamType}
                                             knownPurchases={value.KnownPurchases}/>

          <Divider orientation="vertical" flexItem className="divider--blue"/>

          <CustomerInformationPatientAttendance visitOccurrencePerYear={value.VisitOccurrence}
                                                missedOrCancelledThisYear={value.MissedOrCancelled}
                                                missedOrCancelledTotal={value.MissedOrCancelledTotal}/>

          {/* <Divider orientation="vertical" flexItem className="divider--blue"/> */}

          {/* <CustomerInformationValueScore lensSelectionValue={value.ValueScore.LensSelectionValue} frameSelectionValue={value.ValueScore.FrameSelectionValue}
                                           brandsPurchased={value.ValueScore.BrandsPurchased} framesPurchased={value.ValueScore.framesPurchased}
                                           lensPurchased={value.ValueScore.LensPurchased} />
                                           
            <Divider orientation="vertical" flexItem className="divider--blue"/>

            <CustomerInformationValueScoreCircularProgress variant="determinate" score={value.Score} /> */}


        </Grid>

        <Divider component="li" className="row--divider" key={index}/>

      </>
    )
  }

  return (
    <React.Fragment>
      <Box bgcolor="background.paper">
        <div className='div-customer-information'>
          {items}
        </div>
      </Box>
    </React.Fragment>
  );

}

export default CustomerInformation;