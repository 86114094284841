import Crypto from 'crypto-js';
import Constants from '../constants';
import Utils from '../utils';

const encryptString = (stringToEncrypt) => {
  return Crypto.AES.encrypt(stringToEncrypt, process.env.REACT_APP_ENCRYPTION_KEY).toString();
}

const decryptString = (stringToDecrypt) => {
  var key = Crypto.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  var iv  = Crypto.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);


  //Decode from text    
  var cipherParams = Crypto.lib.CipherParams.create({
      ciphertext: Crypto.enc.Base64.parse(stringToDecrypt)
  });
  var decryptedFromText = Crypto.AES.decrypt(cipherParams, key, { iv: iv});
  return decryptedFromText.toString(Crypto.enc.Utf8);
}

export default {
  encryptString,
  decryptString
};