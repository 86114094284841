import React, { useState,useEffect } from "react";
import { getCoverKey, getJwt } from "./ReportsUtils";
import {
  useApptReports,
  getPreApptTypes,
  getApptCommMethods,
  PageTitle,
  Loading,
  LoadingError,
  ContentContainer,
  ApptFilters,
  ApptActions,
  PreApptTable,
  Pagination,
  ApptApi,
  ApptSort,
  PreApptType,
} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import "./AppointmentsReports.scss";
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const PAGE_LIMIT = 10;

const RemindersPage = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    type: PreApptType.MSG,
    commMethods: [],
    providers: [],
    locations: [],
  });

  const coverKey = getCoverKey();
  const authorization = getJwt();
  const [allowRemindersMenu] = useState(UserService.checkElementAccess(Constants.remindersMenu));

  const [apiBody, setApiBody] = useState({
    reportType: PreApptType.MSG,
    locKeys: [],
    acctKeys: [],
    commMethods: [],
    pageSize: PAGE_LIMIT,
    pageStart: 1,
    beginDate: moment().startOf("day").format(),
    endDate: moment().endOf("day").format(),
    searchPhrase: "",
    sortColumn: "",
    sortOrder: ApptSort.NOT_SORTED,
  });

  const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

  const {
    dates,
    locations,
    providers,
    loadingFilters,
    errorFilters,
    columns,
    apptData,
    loadingData,
    errorData,
    FiltersProps,
    setDateState,
  } = useApptReports(
    coverKey,
    authorization,
    ApptApi.PRE_APPOINTMENTS,
    apiBody
  );

  const handleShowReport = (date = "") => {
    const startDate = date || dates.from;
    const endDate = date || dates.to;
    setApiBody({
      ...apiBody,
      locKeys: filters.locations.map((item) => parseInt(item)),
      acctKeys: filters.providers.map((item) => parseInt(item)),
      commMethods: filters.commMethods?.map((item) => item) || [],
      reportType: filters.type || PreApptType.MSG,
      beginDate: moment(startDate || moment().startOf("day")).format(),
      endDate: moment(endDate || moment().endOf("day")).format(),
    });
  };

  const handleQuickReport = () => {
    const date = moment().subtract(1, "day").format("YYYY-MM-DD");
    setDateState({ from: date, to: date });
    handleShowReport(date);
  };

  const AUTH_AVAILABLE = coverKey && authorization;
  const typeFilter = getPreApptTypes();

  useEffect( async() => {    
      UserService.checkUser();
  },[]);

  return (
    <>
      {
      allowRemindersMenu ?
      <div className="appt-reports-page-wrapper">
        <div className="web-appointments-wrapper">
          <div className="page-picker-containers">
            <PageTitle title="Reminders / Pre-Appointments" />
          </div>
          <ContentContainer>
            {coverKey && locations && providers && (
              <>
                <ApptFilters
                  {...FiltersProps}
                  filters={filters}
                  types={typeFilter}
                  commMethods={getApptCommMethods()}
                  locations={locations}
                  providers={providers}
                  setFilters={handleChange}
                  handleShowReport={handleShowReport}
                  handleQuickReport={handleQuickReport}
                />
                <div className="web-appointments-results">
                  <ApptActions
                    search={apiBody.searchPhrase}
                    setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                    coverKey={coverKey}
                    authorization={authorization}
                    apiName={ApptApi.PRE_APPOINTMENTS}
                    body={apiBody}
                  />
                  <div className="web-appointments-overflow">
                    <PreApptTable
                      res={apptData?.pagedItems}
                      loading={loadingData}
                      columns={columns}
                      error={errorData}
                    />
                  </div>
                  <Pagination
                    page={page}
                    setPage={(p) => {
                      setPage(p);
                      setApiBody({
                        ...apiBody,
                        pageStart: p,
                      });
                    }}
                    total={apptData?.totalItemCount}
                    pageSize={PAGE_LIMIT}
                  />
                </div>
              </>
            )}
            {AUTH_AVAILABLE && loadingFilters && <Loading full />}
            {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
              <LoadingError />
            )}
          </ContentContainer>
        </div>
      </div>
      :
      <div className="loading-error undefined">
        Not enough user rights
      </div>
      }    
    </>
  );
};

export default RemindersPage;
