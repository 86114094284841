import React, { useEffect } from 'react';
import {PageTitle, WEB_APPT_TABS,TabsList,Tab} from "@essilorditac/customer-dash-ui";
import {WebAppointments} from "./WebAppointments";
import {WebScheduled} from "./WebScheduled";
import UserService from '../../Store/UserService';
import Constants from '../../constants';


const AppointmentsPage = () => {
    const [tab, setTab] = React.useState(0);
    const [allowAppointmentsMenu] = React.useState(UserService.checkElementAccess(Constants.appointmentsMenu));

    useEffect( async() => {    
        UserService.checkUser();
    },[]);

    return(
    <>
        {allowAppointmentsMenu ?
        <div className='appt-reports-page-wrapper appointments-page'>
            <div className="web-appointments-wrapper">
                <div className="page-picker-containers">
                    <PageTitle title="Appointments" />
                </div>
            <TabsList
                tabs={WEB_APPT_TABS}
                selected={tab}
                setTab={setTab}
            >
                <Tab index={0} selected={tab}>

                <WebAppointments />
                </Tab>
                <Tab index={1} selected={tab}>
                    <WebScheduled />
                </Tab>
            </TabsList>
            </div>
        </div>
        :
        <div className="loading-error undefined">
          Not enough user rights
        </div>
        }
    </>
    )
}

export default AppointmentsPage;