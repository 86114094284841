import axios from 'axios';
import {format} from 'date-fns';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Constants from '../../constants';
import "./CustomerInformationPage.scss";
import CustomerInformation from '../../Components/CustomerInformation/CustomerInformation';
import Overlay from '../../Components/Overlay/Overlay';
import Seo from '../../Components/SEO/Seo';
import Storage from '../../storage';
import Utils from '../../utils';

import Box from '@material-ui/core/Box';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
//import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LensIcon from '@material-ui/icons/Lens';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};


class CustomerInformationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appointmentData : [],
      appointmentDate: new Date(),
      hasData: false,
      redirectToLogin: false,
      showLoadingOverlay: false,
      openSnackBar : false,
      SnackbarMessage: '',
      errorSeverity : 'success',
      userRole:''
    };
  };

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);
    let redirect = false;
    if (user && user.jwt) {
      try {
        const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);
        const jwtData = Utils.decodeJwt(user.jwt);
        this.setState(() => ({ userRole: jwtData.role }));
      }
      catch {
        this.setState(() => ({
          redirectToLogin: true
        }));
        redirect = true;
      }
    }
    else {
      this.setState(() => ({ redirectToLogin: true }));
      redirect = true;
    }

    if (!redirect) {
      const dateNow = format(new Date(), 'yyyy-LL-dd');
      await this._getCustomerInformation(dateNow);
    }

  }

  _handleDateChange = (date) => {
    this.setState(() => ({ appointmentDate: date }));
    this._getCustomerInformation(format(date, 'yyyy-LL-dd'));
  };


  _getCustomerInformation = async (date) => {
    this.setState(() => ({ showLoadingOverlay: true }));
    try {
      const url = `${process.env.REACT_APP_CUSTOMER_INFORMATION_API}/getAppointments?appointmentDate=${date}`;
      let res = await axios.get(url);

      if (res?.data.length > 0) {
        this.setState(() => ({ appointmentData: res.data, hasData: true }));
      } else {
        this.setState(() => ({ appointmentData: [], hasData: false }));
      }
    } catch {
      this.setState(() => ({ errorSeverity: 'error', openSnackBar: true, SnackbarMessage: 'Unexpected error in getting appointments.'}));
    }
    this.setState(() => ({ showLoadingOverlay: false }));
  }

  /**
   * Renders the component.
   */
  render() {
    const {
      redirectToLogin,
      appointmentData,
      appointmentDate,
      hasData,
      showLoadingOverlay,
      openSnackBar,
      SnackbarMessage,
      errorSeverity
    } = this.state;

    if (redirectToLogin) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    let minDt = new Date();
    minDt.setDate(minDt.getDate() - 365);
    let maxDt = new Date();
    maxDt.setDate(maxDt.getDate() + 365);

    const dateNow = format(new Date(), 'yyyy-LL-dd');
    const apptDate = format(appointmentDate, 'yyyy-LL-dd');
    const futureAppt = (dateNow < apptDate);

    let headerDescription = "Today's Appointments"

    if (futureAppt) {
      headerDescription = "Future Appointments"
    } else if (apptDate < dateNow) {
      headerDescription = "Past Appointments"
    }

    const theme = createMuiTheme({
      typography: {
        fontFamily: [
          ' "Open Sans"',
          ' sans-serif'
        ].join(',')
      },
    });

    return (
      <>
        <Seo title='4PC Customer Information'/>

        <Box p={2} width="85%">
          <div className="div-customer-information-header">
            <Box mt={4} px={2} pt={2} pb={1} bgcolor="background.paper">

              <Grid container wrap="nowrap" direction="row" justify="space-between"  alignItems="center">
                <Grid item xs={6} sm={4}>
                  <MuiThemeProvider theme={theme}>
                    <Typography noWrap variant="h6" align="left">
                      {headerDescription}
                    </Typography>
                  </MuiThemeProvider>
                  { futureAppt ? null :
                    <div className="noshow--cancelled">
                      <LensIcon fontSize='small' className="noshow--icon"/> <span className="span_no_show">No Show</span>
                      <LensIcon fontSize='small' className="cancelled--icon"/> <span className="span_cancelled">Cancelled</span>
                    </div>
                  }
                </Grid>

{/*                 <Grid item xs={6} sm={4} id="grid-datepicker">
                  <Typography noWrap align="center" id="spanAppointmentDate">Appointment Date: </Typography>
                  <div className="appointmentDatePicker">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoComplete="off"
                        margin="none"
                        id="date-dialog"
                        format="MM/dd/yyyy"
                        value={appointmentDate}
                        maxDate={maxDt}
                        minDate={minDt}
                        onChange={e=>this._handleDateChange(e)}
                        InputProps={{
                          endAdornment: (
                            <Icon>
                              today
                            </Icon>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid> */}
              </Grid>
            </Box>

            <Divider component="li"/>
          </div>

          { hasData?
            <CustomerInformation data={appointmentData}/>
            :
            <Box p={3} bgcolor="background.paper">
              <Typography noWrap variant="h6" align="center" color="textSecondary">
                No records found.
              </Typography>
            </Box>
          }


        </Box>


        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
          <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
            {SnackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }

}
export default CustomerInformationPage;