import React, { useState,useEffect } from "react";
import { getCoverKey, getJwt } from "./ReportsUtils";
import {
  useApptReports,
  getRecallsApptTypes,
  getApptCommMethods,
  PageTitle,
  Loading,
  LoadingError,
  ContentContainer,
  ApptFilters,
  ApptActions,
  RecallsApptTable,
  Pagination,
  ApptApi,
  ApptSort,
  RecallApptType,
} from "@essilorditac/customer-dash-ui";
import moment from "moment";
import "./AppointmentsReports.scss";
import UserService from '../../Store/UserService';
import Constants from '../../constants';

const PAGE_LIMIT = 10;

const RecallDetailsPage = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    type: RecallApptType.MESSAGED,
    commMethods: [],
    providers: [],
    locations: [],
  });

  const coverKey = getCoverKey();
  const authorization = getJwt();
  const [allowrecallDetailMenu] = useState(UserService.checkElementAccess(Constants.recallDetailMenu));

  const [apiBody, setApiBody] = useState({
    locKeys: [],
    acctKeys: [],
    commMethods: [],
    reportType: RecallApptType.MESSAGED,
    pageSize: PAGE_LIMIT,
    pageStart: 1,
    beginDate: moment().startOf("day").format(),
    endDate: moment().endOf("day").format(),
    searchPhrase: "",
    sortColumn: "",
    sortOrder: ApptSort.NOT_SORTED,
  });

  const handleChange = (field, val) => setFilters({ ...filters, [field]: val });

  const {
    dates,
    locations,
    providers,
    loadingFilters,
    errorFilters,
    columns,
    apptData,
    loadingData,
    errorData,
    FiltersProps,
    setDateState,
  } = useApptReports(coverKey, authorization, ApptApi.RECALLS, apiBody);

  const handleShowReport = (start = "", end = "") => {
    const startDate = start || dates.from;
    const endDate = end || dates.to;
    setApiBody({
      ...apiBody,
      locKeys: filters.locations.map((item) => parseInt(item)),
      acctKeys: filters.providers.map((item) => parseInt(item)),
      commMethods: filters.commMethods?.map((item) => item) || [],
      reportType: filters.type || RecallApptType.MESSAGED,
      beginDate: moment(startDate || moment().startOf("day")).format(),
      endDate: moment(endDate || moment().endOf("day")).format(),
    });
  };

  const handleQuickReport = () => {
    const start = moment().subtract(1, "month").format("YYYY-MM-DD");
    const end = moment().format("YYYY-MM-DD");
    setDateState({ from: start, to: end });
    handleShowReport(start, end);
  };

  const AUTH_AVAILABLE = coverKey && authorization;
  const typeFilter = getRecallsApptTypes();

  useEffect( async() => {    
      UserService.checkUser();
  },[]);

  return (
    <>
      {allowrecallDetailMenu ?
        <div className="appt-reports-page-wrapper">
          <div className="web-appointments-wrapper">
            <div className="page-picker-containers">
              <PageTitle title="Recalls" />
            </div>
            <ContentContainer>
              {coverKey && locations && providers && (
                <>
                  <ApptFilters
                    {...FiltersProps}
                    filters={filters}
                    types={typeFilter}
                    commMethods={getApptCommMethods()}
                    locations={locations}
                    providers={providers}
                    setFilters={handleChange}
                    handleShowReport={handleShowReport}
                    handleQuickReport={handleQuickReport}
                  />
                  <div className="web-appointments-results">
                    <ApptActions
                      search={apiBody.searchPhrase}
                      setSearch={(v) => setApiBody({ ...apiBody, searchPhrase: v })}
                      coverKey={coverKey}
                      authorization={authorization}
                      apiName={ApptApi.PRE_APPOINTMENTS}
                      body={apiBody}
                    />
                    <div className="web-appointments-overflow">
                      <RecallsApptTable
                        res={apptData?.pagedItems}
                        loading={loadingData}
                        columns={columns}
                        error={errorData}
                      />
                    </div>
                    <Pagination
                      page={page}
                      setPage={(p) => {
                        setPage(p);
                        setApiBody({
                          ...apiBody,
                          pageStart: p,
                        });
                      }}
                      total={apptData?.totalItemCount}
                      pageSize={PAGE_LIMIT}
                    />
                  </div>
                </>
              )}
              {AUTH_AVAILABLE && loadingFilters && <Loading full />}
              {(!AUTH_AVAILABLE || (AUTH_AVAILABLE && errorFilters)) && (
                <LoadingError />
              )}
            </ContentContainer>
          </div>
        </div>
        :
        <div className="loading-error undefined">
          Not enough user rights
        </div>
      }
    </>
  );
};

export default RecallDetailsPage;
