import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './CustomerInformationPatientAttendance.scss';

const CustomerInformationPatientAttendance = (props) => {
  const {
    visitOccurrencePerYear,
    missedOrCancelledThisYear,
    missedOrCancelledTotal
  } = props;

  let hasTotalMissedOrCancelled = (missedOrCancelledTotal > 0) ? true : false;
  let missedOrCancelledTotalDescription = (missedOrCancelledTotal === 1) ? ' TIME TOTAL' : ' TIMES TOTAL';
  let visitOccurenceDescription;
  let missedOrCancelledThisYearDescription;

  if (visitOccurrencePerYear === 1) {
    visitOccurenceDescription = visitOccurrencePerYear + ' Time per year'
  } else if (visitOccurrencePerYear > 0 && visitOccurrencePerYear !== 1) {
    visitOccurenceDescription = visitOccurrencePerYear + ' Times per year'
  } else {
    visitOccurenceDescription = 'None'
  }

  if (missedOrCancelledThisYear === 1) {
    missedOrCancelledThisYearDescription = missedOrCancelledThisYear + ' Time this year'
  } else if (missedOrCancelledThisYear > 0 && missedOrCancelledThisYear !== 1) {
    missedOrCancelledThisYearDescription = missedOrCancelledThisYear + ' Times this year'
  } else {
    missedOrCancelledThisYearDescription = 'None'
  }

  return (
    <Grid item xs={3} sm={3}>
      <div className="div-patient-attendance">
        <Typography variant="body2" align="center">
          PATIENT ATTENDANCE
        </Typography>

        <Divider/>

        <Typography variant="body2" align="left" className="attendance-label">
          VISIT OCCURRENCE:
        </Typography>
        <Typography variant="body2" align="left" className="attendance-description">
          {visitOccurenceDescription}
        </Typography>

        <Divider variant="middle"/>

        <Typography variant="body2" align="left" className="attendance-label">
          NUMBER OF MISSED OR CANCELLED:
        </Typography>
        <Typography variant="body2" align="left" className="attendance-description">
          {missedOrCancelledThisYearDescription}
        </Typography>

        {hasTotalMissedOrCancelled ?
          <div className="attendance-missed-total">
            <Typography align="center">
              {missedOrCancelledTotal}{missedOrCancelledTotalDescription}
            </Typography>
          </div>
          : null
        }

      </div>
    </Grid>
  );
}

export default CustomerInformationPatientAttendance;