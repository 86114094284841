import React, { useState, useEffect } from 'react';
import '../PatientForm/FormStatus.scss';
import './RegistrationForms.scss';
import '@inovua/reactdatagrid-community/index.css';
import moment from 'moment/min/moment-with-locales';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Icon } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { useRequest } from '../../ContextLib/contextHooks';

const RegistrationFormsFilter = ({ _setStartDate, _setEndDate, _searchType, _searchValue, _onclick, _coverKey, _onLocKeyFilter, _currentLocKey }) => {

  const [currentSelection, setCurrentSelection] = useState(0);

  const _sdt = sessionStorage.getItem("rf-sdt");
  const _edt = sessionStorage.getItem("rf-edt")
  const sdt = moment(_sdt ? JSON.parse(_sdt) : new Date()).format("MM/DD/YYYY");
  const edt = (_edt ? moment(JSON.parse(_edt)) : moment(new Date()).add(7, 'days')).format("MM/DD/YYYY");

  const [startDate, setStartDate] = useState(sdt);
  const [endDate, setEndDate] = useState(edt);
  const [searchValue, setSearchValue] = useState("");
  const [notificationBody, setNotificationBody] = useState(null);
  const [open, setOpen] = useState(false);
  const [coverKeyOrgName, setCoverKeyOrgName] = useState("");
  const [locations, setLocations] = useState([]);
  const _filteredlocKey = sessionStorage.getItem("rf-locKey");
  const [currentLocKey, setCurrentLocKey] = useState(_filteredlocKey !== null ?_filteredlocKey: -1);
  const Request = useRequest();

  useEffect(() => {
    sessionStorage.setItem("REACT_APP_WEBSCHEDULERCONFIG_API", process.env.REACT_APP_WEBSCHEDULERCONFIG_API);

  }, []);

  const getCover = async () => {
    let d = {
      cover: null
    };

    let res = await Request.getCover({ coverKey: _coverKey });
      console.log(res);

    if (res.success) {
      d.cover = res.data;
      if (d.cover) {
        setCoverKeyOrgName(res.data.coverKey + ': ' + res.data.orgName);
      }

    }
  };

  useEffect(async () => {
    await getCover();

  }, [_coverKey]);

  const getLocations = async () => {
    let res = await Request.getLocationsByCoverKey({ coverKey: _coverKey });
    setLocations(res.data);
  };

  useEffect(async () => {
    await getLocations();

  }, [_coverKey]);

 
  const dateRange = () => {
    return (
      <div className='date-range'>
        <div className='date-range-label'>Appointment Date : </div>
        <div className='date-range-fields'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="From"
              inputVariant="outlined"
              autoComplete="off"
              margin="none"
              id="date-dialog"
              format="MM/dd/yyyy"
              value={startDate}
              size="small"
              //maxDate={maxDt}
              //minDate={minDt}
              onChange={(e) => dateStartChange(e)}
              InputProps={{
                endAdornment: (
                  <Icon>
                    today
                  </Icon>
                ),
              }}
              data-tut="reactour__appointmentdate"
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="To"
              inputVariant="outlined"
              autoComplete="off"
              margin="none"
              id="date-dialog"
              format="MM/dd/yyyy"
              value={endDate}
              size="small"
              //maxDate={maxDt}
              //minDate={minDt}
              onChange={(e) => dateEndChange(e)}
              InputProps={{
                endAdornment: (
                  <Icon>
                    today
                  </Icon>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  };

  const dateStartChange = async (date) => {
    const _date = moment(date).format('MM/DD/YYYY');
    if (moment(date).isAfter(moment(endDate))) {
      setNotificationBody("Invalid date range!")
      setOpen(true)
    }
    else {
      sessionStorage.setItem("rf-sdt", JSON.stringify(_date));
      setStartDate(_date);
      _setStartDate(_date);
    }
  }

  const dateEndChange = async (date) => {
    const _date = moment(date).format('MM/DD/YYYY');
    if (moment(date).isBefore(moment(startDate))) {
      setNotificationBody("Invalid date range!")
      setOpen(true)
    }
    else {
      sessionStorage.setItem("rf-edt", JSON.stringify(_date));
      setEndDate(_date);
      _setEndDate(_date);
    }
  };
  const locationChanged = (e) => {
    sessionStorage.setItem("rf-locKey", JSON.stringify(e.target.value));
     _onLocKeyFilter(e.target.value);
    setCurrentLocKey(e.target.value);
    _currentLocKey(e.target.value);
  };

  const selectionChange = (e) => {
    if (e.target.value === 0)
      setSearchValue("")

    setCurrentSelection(e.target.value)
    _searchType(e.target.value)
  }

  const searchChange = (e) => {
    _searchValue(e.target.value)
    setSearchValue(e.target.value)
  }

  return (
    <>
      <div className='registration-forms-filter'>
        {dateRange()}

        <div className='filterby'>
          <div className='filterby-label'>Filter By :</div>
          <TextField className='search' size="small" value={coverKeyOrgName} fullWidth label="Cover" id="id-coverkey" variant="outlined" onChange={(e) => searchChange(e)} />
          <FormControl className='select' condense fullWidth variant="outlined" size="small" style={{ width: '300px' }}>
            <InputLabel id="id">Location</InputLabel>
            <Select
              labelId="locationId"
              label="Location"
              id="locationId"
              value={currentLocKey}
              onChange={(e) => locationChanged(e)}
              renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
            >
              <option value="-1">All Locations</option>
              {locations.map((value) => (
                <option value={value.locKey} key={value.locKey}>
                  {value.locKey} - {value.orgName}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl className='select' condense fullWidth variant="outlined" size="small" style={{ width: '200px' }}>
            <InputLabel id="id">Select</InputLabel>
            <Select
              labelId="labelId"
              id="id"
              value={currentSelection}
              label="Select"
              onChange={e => selectionChange(e)}
              data-tut="reactour__filterby"
            >
              <MenuItem value={0}>-- Search All --</MenuItem>
              <MenuItem value={1}>Patient Last Name</MenuItem>
              <MenuItem value={2}>Patient Email</MenuItem>
              <MenuItem value={3}>Patient Phone</MenuItem>
            </Select>
          </FormControl>
          <TextField className='search' size="small" value={searchValue} fullWidth label="Search" id="id-textfield" variant="outlined" onChange={(e) => searchChange(e)} />
          <Button className='btn-search' title="Search" variant="outlined" onClick={_onclick} >
            <Search />&nbsp;Search
          </Button>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={2000}
        variant='error'
        onClose={() => setOpen(false)}
      ><Alert severity="error">{notificationBody}</Alert></Snackbar>

    </>
  );
};

export default RegistrationFormsFilter;