import React from 'react';
import PropTypes from 'prop-types';
import './Overlay.scss';

/**
 * Represents an overlay that can contain any content.
 * @param {Object} props The component properties.
 */
const Overlay = (props) => {
  const {
    children,
    show
  } = props;

  if (show) {
    // Prevent the user from scrolling the body while the overlay is active.
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
    return null;
  }

  return (
    <div className="overlay">
      {children}
    </div>
  );
};

// Strongly typed properties that this component will receive.
Overlay.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool
};

export default Overlay;
