import React from 'react';
import './TwoWayCard.scss';
import './TwoWayCardSkeleton.scss';

const TwoWayCardSkeleton = () => {

  return (
    <div className='card--skeletons'>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='card--holder'>
        <div className='card'>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default TwoWayCardSkeleton;