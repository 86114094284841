import React from 'react';

const DefaultFilterValues = Object.freeze({
    cover: null,
    locKey: 0,
    acctKey: 0,
    locations: [],
    providers: []
});

class ClFiltersContextObject {
    _clients = {
        valuesUpdated: {},
        filtersChanged: {}
    };
    _values = {...DefaultFilterValues};

    get Values() {
        return this._values;
    }

    ClearProviders() {
        this.SetValues({acctKey: 0});
        this.UpdateClients_FiltersChanged("provider");
    }

    SetValues(value) {
        this._values = {...this._values, ...value};
        this.UpdateClients_ValuesUpdated();
    }

    UpdateClients_ValuesUpdated() {
        Object.values(this._clients.valuesUpdated).forEach(x => {
            x(this._values);
        });
    };

    UpdateClients_FiltersChanged(source) {
        Object.values(this._clients.filtersChanged).forEach(x => {
            x(this._values, source);
        });
    };

    Subscribe_ValuesUpdated(componentId, delegate) {
        if (delegate) this._clients.valuesUpdated[componentId] = delegate;
    }

    Subscribe_FiltersChanged(componentId, delegate) {
        if (delegate) this._clients.filtersChanged[componentId] = delegate;
    }

    UnSubscribe_ValuesUpdated(componentId) {
        if (this._clients.valuesUpdated[componentId]) {
            delete this._clients.valuesUpdated[componentId];
        }
    }

    UnSubscribe_FiltersChanged(componentId) {
        if (this._clients.filtersChanged[componentId]) {
            delete this._clients.filtersChanged[componentId];
        }
    }
}

const FiltersContext = React.createContext(new ClFiltersContextObject())
export default FiltersContext;