import { useContext, useEffect, useReducer, useState } from 'react';
import FiltersContext from './CoreConsumer/filtersContext';
import UIContext, {ClUIClient} from './CoreConsumer/uiContext';
import {ClDataRequestObject} from './CoreConsumer/dataRequest';
import axios from 'axios';
import { useV5 } from './CoreConsumer/v5Contexts';

export function useUIContext(componentId) {
    const uiContext = useContext(UIContext);

    return new ClUIClient(uiContext, componentId);
}

export function useFiltersContext(componentId, filtersChangedDelegate, valuesUpdatedDelegate) {
    const context = useContext(FiltersContext);

    context.Subscribe_FiltersChanged(componentId, filtersChangedDelegate);
    context.Subscribe_ValuesUpdated(componentId, valuesUpdatedDelegate);

    useEffect(()=> {
        filtersChangedDelegate && filtersChangedDelegate(context.Values);

        return ()=> {
            context.UnSubscribe_FiltersChanged(componentId);   
            context.UnSubscribe_ValuesUpdated(componentId);     
        }
    }, []);

    return context;
}

export function useRequest() {
    const source = axios.CancelToken.source();

    const dro = new ClDataRequestObject(source.token);

    useEffect(()=> {        
        return ()=> {
            source.cancel();    
        }
    }, []);

    return dro.Request;
}

export function useV5Request(onReturn) {
    const source = axios.CancelToken.source();

    const dro = new ClDataRequestObject(source.token, onReturn);

    useEffect(()=> {        
        return ()=> {
            source.cancel();    
        }
    }, []);

    return dro.V5Request;
}

export function useResponsiveUtil() {    
    const bp = {
        sm: {
            limit: 1024,
            window: false
        },
        xs: {
            limit: 750,
            window: false
        }
    }
    const [breakPoints, setBreakPoints] = useState(bp);
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

    const getData = () => {
        breakPoints.sm.window = windowInnerWidth <= breakPoints.sm.limit;
        breakPoints.xs.window = windowInnerWidth <= breakPoints.xs.limit;
        setBreakPoints(breakPoints);
    }

    const setWidth = () => setWindowInnerWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', setWidth);
        return () => window.removeEventListener('resize', setWidth);
    }, []);

    useEffect(() => {
        getData();
    }, [windowInnerWidth]);

    return breakPoints;
}

export function useV5_With_FilterAdapter(componentId) {
    const { c, f, coverLocAcctDt, n, Request, V5Request } = useV5();
            
    const triggerUpdates = useReducer(()=>({}))[1];   
    f.triggerUpdates = triggerUpdates;
    c.triggerUpdates = triggerUpdates;

    const filtersContext = useFiltersContext(componentId, async (context, source) => {                
        if (!context.cover) return;
        f.covers = [context.cover];
        f.locations = context.locations;
        f.providers = context.providers;        
        f.coverKey = context.cover.coverKey;
        f.locKey = context.locKey;
        f.acctKey = context.acctKey;

        f.triggerUpdates();

        if (source == "location") {
            filtersContext.ClearProviders();
        }
    });

    return {
        c: c,
        f: f,
        coverLocAcctDt: coverLocAcctDt,
        n: n,
        Request: Request,
        V5Request: V5Request
    };
}