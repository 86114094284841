import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useFiltersContext, useRequest, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { objectsAreEqual } from '../../../../ContextLib/Core/coreLib';

import "./General.scss";
import Config from "./general-config.json";
import General_Info from './General_Info';
import { useTour } from '@reactour/tour'
import TourSteps from '../../../../Store/TourSteps'

export default function General() {
  var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');

  const { setIsOpen, setCurrentStep ,setSteps } = useTour();

    const componentId = "general";
    const ui = useUIContext(componentId);

    const filtersContext = useFiltersContext(componentId, async (context) => {
        //On filters changed
        setLocKey(context.locKey);
        setAcctKey(context.acctKey);
        setFiltersReady(coverKey != 0);
      });

    const [locKey, setLocKey] = useState(filtersContext.Values.locKey);
    const [acctKey, setAcctKey] =  useState(filtersContext.Values.acctKey);

    const Request = useRequest(); 

    const confAndFilters = () => {  
      return {...Config.fields,
        coverKey: coverKey,
        locKey: locKey,
        acctKey: acctKey};
    };
    
    let p = confAndFilters();
  
    const [rec, setRec] = useState(p);
    const [recBak, setRecBak] = useState(p);
    const [inDB, setInDB] = useState(false);
    const [inDBBak, setInDBBak] = useState(false);
    const [isDefaultConfig, setIsDefaultConfig] = useState(true);
    const [notify, setNotify] = useState("");
    const [filtersReady, setFiltersReady] = useState(false);
  
    const [mobileImage, setMobileImage] = useState();
    const [desktopImage, setDesktopImage] = useState();
    const [profileImage, setProfileImage] = useState();
  
    const [endingMsg, setEndingMsg] = useState(p.endingMesg);
  

   // const [isTourOpen, setIsTourOpen] = useState(false);

    const isValidEmailAddress = (emailAddress) => {
      if (!emailAddress) return true;
  
      const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
        "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
        "[a-zA-Z0-9])?)*$");
      return emailAddressRegExp.test(emailAddress);
    };
    
    const deleteImage = async (type) => {      
      await Request.deleteAllFile({
        coverKey: coverKey, 
        locKey: locKey, 
        acctKey: acctKey,
        picFileType: type
      });
    }
  
    const uploadImage = async (type) => {    
      let ret = "";
      let img;
      let fn;
      if (type == 1) {
        img = mobileImage;
        fn = "mobile.png";
      } else if (type == 2) {
        img = desktopImage;
        fn = "desktop.png";
      } else {
        img = profileImage;
        fn = "profile.png";
      }
  
      if (img) {  
        const res = await Request.postImageByDataUrl({
          coverKey: coverKey, 
          locKey: locKey, 
          acctKey: acctKey,
          type: type,
          data: img,
          fn: fn
        });
  
        if (res.success) {
          ret = res.data;
        }
      }
      return ret;
    }
  
    const updateBucket = () => {
      if (mobileImage) uploadImage(1); else deleteImage(1);
      if (desktopImage) uploadImage(2); else deleteImage(2);
      if (profileImage) uploadImage(3); else deleteImage(3);
    }
    
    const onSave = async () => {
      if (!filtersReady) {
        ui.MessageBox("Please select a Cover first");
        return;
      }
  
      if (!isValidEmailAddress(rec.emailSchedTo)) {
        ui.ShowSnackbar('Invalid \'Notification Email\' entry', Severity.error);
        return;
      }
    
      let confirmed = await ui.ConfirmBox("Are you sure you want to save this configuration?");
      if (confirmed == 1) {      
        ui.ShowOverlay();
  
        updateBucket();
  
        let res = await Request.saveGeneral({
          ...rec, 
          endingMesg: endingMsg,
          coverKey: coverKey, 
          locKey: locKey, 
          acctKey: acctKey
        });
  
        if (res.success && res.data) {
          loadData();
  
          let d = res.data;
          let summary = "";
          if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing records";
          if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new records"; 
          
          ui.MessageBox("Preferences were successfully saved." + summary);
        } else {
          ui.ErrorBox(res.error, "Saving Preferences");
        }     
        
        ui.HideOverlay();
      }
     
    };
    
    const onChange = (fieldName, value) => {
      let obj = {};
      obj[fieldName] = value;
  
      let newRec = {...rec, ...obj};   
      
      setRec(newRec);
      updateNotify({
        rec: newRec,
        recBak: recBak,
        inDB: inDB,
        inDBBak: inDBBak,
        isDefaultConfig: isDefaultConfig
      }, true);
    };
  
    const dataIsEqualToDefaultConfig = (data) => {
      let obj1 = confAndFilters();
      return objectsAreEqual(data, obj1);
    }
  
    const updateNotify = (s, reevaluate) => {
      let ret = "";
  
      if (reevaluate) {
        s.isDefaultConfig = dataIsEqualToDefaultConfig(s.rec);
  
        let eq2 = objectsAreEqual(s.recBak, s.rec);
        s.inDB = s.inDBBak && eq2;    
  
        setIsDefaultConfig(s.isDefaultConfig);
        setInDB(s.inDB);
      }
  
      ret += s.isDefaultConfig ? "Default Configuration" : "";
      ret += s.inDB ? (ret == "" ? "" : ". ") + "This setting has already been saved in the Database" : "";
      
      setNotify(ret);
    }
  
    const loadData = async () => {  
      if (coverKey != 0) {
        
        ui.ShowOverlay();
        let res = await Request.getGeneral({coverKey: coverKey, locKey: locKey, acctKey: acctKey});
  
        if (res.success) {
          var p, db, eq;
  
          if (res.data) {      
  
            p = {...confAndFilters(), ...res.data};
            eq = dataIsEqualToDefaultConfig(res.data);        
            db = true;
          } else {
            p = confAndFilters();
            eq = true;
            db = false;
          }
    
          setRec(p);
            setRecBak(p);
            setInDB(db);
            setInDBBak(db);
            setIsDefaultConfig(eq);
      
            updateNotify({
              rec: p,
              recBak: p,
              inDB: db,
              inDBBak: db,
              isDefaultConfig: eq
            });
        } else if (!res.cancelled) {
          ui.ErrorBox(res.error, "Getting Preferences");
        }
  
        ui.HideOverlay();
      }
    };

    useEffect(() => {        
      loadData();   
      // set Tours
      //setIsOpen(false)
      setSteps(TourSteps.tourStepsGeneral)             
  }, [locKey, acctKey]);
  
  
    return (
      <>
      <div className="reporting-box">
        <Box p={2} bgcolor="background.paper" className="page-header"> 
          <Typography variant="h5" >
            General
          </Typography>
        </Box>
        <Divider />
        <Box p={2} bgcolor="background.paper" className="info">
          <Grid container direction="row" justify="flex-start"  alignItems="flex-end" spacing={3}>
            <Grid item xs={12} sm={6}>
              <h3>  
              The following information is Patient Information data:
              </h3>
            </Grid>
          </Grid>        
        </Box>        
        <Divider />
       
        <Box p={2} bgcolor="background.paper" border="1px solid #e0e0e0">
          {notify && <div className="default-config">{notify}</div>}
          
          <General_Info 
            model={rec} 
            setEndingMsg={setEndingMsg} 
            onChange={onChange} 
            setMobileImage={setMobileImage} 
            setDesktopImage={setDesktopImage} 
            setProfileImage={setProfileImage} 
            isValidEmailAddress={isValidEmailAddress}
          />
        </Box>
        <Box pt={1}>
          <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
            <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
              <Button
                variant="contained"
                color={filtersReady ? "primary" : "default"}
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>   
          </Grid>       
        </Box>
      </div>
     </>
    );
  }
  
  