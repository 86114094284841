import React, { useContext, useEffect, useState } from 'react';
import './TabPanels.scss';
import CRMTabsContext from '../CRMTabsContext';
import { useTour } from '@reactour/tour'
import TourSteps from '../../../Store/TourSteps';
import touchApp from 'material-ui/svg-icons/action/touch-app';

const TabPanels = ({componentId, tabHeadComponentId, panels, noContainer}) => {
    const tabContext = useContext(CRMTabsContext);
    const [tabValue, setTabValue] = useState(0);
    const { setIsOpen, setCurrentStep ,setSteps } = useTour();

    tabContext.Subscribe(componentId, tabHeadComponentId, (value) => {
        if (componentId === "registration-forms")
            setIsOpen(false)
        setTabValue(value);
    });

    useEffect(()=>{
        return ()=> {
            tabContext.UnSubscribe(componentId);
        };
    },[]);

    return (
        <>
            {noContainer ? (
                <>{panels[tabValue]}</>
            ) : (
                <div className="pi-tabpanel">
                    {panels[tabValue]}
                </div>
            )}
        </>
    );
};

export default TabPanels;