import React, { useState, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import TimerIcon from '@material-ui/icons/Timer';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Help from '@material-ui/icons/Help';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { useFiltersContext, useUIContext } from '../../../../ContextLib/contextHooks';

import InsuranceList from "./insuranceList.json";
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { ToggleOnTwoTone } from '@material-ui/icons';

const prePropText = 'Number of days out that a patient can schedule an appointment for. 0 will allow them to schedule for the same day. Please note that processing times will be updated for all Insurance carriers';

export default function InsuranceTypes_Form({saveData, setExpanded}) {    
    var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');

    let componentId = "insuranceTypes_Form";
    const filtersContext = useFiltersContext(componentId, (context) => {
        //On filters changed
        setLocKey(context.locKey);
        setAcctKey(context.acctKey);
      });

    const ui = useUIContext(componentId);

    const [locKey, setLocKey] = useState(filtersContext.Values.locKey);
    const [acctKey, setAcctKey] = useState(filtersContext.Values.acctKey);

    const [insName, setInsName] = useState("");
    const [daysBlocked, setDaysBlocked] = useState(0);
    const [orderKey, setOrderKey] = useState(1);
    const [showDisplayOrder, setShowDisplayOrder] = useState(false);

    const useStyles = makeStyles((theme) => ({
        margin: {
            margin: theme.spacing(1),
        },
        root: {
            padding: 5,
            fontWeight: 600,
        },
        addinstype: {
            fontSize: "16px",
            fontWeight: "bold"
        }
    }));

    const classes = useStyles();

    const clear = () => {
        setInsName("");
        setDaysBlocked(0);
    };

    const _saveData = async () => {
        let errors = [];
        if (insName == "") {
            errors.push("Insurance Carrier is required");
        }
        
        if (isNaN(daysBlocked)) {
            errors.push("Invalid Pre-Processing Time entry");
        }

        if (errors.length == 1) {
            ui.ShowSnackbar(errors[0], Severity.error);
            return;
        } else if (errors.length > 1) {
            ui.ShowSnackbar(<ul>{errors.map(x => <li>{x}</li>)}</ul>, Severity.error);
            return;
        }

        const model = { 
            coverKey: coverKey, 
            locKey: locKey, 
            acctKey: acctKey, 
            insName: insName, 
            daysBlocked: daysBlocked,
            orderKey: orderKey  
        };
          
        saveData(model, ()=> {
            clear();
            setExpanded(false);
        });
    };

    const toogleTT = (v) => {
        if (v == false) {
            document.getElementById('daysBlocked-label')?.removeAttribute('tooltip');
        } else {
            document.getElementById('daysBlocked-label')?.toggleAttribute('tooltip');
        }        
    }

    const handleOnInsNameChange = (value) => {
        let _insName = value.trim();
        setInsName(_insName);
        handleToggleDisplayOrder(_insName.toLowerCase());
    }

    const handleToggleDisplayOrder = (strInsName) => {
        if (strInsName === 'no insurance' || strInsName === 'other insurance') {
            setShowDisplayOrder(true);
            setOrderKey(1);
        } else {
            setShowDisplayOrder(false);
            setOrderKey(0);
        }
    }

    useEffect(() => {
        document.onclick = () => {
            toogleTT(false);
        }; 
    }, []);

  return (
    <Box p={2} onClick={() => toogleTT(false)} data-tut="reactour__insurance_carrier">
        <ValidatorForm onSubmit={_saveData} onError={errors => console.log(errors)} >
            <Grid container justify="flex-start" alignItems="center" spacing={2} id="grid-1" >
                <Grid item style={{ width: 250 }} >
                    <Autocomplete
                                freeSolo
                                id="insuranceType"
                                value={insName}
                                disableClearable
                                options={InsuranceList.map((option) => option)}
                                onInputChange={(event, newValue) => {
                                    handleOnInsNameChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Insurance Carrier"
                                        variant="outlined"
                                        validators={['required']}
                                        value={insName}
                                        onInput={(e)=>handleOnInsNameChange(e.target.value)} 
                                        InputProps={{ ...params.InputProps, type: 'search'}}
                                        inputProps={{ ...params.inputProps, maxLength: 50 }}
                                    />
                                )}
                            />
                </Grid>

                <Grid item>
                    <TextField
                        data-tut="reactour__insurance_daysBlocked"
                        id="daysBlocked"
                        value={daysBlocked}
                        onChange={e => setDaysBlocked(parseInt(e.target.value))}
                        label={
                        <>
                            <span>Pre-Processing Time (days)</span>
                            <a href="#" className='help-icon' onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                toogleTT();
                            }}>
                                <Help />
                            </a>
                            <div className='help-icon-tooltip'>{prePropText}</div> 
                        </>}
                        type="number"
                        validators={['required']}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TimerIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />
                </Grid>
                { showDisplayOrder && 
                    <Grid item>
                        <FormControl>
                            <InputLabel id="display-order-label" className="field--label">Display Order</InputLabel>
                            <Select
                                labelId="display-order-label"
                                label="Display Order"
                                id="displayOrder"
                                variant="outlined"
                                value={orderKey}
                                onChange={ e=>setOrderKey(e.target.value)}  
                                >
                                <MenuItem value={1}>Display first</MenuItem>
                                <MenuItem value={-1}>Display last</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item >
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    </Box>
  );
}