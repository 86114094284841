import React, { useEffect, useState, useContext } from 'react';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useFiltersContext, useRequest, useUIContext } from '../../../../ContextLib/contextHooks';
import { Severity } from '../../../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import { objectsAreEqual } from '../../../../ContextLib/Core/coreLib';

import "./PatientInfo.scss";

import Config from "./patientinfo-config.json";
import PatientInfo_Validation from './PatientInfo_Validation';
import PatientInfo_Demographics from './PatientInfo_Demographics';
import PatientInfo_Insurance from './PatientInfo_Insurance';
import PatientInfo_Others from './PatientInfo_Others';

import Tabs from '../../../../CRMComponents/CRMTabs/Tabs';
import TabPanels from '../../../../CRMComponents/CRMTabs/TabPanels/TabPanels';

import { useTour } from '@reactour/tour'
import TourSteps from '../../../../Store/TourSteps'
import {TourServiceContext} from '../../../../Store/TourServiceProvider'

export default function PatientInfo() {
  var coverKey = sessionStorage.getItem('onlinebookingsetup-coverkey');
  const { setIsOpen, setCurrentStep ,setSteps } = useTour();

    const componentId = "patientinfo";
    const ui = useUIContext(componentId);
    const {
      setCurrentTab,
      setTourNext
     } = useContext(TourServiceContext);  

    const filtersContext = useFiltersContext(componentId, async (context) => {
        //On filters changed

        setCover(context.cover);
        setLocKey(context.locKey);
        setAcctKey(context.acctKey);
      });

    const [cover, setCover] = useState(filtersContext.Values.cover);
    const [locKey, setLocKey] = useState(filtersContext.Values.locKey);
    const [acctKey, setAcctKey] =  useState(filtersContext.Values.acctKey);

    const Request = useRequest(); 

    const confAndFilters = () => {    
        return {...Config.fields,
          coverKey: coverKey,
          locKey: locKey,
          acctKey: acctKey};
      };
      
      let p = confAndFilters();
    
      const [rec, setRec] = useState(p);
      const [recBak, setRecBak] = useState(p);
      const [inDB, setInDB] = useState(false);
      const [inDBBak, setInDBBak] = useState(false);
      const [isDefaultConfig, setIsDefaultConfig] = useState(true);
      const [notify, setNotify] = useState("");
        
      const [tabValue, setTabValue] = useState(0);
      const onSave = async () => {    
    
        let confirmed = await ui.ConfirmBox("Are you sure you want to save this configuration?");
        if (confirmed == 1) {      
          ui.ShowOverlay();
    
          let res = await Request.savePatientInfo({
            ...arrangeOthers(rec), 
            CoverKey: coverKey, 
            LocKey: locKey, 
            AcctKey: acctKey
          });
    
          if (res.success && res.data) {
            await loadData();
    
            let d = res.data;
            let summary = "";
            if (d.updated.length > 0) summary += " Updated " + d.updated.length + " existing records";
            if (d.inserted.length > 0) summary += (summary == "" ? "" : " and") + " Added " + d.inserted.length + " new records"; 
            
            ui.ShowSnackbar("Preferences were successfully saved." + summary);
          } else {
            ui.ShowSnackbar(res.error, Severity.error);
          }      
          
          ui.HideOverlay();
        }      
      };
      
      const onChange = (fieldName, value) => {
        let obj = {};
        obj[fieldName] = value;
    
        let newRec = {...rec, ...obj};   
        setRec(newRec);
        updateNotify({
          rec: newRec,
          recBak: recBak,
          inDB: inDB,
          inDBBak: inDBBak,
          isDefaultConfig: isDefaultConfig
        }, true);
      };
    
      const onChange_Multiple = (fieldName, value, fieldName2, value2, fromDelete) => {
        if (fieldName2 === 'notesLbl' || fieldName2 === 'dReferredBy1lbl' || fieldName2 === 'dReferredBy2lbl' ||
            fieldName2 === 'dReferredBy3lbl' || fieldName2 === 'dReferredBy4lbl' || fieldName2 === 'dReferredBy5lbl') {
            value2 = removeExtraQuestionMark(value2);
        }
        let obj = {};
        obj[fieldName] = value;
    
        let obj2 = {};
        obj2[fieldName2] = value2;
    
        let newRec = {...rec, ...obj, ...obj2};   
        
        if (fromDelete) newRec = arrangeOthers(newRec);
        setRec(newRec);
        updateNotify({
          rec: newRec,
          recBak: recBak,
          inDB: inDB,
          inDBBak: inDBBak,
          isDefaultConfig: isDefaultConfig
        }, true);
      }
    
      const isLocked = (fieldName) => {
        return fieldName == "fname" || fieldName == "lname" || Config.locks.some(x => x == fieldName);
      };
    
      const dataIsEqualToDefaultConfig = (data) => {
        let obj1 = confAndFilters();
        return objectsAreEqual(data, obj1);
      }
    
      const updateNotify = (s, reevaluate) => {
        let ret = "";
    
        if (reevaluate) {
          s.isDefaultConfig = dataIsEqualToDefaultConfig(s.rec);
    
          let eq2 = objectsAreEqual(s.recBak, s.rec);
          s.inDB = s.inDBBak && eq2;    
    
          setIsDefaultConfig(s.isDefaultConfig);
          setInDB(s.inDB);
        }
    
        ret += s.isDefaultConfig ? "Default Configuration" : "";
        ret += s.inDB ? (ret == "" ? "" : ". ") + "This setting has already been saved in the Database" : "";
        
        setNotify(ret);
      }
    
      const loadData = async () => {    
        ui.ShowOverlay();
          let res = {};
          res = await Request.getPatientInfo({coverKey: coverKey, locKey: locKey, acctKey: acctKey});
    
          if (res.success) {
            var p, db, eq;
    
            if (res.data) {        
    
              //TEMPORARY
              if (res.data.vL4DSSN == -1) res.data.vL4DSSN = 0;
              if (res.data.vWholeAddress == -1) res.data.vWholeAddress = 0;
    
              p = {...confAndFilters(), ...res.data};
              eq = dataIsEqualToDefaultConfig(res.data);        
              db = true;
            } else {
              p = confAndFilters();
              eq = true;
              db = false;
            }
      
            setRec(p);
              setRecBak(p);
              setInDB(db);
              setInDBBak(db);
              setIsDefaultConfig(eq);
        
              updateNotify({
                rec: p,
                recBak: p,
                inDB: db,
                inDBBak: db,
                isDefaultConfig: eq
              });
          } else if (!res.cancelled) {
            ui.ShowSnackbar(res.error, Severity.error);
          }
    
          ui.HideOverlay();
      };
    
      const arrangeOthers = (model) => {
        let tmp = [
          {a: model.notesFld, b: model.notesLbl},
          {a: model.dReferredBy1, b: model.dReferredBy1lbl},
          {a: model.dReferredBy2, b: model.dReferredBy2lbl},
          {a: model.dReferredBy3, b: model.dReferredBy3lbl},
          {a: model.dReferredBy4, b: model.dReferredBy4lbl},
          {a: model.dReferredBy5, b: model.dReferredBy5lbl}
        ];
    
        tmp = tmp.filter(x => x.b.trim() != "");
        let dummy = {
          notesFld: -1,
          dReferredBy1: -1,
          dReferredBy2: -1,
          dReferredBy3: -1,
          dReferredBy4: -1,
          dReferredBy5: -1,
          notesLbl: "",      
          dReferredBy1lbl: "",
          dReferredBy2lbl: "",
          dReferredBy3lbl: "",
          dReferredBy4lbl: "",
          dReferredBy5lbl: ""
        }
    
        tmp.forEach((x, i)=>{
          if (i == 0) {
            dummy.notesFld=x.a;
            dummy.notesLbl=appendQuestionMark(x.b);
          } else if (i == 1) {
            dummy.dReferredBy1=x.a;
            dummy.dReferredBy1lbl=appendQuestionMark(x.b);
          } else if (i == 2) {
            dummy.dReferredBy2=x.a;
            dummy.dReferredBy2lbl=appendQuestionMark(x.b);
          } else if (i == 3) {
            dummy.dReferredBy3=x.a;
            dummy.dReferredBy3lbl=appendQuestionMark(x.b);
          } else if (i == 4) {
            dummy.dReferredBy4=x.a;
            dummy.dReferredBy4lbl=appendQuestionMark(x.b);
          } else if (i == 5) {
            dummy.dReferredBy5=x.a;
            dummy.dReferredBy5lbl=appendQuestionMark(x.b);
          }    
        });
    
        return {...model, ...dummy};
      }

      const appendQuestionMark = (customQuestionValue) => {
        if (customQuestionValue.slice(-1) !== "?") {
          return customQuestionValue + "?";
        }
        return customQuestionValue;
      }

      const removeExtraQuestionMark = (customQuestionValue) => {
        let valueLength = customQuestionValue.length - 1;
        if (customQuestionValue.slice(-2) === '??') {
           return customQuestionValue.substring(0, valueLength);
        }
        return customQuestionValue;
      }

      useEffect(() => {        
          loadData();      
          // set Tours
          //setIsOpen(false)
          setSteps(TourSteps.tourStepsPatient) 
          setCurrentTab(componentId)               
      }, [locKey, acctKey]);


    const tabProps = {
      componentId: 'patient-info-tabs',
      items: ['Patient Validation','Patient Demographics','Patient Insurance','Other'],
      baseBgColor:'var(--app-color-light)',
      tabStyle:{
        color: 'gray',
        bgColor: '#F4F5F8'
      },
      activeTabStyle:{
        bgColor: 'white',
        color: '#3f51b5'
      },
      onClick: setTabValue
    };

    const tabPanelsProps = {
      componentId: "patient-info-panels", 
      tabHeadComponentId: "patient-info-tabs",
      panels: [
        <PatientInfo_Validation model={rec} isLocked={isLocked} onChange={onChange} />,
        <PatientInfo_Demographics model={rec} isLocked={isLocked} onChange={onChange} />,
        <PatientInfo_Insurance model={rec} isLocked={isLocked} onChange={onChange} />,
        <PatientInfo_Others model={rec} onChangeMultiple={onChange_Multiple} />
      ]
    };   
    
    return (<>
        {cover ? (<>
                            
                <Box p={2} className='patient-info'>
                    {notify && <div className="default-config">{notify}</div>}
                    
                    <Tabs {...tabProps}/>
                    <TabPanels {...tabPanelsProps}/>                    
                    
                  <Box pt={1}>
                      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
                          <Grid item xs={12} sm={12} style={{textAlign:"right"}}>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={onSave}
                              >
                                  Save
                              </Button>
                          </Grid>   
                      </Grid>       
                  </Box>
                </Box>
 
        </>) : (<>
            {ui.uiJSX.loadingJSX}
        </>)}        
    </>);
}